import React from 'react';
import { Switch } from 'react-router-dom';
import { AdminLoggedLayout, LoggedLayout, NonLoggedLayout, LoginLayout, PublicLayout, PublicLayoutCargo } from './layouts';
import {
  AdminSettings,
  AdminSettingIndex,
  AdminCargoLeadIndex,
  AdminOurFerries,
  CargoAvailability,
  CargoServices,
  CargoLeads,
  CargoContact,
  CargoTermsAndConditions,
  CargoItineraries,
  Login,
  Home,
  HomeCargo,
  HomeContact,
  HomePartnersContact,
  Register,
  RecoverPassword,
  ResetPassword,
  Page,
  Profile,
  ProfileEdit,
  PublicError,
  Faqs,
  FaqCategoryIndex,
  FaqCategoryNew,
  FaqCategoryEdit,
  FaqIndex,
  FaqNew,
  FaqEdit,
  IncludedElementIndex,
  IncludedElementNew,
  IncludedElementEdit,
  AttractionsIndex,
  AttractionNew,
  AttractionEdit,
  AdvisorsIndex,
  AdvisorNew,
  AdvisorEdit,
  OfficesIndex,
  OfficeNew,
  OfficeEdit,
  Blogs,
  BlogIndex,
  BlogNew,
  BlogEdit,
  BlogTemplates,
  LeadsIndex,
  GuidesIndex,
  GuideNew,
  GuideEdit,
  GalleriesIndex,
  GalleryNew,
  GalleryEdit,
  ReviewIndex,
  ReviewNew,
  ReviewShow,
  ReviewEdit,
  RouteIndex,
  RouteNew,
  RouteEdit,
  RouteShow,
  SlideIndex,
  SlideNew,
  SlideShow,
  SlideEdit,
  SlidesHero,
  TheRoute,
  UserIndex,
  UserNew,
  UserEdit,
  Videos,
  VideosIndex,
  VideoNew,
  VideoEdit,
  PageIndex,
  PageNew,
  PageEdit,
  PageTemplates,
  Guides,
  GuideShow,
  GalleryShow,
  VideosGuidesGalleries,
  TourismRates,
  Dashboard,
  Landing,
  NavbarAndFooter,
  EbooksAndVideos,
  PagesAndBlogs,
  FaqsAndCategories,
  RouteAndIncludedElements,
  CargoRates,
  OurFerries,
  OurLeaderIndex,
  OurLeaderEdit,
  OurLeaderNew,
  OurLeaders,
  FormSuccess,
  FormError
} from '../screens';

const routes = (
  <Switch>
    {/* Platform routes */}
    <LoggedLayout exact path="/home" component={Home} />
    <AdminLoggedLayout exact path="/admin/dashboard" component={Dashboard} />
    <AdminLoggedLayout exact path="/admin/navbar_and_footer" component={NavbarAndFooter} />
    <AdminLoggedLayout exact path="/admin/ebooks_and_videos" component={EbooksAndVideos} />
    <AdminLoggedLayout exact path="/admin/pages_and_blogs" component={PagesAndBlogs} />
    <AdminLoggedLayout exact path="/admin/tourism_faqs_and_categories" component={(props) => <FaqsAndCategories {...props} categoryType='tourism' />} />
    <AdminLoggedLayout exact path="/admin/cargo_faqs_and_categories" component={ (props) => <FaqsAndCategories {...props} categoryType='cargo'/> }/>
    <AdminLoggedLayout exact path="/admin/routes_and_included" component={(props) => <RouteAndIncludedElements {...props} routeType='both' />} />
    <AdminLoggedLayout exact path="/admin/cargo_routes_and_included" component={ (props) => <RouteAndIncludedElements {...props} routeType='cargo'/> }/>
    <AdminLoggedLayout exact path="/admin/landing" component={Landing} />
    <AdminLoggedLayout exact path="/admin/profile" component={Profile} />
    <AdminLoggedLayout exact path="/admin/profile/edit" component={ProfileEdit} />
    <AdminLoggedLayout exact path="/admin/attractions" component={AttractionsIndex} />
    <AdminLoggedLayout exact path="/admin/attractions/new" component={AttractionNew} />
    <AdminLoggedLayout exact path="/admin/attractions/:id/edit" component={AttractionEdit} />
    <AdminLoggedLayout exact path="/admin/advisors" component={AdvisorsIndex} />
    <AdminLoggedLayout exact path="/admin/advisors/new" component={AdvisorNew} />
    <AdminLoggedLayout exact path="/admin/advisors/:id/edit" component={AdvisorEdit} />
    <AdminLoggedLayout exact path="/admin/offices" component={OfficesIndex} />
    <AdminLoggedLayout exact path="/admin/offices/new" component={OfficeNew} />
    <AdminLoggedLayout exact path="/admin/offices/:id/edit" component={OfficeEdit} />
    <AdminLoggedLayout exact path="/admin/blogs" component={BlogIndex} />
    <AdminLoggedLayout exact path="/admin/blogs/new" component={BlogNew} />
    <AdminLoggedLayout exact path="/admin/blogs/:id/edit" component={BlogEdit} />
    <AdminLoggedLayout exact path="/admin/blogs/templates" component={BlogTemplates} />
    <AdminLoggedLayout exact path="/admin/leads" component={LeadsIndex} />
    <AdminLoggedLayout exact path="/admin/galleries" component={GalleriesIndex} />
    <AdminLoggedLayout exact path="/admin/galleries/new" component={GalleryNew} />
    <AdminLoggedLayout exact path="/admin/galleries/:id/edit" component={GalleryEdit} />
    <AdminLoggedLayout exact path="/admin/guides" component={GuidesIndex} />
    <AdminLoggedLayout exact path="/admin/guides/new" component={GuideNew} />
    <AdminLoggedLayout exact path="/admin/guides/:id/edit" component={GuideEdit} />
    <AdminLoggedLayout exact path="/admin/faq_categories" component={FaqCategoryIndex} />
    <AdminLoggedLayout exact path="/admin/faq_categories/new" component={FaqCategoryNew} />
    <AdminLoggedLayout exact path="/admin/faq_categories/:id/edit" component={FaqCategoryEdit} />
    <AdminLoggedLayout exact path="/admin/faqs" component={FaqIndex} />
    <AdminLoggedLayout exact path="/admin/faqs/new" component={FaqNew} />
    <AdminLoggedLayout exact path="/admin/faqs/:id/edit" component={FaqEdit} />
    <AdminLoggedLayout exact path="/admin/included_elements" component={IncludedElementIndex} />
    <AdminLoggedLayout exact path="/admin/included_elements/new" component={IncludedElementNew} />
    <AdminLoggedLayout exact path="/admin/included_elements/:id/edit" component={IncludedElementEdit} />
    <AdminLoggedLayout exact path="/admin/reviews" component={ReviewIndex} />
    <AdminLoggedLayout exact path="/admin/reviews/new" component={ReviewNew} />
    <AdminLoggedLayout exact path="/admin/reviews/:id" component={ReviewShow} />
    <AdminLoggedLayout exact path="/admin/reviews/:id/edit" component={ReviewEdit} />
    <AdminLoggedLayout exact path="/admin/routes" component={RouteIndex} />
    <AdminLoggedLayout exact path="/admin/routes/new" component={RouteNew} />
    <AdminLoggedLayout exact path="/admin/routes/:id/edit" component={RouteEdit} />
    <AdminLoggedLayout exact path="/admin/tourism_slides" component={(props) => <SlideIndex {...props} categoryType='tourism_slide' />} />
    <AdminLoggedLayout exact path="/admin/slides" component={(props) => <SlideIndex {...props} categoryType='all' />} />
    <AdminLoggedLayout exact path="/admin/slides/new"  component={(props) => <SlideNew {...props} />} />
    <AdminLoggedLayout exact path="/admin/slides/:id" component={SlideShow} />
    <AdminLoggedLayout exact path="/admin/slides/:id/edit" component={SlideEdit} />
    <AdminLoggedLayout exact path="/admin/videos" component={VideosIndex} />
    <AdminLoggedLayout exact path="/admin/videos/new" component={VideoNew} />
    <AdminLoggedLayout exact path="/admin/videos/:id/edit" component={VideoEdit} />
    <AdminLoggedLayout exact path="/admin/pages" component={PageIndex} />
    <AdminLoggedLayout exact path="/admin/pages/new" component={PageNew} />
    <AdminLoggedLayout exact path="/admin/pages/:id/edit" component={PageEdit} />
    <AdminLoggedLayout exact path="/admin/pages/templates" component={PageTemplates} />
    <AdminLoggedLayout exact path="/admin/users" component={UserIndex} />
    <AdminLoggedLayout exact path="/admin/users/new" component={UserNew} />
    <AdminLoggedLayout exact path="/admin/users/:id/edit" component={UserEdit} />
    <AdminLoggedLayout exact path="/admin/settings_cargo" component={() => <AdminSettingIndex settingType='cargo' />} />
    <AdminLoggedLayout exact path="/admin/tourism_our_ferries" component={(props) => <AdminOurFerries {...props} categoryType='our_ferries_category' />} />
    <AdminLoggedLayout exact path="/admin/settings" component={AdminSettings} />
    <AdminLoggedLayout exact path="/admin/cargo_navbar_and_footer" component={(props) => <NavbarAndFooter {...props} cargo />} />
    <AdminLoggedLayout exact path="/admin/cargo_leads" component={AdminCargoLeadIndex} />
    <AdminLoggedLayout exact path="/admin/our_leaders" component={OurLeaderIndex} />
    <AdminLoggedLayout exact path="/admin/our_leaders/new" component={OurLeaderNew} />
    <AdminLoggedLayout exact path="/admin/our_leaders/:id/edit" component={OurLeaderEdit} />
    <AdminLoggedLayout exact path="/admin/tourism_slides_hero" component={(props) => <SlidesHero {...props} categoryType='tourism_hero'/>} />
    <AdminLoggedLayout exact path="/admin/cargo_slides_hero" component={(props) => <SlidesHero {...props} categoryType='cargo_hero' />} />
    
    <LoginLayout path="/login" component={Login} />
    <NonLoggedLayout exact path="/register" component={Register} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />
    <NonLoggedLayout exact path="/recover_password" component={RecoverPassword} />
    <PublicLayout exact path="/" component={Home} />
    <PublicLayoutCargo cargo exact path="/cargo" component={HomeCargo} />
    <PublicLayoutCargo cargo exact path="/cargo/leads" component={CargoLeads} />
    <PublicLayoutCargo cargo exact path="/cargo/availability" component={CargoAvailability} />
    <PublicLayoutCargo cargo exact path="/cargo/services" component={CargoServices} />
    <PublicLayoutCargo cargo exact path="/cargo/contact" component={CargoContact} />
    <PublicLayoutCargo cargo exact path='/cargo/rates' component={CargoRates} />
    <PublicLayoutCargo cargo exact path='/cargo/terms-and-conditions' component={CargoTermsAndConditions} />
    <PublicLayoutCargo cargo exact path="/cargo/faqs" component={(props) => <Faqs {...props} categoryType='cargo' />} />
    <PublicLayoutCargo cargo exact path='/cargo/itineraries' component={CargoItineraries}/>
    <PublicLayout exact path="/videos-guides" component={VideosGuidesGalleries} />
    <PublicLayout exact path="/tourism-rates" component={TourismRates} />
    <PublicLayout exact path="/videos" component={Videos} />
    <PublicLayout exact path="/guides" component={Guides} />
    <PublicLayout exact path="/guides/:id" component={GuideShow} />
    <PublicLayout exact path="/galleries/:id" component={GalleryShow} />
    <PublicLayout exact path="/public/blogs" component={Blogs} />
    <PublicLayout exact path="/public/faqs" component={(props) => <Faqs {...props} categoryType='tourism' />} />
    <PublicLayout exact path="/public/429" component={PublicError} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/partners-contact" component={HomePartnersContact} />
    <PublicLayout exact path="/routes/:id" component={RouteShow} />
    <PublicLayout exact path='/the-route' component={TheRoute} />
    <PublicLayout exact path='/our-ferries' component={OurFerries} />
    <PublicLayout exact path='/our-leaders' component={OurLeaders} />
    <PublicLayout exact path='/form/success' component={FormSuccess} />
    <PublicLayout exact path='/form/error' component={FormError} />
    <PublicLayout path="/*" component={Page} />
  </Switch >
);

export default routes;
