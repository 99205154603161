import * as Yup from 'yup';
import '../../../services/yupCustomMethods';

const validationSchema = Yup.object().shape({
  cargoAvailability: Yup.object().shape({
    routeId: Yup.string().required('Debes seleccionar una ruta'),
    shipId: Yup.string().required('Debes seleccionar un barco')
  })
});

export default validationSchema;
