import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

import { deleteAdminGuideRequest } from '../../../requests/admin/adminGuides';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false,
    withCopyClipboard = false,
    copyText = 'Copiar url de la guía en el portapapeles'
  }) => [
    {
      name: 'Posición',
      selector: 'position',
      sortable: true,
      cell: ({ position }) => position,
      grow: '.5'
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      cell: ({ title }) => title,
      grow: '1.5'
    },
    {
      name: 'Descripción',
      selector: 'description',
      sortable: true,
      cell: ({ description }) => `${description.slice(0, 20)}...`,
      grow: '1.5'
    },
    {
      name: 'Activo',
      selector: 'active',
      grow: '.5',
      sortable: true,
      cell: ({ active }) => (active ? 'Sí' : 'No')
    },
    {
      name: 'Url de la guía',
      selector: 'guide_show_url',
      sortable: true,
      cell: ({ guideShowUrl }) =>
        guideShowUrl ? (
          <a href={`${window.location.origin}/guides/${guideShowUrl}`}>
            {guideShowUrl}
          </a>
        ) : (
          '-'
        ),
      grow: '1.5'
    },
    {
      name: 'Página de la guía',
      cell: ({ pageUrl }) => {
        if (pageUrl) {
          return (
            <a href={`${window.location.origin}/${pageUrl}`}>{`${pageUrl.slice(
              0,
              30
            )}...`}</a>
          );
        }
        return 'No hay información';
      },
      grow: '1.5'
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
          withCopyClipboard={withCopyClipboard}
          showClick={showClick}
          editClick={editClick}
          copyText={copyText}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const GuidesDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminGuideRequest,
    onDeleteMessage: 'Guía eliminada exitosamente',
    confirmationText: '¿Estás seguro de eliminar esta guía?',
    modalTitle: 'Eliminar Guía'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      case 'show':
        window.location.href = `${window.location.origin}/guides/${item.guideShowUrl}`;
        break;
      case 'copyClipboard':
        navigator.clipboard
          .writeText(`${window.location.origin}/guides/${item.guideShowUrl}`)
          .then(() => {
            // eslint-disable-next-line no-alert
            alert('Link copiado al portapapeles');
          });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'position':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: true,
          withEdit: true,
          withDestroy: true,
          showClick: 'show',
          withCopyClipboard: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not needed
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        RowsPage={10}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(GuidesDataTable));
