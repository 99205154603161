import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import SEO from '../../components/Utils/SEO/SEO';
import error from '../../assets/images/error.svg';
import { FormStatus } from '../../components';

const FormSuccess = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Row className="justify-content-center align-items-center full-height">
      <SEO
        title={t('tourism.form.error.seo.title')}
        description={t('tourism.form.error.seo.description')}
      />
      <FormStatus
        statusImageSource={error}
        alertText={t('tourism.form.error.body.alertText')}
        mainText={t('tourism.form.error.body.mainText')}
        descriptionText={t('tourism.form.error.body.descriptionText')}
        buttonText={t('tourism.form.error.body.buttonText')}
        buttonOnPress={handleGoBack}
      />
    </Row>
  );
};

export default connect()(FormSuccess);
