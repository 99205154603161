import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormikInputRadio = ({
  abbr,
  label,
  margin,
  field,
  direction,
  delayShow,
  delayHide,
  onClick,
  options = [],
  fieldName = '',
  setData = () => {},
  columnClass = 'two-columns',
  customFormLabel = '',
  ...props
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <section>
      {label && (
        <Form.Label className={`${customFormLabel}`}>
          {label} {abbr && <abbr className="text-danger">*</abbr>}
        </Form.Label>
      )}
      <Form.Group className="form-radio-input" {...props}>
        <ul
          className={`remove-padding-left small-screen-responsiveness ${columnClass}`}
        >
          {options.map(option => (
            <div
              key={`${option.label}_${option.value}`}
              className="d-flex justify-content-start align-items-center mb-2"
            >
              <Form.Label
                htmlFor={option.value}
                className={`custom-radio-label m-1 ${
                  String(field.value) === String(option.value) ? 'checked' : ''
                }`}
              />
              <div className="cargo-lead-input-option-text">
                <Form.Check
                  key={`${option.label}_${option.value}`}
                  {...field}
                  type="radio"
                  label={option.label}
                  value={option.value}
                  name={field.name}
                  id={option.value}
                  style={{
                    paddingLeft: 5
                  }}
                  onClick={() => {
                    if (!fieldName) return;
                    setData(option);
                    setFieldValue(`${fieldName}`, option?.value || '');
                  }}
                />
              </div>
            </div>
          ))}
        </ul>
      </Form.Group>
    </section>
  );
};

FormikInputRadio.propTypes = {
  direction: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number
};

FormikInputRadio.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0
};
export default FormikInputRadio;
