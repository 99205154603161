import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSidebar } from '../../../actions/utils';
import { ButtonComponent } from '../Button';
import IcoMoon from '../Icon/IcoMoon';

const InnerNavbar = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const publicRoutes = ['', 'components', 'contact', 'page'];
  const { user } = useSelector(state => state.auth);

  return (
    <Navbar
      className="navbar-transparent"
      expand="lg"
      fixed="top"
      id="navbar-transparent"
    >
      {user.id > 0 && !publicRoutes.includes(path[1]) ? (
        <ButtonComponent
          typeButton="Icon"
          icon="menu"
          iconSize={32}
          className="hamburger-menu icon"
          variant="outline-info"
          iconColor="black"
          onClick={() => dispatch(toogleSidebar())}
        />
      ) : (
        <Navbar.Toggle className="p-0" aria-controls="basic-navbar-nav">
          <IcoMoon icon="menu" size={32} />
        </Navbar.Toggle>
      )}
      {children}
    </Navbar>
  );
};

export default InnerNavbar;
