import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Sidebar from '../../components/Utils/Sidebar';

const LoggedLayout = ({ component: Component, ...rest }) => {
  const { sidebarOpen } = useSelector(state => state.utils);

  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        if (!payload)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );

        return (
          <>
            <Sidebar sidebarOpen={sidebarOpen} />
            <div className={`${sidebarOpen ? '' : 'content-body'}`}>
              <Container className="container-layout pt-5" fluid>
                <Component {...props} />
              </Container>
            </div>
          </>
        );
      }}
    />
  );
};

export default LoggedLayout;
