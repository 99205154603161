import ApiService from '../services/apiService';

export const indexPagesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/pages${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showPagesRequest = (
  url,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/pages/${url}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
