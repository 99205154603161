// navimag_frontend/src/app/requests/admin/adminGalleries.js
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminGalleryRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/galleries/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminGalleriesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/galleries${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminGalleryRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/admin/galleries', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminGalleryRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/galleries/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminGalleryRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/admin/galleries/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminGalleriesRequest = AwesomeDebouncePromise(
  indexAdminGalleriesRequest,
  300
);
