const basicUser = {
  id: undefined,
  active: true,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  roleUserAttributes: {
    id: undefined,
    roleId: undefined
  }
};

export default basicUser;
