import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';

const customStyles = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isSelected ? '#8f0b1d' : 'white',
    fontSize: '12px',
    '&:hover': {
      backgroundColor: 'rgba(145, 145, 145, 0.415)',
      cursor: 'pointer'
    }
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    borderRadius: '10px',
    boxShadow: state.isFocused
      ? '0 0 0 0.2rem rgba(214, 214, 214, 0.551);'
      : 'none',
    border: state.isFocused
      ? '1px solid #1b1b1b'
      : '1px solid rgba(0, 0, 0, 0.296)',
    '&:hover': {
      border: state.isFocused
        ? '1px solid #1b1b1b'
        : '1px solid rgba(0, 0, 0, 0.296)'
    }
  })
};

const handleDefaultMultiValue = (value, defaultValue, defaultMultiValues) => {
  if (defaultValue && value && value === defaultValue) {
    return defaultMultiValues;
  }
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  if (isMulti) {
    return handleDefaultMultiValue(value, defaultValue, defaultMultiValues);
  }
  if (
    defaultValue &&
    value &&
    defaultValue !== '' &&
    value !== '' &&
    value === defaultValue
  ) {
    const vAttribute = options.filter(e => defaultValue === e.value);
    return vAttribute[0];
  }
  if (value === '') return null;
  return undefined;
};

const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  placeholder,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Form.Group
      controlId={label}
      className={`${isDisabled ? 'disabled' : ''} ${
        error && touched ? 'is-invalid' : ''
      }`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on' : ''}`}
        placeholder={placeholder || 'Buscar...'}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        loadingMessage={() => 'Cargando...'}
        noOptionsMessage={() => 'No hay opciones'}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        styles={customStyles}
        {...props}
      />
      {error && touched && (
        <Form.Text className="text-danger">{error}</Form.Text>
      )}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikSelect.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};

export default FormikSelect;
