import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';
import SEO from '../../../components/Utils/SEO/SEO';

import { useWindowSize } from '../../../hooks';
import FirstSection from '../Landing/FirstSection';
import CargoLeadForm from './CargoLeadForm';
import createCargoLeadRequest from '../../../requests/cargoLead';
import { indexRoutesRequest } from '../../../requests/routes';
import { DefaultModal } from '../../../components';
import SuccessCard from '../../../components/Utils/Cards/SuccessCard';
import { uuidV4 } from '../../../services/utils';

const CargoLeads = props => {
  const [cargoLead, setCargoLead] = useState({
    routeId: '',
    cargoRateId: '',
    linealLength: '',
    roundTrip: '',
    name: '',
    email: '',
    rut: '',
    nationality: '',
    acceptFutureCommunications: false
  });
  const [routes, setRoutes] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const formRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customSlidesStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '40vh',
    overflow: 'hidden',
    zIndex: -1000
  };

  const routesBuilder = routesData => {
    const routesArray = [];
    routesData.forEach(routeData => {
      const originId = uuidV4();
      routesArray.push({
        ...routeData,
        routeId: routeData.id,
        id: originId,
        value: originId,
        routeOrigin: routeData.origin
      });

      const destinationId = uuidV4();
      const mirrorRoute = {
        ...routeData,
        label: `${routeData.destination} - ${routeData.origin}`,
        value: destinationId,
        routeId: routeData.id,
        id: destinationId,
        routeOrigin: routeData.destination
      };
      routesArray.push(mirrorRoute);
    });
    setRoutes(routesArray);
  };

  const reviewRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        route_type_filter: 'cargo',
        sort_column: 'position',
        language: 'es',
        serializer_selector: 'CargoRoutesLeadsSerializer',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        routesBuilder(camelCaseRecursive(result.data.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const [isDesktop, setIsDesktop] = useState(true);
  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  console.log(isDesktop);

  const handleSuccessCreate = () => {
    setShowSuccessModal(true);
  };

  const handleCreateCargoLeadRequest = _cargoLead => {
    createCargoLeadRequest({
      dispatch,
      params: _cargoLead,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  const updateInitialValues = () => {
    if (routes.length) {
      setCargoLead({
        ...cargoLead,
        routeId: routes[0].id
      });
    }
  };

  const handleCloseRedirect = () => {
    setShowSuccessModal(false);
    const { history } = props;
    history.push('/cargo');
  };

  useEffect(updateInitialValues, [routes]);
  useEffect(reviewRequestWrapper, []);

  return (
    <div className="mb-5">
      <SEO
        title="Navimag Carga - Precotizador"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Cotizador"
        subtitle="Realiza ahora tu cotización de vehículos, camiones o carga vía teléfono o mail."
        slideType="cargo_leads"
        customHeroStyle="cargo-leads-custom-hero"
        customSlidesStyles={customSlidesStyles}
      />

      <Row className="justify-content-center">
        <Col className="cargo-lead-form-container">
          <CargoLeadForm
            cargoLead={cargoLead}
            setCargoLead={setCargoLead}
            action="new"
            formRequest={handleCreateCargoLeadRequest}
            routes={routes}
            formRef={formRef}
          />
        </Col>
      </Row>
      <DefaultModal
        title=""
        body={
          <SuccessCard
            title="¡Tu cotización fue enviada con éxito!"
            text="Te enviaremos la información solicitada los más pronto posible"
            btnText="¡Entendido!"
            onClick={handleCloseRedirect}
          />
        }
        handleClose={handleCloseRedirect}
        show={showSuccessModal}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(CargoLeads);
