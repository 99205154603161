import React, { useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { withFormik, Form, getIn, useFormikContext } from 'formik';
import snakecaseKeys from 'snakecase-keys';

import {
  validationSchema,
  firstStepFields,
  secondStepFields
} from './CargoLeadFormValidations';
import FirstPageCargoLeadsForm from './FirstPageCargoLeadsForm';
import SecondPageCargoLeadsForm from './SecondPageCargoLeadsForm';
import CargoLeadFormStepMap from '../../../components/Cargo/CargoLeads/CargoLeadStepMapForm';

const CargoLeadForm = ({
  errors,
  isSubmitting,
  setFieldTouched,
  setSubmitting,
  formRef,
  ...props
}) => {
  const { handleSubmit, routes } = props;
  const submitBtnText = 'Cotizar';
  const modelName = 'cargoLead';
  const [step, setStep] = useState(1);

  const { values, isValid } = useFormikContext();
  const checkStep = stepFields => {
    const stepErrors = getIn(errors, modelName);
    if (!stepErrors) return true;
    const result = stepFields.filter(value => stepErrors[value]);
    return result.length === 0;
  };

  const checkFirstStep = () => {
    return checkStep(firstStepFields);
  };

  const firstStepTouch = () => {
    firstStepFields.forEach(field =>
      setFieldTouched(`${modelName}[${field}]`, true)
    );
  };

  const checkSecondStep = () => {
    return checkStep(secondStepFields);
  };

  const secondStepTouch = () => {
    secondStepFields.forEach(field =>
      setFieldTouched(`${modelName}[${field}]`, true)
    );
  };

  const validStep = stepToValidate => {
    const validations = { 1: checkFirstStep, 2: checkSecondStep };
    const showErrors = { 1: firstStepTouch, 2: secondStepTouch };
    const validation = validations[stepToValidate];
    if (validation) {
      if (!validation()) {
        const showError = showErrors[stepToValidate];
        showError();
        return false;
      }
    }
    return true;
  };

  const toggleStep = ({ back = false }) => {
    let steps = { 0: 1, 1: 2 };
    const limitStep = back ? 0 : 2;
    if (back) {
      steps = { 2: 1 };
      setStep(steps[step] || limitStep);
    }

    if (validStep(step)) {
      setStep(steps[step] || limitStep);
    }
  };

  const handleOnSubmit = () => {
    handleSubmit();
  };

  return (
    <Row
      ref={formRef}
      className="justify-content-md-center cargo-lead-main-form-container"
    >
      <Col md={11}>
        <Form>
          <Row className="mb-5">
            <Col>
              <h3 className="form-title mb-2">
                Transporta con la experiencia y seguridad de Navimag
              </h3>
              <p className="form-description">
                Déjanos tus datos y uno de nuestros agentes comerciales te
                contactará dentro de 72 horas hábiles y te confirmará el precio
                final.
              </p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col>
              <h4 className="form-subtitle">
                Cotiza ahora y recibe el precio provisorio en tu mail
              </h4>
            </Col>
          </Row>

          <Row className="justify-content-md-center">
            <Col md={10}>
              <CargoLeadFormStepMap step={step} />

              {step === 1 && (
                <FirstPageCargoLeadsForm
                  modelName={modelName}
                  routes={routes}
                />
              )}
              {step === 2 && <SecondPageCargoLeadsForm modelName={modelName} />}
            </Col>
          </Row>

          <Row className="d-flex align-items-center justify-content-md-center">
            <Col md={10}>
              <Row className="d-flex justify-content-between">
                <Col md={3}>
                  {step > 1 && (
                    <Button
                      block
                      variant="primary-outline"
                      onClick={() => {
                        formRef.current.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start'
                        });
                        toggleStep({ back: true });
                      }}
                    >
                      Volver
                    </Button>
                  )}
                </Col>

                <Col md={3}>
                  {step === 2 ? (
                    <Button
                      disabled={!isValid && isSubmitting}
                      block
                      variant="primary"
                      onClick={handleOnSubmit}
                    >
                      {isSubmitting ? (
                        <Spinner animation="border" role="status" size="sm">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      ) : (
                        submitBtnText
                      )}
                    </Button>
                  ) : (
                    <Button
                      block
                      variant="primary"
                      onClick={() => {
                        setTimeout(
                          () =>
                            formRef.current.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start'
                            }),
                          10
                        );
                        toggleStep({ back: false });
                      }}
                      disabled={
                        values.cargoLead.cargoRouteId === '' ||
                        (values.cargoLead.roundTrip === '' &&
                          values.cargoLead.linealLength === '')
                      }
                    >
                      Siguiente
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

const setInitialValues = ({ cargoLead }) => {
  return {
    cargoLead: {
      ...cargoLead
    }
  };
};

const handleSubmit = (values, { props }) => {
  const selectedRoute = props.routes.find(
    route => route.id === values.cargoLead.routeId
  );
  const paramsToSend = snakecaseKeys({
    cargoLead: {
      ...values.cargoLead,
      routeId: selectedRoute.routeId,
      routeOrigin: selectedRoute.routeOrigin
    }
  });
  const { formRequest, onHide } = props;

  formRequest(paramsToSend);
  if (onHide) onHide();
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  handleSubmit,
  validationSchema,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(CargoLeadForm);
