import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { redirectToReservationWithoutParams } from '../../../services/utils';
import IncludedList from './IncludedList';
import ItineraryCard from './ItineraryCard';
import SidebarRouteInfo from './SidebarRouteInfo';
import { showRouteRequest } from '../../../requests/routes';

const RouteCard = ({
  route,
  availableTourismItineraries,
  tourismSeasonStart,
  tourismSeasonEnd,
  isDestination = false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);

  const routeName = isDestination
    ? `${route.destination} - ${route.origin}`
    : `${route.origin} - ${route.destination}`;
  const routeDuration = isDestination
    ? route.destinationDuration
    : route.routeDuration;
  const routeCheckIn = isDestination
    ? route.destinationCheckin
    : route.routeCheckIn;

  const getSailDate = routeNameData => {
    return (
      availableTourismItineraries
        .filter(itinerary => {
          const date = new Date(itinerary.fechaZarpe);
          return tourismSeasonStart < date && date < tourismSeasonEnd;
        })
        .find(itinerary => routeNameData.includes(itinerary.routeDestination))
        ?.fechaZarpe || null
    );
  };

  const sailDate = getSailDate(routeName);

  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  function fetchRoute(id) {
    showRouteRequest(id, {
      dispatch,
      successCallback: fetchedRoute =>
        window.open(fetchedRoute.data.attached_brochure.file_url)
    });
  }

  const openBrochure = id => {
    fetchRoute(id);
  };

  return (
    <Col className="route-card-section p-4 m-2">
      <h6 className="text-left text-primary mb-3">{routeName}</h6>
      <p className="subtitle">{routeDuration}</p>
      <p className="font-check-in">{routeCheckIn}</p>
      <div className="route-card-section-included-list">
        <p className="subtitle mt-3 mb-1">
          {t('tourism.landing.routesSection.includes')}
        </p>
        <IncludedList includedElements={route.includedElements} />
      </div>
      <ItineraryCard sailDate={sailDate} />
      <div className="d-flex route-btn-container">
        {route.attachedBrochure.fileUrl && (
          <Button
            type="submit"
            variant="primary-outline"
            className="route-btn"
            onClick={() => openBrochure(route.id)}
          >
            {t('tourism.landing.routesSection.downloadBrochure')}
          </Button>
        )}

        <Button
          onClick={() => toggleSidebar()}
          variant="primary-outline"
          className={`route-btn ${!route.attachedBrochure.fileUrl &&
            'w-50 mr-1'}`}
        >
          {t('tourism.landing.routesSection.tripDetails')}
        </Button>
        <Button
          variant="primary"
          className={`route-btn ${!route.attachedBrochure.fileUrl &&
            'w-50 ml-1'}`}
          onClick={redirectToReservationWithoutParams}
        >
          {t('tourism.landing.routesSection.bookMyTrip')}
        </Button>
      </div>
      <SidebarRouteInfo
        route={route}
        collapsed={sidebarCollapsed}
        toggleSidebar={toggleSidebar}
        sailDate={sailDate}
        isDestination={isDestination}
      />
    </Col>
  );
};

export default RouteCard;
