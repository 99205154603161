import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import LeadsDataTable from './LeadsDataTable';
import {
  debounceIndexAdminLeadsRequest,
  indexAdminLeadsRequest
} from '../../../requests/admin/adminLeads';
import useFetchData from '../../../hooks/useFetchData';
import { BasicDropdown } from '../../../components';
import { downloadFile } from '../../../services/utils';
import DateFilter from '../../../components/Utils/Filter/DatesFilter';
import '../../../assets/stylesheets/screens/admin/leads/leads.scss';

const rowsPage = 10;

export const leadFilterParams = {
  dateTo: '',
  dateFrom: ''
};

const clientTypes = {
  Carga: 'cargo',
  Turista: 'tourist',
  Todas: 'all'
};

const LeadsIndex = () => {
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState('Todas');
  const customParamsRef = useRef({ ...leadFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });
  const {
    data: leads,
    totalData: totalLeads,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminLeadsRequest,
    customParams: {
      ...customParams,
      client_type: clientTypes[selectedCategory],
      display_length: rowsPage
    }
  });

  const modelUrl = '/admin/leads';

  const handleSuccessDownload = response => {
    downloadFile(response);
    setMoreData(m => !m);
  };

  const downloadIndex = format => {
    indexAdminLeadsRequest({
      dispatch,
      format,
      successCallback: handleSuccessDownload
    });
  };

  useEffect(() => {
    handleIndexRequest(snakecaseKeys(customParams));
  }, [customParams, selectedCategory]);

  return (
    <div className="lead-index pt-4">
      <Row id="index-leads">
        <Col>
          <h4 className="text-primary ml-4">Leads</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2} sm={12}>
          <BasicDropdown
            variant="button"
            titleDrop="Download"
            items={[
              {
                key: '1',
                title: 'pdf',
                props: {
                  onClick: () => downloadIndex('pdf'),
                  disabled: isFetching
                }
              },
              {
                key: '2',
                title: 'xlsx',
                props: {
                  onClick: () => downloadIndex('xlsx'),
                  disabled: isFetching
                }
              }
            ]}
          />
        </Col>
        <Col>
          <DateFilter
            customParams={customParams}
            finishDateField="dateTo"
            setCustomParams={setCustomParams}
            startDateField="dateFrom"
          />
        </Col>
      </Row>
      <LeadsDataTable
        tableData={leads}
        tableDataAmount={totalLeads}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
        categories={['Todas', 'Turista', 'Carga']}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        rowsPage={rowsPage}
      />
    </div>
  );
};

export default withRouter(connect()(LeadsIndex));
