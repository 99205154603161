import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../../components';
import UserForm from './UserForm';
import basicUser from './basicUser';

import {
  showAdminUserRequest,
  updateAdminUserRequest
} from '../../../requests/admin/adminUsers';
import { sendAlert } from '../../../actions/utils';

const UserEdit = props => {
  const [user, setUser] = useState(basicUser);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _user = camelCaseRecursive(response.data);
    setUser(_user);
  };

  useEffect(() => {
    const { match } = props;
    const userId = match.params.id;
    showAdminUserRequest(userId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = () => {
    const { history } = props;
    history.push(`/admin/users`);
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Usuario actualizado con éxito'
      })
    );
  };

  const handleUpdateRequest = _user => {
    const userId = _user.user.id;
    updateAdminUserRequest(userId, {
      dispatch,
      params: _user,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-users" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar Usuario</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <LinkBtn variant="light" block to="/admin/users">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <UserForm user={user} action="edit" formRequest={handleUpdateRequest} />
    </div>
  );
};

export default connect()(UserEdit);
