import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import OurLeaderForm from './OurLeaderForm';
import ourLeaderData from '../../../components/OurLeaders/ourLeader';
import { createAdminOurLeaderRequest } from '../../../requests/admin/adminOurLeaders';
import { sendAlert } from '../../../actions/utils';

const OurLeaderNew = props => {
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const [ourLeader, setOurLeader] = useState(ourLeaderData);
  const dispatch = useDispatch();

  const handleGoback = () => {
    history.push({
      pathname: '/admin/our_leaders',
      state: { type: 'Rutas' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Líder creado con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _ourLeader => {
    createAdminOurLeaderRequest({
      dispatch,
      params: _ourLeader,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-our-leaders" className="mb-4">
        <Col>
          <h4 className="text-primary">Crear líder</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <OurLeaderForm
        ourLeader={ourLeader}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default connect()(OurLeaderNew);
