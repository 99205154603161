import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useTranslation } from 'react-i18next';
import { useHistory, withRouter } from 'react-router-dom';
import { showGalleriesRequest } from '../../requests/galleries';
import '../../assets/stylesheets/components/galleries/Gallery.scss';
import GuidesSection from '../Home/Landing/GuidesSection';
import KnowMoreCards from '../../components/KnowMoreCards/KnowMoreCards';

const GalleryShow = ({ match }) => {
  const language = localStorage.getItem('language') || 'es';
  const { settings } = useSelector(state => state.utils);
  const history = useHistory();
  const { id } = match.params;
  const [gallery, setGallery] = useState({});
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleGoback = () => {
    history.push({ pathname: '/videos-guides', state: { type: 'galleries' } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    showGalleriesRequest(id, {
      dispatch,
      successCallback: response => {
        if (response.status === 200) {
          setGallery(camelCaseRecursive(response.data));
          setNotFound(false);
        } else {
          setNotFound(true);
        }
      }
    });
  }, [id, dispatch]);

  const images = gallery.attachedImagesUrls
    ? gallery.attachedImagesUrls.map(img => ({
        original: img.fileUrl,
        thumbnail: img.fileUrlMinified
      }))
    : [];

  return (
    <>
      {notFound ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: '80vh' }}
        >
          <h4 className="text-primary">
            {t('galleries.galleryNotFoundTitle')}
          </h4>
          <p>{t('galleries.galleryNotFoundText')}</p>
        </div>
      ) : (
        <>
          <div
            id="show-gallery"
            className="col-12 col-lg-9 mx-auto"
            style={{ marginTop: 100, marginBottom: 100, paddingTop: 70 }}
          >
            <Button
              className="mb-4"
              variant="light"
              onClick={() => handleGoback()}
            >
              {language === 'es' ? 'Volver' : 'Back'}
            </Button>
            <h4 className="text-primary mb-4">{gallery.name}</h4>
            <div className="gallery-page">
              <ImageGallery items={images} />
            </div>
            <KnowMoreCards />
          </div>
          |
          <GuidesSection settings={settings} />
        </>
      )}
    </>
  );
};

export default withRouter(GalleryShow);
