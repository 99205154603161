import React, { useEffect, useState, useRef } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { debounceIndexFaqsRequest } from '../../requests/faq';
import useFetchData from '../../hooks/useFetchData';
import PublicFaqDataTable from '../../screens/FAQs/PublicFaqDataTable';
import '../../assets/stylesheets/components/faqs/Faqs.scss';
import indexFaqCategoriesRequest from '../../requests/faqCategories';

export const faqFilterParams = {
  dateTo: '',
  dateFrom: ''
};

const FAQs = ({ categoryType }) => {
  const language = localStorage.getItem('language') || 'es';
  const initialSelectedCategory = language === 'es' ? 'Todas' : 'All';
  const [selectedCategory, setSelectedCategory] = useState(
    initialSelectedCategory
  );
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const customParamsRef = useRef({ ...faqFilterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });

  const fetchFaqCategories = () => {
    indexFaqCategoriesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        language,
        category_type: categoryType
      },
      successCallback: result => {
        const updatedCategories = camelCaseRecursive(result.data.data);
        updatedCategories.unshift({ category: initialSelectedCategory });
        setCategories(updatedCategories);
      }
    });
  };

  const {
    data: faqs,
    totalData: totalFaqs,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexFaqsRequest,
    customParams: {
      ...customParams,
      sort_column: 'position',
      sort_direction: 'asc',
      category: selectedCategory,
      display_length: 1000,
      language,
      faq_category_category_type: categoryType
    }
  });

  useEffect(fetchFaqCategories, []);
  useEffect(() => {
    setCustomParams({ ...customParams, category: selectedCategory });
  }, [selectedCategory]);

  useEffect(() => {
    handleIndexRequest(snakecaseKeys(customParams));
  }, [customParams]);

  return (
    <div className="public-faqs">
      <PublicFaqDataTable
        tableData={faqs}
        tableDataAmount={totalFaqs}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        setMoreData={setMoreData}
        RowsPage={1000}
        categoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </div>
  );
};

export default FAQs;
