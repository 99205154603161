import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { t } from 'i18next';

import { uuidV4 } from '../../services/utils';
import TourismOurFerriesCard from '../Utils/Cards/TourismOurFerriesCard';
import { useScrollPosition } from '../../hooks';

const FerryCharacteristics = ({
  data,
  setShowSubHeader = () => {},
  onClick = () => {}
}) => {
  const ferryCharacteristicsRef = useRef();
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (ferryCharacteristicsRef.current !== null) {
      const { offsetTop } = ferryCharacteristicsRef.current;
      if (scrollPosition.y >= offsetTop - 682) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };

  useEffect(evaluatePosition, [scrollPosition]);
  return (
    <Container ref={ferryCharacteristicsRef} className="mt-4">
      <Row className="align-items-center justify-content-center">
        <Col className="d-flex justify-content-center">
          <h3 className="text-primary text-center title mt-4 mb-4 ">
            {t('tourism.ourFerries.ferryCharacteristics.title')}
          </h3>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center our-ferries-characteristics">
        {data &&
          data.map(characteristics => (
            <Col
              className="d-flex justify-content-center"
              sm={12}
              lg={6}
              xl={3}
              key={uuidV4()}
            >
              <TourismOurFerriesCard
                title={characteristics.title}
                description={characteristics.description}
                imgSrc={characteristics.imageUrl}
              />
            </Col>
          ))}
      </Row>
      <Row className="align-items-center justify-content-center mt-2 our-ferries-characteristics">
        <Col className="d-flex flex-column justify-content-center align-items-center mb-4">
          <p className="our-ferries-description-text text-align-center text-center mb-2">
            {t('tourism.ourFerries.ferryCharacteristics.description')}
          </p>
          <p className="our-ferries-description-text text-center mb-4">
            {t('tourism.ourFerries.ferryCharacteristics.download')}
          </p>
          <Button
            onClick={onClick}
            target="_blank"
            type="submit"
            variant="primary"
            className="w-20 attraction-button mb-4"
          >
            {t('tourism.ourFerries.common.brochure')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default FerryCharacteristics;
