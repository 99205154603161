import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';

import FirstSection from '../Landing/FirstSection';
import SEO from '../../../components/Utils/SEO/SEO';
import '../../../assets/stylesheets/components/cargo/cargoContact/cargoContact.scss';
import envelopeSolid from '../../../assets/images/cargo/envelope-solid.svg';
import fileLinesRegular from '../../../assets/images/cargo/file-lines-regular.svg';
import whatsapp from '../../../assets/images/cargo/whatsapp.svg';
import { IcoMoon } from '../../../components';
import indexAdvisorsRequest from '../../../requests/advisors';

const CargoContact = props => {
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [salesEmail, setSalesEmail] = useState('');
  const [advisors, setAdvisors] = useState([]);
  const dispatch = useDispatch();
  const { settings } = props;
  const history = useHistory();

  const natalesAdvisors = advisors.filter(
    advisor => advisor.route === 'natales'
  );
  const chacabucoAdvisors = advisors.filter(
    advisor => advisor.route === 'chacabuco'
  );

  const advisorRequest = () => {
    indexAdvisorsRequest({
      dispatch,
      params: {
        actives: true
      },
      successCallback: result => {
        const sortedAdvisors = camelCaseRecursive(result.data.data).sort(
          (a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }
        );
        setAdvisors(sortedAdvisors);
      }
    });
  };

  useEffect(advisorRequest, []);

  useEffect(() => {
    setWhatsappNumber(settings?.cargoWhatsapp || '');
    setSalesEmail(settings?.cargoSalesEmail || '');
  }, [settings]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleEmailClick = () => {
    window.location.href = `mailto:${salesEmail}`;
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${whatsappNumber}`, '_blank');
  };

  const handleCargoLeadRedirect = () => {
    history.push('/cargo/leads');
  };

  return (
    <div className="mb-5">
      <SEO
        title="Navimag Carga - Contacto"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Contáctanos"
        subtitle="Cotiza online, resuelve tus dudas por whatsapp o reserva por mail."
        slideType="cargo_contact"
        slideHeight="40vh"
      />
      <Container className="py-4 my-4">
        <h5>Contacta a tu asesor</h5>
        <p>
          Comunicate vía mail o teléfono con nuestros ejecutivos, ellos están
          disponibles para resolver tus dudas y agendar tus reservas.
        </p>
        <p className="mt-4 font-weight-bold">Horarios de atención</p>
        <p>Lunes a Viernes: 09:00 - 13:00 hrs y 14:30 - 18:30 hrs</p>
        <p>Sábado: 10:00 - 14:00 hrs</p>
      </Container>
      <Container>
        <Row className="m-auto" style={{ width: '100%' }}>
          <Col sm={12} md={4}>
            <div className="cargo-contact-card">
              <img
                src={fileLinesRegular}
                alt="fileLinesRegular"
                height="60px"
              />
              <p className="font-weight-bold mt-3">Cotiza en línea</p>
              <Button
                onClick={handleCargoLeadRedirect}
                className="w-75 py-2 mt-3"
                style={{ fontSize: '12px' }}
              >
                Ir al cotizador
              </Button>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="cargo-contact-card">
              <img src={whatsapp} alt="whatsapp" height="60px" />
              <p className="font-weight-bold mt-3">Contactar por Whatsapp</p>
              <Button
                className="w-75 py-2 mt-3"
                style={{ fontSize: '12px' }}
                onClick={handleWhatsAppClick}
              >
                Ir a Whatsapp
              </Button>
            </div>
          </Col>
          <Col sm={12} md={4}>
            <div className="cargo-contact-card">
              <img src={envelopeSolid} alt="envelopeSolid" height="60px" />
              <p className="font-weight-bold mt-3">Escríbenos por correo</p>
              <Button
                className="w-75 py-2 mt-3"
                style={{ fontSize: '12px' }}
                onClick={handleEmailClick}
              >
                Contactar vía correo
              </Button>
            </div>
          </Col>
        </Row>

        <Container className="py-4 my-4">
          <h5>Nuestros asesores</h5>
          <p>Ruta Natales</p>
        </Container>
        <Row className="m-auto" style={{ width: '100%' }}>
          {natalesAdvisors.map(advisor => (
            <Col sm={12} md={4} lg={3}>
              <div className="cargo-contact-card">
                <IcoMoon icon="person" size={50} color="gray" />
                <p
                  className="m-2 font-weight-bold"
                  style={{ fontSize: '17px' }}
                >
                  {advisor.name}
                </p>
                <p className="m-2 ">{advisor.email}</p>
                <a href={`tel: ${advisor.phone}`}>
                  <p className="m-2 ">{advisor.phone}</p>
                </a>
                <Button
                  block
                  variant="primary-outline"
                  onClick={() => {
                    window.location.href = `mailto:${advisor.email}`;
                  }}
                >
                  Enviar correo
                </Button>
              </div>
            </Col>
          ))}
        </Row>
        <Container className="py-4 my-4">
          <p>Ruta Chacabuco</p>
        </Container>
        <Row className="m-auto" style={{ width: '100%' }}>
          {chacabucoAdvisors.map(advisor => (
            <Col sm={12} md={4} lg={3}>
              <div className="cargo-contact-card">
                <IcoMoon icon="person" size={50} color="gray" />
                <p
                  className="m-2 font-weight-bold"
                  style={{ fontSize: '17px' }}
                >
                  {advisor.name}
                </p>
                <p className="m-2 ">{advisor.email}</p>
                <a href={`tel: ${advisor.phone}`}>
                  <p className="m-2 ">{advisor.phone}</p>
                </a>
                <Button
                  block
                  variant="primary-outline"
                  onClick={() => {
                    window.location.href = `mailto:${advisor.email}`;
                  }}
                >
                  Enviar correo
                </Button>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { settings } = state.utils;
  return {
    settings
  };
};

export default connect(mapStateToProps)(CargoContact);
