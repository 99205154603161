import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormikInput, UploadImage, FormikSelect } from '../../../components';
import { languages } from '../../../services/utils';

const IncludedElementForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    includedElement
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';

  return (
    <Form className="w-100 p-4">
      <Row className="justify-content-center pt-4">
        <Col md={6} className="d-flex flex-column justify-content-center">
          <Field name="includedElement[image]">
            {({ field }) => {
              const { name } = field;
              const { fileInfo } = includedElement;
              return (
                <UploadImage
                  {...field}
                  modelType="includedElement"
                  imageUrl={getIn(fileInfo, 'fileUrl')}
                  fileName={getIn(fileInfo, 'filename')}
                  onChange={_avatar => setFieldValue(name, _avatar)}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  customImageStyles="custom-square-img m-auto"
                />
              );
            }}
          </Field>
          <Field name="includedElement[description]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Descripción"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="includedElement[language]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  label="Idioma"
                  placeholder="Seleccionar Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={Object.entries(languages).map(([value, label]) => ({
                    value,
                    label
                  }))}
                  onChange={selectedOption => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : '';
                    setFieldValue(name, selectedValue);
                  }}
                  defaultValue={field.value}
                />
              );
            }}
          </Field>
          <Button
            className="mt-4"
            type="submit"
            variant={submitVariant}
            block
            onClick={onHide}
          >
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { id, description, fileInfo, language } = props.includedElement;
  return {
    includedElement: {
      id,
      description,
      language,
      image: getIn(fileInfo, 'fileSignature')
    },
    languageTranslate: languages[language]
  };
};

const validationSchema = Yup.object().shape({
  includedElement: Yup.object().shape({
    image: Yup.mixed().required('Debes adjuntar una imagen'),
    description: Yup.string().required('Requerido'),
    language: Yup.string().required('El idioma es requerido')
  })
});

const handleSubmit = (values, { props }) => {
  const { image } = values.includedElement;
  const valuesCopy = values;
  delete valuesCopy.includedElement.image;
  const paramsToSend = {
    included_element: { ...snakecaseKeys(valuesCopy.includedElement), image }
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(IncludedElementForm);
