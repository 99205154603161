import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { CarouselComponent } from '../../../components';
import { indexSlidesRequest } from '../../../requests/slides';

const RouteAndTerminals = ({ isDesktop }) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: 'transport'
      },
      successCallback: result => {
        const updatedSlides = camelCaseRecursive(result.data.data).map(
          slide => ({
            ...slide,
            img: slide.fileInfo.fileUrl
          })
        );
        setSlides(updatedSlides);
      }
    });
  };

  useEffect(slidesRequest, []);

  const cargoServicesRedirect = () => {
    history.push(`/cargo/services`);
  };

  return (
    <Row className="fifth-section">
      <Col
        md={isDesktop ? 7 : 12}
        className="d-flex flex-column justify-content-center col-right"
      >
        <h2 className="text-primary mb-2 title">Rutas y Terminales</h2>
        <h5 className="mb-3">Trasladamos carga y vehículos.</h5>
        <p>
          Nuestros ferries zarpan todas las semanas del año desde tres puertos
          de embarque en el sur de Chile: Puerto Montt, Chacabuco y Puerto
          Natales.
        </p>
        <div className="d-flex justify-content-end mt-4">
          <Button
            variant="primary"
            className="mr-3 btn-mobile px-3 py-2"
            onClick={cargoServicesRedirect}
          >
            Consultar rutas
          </Button>
        </div>
      </Col>
      <Col md={5}>
        <CarouselComponent
          noArrow
          className="carousel-landing"
          classNameImg="img-fifth-section"
          data={slides}
        />
      </Col>
    </Row>
  );
};

export default RouteAndTerminals;
