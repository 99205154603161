import React from 'react';
import IcoMoon from 'react-icomoon';
import PropTypes from 'prop-types';

const iconSet = require('./selection.json');

const Icon = ({ marginIcon, ...props }) => {
  return (
    <div className="d-inline-block" style={{ margin: marginIcon }}>
      <IcoMoon iconSet={iconSet} {...props} />
    </div>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  marginIcon: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Icon.defaultProps = {
  className: 'icomoon',
  marginIcon: '5px',
  size: 20
};

export default Icon;
