import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../hooks';
import { FormBookTrip, CenteredModal, RoutesBreakdown } from '../../components';
import FirstSection from '../Home/Landing/FirstSection';
import FifthSection from '../Home/Landing/FifthSection';
import SixthSection from '../Home/Landing/SixthSection';
import GuidesSection from '../Home/Landing/GuidesSection';
import SEO from '../../components/Utils/SEO/SEO';
import { redirectToReservation, uuidV4 } from '../../services/utils';
import { indexRoutesRequest } from '../../requests/routes';

const bookTrip = {
  route: '',
  month: '',
  year: '',
  adults: 0,
  children: 0,
  infants: 0
};

const TheRoute = () => {
  const routeBreakdownRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const { windowSize } = useWindowSize();
  const { settings } = useSelector(state => state.utils);
  const { t } = useTranslation();

  const [routes, setRoutes] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  const shipDirectionFilter = (data, direction) => {
    return data.filter(({ shipDirection }) => shipDirection === direction);
  };

  const routesBuilder = routesData => {
    const routesArray = [];
    routesData.forEach(routeData => {
      const northSouthDestionationArray = [
        ...routeData.daysItineraryDescriptionsAttributes
      ];

      const northSouthRoute = {
        ...routeData,
        id: uuidV4(),
        routeName: `${routeData.origin} - ${routeData.destination}`,
        daysItineraryDescriptionsAttributes: shipDirectionFilter(
          northSouthDestionationArray,
          'north_south'
        )
      };
      routesArray.push(northSouthRoute);

      const southNorthDestionationArray = [
        ...routeData.daysItineraryDescriptionsAttributes
      ];
      const southNorthRoute = {
        ...routeData,
        id: uuidV4(),
        routeName: `${routeData.destination} - ${routeData.origin}`,
        daysItineraryDescriptionsAttributes: shipDirectionFilter(
          southNorthDestionationArray,
          'south_north'
        )
      };
      routesArray.push(southNorthRoute);
    });
    setRoutes(routesArray);
    setSelectedRoute(routesArray[0]);
  };

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        language: localStorage.getItem('language'),
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        routesBuilder(camelCaseRecursive(result.data.data));
      }
    });
    return () => cancelToken.cancel();
  };

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  const handleCloseModal = () => setShowModal(false);
  useEffect(routesRequestWrapper, []);

  return (
    <>
      <SEO
        title={t('tourism.theRoute.seo.title')}
        description={t('tourism.theRoute.seo.description')}
      />
      <FirstSection
        bookTrip={bookTrip}
        isDesktop={isDesktop}
        setShowModal={setShowModal}
        handleCreateRequest={redirectToReservation}
        title={t('tourism.theRoute.hero.title')}
        subtitle={t('tourism.theRoute.hero.description')}
        slideType="tourism_the_route"
      />

      <div ref={routeBreakdownRef} />
      <MarginBottomWrapper
        childComponent={
          <RoutesBreakdown
            routes={routes}
            setSelectedRoute={setSelectedRoute}
            selectedRoute={selectedRoute}
          />
        }
      />

      <MarginBottomWrapper
        childComponent={<FifthSection isDesktop={isDesktop} />}
      />
      <SixthSection />

      <GuidesSection settings={settings} />

      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(TheRoute);

const MarginBottomWrapper = ({ childComponent }) => {
  return (
    <Row className="mb-5 align-items-center justify-content-center">
      <Col md={10}>{childComponent}</Col>
    </Row>
  );
};
