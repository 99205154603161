import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminNavbarRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/navbars/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminNavbarRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/navbars${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexAdminNavbarRootCategoriesRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', `/admin/navbars/navbar_root_categories`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminNavbarRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/admin/navbars', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminNavbarRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/navbars/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const updateAdminNavbarTreeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('put', `/admin/navbars/update_tree`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminNavbarRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/admin/navbars/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminNavbarRequest = AwesomeDebouncePromise(
  indexAdminNavbarRequest,
  300
);
