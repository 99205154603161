import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import axios from 'axios';

import { indexRoutesRequest } from '../../requests/routes';
import CustomSelector from '../Utils/Select/CustomSelector';
import PassengerSelector from '../Utils/Select/PassengerSelector';
import { uuidV4 } from '../../services/utils';

const FieldsBookTrip = ({
  modelName,
  setFieldValue,
  errors,
  values,
  touched
}) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('language') || 'es';

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState([]);

  const routesBuilder = routesData => {
    const routesArray = [];
    routesData.forEach(routeData => {
      routesArray.push({ ...routeData, id: uuidV4() });

      const mirrorRoute = {
        ...routeData,
        id: uuidV4(),
        label: `${routeData.destination} - ${routeData.origin}`,
        value: routeData.destinationRouteCode
      };
      routesArray.push(mirrorRoute);
    });
    setRoutes(routesArray);
  };

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        language: localStorage.getItem('language') || 'es',
        serializer_selector: 'TourismRoutesSelectorSerializer',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        routesBuilder(camelCaseRecursive(result?.data?.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const meses = [
    { label: 'Enero', value: 1 },
    { label: 'Febrero', value: 2 },
    { label: 'Marzo', value: 3 },
    { label: 'Abril', value: 4 },
    { label: 'Mayo', value: 5 },
    { label: 'Junio', value: 6 },
    { label: 'Julio', value: 7 },
    { label: 'Agosto', value: 8 },
    { label: 'Septiembre', value: 9 },
    { label: 'Octubre', value: 10 },
    { label: 'Noviembre', value: 11 },
    { label: 'Diciembre', value: 12 }
  ];

  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
  ];

  const years = [
    { label: '2024', value: 2024 },
    { label: '2025', value: 2025 }
  ];

  const monthMapping = {
    Enero: 'January',
    Febrero: 'February',
    Marzo: 'March',
    Abril: 'April',
    Mayo: 'May',
    Junio: 'June',
    Julio: 'July',
    Agosto: 'August',
    Septiembre: 'September',
    Octubre: 'October',
    Noviembre: 'November',
    Diciembre: 'December'
  };

  const getMonthPeriod = (startMonth, endMonth, lang = 'es') => {
    let correctStartMonth = startMonth;
    let correctEndMonth = endMonth;

    if (lang === 'en') {
      correctStartMonth = monthMapping[startMonth];
      correctEndMonth = monthMapping[endMonth];
    }

    const monthList = lang === 'es' ? meses : months;

    const startIdx = monthList.findIndex(
      month => month.label === correctStartMonth
    );
    const endIdx = monthList.findIndex(
      month => month.label === correctEndMonth
    );

    let result;

    if (startIdx <= endIdx) {
      result = monthList.slice(startIdx, endIdx + 1);
    } else {
      result = [
        ...monthList.slice(startIdx),
        ...monthList.slice(0, endIdx + 1)
      ];
    }

    return result;
  };

  useEffect(routesRequestWrapper, []);

  return (
    <>
      <Col md={3} className="form-book-trip-col">
        <CustomSelector
          modelName={`${modelName}[route]`}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          placeholder={t('tourism.subHeader.routePlaceholder')}
          label={t('tourism.subHeader.route')}
          options={routes}
          styles={{ zIndex: 100 }}
        />
      </Col>
      <Col md={2} className="form-book-trip-col">
        <CustomSelector
          modelName={`${modelName}[month]`}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          placeholder={t('tourism.subHeader.chooseDepartureMonth')}
          label={t('tourism.subHeader.month')}
          options={getMonthPeriod('Octubre', 'Marzo', language)}
        />
      </Col>
      <Col md={2} className="form-book-trip-col">
        <CustomSelector
          modelName={`${modelName}[year]`}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          placeholder={t('tourism.subHeader.chooseYear')}
          label={t('tourism.subHeader.year')}
          options={years}
        />
      </Col>
      <Col md={3} className="form-book-trip-col">
        <CustomSelector
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          touched={touched}
          placeholder={t('tourism.subHeader.choosePassengerTypeAndNumber')}
          label={t('tourism.subHeader.passengers')}
          options={[
            <PassengerSelector
              type="adults"
              modelName={`${modelName}[adults]`}
              setFieldValue={setFieldValue}
              values={values}
            />,
            <PassengerSelector
              type="children"
              modelName={`${modelName}[children]`}
              setFieldValue={setFieldValue}
              values={values}
            />,
            <PassengerSelector
              type="infants"
              modelName={`${modelName}[infants]`}
              setFieldValue={setFieldValue}
              values={values}
            />
          ]}
          isJsxOptionType
        />
      </Col>
    </>
  );
};

export default FieldsBookTrip;
