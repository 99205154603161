import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../../components';
import { showAdminReviewRequest } from '../../../requests/admin/adminReviews';
import ReviewCard from '../../../components/Utils/Cards/ReviewCard';

const ReviewShow = props => {
  const { history } = props;
  const [review, setReview] = useState({
    fileInfo: {},
    name: '',
    lastName: '',
    comment: '',
    review: 5,
    reviewLink: '',
    active: true
  });

  const handleSuccessShow = response => {
    const _review = camelCaseRecursive(response.data);
    setReview(_review);
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/landing', state: { type: 'Reviews' } });
  };

  useEffect(() => {
    const { match, dispatch } = props;
    const reviewId = match.params.id;
    showAdminReviewRequest(reviewId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  return (
    <div className="pt-4">
      <Row id="show_reviews">
        <Col>
          <h4>Review preview</h4>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            block
            to={`/admin/reviews/${review.id}/edit`}
          >
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 pt-4 d-flex justify-content-center">
        <Col md={9}>
          <div className="carousel-show">
            <ReviewCard reviewData={review} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default connect()(ReviewShow);
