import React from 'react';
import { Image } from 'react-bootstrap';

const IncludedList = ({ includedElements }) => {
  return (
    <div className="mb-3 content-icons">
      {includedElements.map(element => {
        return (
          <p key={`included-element-image-paragraph-${element.fileInfo.id}`}>
            <Image
              key={`included-element-image-${element.fileInfo.id}`}
              src={element.fileInfo.fileUrl}
              alt="icono comida"
              className="mr-2"
            />
            {element.description}
          </p>
        );
      })}
    </div>
  );
};

export default IncludedList;
