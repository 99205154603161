import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import { showSlideRequest } from '../../requests/admin/adminSlides';

const SlideShow = props => {
  const [slide, setSlide] = useState({
    id: '',
    fileInfo: '',
    position: 0,
    caption: '',
    active: ''
  });

  const handleSuccessShow = response => {
    const _slide = camelCaseRecursive(response.data);
    setSlide(_slide);
  };

  useEffect(() => {
    const { match, dispatch } = props;
    const slideId = match.params.id;
    showSlideRequest(slideId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const slideTypes = {
    main_banner: 'Banner Principal',
    onboard_experience: 'Experiencia a Bordo',
    guide_background: 'Background de guía'
  };

  return (
    <>
      <Row className="mt-3">
        <Col>
          <h5 className="my-2">Slides Show</h5>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            block
            to={`/admin/slides/${slide.id}/edit`}
          >
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/admin/slides">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <div className="carousel-show">
            <Image src={slide.fileInfo.fileUrl} />
          </div>
        </Col>
        <Col md={12}>
          <p className="my-2">Tipo de Slide: {slideTypes[slide.slideType]}</p>
          <p className="my-2">Posición: {slide.position}</p>
          <p className="my-2">Label: {slide.label}</p>
          <div className="d-flex align-items-center">
            <p className="my-1 mr-4">Active:</p>
            <Form.Check custom type="checkbox" checked={slide.active} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default connect()(SlideShow);
