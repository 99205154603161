import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

const CargoDeckCard = ({
  colorText,
  colorCard,
  colorBorder,
  deck,
  occupiedCapacity,
  totalCapacity,
  selectedClassName
}) => {
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className={`cargo-deck-card text-left ${selectedClassName}`}
      border={colorBorder}
    >
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <Card.Title className="cargo-deck-text mb-1">{deck}</Card.Title>
            <Card.Text className="cargo-deck-description-text m-0">
              MLI:{' '}
              <b className="cargo-deck-bold-text m-0">
                {Math.round(totalCapacity - occupiedCapacity)}
              </b>{' '}
              MLI de <b className="cargo-deck-bold-text m-0">{totalCapacity}</b>{' '}
              MLI disponibles{' '}
            </Card.Text>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CargoDeckCard.propTypes = {
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string,
  deck: PropTypes.string,
  occupiedCapacity: PropTypes.number,
  totalCapacity: PropTypes.number
};

CargoDeckCard.defaultProps = {
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null,
  deck: '',
  occupiedCapacity: 0.0,
  totalCapacity: 0.0
};

export default CargoDeckCard;
