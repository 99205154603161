import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import FaqCategoryDataTable from './FaqCategoryDataTable';
import LanguageButtons from '../../../components/Utils/Button/LanguageButtons';

import { debounceIndexAdminFaqCategoriesRequest } from '../../../requests/admin/adminFaqCategories';
import useFetchData from '../../../hooks/useFetchData';

const rowsPage = 10;

const FaqCategoryIndex = ({ categoryType }) => {
  const [language, setLanguage] = useState('es');
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [customParams, setCustomParams] = useState({
    sort_column: 'position',
    sort_direction: 'asc',
    display_length: rowsPage,
    category_type: categoryType,
    language
  });

  const {
    data: faqCategories,
    totalData: totalFaqCategories,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminFaqCategoriesRequest,
    customParams
  });

  const modelUrl = '/admin/faq_categories';
  const clearCustomParams = () => {
    setCustomParams({
      language,
      category_type: categoryType,
      display_length: rowsPage
    });
  };
  const updateCustomParams = () => {
    setCustomParams({
      language,
      category_type: categoryType,
      display_length: rowsPage
    });
    setAllowFetchRecords(true);
  };
  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(updateCustomParams, [language]);
  useEffect(fetchNewRecords, [customParams]);

  return (
    <>
      <Row id="index-faq-categories">
        {categoryType !== 'cargo' && (
          <Col>
            <LanguageButtons language={language} setLanguage={setLanguage} />
          </Col>
        )}
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to={`/admin/faq_categories/new?type=${categoryType}`}
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <FaqCategoryDataTable
        tableData={faqCategories}
        tableDataAmount={totalFaqCategories}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
        rowsPage={rowsPage}
      />
    </>
  );
};

export default withRouter(connect()(FaqCategoryIndex));
