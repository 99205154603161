import React from 'react';
import { Button } from 'react-bootstrap';

import { uuidV4 } from '../../../services/utils';

const CargoLeadFormStepMap = ({ step }) => {
  const steps = [
    { stepNumber: '1', stepName: 'Ruta y vehículo' },
    { stepNumber: '2', stepName: 'Información de contacto' }
  ];

  return (
    <div className="custom-steps-map-container mb-5">
      <div className="cargo-lead-custom-steps-map">
        {steps.map((stepData, index) => {
          const currentNumber = parseInt(stepData.stepNumber, 10);
          return (
            <React.Fragment key={uuidV4()}>
              <div className="cargo-lead-step-container text-center justify-content-center">
                <Button
                  key={`step-${index + 1}`}
                  className={`cargo-lead-custom-steps-map--step btn non-clickable mb-2 ${
                    currentNumber <= step ? 'filled' : ''
                  }`}
                >
                  <>{stepData.stepNumber}</>
                </Button>
                <p>{stepData.stepName}</p>
              </div>
              <hr className="cargo-lead-custom-steps-map--dashed-line" />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CargoLeadFormStepMap;
