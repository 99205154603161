import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';

import success from '../../../assets/images/success.svg';

const SuccessCard = ({
  size,
  colorText,
  colorCard,
  colorBorder,
  title,
  text,
  btnText,
  onClick
}) => {
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className="success-card-container text-center"
      style={{ width: size, cursor: 'pointer' }}
      border={colorBorder}
    >
      <Card.Body>
        <Row className="align-items-center">
          <Col>
            <Card.Img
              style={{
                height: 130,
                width: 130,
                borderRadius: 50,
                marginBottom: 15
              }}
              variant="top"
              src={success}
            />
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Card.Title>{title}</Card.Title>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <Card.Text className="mb-10">{text}</Card.Text>
            <Button
              type="submit"
              variant="primary-outline"
              className="btn-card-section mt-2"
              onClick={onClick}
            >
              {btnText}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SuccessCard.propTypes = {
  size: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

SuccessCard.defaultProps = {
  size: '100%',
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default SuccessCard;
