import ApiService from '../services/apiService';

const createLeadRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/leads', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export default createLeadRequest;
