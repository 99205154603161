import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import CargoLeadTable from './CargoLeadTable';
import { BasicDropdown } from '../../../../components';
import { downloadFile } from '../../../../services/utils';
import {
  debounceIndexAdminCargoLeadsRequest,
  indexAdminCargoLeadsRequest
} from '../../../../requests/admin/adminCargoLeads';
import { useFetchData } from '../../../../hooks';

const AdminCargoLeadIndex = () => {
  const dispatch = useDispatch();
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [dateFilter, setDateFilter] = useState('');
  const [customParams, setCustomParams] = useState({
    sort_column: 'created_at',
    sort_direction: 'desc',
    display_length: 10
  });

  const {
    data: cargoLeads,
    totalData: totalCargoLeads,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminCargoLeadsRequest,
    customParams
  });

  const modelUrl = '/admin/cargo_leads';
  const clearCustomParams = () => {
    setCustomParams({ display_length: 10 });
  };

  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(fetchNewRecords, [customParams]);

  const handleSuccessDownload = response => {
    downloadFile(response);
    setMoreData(m => !m);
  };

  const downloadIndex = format => {
    indexAdminCargoLeadsRequest({
      dispatch,
      format,
      params: {
        display_length: 1000,
        sort_column: 'created_at',
        sort_direction: 'desc',
        ...dateFilter
      },
      successCallback: handleSuccessDownload
    });
  };

  return (
    <>
      <Row id="index-cargo-leads" className="pt-4">
        <Col>
          <h4 className="text-primary ml-4">Cargo Leads</h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <BasicDropdown
            variant="button"
            titleDrop="Download"
            items={[
              {
                key: '1',
                title: 'xlsx',
                props: {
                  onClick: () => downloadIndex('xlsx'),
                  disabled: isFetching
                }
              }
            ]}
          />
        </Col>
      </Row>
      <CargoLeadTable
        tableData={cargoLeads}
        tableDataAmount={totalCargoLeads}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
        setDateFilter={setDateFilter}
      />
    </>
  );
};

export default withRouter(connect()(AdminCargoLeadIndex));
