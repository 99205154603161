import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import FaqCategoryIndex from '../FAQsCategory/FaqCategoryIndex';
import FaqIndex from '../FAQs/FaqIndex';

const FaqsAndCategories = props => {
  const { location, categoryType } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('FAQs');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">FAQs & Categorías de FAQs</h4>
        </Col>
      </Row>
      <ModelFilter
        models={['FAQs', 'Categorías de FAQs']}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '80px' }}>
        <Col>
          {selectedModel === 'FAQs' && <FaqIndex categoryType={categoryType} />}
          {selectedModel === 'Categorías de FAQs' && (
            <FaqCategoryIndex categoryType={categoryType} />
          )}
        </Col>
      </Row>
    </>
  );
};

export default FaqsAndCategories;
