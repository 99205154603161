import * as Yup from 'yup';
import '../../../services/yupCustomMethods';

export const validationSchema = Yup.object().shape({
  cargoLead: Yup.object().shape({
    routeId: Yup.string().required('Debes seleccionar una ruta'),
    cargoRateId: Yup.string().required('Debes seleccionar un tipo de carga'),
    linealLength: Yup.number().typeError('Metro lineal debe ser un número'),
    roundTrip: Yup.string(),
    name: Yup.string().required('Debes ingresar un nombre'),
    email: Yup.string()
      .required('Debes ingresar tu correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    rut: Yup.string()
      .required('Debes ingresar RUT del contratante')
      .rut('El RUT es inválido'),
    nationality: Yup.string().required('Debes seleccionar una nacionalidad')
  })
});

export const firstStepFields = [
  'routeId',
  'cargoRateId',
  'beneficiaryPersonName',
  'linealLength',
  'roundTrip'
];

export const secondStepFields = [
  'name',
  'email',
  'rut',
  'nationality',
  'acceptFutureCommunications'
];
