import React from 'react';
import { useFormikContext } from 'formik';
import CargoRatesForm from './CargoRateForm';

import NestedForm from '../Utils/NestedForm';
import cargoRate from './cargoRate';

const CargoRatesAttributes = ({ modelName, route }) => {
  const { setFieldValue, values } = useFormikContext();
  const cargoRatesAttributes = values?.route?.cargoRatesAttributes;

  const mapResults = cargoRatesAttributes.map((body, index) => {
    if (body._destroy) {
      return undefined;
    }

    return (
      <CargoRatesForm
        key={`cargo-${index.toString()}`}
        index={index}
        parentModel={modelName}
        forNestedAttributes
        route={route}
      />
    );
  });

  return (
    <NestedForm
      attributesTitle="Tarifas de carga"
      mapInputs={mapResults}
      arrayValues={cargoRatesAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${modelName}[cargoRatesAttributes]`}
      newAttributes={{ ...cargoRate }}
      addTitle="Agregar tarifa de carga"
    />
  );
};

export default CargoRatesAttributes;
