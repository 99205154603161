import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { IcoMoon } from '../..';
import IncludedList from './IncludedList';
import ItineraryCard from './ItineraryCard';
import ItineraryDescriptionByDays from './ItineraryDescriptionByDays';
import { redirectToReservationWithoutParams } from '../../../services/utils';
import { showRouteRequest } from '../../../requests/routes';

const SidebarRouteInfo = ({
  route,
  collapsed,
  toggleSidebar,
  sailDate,
  isDestination = false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const routeName = isDestination
    ? `${route.destination} - ${route.origin}`
    : `${route.origin} - ${route.destination}`;
  const routeDuration = isDestination
    ? route.destinationDuration
    : route.routeDuration;
  const routeCheckIn = isDestination
    ? route.destinationCheckin
    : route.routeCheckIn;
  const daysItineraryDescriptions = [
    ...route.daysItineraryDescriptionsAttributes
  ];

  function fetchRoute(id) {
    showRouteRequest(id, {
      dispatch,
      successCallback: fetchedRoute =>
        window.open(fetchedRoute.data.attached_brochure.file_url)
    });
  }

  const openBrochure = id => {
    fetchRoute(id);
  };

  return (
    <>
      <div
        className={`sidebar-route-info-back ${collapsed ? 'd-none' : ''}`}
        onClick={toggleSidebar}
        aria-hidden="true"
      />
      <div
        className={`sidebar-route-info ${
          collapsed ? 'collapsed' : 'right-animation'
        }`}
      >
        <div className="d-flex">
          <IcoMoon
            icon="cancel-circle"
            className="ml-4 mb-2"
            style={{ cursor: 'pointer' }}
            onClick={toggleSidebar}
          />
        </div>

        <div className="p-4">
          <h6 className="text-left text-primary mb-3">{routeName}</h6>
          <p className="subtitle">{routeDuration}</p>
          <p className="font-check-in">{routeCheckIn}</p>
        </div>
        <iframe title="map" src={route.interactiveMapRouteLink} />

        <div className="p-4">
          <ItineraryDescriptionByDays
            daysItineraryDescriptionsAttributes={
              isDestination
                ? daysItineraryDescriptions.reverse()
                : daysItineraryDescriptions
            }
          />
          <ItineraryCard sailDate={sailDate} />
          <div className="route-card-section-included-list">
            <p className="subtitle mt-3 mb-1">
              {t('tourism.landing.routesSection.includes')}
            </p>
            <IncludedList includedElements={route.includedElements} />
          </div>

          <div className="w-100 my-4">
            <h5>{t('tourism.landing.routesSection.moreInformation')}</h5>
            <p>
              {t('tourism.landing.routesSection.downloadBrochureDescription')}
            </p>
            {route?.attachedBrochure?.fileUrl && (
              <Button
                variant="primary-outline"
                className="w-100 mt-4 p-2"
                style={{ fontSize: '12px' }}
                onClick={() => openBrochure(route.id)}
              >
                {t('tourism.landing.routesSection.downloadBrochure')}
              </Button>
            )}
          </div>
        </div>

        <div className="sidebar-route-info-bottom-btn">
          <Button
            variant="primary"
            className="w-100 m-4 p-2"
            onClick={redirectToReservationWithoutParams}
          >
            {t('tourism.landing.routesSection.bookMyTrip')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SidebarRouteInfo;
