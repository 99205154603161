import React, { useState, useEffect } from 'react';
// import camelCaseRecursive from 'camelcase-keys-recursive';
import { useDispatch } from 'react-redux';

import { FormikSelect } from '../../../components';
import { indexGuidesRequest } from '../../../requests/guides';

const GuideSelector = ({ value, label, handleSelectGuide }) => {
  const dispatch = useDispatch();
  const [guides, setGuides] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const guidesRequest = () => {
    indexGuidesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        language: ['Principal guide es'].includes(label) ? 'es' : 'en'
      },
      successCallback: result => {
        const transformedGuides = result.data.data.map(obj => ({
          label: obj.title,
          value: obj.title
        }));
        setGuides(transformedGuides);
      }
    });
  };

  const handleNewSelection = newSelection => {
    setSelectedValue(newSelection);
    handleSelectGuide(newSelection);
  };

  useEffect(guidesRequest, [label]);

  return (
    <div className="w-100">
      <FormikSelect
        label={label}
        placeholder="Seleccionar guía principal"
        options={guides}
        defaultValue={selectedValue}
        value={selectedValue}
        onChange={data => handleNewSelection(data?.value)}
        clas
      />
    </div>
  );
};

export default GuideSelector;
