import camelCaseRecursive from 'camelcase-keys-recursive';
import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toogleSidebar } from '../../../actions/utils';
import MainLogo from '../MainLogo';
import logo from '../../../assets/images/navimag-logo.png';
import { ButtonComponent } from '../Button';
import { useWindowSize } from '../../../hooks';
import IcoMoon from '../Icon/IcoMoon';
import indexSettingRequest from '../../../requests/settings';

const PrincipalNavbar = ({ children, cargo = false, toggleMenu }) => {
  const [settings, setSettings] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const publicRoutes = ['', 'components', 'contact', 'page'];
  const { user } = useSelector(state => state.auth);
  const { windowSize } = useWindowSize();

  const desktopNavbarLogo = () => {
    if (settings?.cargoNavbarLogo !== '' && cargo) {
      return settings.cargoNavbarLogo;
    }
    if (settings?.navbarLogo !== '') {
      return settings.navbarLogo;
    }
    return logo;
  };

  const settingsRequestWrapper = () => {
    indexSettingRequest({
      dispatch,
      successCallback: result => {
        setSettings(camelCaseRecursive(result.data));
      }
    });
  };

  useEffect(settingsRequestWrapper, []);

  return (
    <Navbar
      className={cargo ? 'navbar-black' : 'navbar-red'}
      expand="lg"
      fixed="top"
      id={cargo ? 'navbar-black' : 'navbar-red'}
    >
      <Navbar.Brand
        className={cargo ? 'navbar-brand-black' : 'navbar-brand-red'}
        as={Link}
        to={cargo ? '/cargo' : '/'}
      >
        <MainLogo src={windowSize.width > 991 ? desktopNavbarLogo() : logo} />
      </Navbar.Brand>
      {user.id > 0 && !publicRoutes.includes(path[1]) ? (
        <ButtonComponent
          typeButton="Icon"
          icon="menu"
          iconSize={32}
          className="hamburger-menu icon"
          variant="outline-info"
          iconColor="black"
          onClick={() => dispatch(toogleSidebar())}
        />
      ) : (
        <Navbar.Toggle
          className="p-0"
          aria-controls="basic-navbar-nav"
          onClick={() => toggleMenu()}
        >
          <IcoMoon icon="menu" size={32} />
        </Navbar.Toggle>
      )}
      {children}
    </Navbar>
  );
};

export default PrincipalNavbar;
