import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormikInput, FormikSwitch, FormikSelect } from '../../../components';
import indexRolesRequest from '../../../requests/role';
import useFetchData from '../../../hooks/useFetchData';
import { lowerCaseTextFormat } from '../../../services/utils';

const UserForm = props => {
  const { onHide, submitVariant, errors, touched, action, user } = props;
  const { setFieldValue } = useFormikContext();
  const [defaultRoles, setDefaultRoles] = useState(undefined);

  const { data: roles } = useFetchData({
    debouncedIndexRequest: indexRolesRequest,
    fetchingErrorMessage:
      'Error al buscar roles. Por favor inténtelo nuevamente.',
    customParams: {
      sort_column: 'name',
      sort_direction: 'asc'
    }
  });

  const findDefaultRoles = () => {
    setDefaultRoles(
      roles
        .filter(role =>
          user.roles?.map(element => element.value).includes(role.id)
        )
        .map(element => ({ value: element.value, label: element.label }))
    );
  };

  useEffect(findDefaultRoles, [user.roles, roles]);
  useEffect(() => {
    setFieldValue(
      'user[usersRolesAttributes]',
      user.roles?.map(element => ({ roleId: element.value }))
    );
  }, [defaultRoles]);

  const handleFormatTextToLowerCase = e => {
    const formattedValue = lowerCaseTextFormat(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  const btnMessage = action === 'new' ? 'Crear Usuario' : 'Guardar Cambios';

  const handleSelectorIsMulti = (data, allowEmpty = false) => {
    const newData = data.map(element => ({
      value: element.value,
      label: element.label
    }));
    if ((allowEmpty && newData.length === 0) || (data && data.length === 0)) {
      user.roles = [];
      setFieldValue('user[usersRolesAttributes]', []);
    } else {
      user.roles = newData;
      setFieldValue(
        'user[usersRolesAttributes]',
        data.map(element => ({ roleId: element.value }))
      );
    }
  };

  if (action !== 'new' && (!roles || !user.roles || !defaultRoles)) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    );
  }

  return (
    <Form className="w-100 px-2">
      <Row className="d-flex justify-content-center p-4">
        <Col md={6} sm={12}>
          <Field name="user[firstName]">
            {({ field }) => {
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
          <Field name="user[lastName]">
            {({ field }) => {
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Apellido"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              );
            }}
          </Field>
          <Field name="user[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Correo electrónico"
                onChange={handleFormatTextToLowerCase}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="user[usersRolesAttributes]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                isMulti
                label="Roles"
                placeholder="Seleccionar roles"
                options={roles}
                value={
                  (user.roles &&
                    user.roles.map(element => element.value).join(',')) ||
                  ''
                }
                defaultValue={
                  (user.roles &&
                    user.roles.map(element => element.value).join(',')) ||
                  ''
                }
                defaultMultiValues={defaultRoles}
                onChange={data => handleSelectorIsMulti(data || [], field.name)}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name="user[active]">
            {({ field }) => (
              <FormikSwitch {...field} field={field} label="¿Usuario activo?" />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center p-4">
        <Col md={6} sm={12} className="mt-4">
          <Button
            type="submit"
            variant={submitVariant}
            size="lg"
            onClick={onHide}
            className="w-100"
          >
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    firstName,
    lastName,
    email,
    active,
    usersRolesAttributes,
    roles
  } = props.user;

  return {
    user: {
      id,
      firstName,
      lastName,
      email,
      active,
      usersRolesAttributes,
      roles
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    email: Yup.string()
      .required('Debes ingresar un correo electrónico')
      .email('Debes ingresar un correo electrónico válido'),
    usersRolesAttributes: Yup.array()
      .min(1, 'Debes seleccionar al menos un rol')
      .required('Debes seleccionar al menos un rol'),
    firstName: Yup.string().required('Debes ingresar un nombre'),
    lastName: Yup.string().required('Debes ingresar un apellido'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const { user } = props;
  const updateValues = { ...values }; // Make a copy of the values object

  updateValues.user.usersRolesAttributes = updateValues.user.usersRolesAttributes.map(
    element => {
      const found =
        props.user.usersRolesIds &&
        props.user.usersRolesIds.find(attr => attr.roleId === element.roleId);
      if (found) {
        return { ...element, id: found.id };
      }
      return element;
    }
  );

  if (props.action !== 'new') {
    const preProcessedValues = updateValues.user.usersRolesAttributes;
    const deletedValues = user.usersRolesIds
      .map(role => {
        const found = preProcessedValues.find(
          element => element.roleId === role.roleId
        );
        if (found) {
          return null;
        }
        return { ...role, _destroy: true };
      })
      .filter(elements => {
        return elements != null;
      });
    updateValues.user.usersRolesAttributes = [
      ...preProcessedValues,
      ...deletedValues
    ];
  }

  const paramsToSend = {
    user: snakecaseKeys(
      {
        ...updateValues.user
      },
      { exclude: ['_destroy'] }
    )
  };

  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(UserForm);
