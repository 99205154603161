const cargoRate = {
  id: '',
  vehicleType: '',
  cargoUnit: '',
  loadedInitialRate: '',
  emptyInitialRate: '',
  loadedReturnRate: '',
  emptyReturnRate: '',
  vehicleTypeCategory: 'towing',
  specialPrice: '',
  position: 1,
  active: true
};

export default cargoRate;
