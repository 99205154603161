import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../../components';
import { showAdminPageRequest } from '../../../requests/admin/adminPages';
import basicPage from './basicPage';

const PageShow = ({ match }) => {
  const { id } = match.params;
  const [page, setPage] = useState(basicPage);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    setPage(camelCaseRecursive(response.data));
  };

  const handleShowPage = () => {
    showAdminPageRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(handleShowPage, []);

  return (
    <>
      <Row id="show_pages">
        <Col md={2}>
          <LinkBtn variant="primary" block to={`/admin/pages/${page.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/admin/pages">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <div
        dangerouslySetInnerHTML={{ __html: page.body }}
        style={{
          width: '100%',
          marginTop: '20px',
          padding: '20px',
          borderRadius: '4px'
        }}
      />
    </>
  );
};
export default connect()(PageShow);
