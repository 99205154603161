import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FAQs from '../../components/FAQs/FAQs';
import SEO from '../../components/Utils/SEO/SEO';

const Faqs = ({ categoryType }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={t(`${categoryType}.faqs.seo.title`)}
        description={t(`${categoryType}.faqs.seo.description`)}
      />
      <div
        id="FAQs"
        className="col-12 col-lg-9 mx-auto"
        style={{ marginTop: 200, marginBottom: 100 }}
      >
        <Row id="public-faqs-title">
          <Col>
            <h4>{t(`${categoryType}.faqs.body.title`)}</h4>
          </Col>
        </Row>
        <Row id="public-faqs-body">
          <Col>
            <FAQs categoryType={categoryType} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default withRouter(Faqs);
