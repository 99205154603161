import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import AdminNavbarForm from './AdminNavbarForm';
import { updateAdminNavbarRequest } from '../../../requests/admin/navbar';

const AdminNavbarEdit = props => {
  const {
    isRoot,
    closeModal,
    updateData,
    editCategoryData,
    barType,
    language
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [navbar, setNavbar] = useState(editCategoryData);
  const dispatch = useDispatch();

  const handleSuccessUpdate = () => {
    updateData();
    closeModal();
  };

  const handleUpdateRequest = _navbar => {
    const navbarId = _navbar.navbar.id;
    updateAdminNavbarRequest(navbarId, {
      dispatch,
      params: _navbar,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <AdminNavbarForm
      navbar={navbar}
      action="edit"
      formRequest={handleUpdateRequest}
      isRoot={isRoot}
      barType={barType}
      language={language}
    />
  );
};

export default connect()(AdminNavbarEdit);
