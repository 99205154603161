import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInput, FormikCheckBox } from '../Utils/Input';

const LeaderEducationForm = ({ forNestedAttributes, index, parentModel }) => {
  const modelName = `${parentModel}[leaderEducationsAttributes][${index}]`;
  const { errors, touched } = useFormikContext();

  return (
    <Col className="nested-row-form-container">
      <Row>
        <Col className="d-flex justify-content-center title-label-container">
          {forNestedAttributes && (
            <p className="title-label">{`Formación académica ${index + 1}`}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="details-label-container">
          <p className="details-label">Detalles de estudios</p>
        </Col>
      </Row>
      <Row className="d-flex align-items-end">
        <Col>
          <Field name={`${modelName}[title]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Título o grado académico"
                error={getIn(errors, `${modelName}[title]`)}
                touched={getIn(touched, `${modelName}[title]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[universityName]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Nombre de la institución educativa"
                error={getIn(errors, `${modelName}[universityName]`)}
                touched={getIn(touched, `${modelName}[universityName]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Posición"
                error={getIn(errors, `${modelName}[position]`)}
                touched={getIn(touched, `${modelName}[position]`)}
              />
            )}
          </Field>
        </Col>
        <Col className="d-flex justify-content-center pb-3">
          <Field name={`${modelName}[active]`}>
            {({ field }) => (
              <FormikCheckBox
                {...field}
                field={field}
                label="Activo"
                error={getIn(errors, `${modelName}[active]`)}
                touched={getIn(touched, `${modelName}[active]`)}
                custom
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default LeaderEducationForm;
