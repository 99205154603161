import ApiService from '../services/apiService';

export const indexRoutesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/routes${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showRouteRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/routes/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
