import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useTranslation } from 'react-i18next';

import indexNavbarRequest from '../../../requests/navbar';
import indexOfficesRequest from '../../../requests/office';
import MainLogo from '../MainLogo';
import facebookImg from '../../../assets/images/facebook.webp';
import instagramImg from '../../../assets/images/instagram.webp';
import pinterestImg from '../../../assets/images/pinterest.webp';
import { availableSocialMedia } from '../../../services/utils';
import IcoMoon from '../Icon';

const Footer = props => {
  const { settings, cargo } = props;
  const [data, setData] = useState([]);
  const [offices, setOffices] = useState([]);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const fetchFooterData = () => {
    let barType =
      localStorage.getItem('language') === 'en' ? 'footerEnglish' : 'footer';
    if (cargo) {
      barType = 'footerCargo';
    }

    indexNavbarRequest({
      dispatch,
      params: {
        actives: true,
        bar_type: barType,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setData(camelCaseRecursive(result.data.data));
      }
    });
  };

  const fetchOfficesData = () => {
    indexOfficesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        sort_column: 'position'
      },
      successCallback: result => {
        setOffices(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(fetchFooterData, [cargo]);
  useEffect(fetchOfficesData, []);

  const desktopFooterLogo = () => {
    if (settings?.footerLogo !== '') {
      return settings.footerLogo;
    }
    return undefined;
  };

  const getSocialIcon = socialMedia => {
    const socialMediaData = {
      facebook: {
        link: 'https://www.facebook.com/NavimagFerries/',
        src: facebookImg,
        alt: 'facebookImg'
      },
      instagram: {
        link: 'https://www.instagram.com/navimag_ferries/',
        src: instagramImg,
        alt: 'instagramImg'
      },
      pinterest: {
        link: 'https://www.pinterest.cl/Navimag_Ferries/',
        src: pinterestImg,
        alt: 'pinterestImg'
      }
    };

    if (socialMedia in socialMediaData) {
      const { src, alt, link } = socialMediaData[socialMedia];
      const isFound = Object.keys(settings).find(
        settingKey => settingKey === `${socialMedia}Link`
      );
      const handleClick = () => {
        window.open(isFound ? settings[`${socialMedia}Link`] : link, '_blank');
      };

      return (
        <div
          key={`footer-div-${socialMedia}`}
          type="button"
          aria-hidden="true"
          className="social-media-icon-btn"
          onClick={handleClick}
        >
          <img className="social-media-icon" src={src} alt={alt} />
        </div>
      );
    }

    return null; // Opcional: Manejar un caso donde el nombre del icono no coincide con ninguno conocido
  };

  return (
    <footer className="footer">
      <Row>
        <div className="col-lg-2">
          <MainLogo className="footer-width" src={desktopFooterLogo()} />
          <p className="subtitle-footer pt-20px mb-2 mt-3">
            {t('footer.followUs')}
          </p>
          <div className="d-flex">
            {availableSocialMedia.map(socialMedia =>
              getSocialIcon(socialMedia.name)
            )}
          </div>
        </div>

        {data?.map((category, index) => {
          return category?.nestedCategories.length ? (
            <div
              key={`footer-div-${category.category}-${category.id}`}
              className="col-lg-2 col-md-12 pt-20px"
            >
              <p
                key={`footer-category-${category.category}-${category.id}`}
                className="subtitle-footer"
              >
                {category.category}
              </p>
              {category?.nestedCategories?.map(subcategory => {
                return (
                  <a
                    key={`footer-subcategory-${subcategory.category}-${subcategory.id}`}
                    href={subcategory.link}
                    className="row"
                  >
                    {subcategory.category}
                  </a>
                );
              })}
            </div>
          ) : (
            <div
              key={`footer-div-${category.category}-${category.id}`}
              className="col-lg-2 col-md-12 pt-20px"
            >
              <p
                key={`footer-category-${category.category}-${category.id}`}
                className="subtitle-footer"
              >
                {category.category}
              </p>
              {data.length === index + 1 && (
                <p className="subtitle-footer pt-20px">
                  {t('footer.followUs')}
                </p>
              )}
            </div>
          );
        })}

        {!cargo && (
          <div className="col-lg-2 col-md-12 pt-20px">
            {offices?.map(office => {
              return (
                <a
                  key={`footer-div-${office.id}`}
                  href={`tel:${office.phone}`}
                  className="row mb-3 p-4 offices-btn"
                >
                  <p className="subtitle-footer col-sm-12 pl-0 pr-0 ">
                    {office.name}
                  </p>
                  <p
                    className="col-sm-12 pl-0 pr-0"
                    style={{ color: 'lightgray' }}
                  >
                    {office.phone}
                  </p>
                </a>
              );
            })}
          </div>
        )}

        {cargo && (
          <div className="col-lg-3 col-md-12 pt-20px ml-auto mr-4">
            <a
              key="footer-div-01"
              href="/cargo/leads"
              className="row mb-3 p-4 offices-btn"
            >
              <p className="subtitle-footer col-sm-12 pl-0 pr-0 ">
                Cotiza tu carga Online
              </p>
              <p className="col-sm-12 pl-0 pr-0" style={{ color: 'lightgray' }}>
                Recibe un precio aproximado en minutos a tu mail
              </p>
            </a>
            <a
              key="footer-div-02"
              href="tel: +56 9 6122 7608"
              className="row mt-4 p-4 offices-btn"
            >
              <p className="subtitle-footer col-sm-12 pl-0 pr-0 ">
                Contáctanos
              </p>
              <p className="col-sm-12 pl-0 pr-0" style={{ color: 'lightgray' }}>
                +56 9 6122 7608
              </p>
            </a>
            <Button
              type="submit"
              variant="primary"
              className="d-flex justify-content-center align-items-center w-100 row mt-4 p-2"
              onClick={() =>
                window.open('https://navimag.nnodes.com/mapa', '_blank')
              }
            >
              Rastrea el Ferry
              <div className="box-icon-truck">
                <IcoMoon icon="truck" size={14} />
              </div>
            </Button>
          </div>
        )}
      </Row>
      <Row>
        <div className="col-md-3 col-sm-12 m-auto signature">
          <a
            href="https://nnodes.com"
            rel="noreferrer"
            target="_blank"
            className="m-auto"
          >
            Desarrollo y Diseño por<b className="ml-1">Nnodes</b>
          </a>
        </div>
      </Row>
    </footer>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  const { settings } = state.utils;
  return {
    ongoingRequest,
    signedIn,
    settings
  };
};

export default connect(mapStateToProps)(Footer);
