import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imagen from '../../../assets/images/guide_01.png';
import LeadNew from '../../../components/Leads/LeadNew';
import Slides from '../../../components/Utils/Slides';
import { indexSlidesRequest } from '../../../requests/slides';
import {
  indexGuidesRequest,
  showGuidesRequest
} from '../../../requests/guides';

const GuidesSection = ({ settings, guideId }) => {
  const language = localStorage.getItem('language') || 'es';
  const [principalGuide, setPrincipalGuide] = useState({});
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const principalGuideRequest = () => {
    indexGuidesRequest({
      dispatch,
      params: {
        active: true,
        principal_guide:
          language === 'es'
            ? settings.principalGuideEs
            : settings.principalGuideEn,
        language
      },
      successCallback: result => {
        setPrincipalGuide(camelCaseRecursive(result.data.data[0]));
      }
    });
  };

  const selectedGuideRequest = () => {
    showGuidesRequest(guideId, {
      dispatch,
      successCallback: response => {
        setPrincipalGuide(camelCaseRecursive(response.data));
      }
    });
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: 'background_guide'
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };

  const setCurrentGuide = () => {
    if (settings) principalGuideRequest();
    if (guideId) selectedGuideRequest();
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  useEffect(setCurrentGuide, [settings, guideId]);
  useEffect(slidesRequest, []);

  return (
    <Row className="guides-section">
      <Slides items={slides} />
      <Col className="title_text mt-4 d-flex flex-column justify-content-center align-items-center ">
        <img
          className="guide-image"
          src={principalGuide?.attachedPreviewImage?.fileUrl || imagen}
          alt="Guide"
        />
        <p
          style={{
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            maxWidth: '70%',
            textAlign: 'center'
          }}
        >
          {principalGuide?.title || t('tourism.landing.guideSection.title')}
        </p>
        <p
          style={{
            color: 'white',
            fontSize: '15px',
            maxWidth: '70%',
            textAlign: 'center'
          }}
        >
          {principalGuide?.description ||
            t('tourism.landing.guideSection.description')}
        </p>
        <Button
          as={Link}
          to={{ pathname: '/videos-guides', state: { type: 'guides' } }}
          className="m-4 see-guides-btn"
        >
          {t('tourism.landing.guideSection.showAllGuides')}
        </Button>
      </Col>
      <Col className="title_text mt-4 d-flex flex-column justify-content-center align-items-center col-12 col-lg-6">
        <LeadNew guideId={principalGuide?.id} />
      </Col>
    </Row>
  );
};

export default GuidesSection;
