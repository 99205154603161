import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const TourismOurFerriesCard = ({
  colorText,
  colorCard,
  colorBorder,
  title,
  description,
  imgSrc
}) => {
  return (
    <div className="card-body-placeholder mb-4">
      <Card
        bg={colorCard}
        text={colorText}
        className="our-ferries-characteristics-card text-center"
        // style={{ width: size }}
        border={colorBorder}
      >
        <Card.Img className="card-image-container" variant="top" src={imgSrc} />
        <div className="card-body-opacity" />
        <Card.Body className="card-body">
          <Card.Text className="card-title-text">{title}</Card.Text>
          <Card.Text className="card-body-category-text">
            {description}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

TourismOurFerriesCard.propTypes = {
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

TourismOurFerriesCard.defaultProps = {
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default TourismOurFerriesCard;
