import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

const FormStatus = ({
  statusImageSource = '',
  alertText = '',
  mainText = '',
  descriptionText = '',
  buttonText = '',
  buttonOnPress = () => {}
}) => {
  return (
    <Row>
      <Col className="flex align-items-center" md={10}>
        <Row className="flex justify-content-center align-items-center mb-4">
          <img
            src={statusImageSource}
            alt="Success"
            style={{ height: '100px' }}
          />
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col>
            {alertText && (
              <h3 className="text-center text-primary">{alertText}</h3>
            )}
            {mainText && <h5 className="text-center mt-3 mb-4">{mainText}</h5>}
            {descriptionText && (
              <p className="text-center mt-1 mb-4">{descriptionText}</p>
            )}
            <Row className="flex justify-content-center align-items-center">
              <Button variant="primary-outline" onClick={buttonOnPress}>
                {buttonText}
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormStatus;
