import React from 'react';
import '../../../assets/stylesheets/components/utils/category-filter.scss';

const CategoryFilter = ({
  categories,
  setSelectedCategory,
  selectedCategory,
  setTextInput
}) => {
  return (
    <div className="category-filter">
      {categories?.map(category => (
        <div
          aria-hidden="true"
          onClick={() => {
            setSelectedCategory(category);
            setTextInput('');
          }}
          className={`category-selector-box ${
            selectedCategory === category
              ? 'category-selector-box-active'
              : 'category-selector-hover'
          }`}
        >
          <p
            className={`category-selector-box-text ${
              selectedCategory === category ? 'text-light' : ''
            }`}
          >
            {category}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
