import { getIn } from 'formik';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

const responseToBlob = response => {
  const format = response.headers['content-type'].split('/')[1];
  let blob = '';
  const { data } = response;
  if (format === 'xlsx') {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i !== byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } else if (format === 'csv') {
    const readUTF8 = data.toString('utf8');
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([data], { type: response.headers['content-type'] });
  }
  return blob;
};

const parseFilename = headers => {
  let filename = '';
  const disposition = headers['content-disposition'];
  if (
    disposition &&
    (disposition.indexOf('attachment') !== -1 ||
      disposition.indexOf('inline') !== -1)
  ) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

function getSubStringBeforeChar(str, char) {
  // Find the index of the first occurrence of char
  const indexOfChar = str.indexOf(char);

  // Check if there's a char (indexOfDash !== -1) and return the substring before it
  // Otherwise, return the original string if there's no dash
  return indexOfChar !== -1 ? str.substring(0, indexOfChar) : str;
}

const downloadFile = response => {
  const blob = responseToBlob(response);
  const filename = parseFilename(response.headers);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${filename}`;
  link.click();
  window.URL.revokeObjectURL(url);
};

const insertString = (value, position, str) => {
  return value.slice(0, position) + str + value.slice(position);
};

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1);

const capitalizeString = str => {
  if (!str) return '';
  const lower = str.toLowerCase();
  return capitalizeFirstLetter(lower);
};

const lowerCaseTextFormat = text => {
  if (text) return text.toLowerCase();
  return '';
};

const rutFormat = value => {
  let rut = value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  if (rut.length > 1) {
    rut = insertString(rut, -1, '-');
  }
  if (rut.length > 5) {
    rut = insertString(rut, -5, '.');
  }
  if (rut.length > 9) {
    rut = insertString(rut, -9, '.');
  }
  return rut;
};

const rutValidation = rutInput => {
  // FORMAT
  const rut = rutInput
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  const body = rut.slice(0, -1);
  let dv = rut.slice(-1).toUpperCase();
  // Compute
  let sum = 0;
  let multiple = 2;
  // For every digit
  for (let i = 1; i <= body.length; i += 1) {
    // Get product
    const index = multiple * rut.charAt(body.length - i);
    // add to count
    sum += index;
    // In range [2,7]
    if (multiple < 7) {
      multiple += 1;
    } else {
      multiple = 2;
    }
  }
  // Division Remainder
  const dvComputed = 11 - (sum % 11);
  // (0 & K)
  dv = dv === 'K' ? 10 : dv;
  dv = dv === '0' ? 11 : dv;
  // Is valid?
  if (`${dvComputed}` !== `${dv}`) {
    return false;
  }
  return true;
};
const isValidRut = rut => {
  const result =
    (/^\d{1,2}\.?\d{3}\.?\d{3}[-]?[0-9kK]{1} *$/i.test(rut) ||
      /^\d{1,2}\.\d{3}\.\d{3}[-]?[0-9kK]{1} *$/i.test(rut)) &&
    rutValidation(rut);
  return result;
};

const validRutInput = e => {
  const re = /^[0-9kK\b]+$/;
  const rawRut = e.target.value
    .replace(/\s/g, '')
    .replace(/\./g, '')
    .replace(/-/g, '');
  return e.target.value === '' || re.test(rawRut);
};

const validPhoneInput = e => {
  const re = /^[0-9\b]+$/;
  return e.target.value === '' || re.test(e.target.value);
};

const isObject = v => typeof v === 'object';
const camelCase = str => str.replace(/[_.-](\w|$)/g, (_, x) => x.toUpperCase());

const camelCaseEmptyStringRecursive = obj => {
  if (obj === null) return '';

  if (Array.isArray(obj)) {
    return obj.map(value => camelCaseEmptyStringRecursive(value));
  }

  if (isObject(obj)) {
    const newObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      newObject[camelCase(key)] = camelCaseEmptyStringRecursive(value);
    });
    return newObject;
  }
  return obj;
};

const filterArray = (array, query, keys) => {
  if (query === '') {
    return array;
  }
  if (array.length === 0) {
    return array;
  }
  return array.filter(item => {
    let inQuery = false;
    keys.forEach(key => {
      let vKey = getIn(item, key);
      vKey = Array.isArray(vKey) ? vKey.map(k => k.label) : vKey;
      if (
        String(vKey)
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        inQuery = true;
      }
    });
    return inQuery;
  });
};

const sortByAttribute = (array, attribute, order = 'asc') => {
  return array.sort((a, b) => {
    const aAttr = a[attribute]?.toLowerCase();
    const bAttr = b[attribute]?.toLowerCase();

    if (order === 'desc') {
      if (aAttr < bAttr) return 1;
      if (bAttr < aAttr) return -1;
    } else {
      if (aAttr > bAttr) return 1;
      if (bAttr > aAttr) return -1;
    }
    return 0;
  });
};

const clpFormat = new Intl.NumberFormat('es-CL', {
  currency: 'CLP',
  style: 'currency'
});
const usdFormat = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  style: 'currency'
});

const uuidV4 = () => {
  const uuid = uuidv4();
  return uuid;
};

const redirectToReservation = values => {
  const urlTicketPage = process.env.REACT_APP_URL_TICKET_PAGE;
  const { route, month, year, adults, children, infants } = values.bookTrip;
  const language = localStorage.getItem('language') || 'es';
  const languageParam = `${language}-${language === 'es' ? 'ES' : 'US'}`;

  const mdata = `CodigoRuta=${route}&MesNumero=${month}&AnioViaje=${year}&NumeroAdultos=${adults}&NumeroNinios=${children}&NumeroInfantes=${infants}&NombreRuta=&MesNombre="‌"`;
  const url = `${urlTicketPage}/ecommerce/Dispatcher.aspx?Modo=step02&Usuario=Anonimo&Empresa=NMAG&Idioma=${languageParam}&${mdata}`;
  window.open(url, '_blank');
};
const redirectToReservationWithoutParams = () => {
  const urlTicketPage = process.env.REACT_APP_URL_TICKET_PAGE;
  const language = localStorage.getItem('language') || 'es';
  const languageParam = `${language}-${language === 'es' ? 'ES' : 'US'}`;
  const url = `${urlTicketPage}/ecommerce/Dispatcher.aspx?Empresa=NMAG&Idioma=${languageParam}&Usuario=Anonimo&Modo=NoPago`;
  window.open(url, '_blank');
};

function generateSummary(values) {
  const language = localStorage.getItem('language') || 'en';

  const translations = {
    en: {
      adults: 'adults',
      children: 'children',
      infants: 'infants',
      and: 'and'
    },
    es: {
      adults: 'adultos',
      children: 'niños',
      infants: 'infantes',
      and: 'y'
    }
  };

  const currentTranslation = translations[language];
  const summaryParts = [];

  if (values.adults > 0) {
    summaryParts.push(`${values.adults} ${currentTranslation.adults}`);
  }

  if (values.children > 0) {
    summaryParts.push(`${values.children} ${currentTranslation.children}`);
  }

  if (values.infants > 0) {
    summaryParts.push(`${values.infants} ${currentTranslation.infants}`);
  }

  if (summaryParts.length === 0) {
    return null;
  }

  if (summaryParts.length === 1) {
    return summaryParts[0];
  }

  const lastPart = summaryParts.pop();
  const joinedParts = summaryParts.join(', ');
  return `${joinedParts} ${currentTranslation.and} ${lastPart}`;
}

const languages = { es: 'Español', en: 'English', fr: 'Français' };

const languageOptions = [
  { value: 'es', label: 'Español' },
  { value: 'en', label: 'English' }
];

const routeOptions = [
  { value: 'both', label: 'Turismo y Carga' },
  { value: 'cargo', label: 'Carga' }
];
const supportedImagaFormats = ['image/jpg', 'image/jpeg', 'image/png'];

const availableSocialMedia = [
  { name: 'facebook' },
  { name: 'instagram' },
  { name: 'pinterest' }
];

const nationalityOptions = [
  { value: 'Chileno', label: 'Chileno' },
  { value: 'Extranjero', label: 'Extranjero' }
];

const binaryOptions = [
  { value: 'true', label: 'Sí' },
  { value: 'false', label: 'No' }
];

const cargoRateImagesOptions = [
  {
    value: 'towing',
    label: 'Carros y Semi-Remolques',
    linealLength: true,
    roundTrip: false
  },
  { value: 'buses', label: 'Buses', linealLength: true, roundTrip: false },
  { value: 'trucks', label: 'Camiones', linealLength: true, roundTrip: false },
  {
    value: 'articulated_trucks',
    label: 'Camiones articulados',
    linealLength: true,
    roundTrip: false
  },
  {
    value: 'machinery',
    label: 'Maquinarias',
    linealLength: true,
    roundTrip: false
  },
  {
    value: 'lightweight_vehicles',
    label: 'Vehículos livianos',
    linealLength: false,
    roundTrip: true
  },
  {
    value: 'motorcycles',
    label: 'Motocicletas',
    linealLength: false,
    roundTrip: true
  },
  {
    value: 'vans',
    label: 'Furgonetas',
    linealLength: true,
    roundTrip: false
  },
  {
    value: 'carrier',
    label: 'Transportista',
    linealLength: false,
    roundTrip: true
  }
];

const cargoRateCardCategoryOptions = [
  { value: 'towing_category', label: 'Rodados' },
  { value: 'lightweight_category', label: 'Vehículos menores' },
  { value: 'machinery_category', label: 'Maquinaria' },
  { value: 'general_category', label: 'Carga General' }
];

const tourismShipDirectionOptions = {
  north_south: 'Norte - Sur',
  south_north: 'Sur - Norte'
};

const cargoRateRatesTableOptions = [
  {
    value: 'buses_small_trucks_towing',
    label: 'Camiones, Buses, Carros y Semirremolques'
  },
  { value: 'no_category', label: 'Sin categoría' }
];

export {
  camelCaseEmptyStringRecursive,
  capitalizeString,
  clpFormat,
  downloadFile,
  languages,
  languageOptions,
  routeOptions,
  nationalityOptions,
  cargoRateImagesOptions,
  cargoRateCardCategoryOptions,
  cargoRateRatesTableOptions,
  binaryOptions,
  isValidRut,
  filterArray,
  rutFormat,
  availableSocialMedia,
  sortByAttribute,
  uuidV4,
  validRutInput,
  validPhoneInput,
  lowerCaseTextFormat,
  tourismShipDirectionOptions,
  supportedImagaFormats,
  redirectToReservation,
  redirectToReservationWithoutParams,
  usdFormat,
  generateSummary,
  getSubStringBeforeChar
};
