import React, { useState } from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useTranslation } from 'react-i18next';

import LeadForm from './LeadForm';
import createLeadRequest from '../../requests/lead';

const LeadNew = ({ guideId }) => {
  const [guideData, setGuideData] = useState(undefined);
  const [lead] = useState({
    name: '',
    email: '',
    active: true,
    clientType: '',
    destinyType: '',
    travelTime: '',
    travelerType: '',
    phone: 'N/A',
    message: 'N/A'
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSuccessCreate = response => {
    setIsLoading(false);
    setGuideData(camelCaseRecursive(response.data.guide));
  };

  const openGuide = () => {
    window.open(guideData.file.fileUrl);
  };

  const handleCreateRequest = _lead => {
    setIsLoading(true);
    const leadParams = {
      ..._lead,
      guideId
    };
    leadParams.lead.title = 'Lead formulario guía';
    leadParams.lead.labels = 'Descargó guía';

    delete leadParams.lead.phone;
    delete leadParams.lead.message;

    createLeadRequest({
      dispatch,
      params: leadParams,
      successCallback: response => handleSuccessCreate(response),
      failureCallback: () => setIsLoading(false)
    });
  };

  const successGuideMessage = () => {
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center w-100 h-100 text-center">
        <Row>
          <Col>
            <h2
              className="mb-4"
              style={{ fontWeight: 'bold', fontSize: '20px' }}
            >
              {t('tourism.landing.leadForm.successModalTitle')}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="subtitle">{guideData.title}</p>
          </Col>
        </Row>
        <Button
          type="submit"
          onClick={openGuide}
          className="btn btn-success w-100 p-2 mt-4 mb-2"
        >
          {t('tourism.landing.leadForm.successModalButton')}
        </Button>
      </Container>
    );
  };

  return (
    <>
      <Row
        className="lead-new-form"
        style={{ minWidth: '90%', minHeight: '400px' }}
      >
        <Col>
          {guideData && successGuideMessage()}
          {!guideData && (
            <>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {t('tourism.landing.leadForm.title')}
              </p>
              <LeadForm
                lead={lead}
                action="new"
                formRequest={handleCreateRequest}
                isLoading={isLoading}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default connect()(LeadNew);
