import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

import { uuidV4 } from '../../../services/utils';
import graduationCapSolid from '../../../assets/images/ourLeaders/graduation-cap-solid.svg';

const OurLeaderCard = ({ colorText, colorCard, colorBorder, ourLeader }) => {
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className="our-leaders-characteristics-card text-center"
      // style={{ width: size }}
      border={colorBorder}
    >
      <Card.Img
        className="card-image-container"
        src={ourLeader.fileInfo.fileUrl}
      />
      <Card.Body>
        <Row className="pl-2 pr-2 mb-3 our-leader-name-occupation-container justify-content-start">
          <Col className="d-flex flex-column justify-content-center align-items-start">
            <p className="card-name-text">{ourLeader.name}</p>
            <p className="card-occupation-text">{ourLeader.occupation}</p>
          </Col>
        </Row>

        {ourLeader?.leaderEducationsAttributes?.map(education => {
          return (
            <Row
              className="pl-2 pr-2 mb-2 justify-content-start"
              key={uuidV4()}
            >
              <Col className="d-flex justify-content-start align-items-center">
                <img src={graduationCapSolid} alt="userSolid01" />
                <Row className="pl-3">
                  <p className="card-profession-text pl-2">{education.title}</p>
                  <p className="pl-2 pr-2">-</p>
                  <p className="card-school-name-text">
                    {education.universityName}
                  </p>
                </Row>
              </Col>
            </Row>
          );
        })}
        <Row className="mt-4 pl-2 pr-2 our-leader-goals-container">
          <Col>
            <p className="card-goals-text">{ourLeader.caption}</p>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

OurLeaderCard.propTypes = {
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

OurLeaderCard.defaultProps = {
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default OurLeaderCard;
