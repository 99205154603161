import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';

import '../../../assets/stylesheets/components/rates/Rates.scss';
import RouteFilter from '../../Utils/Filter/RouteFilter';
import { uuidV4 } from '../../../services/utils';

const travelTime = {
  DALKA: 22,
  AURORA: 72,
  ESPERANZA: 72
};

const Itineraries = ({ cargoItineraries }) => {
  const [disclaimer, setDisclaimer] = useState('');
  const [selectedRoute, setSelectedRoute] = useState(cargoItineraries[0]);

  const language = localStorage.getItem('language') || 'es';

  const { settings } = useSelector(state => state.utils);

  const setDisclaimerCode = () => {
    const disclaimerValue = settings.cargoRatesAvailability || '';
    setDisclaimer(disclaimerValue);
  };

  useEffect(setDisclaimerCode, [settings, language]);
  useEffect(() => {
    setSelectedRoute(cargoItineraries[0]);
  }, [cargoItineraries]);

  const itineraryHeader = () => (
    <div className="cargo-rates-header">
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Puerto</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Mes</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Nave</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Zarpe estimado</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Dia zarpe</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Arribo estimado</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Dia arribo</p>
      </div>
    </div>
  );

  const itineraryRowItem = (cargoItinerary, style) => {
    const originPort = cargoItinerary.routeDestination.split('-')[0];
    const departureDate = new Date(cargoItinerary.fechaZarpe);
    const arriveDate = new Date(cargoItinerary.fechaZarpe);
    arriveDate.setHours(
      arriveDate.getHours() + travelTime[cargoItinerary.ship]
    );

    return (
      <div className="cargo-rates-row mb-2" key={uuidV4()}>
        <div
          className={`cargo-rates-row-item ${style} cargo-border-radius-left`}
        >
          <p>{originPort}</p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <p>
            {departureDate.toLocaleString('default', {
              month: 'long'
            })}
          </p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <p>{cargoItinerary.ship}</p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <p>
            {departureDate.toLocaleString('default', {
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <p>{departureDate.toLocaleString('default', { weekday: 'long' })}</p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <p>
            {arriveDate.toLocaleString('default', {
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit'
            })}
          </p>
        </div>
        <div
          className={`cargo-rates-row-item cargo-border-radius-right ${style}`}
        >
          <p>{arriveDate.toLocaleString('default', { weekday: 'long' })}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="my-4 cargo-rates-content-box">
      <h3 className="cargo-rates-title text-primary">Itinerarios de carga</h3>
      <p className="mt-3 mb-4">{disclaimer}</p>
      <>
        <RouteFilter
          routes={cargoItineraries}
          setSelectedRoute={setSelectedRoute}
          selectedRoute={selectedRoute}
          customContainerStyle="cargo-route-selector-container"
          customSelectorBoxStyle="cargo-route-selector-box"
          customSelectorBoxTextStyle="cargo-route-selector-box-text"
          customSelectedBoxStyle="cargo-route-selector-box-active"
        />

        <Card className="p-4 mt-4">
          {itineraryHeader()}
          {selectedRoute?.itineraryData.map((itinerary, index) => {
            const style = index % 2 ? 'cargo-row-lightgray' : 'cargo-row-gray';
            return itineraryRowItem(itinerary, style);
          })}
        </Card>
      </>
    </div>
  );
};

export default Itineraries;
