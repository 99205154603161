import React from 'react';
import { Form } from 'react-bootstrap';

const FormikInput = ({
  placeholder,
  label,
  size,
  margin,
  error,
  touched,
  helpText,
  inputType,
  isTextArea = false,
  abbr,
  rows = 1,
  ...props
}) => (
  <Form.Group className={margin}>
    {label ? (
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    ) : (
      false
    )}
    <Form.Control
      size={size}
      placeholder={placeholder}
      type={isTextArea ? undefined : inputType}
      as={isTextArea ? inputType : undefined}
      className={error && touched ? 'is-invalid' : ''}
      style={{
        minHeight: isTextArea ? '100px' : 'auto',
        border: '1px solid rgba(0,0,0,0.296)'
      }}
      rows={rows}
      {...props}
    />
    {error && touched ? (
      <Form.Text className="text-danger">{error}</Form.Text>
    ) : null}
    {helpText ? <Form.Text className="text-muted">{helpText}</Form.Text> : null}
  </Form.Group>
);

export default FormikInput;
