import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import AdminFooterIndex from '../Footer/AdminFooterIndex';
import AdminNavbarIndex from '../Navbar/AdminNavbarIndex';
import OfficesIndex from '../Offices/OfficesIndex';

const NavbarAndFoote = props => {
  const { location, cargo = false } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Navbar');
  const tourismModels = ['Navbar', 'Footer', 'Oficinas'];
  const cargoModels = ['Navbar', 'Footer'];

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Navbar & Footer</h4>
        </Col>
      </Row>
      <ModelFilter
        models={cargo ? cargoModels : tourismModels}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '55px' }}>
        <Col className="mt-4">
          {selectedModel === 'Navbar' && <AdminNavbarIndex cargo={cargo} />}
          {selectedModel === 'Footer' && <AdminFooterIndex cargo={cargo} />}
          {selectedModel === 'Oficinas' && !cargo && <OfficesIndex />}
        </Col>
      </Row>
    </>
  );
};

export default NavbarAndFoote;
