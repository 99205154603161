import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import GuidesIndex from '../Guides/GuidesIndex';
import VideosIndex from '../Videos/VideosIndex';

const EbooksAndVideos = props => {
  const { location } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Guías');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">E-books & Videos</h4>
        </Col>
      </Row>
      <ModelFilter
        models={['Guías', 'Videos']}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '80px' }}>
        <Col>
          {selectedModel === 'Guías' && <GuidesIndex />}
          {selectedModel === 'Videos' && <VideosIndex />}
        </Col>
      </Row>
    </>
  );
};

export default EbooksAndVideos;
