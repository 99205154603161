import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rating } from 'react-simple-star-rating';

const ReviewCard = ({
  size,
  colorText,
  colorCard,
  colorBorder,
  reviewData
}) => {
  const {
    name,
    lastName,
    title,
    comment,
    fileInfo,
    review,
    reviewLink
  } = reviewData;

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  return (
    <Card
      bg={colorCard}
      text={colorText}
      className="card-reviewers"
      style={{ width: size }}
      border={colorBorder}
    >
      <Card.Body>
        <Row>
          <Col md={4}>
            <Card.Img
              style={{
                height: 70,
                width: 70,
                borderRadius: 50,
                marginBottom: 15
              }}
              variant="top"
              src={fileInfo.fileUrl}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 10, offset: 3 }}>
            <Card.Title>{title}</Card.Title>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            {(name || lastName) && (
              <Card.Subtitle className="text-muted">
                {name} {lastName}
              </Card.Subtitle>
            )}
          </Col>
          <Col md={{ span: 6, offset: 3 }}>
            <Rating initialValue={review} size={25} readonly />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Card.Text>{comment}</Card.Text>
          </Col>
        </Row>
        <Row className="mt-1">
          <Button
            onClick={() => window.open(`${reviewLink}`, '_blank')}
            target="_blank"
            type="submit"
            variant="primary-outline"
            className="ml-auto mr-3"
          >
            {t('tourism.landing.reviews.showComment')}
          </Button>
        </Row>
      </Card.Body>
    </Card>
  );
};

ReviewCard.propTypes = {
  size: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

ReviewCard.defaultProps = {
  size: '100%',
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default ReviewCard;
