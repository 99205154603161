import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import BlogsGrid from '../../components/Blogs/BlogsGrid';
import SEO from '../../components/Utils/SEO/SEO';
import FirstSection from '../Home/Landing/FirstSection';
import { CenteredModal, FormBookTrip } from '../../components';
import { redirectToReservation } from '../../services/utils';
import { useWindowSize } from '../../hooks';

const Blogs = ({ customParams }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const { t } = useTranslation();
  const { windowSize } = useWindowSize();
  const routesBreakdownRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <SEO
        title={t('tourism.blogs.seo.title')}
        description={t('tourism.blogs.seo.description')}
      />
      <div ref={routesBreakdownRef} />
      <FirstSection
        isDesktop={isDesktop}
        setShowModal={setShowModal}
        handleCreateRequest={redirectToReservation}
        title={t('tourism.blogs.blogsGrid.travelBlog')}
        slideType="blog_header"
      />
      <Row className="d-flex bottom-animation">
        <Col className="title_text mt-4">
          <BlogsGrid customParams={customParams} />
        </Col>
      </Row>
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

export default Blogs;
