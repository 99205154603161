import { defineAbility, AbilityBuilder } from '@casl/ability';
import jwt from 'jwt-decode';
import camelCaseRecursive from 'camelcase-keys-recursive';

import tourismAdminAbility from './Role/tourismAdminAbility';
import cargoAdminAbility from './Role/cargoAdminAbility';
import touristAbility from './Role/touristAbility';
import cargoAbility from './Role/cargoAbility';

const adminAbility = can => {
  can('manage', 'all');
};

const nonCompanyAbility = (can, cannot) => {
  can('read', 'something');
  cannot('manage', 'app');
};

const setUpAbility = (can, cannot) => {
  const payload = localStorage.getItem('jwt');
  if (payload) {
    const { roles, email } = camelCaseRecursive(jwt(payload));

    if (!email) {
      nonCompanyAbility(can, cannot);
    } else {
      if (roles?.includes('admin')) {
        adminAbility(can, cannot);
      }
      if (roles?.includes('tourist') || roles?.includes('tourism_admin')) {
        touristAbility(can, cannot);
      }
      if (roles?.includes('tourism_admin')) {
        tourismAdminAbility(can, cannot);
      }
      if (roles?.includes('cargo') || roles?.includes('cargo_admin')) {
        cargoAbility(can, cannot);
      }
      if (roles?.includes('cargo_admin')) {
        cargoAdminAbility(can, cannot);
      }
    }
  }
};

export default defineAbility((can, cannot) => setUpAbility(can, cannot));

export const updateAbility = ability => {
  const { can, cannot, rules } = new AbilityBuilder();
  setUpAbility(can, cannot);
  ability.update(rules);
};
