import React from 'react';
import { Row, Col, Button, Container } from 'react-bootstrap';

const ImageAndText = ({
  isDesktop,
  srcImg,
  title,
  subtitle,
  description,
  btnText,
  btnVariant = 'primary',
  onClick = () => {}
}) => {
  return (
    <Container>
      <Row className="image-and-text-component align-items-center justify-content-center mt-4 mb-4">
        <Col
          className="our-ferries-image-container mb-4"
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <img
            className="d-block w-100 main-img"
            src={srcImg}
            alt="imagen Ferry Esperanza"
          />
        </Col>
        <Col
          md={isDesktop ? 7 : 12}
          className="d-flex flex-column justify-content-center col-right"
        >
          <h2 className="text-primary mb-2 title">{title}</h2>
          <h5 className="mb-3">{subtitle}</h5>
          <p>{description}</p>
          <div
            className={`d-flex mt-4 ${
              isDesktop ? 'justify-content-end' : 'justify-content-center'
            }`}
          >
            <Button
              onClick={onClick}
              variant={btnVariant}
              className="mr-3 btn-mobile px-3 py-2"
            >
              {btnText}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ImageAndText;
