import React from 'react';
import { useFormikContext } from 'formik';
import TourismRatesForm from './TourismRateForm';

import NestedForm from '../Utils/NestedForm';
import tourismRate from './tourismRate';

const TourismRatesAttributes = ({ modelName }) => {
  const { setFieldValue, values } = useFormikContext();
  const tourismRatesAttributes = values?.route?.tourismRatesAttributes;

  const mapResults = tourismRatesAttributes.map((body, index) => {
    if (body._destroy) {
      return undefined;
    }

    return (
      <TourismRatesForm
        key={`tourism-${index.toString()}`}
        index={index}
        parentModel={modelName}
        forNestedAttributes
      />
    );
  });

  return (
    <NestedForm
      attributesTitle="Tarifas de turismo"
      mapInputs={mapResults}
      arrayValues={tourismRatesAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${modelName}[tourismRatesAttributes]`}
      newAttributes={{ ...tourismRate }}
      addTitle="Agregar tarifa de turismo"
    />
  );
};

export default TourismRatesAttributes;
