import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { requestSignOut } from '../../actions/auth';
import '../../assets/stylesheets/screens/profile/profile.scss';
import person from '../../assets/images/person.svg';
import showMeRequest from '../../requests/auth';
import { CenteredModal } from '../../components';
import ChangePassword from '../Auth/ChangePassword';

const Profile = () => {
  const cancelToken = axios.CancelToken.source();
  const [user, setUser] = useState({ avatar: [] });
  const [showModal, setShowModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const dispatch = useDispatch();

  const dispatchGetMe = () => {
    showMeRequest({
      dispatch,
      params: { cancelToken: cancelToken.token },
      successCallback: response => {
        if (response.data.logged_in) {
          setUser(camelCaseRecursive(response.data.user_info));
        }
      }
    });
  };

  useEffect(() => {
    dispatchGetMe();
    return () => cancelToken.cancel();
  }, []);

  const avatarUrl = user.avatar.length ? user.avatar[0].url : '';

  const { firstName, lastName, email, assignedRoles } = user;
  const roles = {
    admin: 'Administrador',
    tourist: 'Turista'
  };

  const handleCloseModal = () => setShowModal(false);
  const handleCloseChangePasswordModal = () =>
    setShowChangePasswordModal(false);

  return (
    <div className="profile">
      <Row className="mb-4">
        <h4 className="text-primary profile-title">Mi perfil</h4>
        <div className="ml-auto header-buttons">
          <SessionButtons
            setShowModal={setShowModal}
            setShowChangePasswordModal={setShowChangePasswordModal}
          />
        </div>
      </Row>
      <Row className="align-items-center">
        <Col md={4} sm={12} className="profile-avatar-container">
          <div className="profile-image-container">
            <img
              src={avatarUrl || person}
              alt="hello"
              className={
                avatarUrl ? 'profile-image' : 'profile-image-placeholder'
              }
            />
          </div>
        </Col>
        <Col md={8} sm={12} className="profile-info-container">
          <h5 className="mb-3">
            {firstName} {lastName}
          </h5>
          <p>
            <span className="font-weight-bold">Correo: </span>
            {email}
          </p>
          <p>
            <span className="font-weight-bold">Roles Usuario: </span>
            {assignedRoles?.map(role => roles[role]).join(' - ')}
          </p>
        </Col>
      </Row>
      <div className="w-100 mt-4 bottom-buttons">
        <SessionButtons
          setShowModal={setShowModal}
          setShowChangePasswordModal={setShowChangePasswordModal}
        />
      </div>
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <CloseSessionModalContent
            dispatch={dispatch}
            handleCloseModal={handleCloseModal}
          />
        }
      />
      <CenteredModal
        size="md"
        onHide={handleCloseChangePasswordModal}
        onClickHide={handleCloseChangePasswordModal}
        show={showChangePasswordModal}
        body={<ChangePassword setModalShow={setShowChangePasswordModal} />}
      />
    </div>
  );
};

const CloseSessionModalContent = ({ dispatch, handleCloseModal }) => (
  <div className="profile-close-session-modal">
    <p>Estas por cerrar sesión, ¿quieres seguir?</p>
    <Row className="w-100 justify-content-between">
      <Button
        variant="light"
        className="profile-close-session-modal-back-btn"
        onClick={handleCloseModal}
      >
        Volver
      </Button>
      <Button
        as={Link}
        to="/"
        onClick={() => dispatch(requestSignOut())}
        variant="primary"
        className="profile-close-session-modal-close-btn"
      >
        Sí, cerrar sesión
      </Button>
    </Row>
  </div>
);

const SessionButtons = ({ setShowModal, setShowChangePasswordModal }) => (
  <>
    <Button
      variant="primary-outline"
      className="profile-header-btn"
      onClick={() => setShowModal(true)}
    >
      Cerra sesión
    </Button>
    <Button
      variant="primary-outline"
      className="profile-header-btn"
      onClick={() => setShowChangePasswordModal(true)}
    >
      Cambiar contraseña
    </Button>
    <Button
      variant="primary"
      className="profile-header-btn"
      as={Link}
      to="/admin/profile/edit"
    >
      Editar perfil
    </Button>
  </>
);

export default connect()(Profile);
