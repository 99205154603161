import includedElement from './includedElement';

const route = {
  id: '',
  routeName: '',
  routeCode: '',
  routeDuration: '',
  routeCheckIn: '',
  origin: '',
  destination: '',
  includedElements: [includedElement],
  routeType: 'both',
  position: 1,
  active: true
};

export default route;
