import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminGuideRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/guides/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminGuidesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/guides${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminGuideRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/admin/guides', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminGuideRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/guides/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminGuideRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/admin/guides/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminGuidesRequest = AwesomeDebouncePromise(
  indexAdminGuidesRequest,
  300
);
