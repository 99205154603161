import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import OfficeForm from './OfficeForm';

import {
  showAdminOfficeRequest,
  updateAdminOfficeRequest
} from '../../../requests/admin/adminOffices';

const OfficeEdit = props => {
  const { history } = props;
  const [office, setOffice] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    phone: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _office = camelCaseRecursive(response.data);
    setOffice(_office);
  };

  useEffect(() => {
    const { match } = props;
    const officeId = match.params.id;

    showAdminOfficeRequest(officeId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = () => {
    history.push(`/admin/offices`);
  };

  const handleUpdateRequest = _office => {
    const officeId = _office.office.id;
    updateAdminOfficeRequest(officeId, {
      dispatch,
      params: _office,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  const handleGoback = () => {
    history.push({
      pathname: '/admin/navbar_and_footer',
      state: { type: 'Oficinas' }
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-offices">
        <Col>
          <h4 className="text-primary pl-4">Editar Oficina</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="mt-4 pt-4">
          <OfficeForm
            office={office}
            action="edit"
            formRequest={handleUpdateRequest}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect()(OfficeEdit);
