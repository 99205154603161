import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import './styles.scss';

const Slides = ({
  items,
  specific,
  showCaption = false,
  customSlidesStyles,
  slideHeight
}) => {
  const sliderStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: slideHeight || '100%',
    overflow: 'hidden',
    zIndex: -1000
  };

  return (
    <Carousel
      className={specific}
      style={customSlidesStyles || sliderStyles}
      indicators={false}
      controls={false}
      fade
    >
      {items.map(item => (
        <Carousel.Item key={`slide-${item.id}`}>
          <img
            src={item.fileInfo.fileUrl}
            alt={item.caption}
            style={{ filter: 'brightness(0.7)' }}
          />
          {showCaption && (
            <Carousel.Caption>
              {item.caption && <h3>{item.caption}</h3>}
            </Carousel.Caption>
          )}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

Slides.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string,
      fileInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      specific: PropTypes.string,
      id: PropTypes.number.isRequired
    })
  ).isRequired
};

export default Slides;
