import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import IcoMoon from '../Icon/IcoMoon';
import MainLogo from '../MainLogo';
import { Can } from '../../../config/abilityContext';
import AccordionNavLink from './SidebarAccordion';
import {
  AccordionItemsTourism,
  AccordionItemsCargo,
  AccordionItemsAdmin
} from './SidebarAccordionItems';

const BasicSidebar = ({ closeSidebar, pathName }) => {
  const [selectedPath, setSelectedPath] = useState('Dashboard');

  return (
    <div className="container-links container-scroll-sidebar ">
      <Nav
        className="flex-column"
        activeKey={pathName}
        defaultActiveKey="/admin/dashboard"
      >
        <Navbar.Brand className="navbar-brand-red" as={Link} to="/">
          <MainLogo style={{ height: '125px', width: '125px' }} />
        </Navbar.Brand>
        <div>
          <Nav.Item>
            <Nav.Link
              eventKey="home"
              as={Link}
              to="/admin/dashboard"
              onClick={closeSidebar}
            >
              <IcoMoon icon="house-solid" />
              <span className="d-inline-block mt-1">Dashboard</span>
            </Nav.Link>
          </Nav.Item>

          <Can I="manage" a="Tourist">
            <Nav.Item>
              <AccordionNavLink
                accordionTitle="Turismo"
                accordionIcon="boat-solid"
                selectedPath={selectedPath}
                setSelectedPath={setSelectedPath}
                accordionItems={AccordionItemsTourism}
              />
            </Nav.Item>
          </Can>

          <Can I="manage" a="Cargo">
            <Nav.Item>
              <AccordionNavLink
                accordionTitle="Carga"
                accordionIcon="truck-solid"
                selectedPath={selectedPath}
                setSelectedPath={setSelectedPath}
                accordionItems={AccordionItemsCargo}
              />
            </Nav.Item>
          </Can>

          <Can I="manage" a="Admin">
            <Nav.Item>
              <AccordionNavLink
                accordionTitle="Administración"
                accordionIcon="book-solid"
                selectedPath={selectedPath}
                setSelectedPath={setSelectedPath}
                accordionItems={AccordionItemsAdmin}
              />
            </Nav.Item>
          </Can>

          <Nav.Item>
            <Nav.Link
              eventKey="profile"
              as={Link}
              to="/admin/profile"
              onClick={closeSidebar}
            >
              <IcoMoon icon="user-solid" />
              <span className="d-inline-block mt-1">Mi Perfil</span>
            </Nav.Link>
          </Nav.Item>
        </div>
      </Nav>
    </div>
  );
};

export default BasicSidebar;
