import React from 'react';
import { Player, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';

const VideoPlayer = ({ src }) => {
  return (
    <div className="video-player-container">
      <Player src={src} muted>
        <ControlBar autoHide={false} className="my-class" />
      </Player>
    </div>
  );
};

export default VideoPlayer;
