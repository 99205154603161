import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';

const ItineraryCard = ({ sailDate }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('language') || 'es';
  moment.locale(language);
  const voyagePoints = () => (
    <div className="d-flex flex-column w-100">
      <div className="route-voyage-line" />
      <div className="d-flex justify-content-between w-100">
        <div className="route-voyage-point align-items-start">
          <div className="point" />
          <p className="route-point-font">
            {t('tourism.landing.routesSection.checkIn')}
          </p>
          <p className="route-point-font-info" />
        </div>
        <div className="route-voyage-point ">
          <div className="point" />
          <p className="route-point-font">
            {t('tourism.landing.routesSection.boarding')}
          </p>
          <p className="route-point-font-info" />
        </div>
        <div className="route-voyage-point align-items-end">
          <div className="point" />
          <p className="route-point-font">
            {t('tourism.landing.routesSection.departure')}
          </p>
          <p className="route-point-font-info" />
        </div>
      </div>
    </div>
  );

  return (
    <div className="route-box-checkin">
      <p className="mb-3 route-box-checkin-title mb-4">
        {t('tourism.landing.routesSection.nextDeparture')}{' '}
        {moment(sailDate).format('D MMMM')}:
      </p>
      <div className="d-flex justify-content-between w-100 px-1">
        {voyagePoints()}
      </div>
      <p className="route-point-font-info mt-4">
        {t('tourism.landing.routesSection.moreInfo')}
      </p>
    </div>
  );
};

export default ItineraryCard;
