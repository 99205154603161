const slideOptions = [
  { label: 'Landing', value: 'principal_banner' },
  { label: 'La ruta', value: 'tourism_the_route' },
  { label: 'Tarifas de turismo', value: 'tourism_rate' },
  { label: 'Nuestros Ferries', value: 'tourism_our_ferries' },
  { label: 'Blogs', value: 'blog_header' },
  { label: 'Nuestros Ferries - Imagenes', value: 'our_ferries_images' },
  { label: 'Nuestros Ferries - Deck', value: 'our_ferries_deck' },
  { label: 'Imagen de Banner Cargo', value: 'cargo_banner_img' },
  { label: 'Background de guía', value: 'background_guide' },
  { label: 'Experiencia Navimag', value: 'navimag_experience' },
  { label: 'Background de Transportes', value: 'transport' },
  { label: 'Landing', value: 'landing_cargo' },
  { label: 'Tarifas de carga', value: 'cargo_rates' },
  { label: 'Disponibilidad', value: 'cargo_availabilty' },
  { label: 'Servicios', value: 'cargo_services' },
  { label: 'Cotizaciones', value: 'cargo_leads' },
  { label: 'Contacto', value: 'cargo_contact' },
  { label: 'Terminos y condiciones', value: 'cargo_terms' },
  { label: 'Itinerarios', value: 'cargo_itineraries' }
];

export const slideTourismHeroOptions = [
  { label: 'Landing', value: 'principal_banner' },
  { label: 'La ruta', value: 'tourism_the_route' },
  { label: 'Tarifas de turismo', value: 'tourism_rate' },
  { label: 'Nuestros Ferries', value: 'tourism_our_ferries' },
  { label: 'Blogs', value: 'blog_header' }
];

export const slideOurFerriesCategory = [
  { label: 'Nuestros Ferries - Imagenes', value: 'our_ferries_images' },
  { label: 'Nuestros Ferries - Deck', value: 'our_ferries_deck' }
];

export const slideTourismOptions = [
  { label: 'Imagen de Banner Cargo', value: 'cargo_banner_img' },
  { label: 'Background de guía', value: 'background_guide' },
  { label: 'Experiencia Navimag', value: 'navimag_experience' }
];

export const slideCargoOptions = [
  { label: 'Background de Transportes', value: 'transport' }
];

export const slideCargoHeroOptions = [
  { label: 'Landing', value: 'landing_cargo' },
  { label: 'Tarifas de carga', value: 'cargo_rates' },
  { label: 'Disponibilidad', value: 'cargo_availabilty' },
  { label: 'Servicios', value: 'cargo_services' },
  { label: 'Cotizaciones', value: 'cargo_leads' },
  { label: 'Contacto', value: 'cargo_contact' },
  { label: 'Terminos y condiciones', value: 'cargo_terms' },
  { label: 'Itinerarios', value: 'cargo_itineraries' }
];

export default slideOptions;
