/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';
import { languages } from '../../../services/utils';

const getTitleCell = ({ title }) => title;
const getActiveCell = item => (item.active ? 'Sí' : 'No');
const getLanguageCell = ({ language }) => languages[language];
const getReferenceCell = ({ referencePageUrl }) => {
  return referencePageUrl ? (
    <a href={`${window.location.origin}/${referencePageUrl}`}>
      {referencePageUrl}
    </a>
  ) : (
    'No hay información'
  );
};

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withClone = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Título',
      selector: () => 'title',
      sortable: true,
      cell: getTitleCell,
      grow: '1.5'
    },
    {
      name: 'URL',
      selector: () => 'url',
      sortable: true,
      cell: ({ url }) => `${url.slice(0, 30)}...`,
      grow: '1.5'
    },
    {
      name: 'Activo',
      selector: () => 'active',
      grow: '.5',
      sortable: true,
      cell: getActiveCell
    },
    {
      name: 'Idioma',
      selector: () => 'language',
      sortable: true,
      cell: getLanguageCell,
      grow: '.5'
    },
    {
      name: 'Referencia',
      selector: () => 'reference',
      grow: '1',
      cell: getReferenceCell
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withClone={withClone}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);
export default columns;
