import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CenteredModal } from '../../../components';
import Icon from '../../../components/Utils/Icon/IcoMoon';
import AdminSettingForm from './AdminSettingForm';

const EditSetting = ({ setting, handleUpdateRequest }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="w-100">
      <Button
        className="p-1 ml-auto"
        variant="outline-warning"
        onClick={() => setShowModal(true)}
        style={{
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Icon icon="pencil" size={14} />
      </Button>
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="d-flex justify-content-center w-100">
            <AdminSettingForm
              setting={setting}
              handleUpdateRequest={handleUpdateRequest}
              handleCloseModal={handleCloseModal}
            />
          </div>
        }
      />
    </div>
  );
};

export default EditSetting;
