import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import AdvisorsDataTable from './AdvisorsDataTable';

import { debounceIndexAdminAdvisorsRequest } from '../../../requests/admin/adminAdvisors';
import useFetchData from '../../../hooks/useFetchData';

const AdvisorsIndex = () => {
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [customParams, setCustomParams] = useState({
    sort_column: 'name',
    sort_direction: 'asc'
  });

  const {
    data: advisors,
    totalData: totalAdvisors,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminAdvisorsRequest,
    customParams
  });

  const modelUrl = '/admin/advisors';

  const clearCustomParams = () => {
    setCustomParams({ display_length: 10 });
  };

  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(fetchNewRecords, [customParams]);

  return (
    <>
      <Row id="index-advisors" className="pt-4">
        <Col>
          <h4 className="text-primary ml-4">Asesores</h4>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to="/admin/advisors/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <AdvisorsDataTable
        tableData={advisors}
        tableDataAmount={totalAdvisors}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(AdvisorsIndex);
