import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { useWindowSize } from '../../../hooks';
import FirstSection from '../Landing/FirstSection';
import CargoAvailabilityForm from './CargoAvailabilityForm';
import {
  indexCargoAvailabilityRoutesRequest,
  indexCargoAvailabilityShipsRequest
} from '../../../requests/cargoAvailability';
import SEO from '../../../components/Utils/SEO/SEO';

const CargoAvailability = () => {
  const [cargoAvailability, setCargoAvailability] = useState({
    routeId: '',
    shipId: '',
    itineraryId: '',
    deckId: ''
  });
  const [routes, setRoutes] = useState([]);
  const [ships, setShips] = useState([]);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const customSlidesStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '40vh',
    overflow: 'hidden',
    zIndex: -1000
  };

  const routesRequestWrapper = () => {
    indexCargoAvailabilityRoutesRequest({
      dispatch,
      params: {},
      successCallback: result => {
        setRoutes(camelCaseRecursive(result.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const shipsRequestWrapper = () => {
    indexCargoAvailabilityShipsRequest({
      dispatch,
      params: {},
      successCallback: result => {
        setShips(camelCaseRecursive(result.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const [isDesktop, setIsDesktop] = useState(true);
  const { windowSize } = useWindowSize();

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  console.log(isDesktop);

  useEffect(routesRequestWrapper, []);
  useEffect(shipsRequestWrapper, [routes]);

  return (
    <div className="mb-5">
      <SEO
        title="Navimag Carga - Disponibilidad"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Consulta el cupo disponible"
        customHeroStyle="cargo-availability-custom-hero"
        customSlidesStyles={customSlidesStyles}
        slideType="cargo_availabilty"
        slideHeight="40vh"
      />

      <Row className="justify-content-md-center">
        <Col className="cargo-lead-form-container">
          <CargoAvailabilityForm
            cargoAvailability={cargoAvailability}
            setCargoAvailability={setCargoAvailability}
            action="new"
            routes={routes}
            ships={ships}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(CargoAvailability);
