import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

import { deleteAdminOfficeRequest } from '../../../requests/admin/adminOffices';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Posición',
      selector: 'position',
      sortable: true,
      cell: ({ position }) => position,
      grow: '1'
    },
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
      cell: ({ name }) => name,
      grow: '1'
    },
    {
      name: 'Teléfono',
      selector: 'phone',
      sortable: true,
      cell: ({ phone }) => phone,
      grow: '1'
    },
    {
      name: 'Activo',
      selector: 'active',
      grow: '1',
      sortable: true,
      cell: ({ active }) => (active ? 'Sí' : 'No')
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const OfficesDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminOfficeRequest,
    onDeleteMessage: 'Oficina eliminada exitosamente',
    confirmationText: '¿Estás seguro de que deseas eliminar esta oficina?',
    modalTitle: 'Eliminar Oficina'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'position':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(OfficesDataTable));
