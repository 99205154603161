import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

// import { deleteLeadRequest } from '../../../requests/admin/adminLeads';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    cell: ({ name }) => name
  },
  {
    name: 'Email',
    selector: 'email',
    grow: '1.7',
    cell: ({ email }) => email
  },
  {
    name: 'Activo',
    selector: () => 'active',
    grow: '0.3',
    cell: ({ active }) => (active ? 'Si' : 'No')
  },
  {
    name: 'Destino',
    selector: 'destinyType',
    cell: ({ destinyType }) => destinyType
  }
]);

const DashboardLeadsDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData
    // deleteOneRequest: deleteLeadRequest,
    // onDeleteMessage: 'Lead eliminado exitosamente',
    // confirmationText: '¿Estás seguro de eliminar este lead?',
    // modalTitle: 'Eliminar Lead'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'name':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        queryFilter={false}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(DashboardLeadsDataTable));
