import React from 'react';
import shipImg from '../../../assets/images/esperanza_itinerary.png';
import backgroundImg from '../../../assets/images/itinerary_texture_background.jpg';
import AvailableItineraries from '../../../components/Landing/Routes/AvailableItineraries';

const SecondSection = ({
  availableTourismItineraries,
  tourismSeasonStart,
  tourismSeasonEnd
}) => {
  return (
    <AvailableItineraries
      availableDates={availableTourismItineraries}
      tourismSeasonStart={tourismSeasonStart}
      tourismSeasonEnd={tourismSeasonEnd}
      shipImage={shipImg}
      aditionalStyles={{ backgroundImage: `url(${backgroundImg})` }}
    />
  );
};

export default SecondSection;
