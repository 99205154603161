/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import IcoMoon from '../Icon';
import { generateSummary, uuidV4 } from '../../../services/utils';

const CustomSelector = ({
  label = 'Label',
  placeholder = 'Selecciona tu opcion',
  options,
  isJsxOptionType = false,
  modelName,
  setFieldValue,
  values
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValueLabel, setSelectedValueLabel] = useState(undefined);

  function toggleIsOpen() {
    setIsOpen(!isOpen);
  }
  function handleSelectOption(selectedOption) {
    setFieldValue(`${modelName}`, selectedOption);
  }

  return (
    <div className="custom-selector-container">
      <p className="font-weight-bold ">
        {label}
        <abbr className="text-danger ml-1">*</abbr>
      </p>
      <div className="custom-selector">
        <div
          className="main-content"
          aria-hidden="true"
          onClick={() => toggleIsOpen()}
        >
          <p className="pl-2 mr-auto">
            {isJsxOptionType
              ? generateSummary(values.bookTrip) || placeholder
              : selectedValueLabel || placeholder}
          </p>
          <IcoMoon
            icon="chevron-down"
            className="mr-2"
            style={{ cursor: 'pointer' }}
            size={12}
            color="gray"
          />
        </div>
        <div
          className={`${isOpen ? 'content-list-open' : 'content-list-close'}`}
        >
          <hr className="m-0" />
          {!isJsxOptionType &&
            options.map(option => (
              <div
                key={uuidV4()}
                aria-hidden="true"
                className="content-list-item"
                onClick={() => {
                  handleSelectOption(option.value);
                  setSelectedValueLabel(option.label);
                  toggleIsOpen();
                }}
              >
                {option.label}
              </div>
            ))}
          {isJsxOptionType &&
            options.map(option => {
              const modifiedOption = { ...option, key: uuidV4() };
              return modifiedOption;
            })}
        </div>
      </div>
    </div>
  );
};

export default CustomSelector;
