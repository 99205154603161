import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Card, Spinner, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';

import { indexRoutesRequest } from '../../../requests/routes';
import '../../../assets/stylesheets/components/rates/Rates.scss';
import RouteFilter from '../../Utils/Filter/RouteFilter';
import { clpFormat, uuidV4 } from '../../../services/utils';

import cargoArticulatedTruck from '../../../assets/images/cargo/cargo-articulated_trucks.svg';
import cargoBuses from '../../../assets/images/cargo/cargo-buses.svg';
import cargoLightWeightVehicles from '../../../assets/images/cargo/cargo-lightweight_vehicles.svg';
import cargoMachinery from '../../../assets/images/cargo/cargo-machinery.svg';
import cargoMotorcycles from '../../../assets/images/cargo/cargo-motorcycles.svg';
import cargoTowing from '../../../assets/images/cargo/cargo-towing.svg';
import cargoTrucks from '../../../assets/images/cargo/cargo-trucks.svg';
import cargoVan from '../../../assets/images/cargo/cargo-van.svg';
import cargoPerson from '../../../assets/images/cargo/cargo-person.svg';

const imageMapping = {
  articulated_trucks: cargoArticulatedTruck,
  buses: cargoBuses,
  lightweight_vehicles: cargoLightWeightVehicles,
  machinery: cargoMachinery,
  motorcycles: cargoMotorcycles,
  towing: cargoTowing,
  trucks: cargoTrucks,
  vans: cargoVan,
  carrier: cargoPerson
};

const CargoRates = ({
  cargoRateNatalesPage,
  cargoRateChacabucoPage,
  setCargoRataPage
}) => {
  const { i18n } = useTranslation();
  const [routes, setRoutes] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');
  const [specialPriceDisclaimer, setSpecialPriceDisclaimer] = useState('');
  const [specialPriceTableName, setSpecialPriceTableName] = useState('');
  const [showSpecialPriceDisclaimer, setShowSpecialPriceDisclaimer] = useState(
    false
  );
  const [isFirstRoute, setIsFirstRoute] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState(undefined);
  const [loading, setIsLoading] = useState(undefined);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const { settings } = useSelector(state => state.utils);

  const setDisclaimerCode = () => {
    const disclaimerValue = settings.cargoRatesAvailability || '';
    setDisclaimer(disclaimerValue);
    const specialPriceDisclaimerValue =
      settings.cargoRatesSpecialPriceDisclaimer || '';
    setSpecialPriceDisclaimer(specialPriceDisclaimerValue);
    const specialPriceTableNameValue =
      settings.cargoRatesSpecialPriceTableName || '';
    setSpecialPriceTableName(specialPriceTableNameValue);
  };

  const itineraryRedirect = () => {
    window.open(selectedRoute?.attachedItinerary?.fileUrl, '_blank');
  };

  const routeRequestWrapper = () => {
    setIsLoading(true);
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token,
        language,
        route_type_filter: 'cargo',
        serializer_selector: 'CargoRatesSerializer'
      },
      successCallback: result => {
        setRoutes(camelCaseRecursive(result.data.data));
        setSelectedRoute(camelCaseRecursive(result.data.data)[0]);
        setIsLoading(false);
      }
    });
    return () => cancelToken.cancel();
  };

  const updateShownPage = () => {
    if (isFirstRoute) {
      setCargoRataPage(cargoRateNatalesPage);
    } else {
      setCargoRataPage(cargoRateChacabucoPage);
    }
  };

  const borderStyleEval = (dataLength, index) => {
    let style = '';
    if (dataLength === 1) {
      style = '';
    } else if (index === 0) {
      style = 'cargo-container-bottom-border';
    } else if (index + 1 === dataLength) {
      style = 'cargo-container-top-border';
    } else {
      style = 'cargo-container-top-bottom-border';
    }
    return style;
  };

  useEffect(routeRequestWrapper, []);
  useEffect(setDisclaimerCode, [settings, language]);
  useEffect(updateShownPage, [updateShownPage]);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  const ratesHeader = () => (
    <div className="cargo-rates-header">
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Item de Cobro</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Imagen referencial</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Largos</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">Unidad de Cobro</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">
          {`${selectedRoute?.origin} - ${selectedRoute?.destination}`}
        </p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">
          {`${selectedRoute?.destination} - ${selectedRoute?.origin}`}
        </p>
        <p>(Cargado)</p>
      </div>
      <div className="cargo-rates-header-item">
        <p className="mt-1 font-weight-bold">
          {`${selectedRoute?.destination} - ${selectedRoute?.origin}`}
        </p>
        <p>(Vacío)</p>
      </div>
    </div>
  );

  const ratesRowItem = (cargoRate, style) => {
    const hasSpecialPrice = cargoRate.specialPrice !== null;
    if (hasSpecialPrice && !showSpecialPriceDisclaimer) {
      setShowSpecialPriceDisclaimer(hasSpecialPrice);
    }

    return (
      <div className="cargo-rates-row mb-2" key={uuidV4()}>
        <div
          className={`cargo-rates-row-item ${style} cargo-border-radius-left`}
        >
          <p className="font-weight-bold">{cargoRate.vehicleType}</p>
        </div>
        <div className={`cargo-rates-row-item ${style}`}>
          <img
            src={imageMapping[cargoRate.vehicleTypeCategory]}
            alt={cargoRate.vehicleTypeCategory}
          />
        </div>
        {hasSpecialPrice ? (
          <>
            <div className="cargo-rates-col">
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>{cargoRate.vehicleLength || '-'}</p>
              </div>
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>{specialPriceTableName}</p>
              </div>
            </div>

            <div className="cargo-rates-col">
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>{cargoRate.cargoUnit}</p>
              </div>
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>{cargoRate.cargoUnit}</p>
              </div>
            </div>

            <div className="cargo-rates-col">
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>
                  {cargoRate.loadedInitialRate
                    ? clpFormat.format(cargoRate.loadedInitialRate)
                    : '-'}{' '}
                </p>
              </div>
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>
                  {cargoRate.specialPrice
                    ? clpFormat.format(cargoRate.specialPrice)
                    : '-'}{' '}
                </p>
              </div>
            </div>

            <div className="cargo-rates-col">
              <div className={`cargo-rates-row-category-item ${style}`}>
                <p>
                  {cargoRate.loadedReturnRate
                    ? clpFormat.format(cargoRate.loadedReturnRate)
                    : '-'}
                </p>
              </div>
              <div className={`cargo-rates-row-category-item ${style}`} />
            </div>

            <div
              className={`cargo-rates-row-item cargo-border-radius-right ${style}`}
            >
              <p>
                {' '}
                {cargoRate.emptyReturnRate
                  ? clpFormat.format(cargoRate.emptyReturnRate)
                  : '-'}{' '}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={`cargo-rates-row-item ${style}`}>
              <p>{cargoRate.vehicleLength || '-'}</p>
            </div>
            <div className={`cargo-rates-row-item ${style}`}>
              <p>{cargoRate.cargoUnit}</p>
            </div>
            <div className={`cargo-rates-row-item ${style}`}>
              <p>
                {cargoRate.loadedInitialRate
                  ? clpFormat.format(cargoRate.loadedInitialRate)
                  : '-'}
              </p>
            </div>
            <div className={`cargo-rates-row-item ${style}`}>
              <p>
                {cargoRate.loadedReturnRate
                  ? clpFormat.format(cargoRate.loadedReturnRate)
                  : '-'}
              </p>
            </div>
            <div
              className={`cargo-rates-row-item cargo-border-radius-right ${style}`}
            >
              <p>
                {cargoRate.emptyReturnRate
                  ? clpFormat.format(cargoRate.emptyReturnRate)
                  : '-'}
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const ratesRowCategoryItem = (cargoRateCategory, style) => {
    const categoriesNames = cargoRateCategory.map(cargoRate => {
      return cargoRate.vehicleType;
    });

    const lastName = categoriesNames.pop();
    const categoryName = `${categoriesNames.join(', ')} y ${lastName}`;

    const vehicleTypeCategoryArray = [];
    const vehicleLengthArray = [];
    const cargoUnitArray = [];
    const loadedInitialRateArray = [];
    const loadedReturnRateArray = [];
    const emptyReturnRateArray = [];

    cargoRateCategory.map(cargoRate => {
      vehicleTypeCategoryArray.push(cargoRate.vehicleTypeCategory);
      vehicleLengthArray.push(cargoRate.vehicleLength);
      cargoUnitArray.push(cargoRate.cargoUnit);
      loadedInitialRateArray.push(cargoRate.loadedInitialRate);
      loadedReturnRateArray.push(cargoRate.loadedReturnRate);
      emptyReturnRateArray.push(cargoRate.emptyReturnRate);
      return null;
    });

    return (
      <div
        className={`cargo-rates-row mb-2 ${style} cargo-rates-category-container-radius`}
        key={uuidV4()}
      >
        <div
          className={`cargo-rates-row-item cargo-border-radius-left ${style} `}
        >
          <p className="font-weight-bold">{categoryName}</p>
        </div>
        <div className="cargo-rates-col">
          {vehicleTypeCategoryArray.map((vehicleTypeCategory, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <img
                  src={imageMapping[vehicleTypeCategory]}
                  alt={vehicleTypeCategory}
                />
              </div>
            );
          })}
        </div>
        <div className="cargo-rates-col">
          {vehicleLengthArray.map((vehicleLength, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <p>{vehicleLength || '-'}</p>
              </div>
            );
          })}
        </div>
        <div className="cargo-rates-col">
          {cargoUnitArray.map((cargoUnit, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <p>{cargoUnit}</p>
              </div>
            );
          })}
        </div>
        <div className="cargo-rates-col">
          {loadedInitialRateArray.map((loadedInitialRate, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <p>
                  {loadedInitialRate
                    ? clpFormat.format(loadedInitialRate)
                    : '-'}
                </p>
              </div>
            );
          })}
        </div>
        <div className="cargo-rates-col">
          {loadedReturnRateArray.map((loadedReturnRate, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <p>
                  {loadedReturnRate ? clpFormat.format(loadedReturnRate) : '-'}
                </p>
              </div>
            );
          })}
        </div>
        <div className="cargo-rates-col ">
          {emptyReturnRateArray.map((emptyReturnRate, index) => {
            const borderStyle = borderStyleEval(
              vehicleTypeCategoryArray.length,
              index
            );
            return (
              <div
                className={`cargo-rates-row-category-item ${style} ${borderStyle}`}
              >
                <p>
                  {emptyReturnRate ? clpFormat.format(emptyReturnRate) : '-'}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="my-4 cargo-rates-content-box">
      <h3 className="cargo-rates-title text-primary">Tarifas de carga</h3>
      <p className="mt-3 mb-4">{disclaimer}</p>
      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: '460px' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!loading && (
        <>
          <Row className="mb-4">
            <Col md={4}>
              {selectedRoute?.attachedItinerary?.fileUrl && (
                <Button
                  type="submit"
                  variant="dark"
                  className="p-2 w-75"
                  style={{ borderRadius: '10px', color: '$primary' }}
                  onClick={itineraryRedirect}
                >
                  Descargar itinerario {selectedRoute?.routeName}
                </Button>
              )}
            </Col>
          </Row>
          <RouteFilter
            routes={routes}
            setSelectedRoute={setSelectedRoute}
            selectedRoute={selectedRoute}
            customContainerStyle="cargo-route-selector-container"
            customSelectorBoxStyle="cargo-route-selector-box"
            customSelectorBoxTextStyle="cargo-route-selector-box-text"
            customSelectedBoxStyle="cargo-route-selector-box-active"
            setIsFirstRoute={setIsFirstRoute}
            setShowSpecialPriceDisclaimer={setShowSpecialPriceDisclaimer}
          />

          <div className="cargo-rates-div-scroll">
            <div className="cargo-rates-div">
              <Card className="p-4 mt-4">
                {ratesHeader()}
                {selectedRoute?.cargoRatesAttributes &&
                  Object.keys(selectedRoute?.cargoRatesAttributes).map(
                    (cargoRateCategoryKey, index) => {
                      let renderedElements = <></>;
                      if (cargoRateCategoryKey !== 'noCategory') {
                        const style =
                          index % 2 ? 'cargo-row-lightgray' : 'cargo-row-gray';
                        return ratesRowCategoryItem(
                          selectedRoute?.cargoRatesAttributes[
                            cargoRateCategoryKey
                          ],
                          style
                        );
                      }
                      renderedElements = selectedRoute?.cargoRatesAttributes[
                        cargoRateCategoryKey
                      ].map((cargoRateCategory, newIndex) => {
                        const style =
                          (index + newIndex) % 2
                            ? 'cargo-row-lightgray'
                            : 'cargo-row-gray';
                        return ratesRowItem(cargoRateCategory, style);
                      });
                      return renderedElements;
                    }
                  )}
                {showSpecialPriceDisclaimer && (
                  <p className="mt-4">{specialPriceDisclaimer}</p>
                )}
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CargoRates;
