import React from 'react';
import { useTranslation } from 'react-i18next';

import ItineraryDay from './ItineraryDay';
import { uuidV4 } from '../../../services/utils';

const ItineraryDescriptionByDays = ({
  daysItineraryDescriptionsAttributes
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <h5 className="itinerary-description-days-title">
        {t('tourism.landing.routesSection.itinerary')}
      </h5>
      {daysItineraryDescriptionsAttributes.map((day, index) => (
        <ItineraryDay key={uuidV4()} day={day} number={index + 1} />
      ))}
    </div>
  );
};

export default ItineraryDescriptionByDays;
