import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import TemplatesGrid from '../../../components/Templates/TemplatesGrid';

const PageTemplates = props => {
  const { history } = props;

  const handleGoback = () => {
    history.push({
      pathname: '/admin/pages_and_blogs',
      state: { type: 'Páginas' }
    });
  };

  return (
    <>
      <Row id="templates" className="mb-4 pt-4">
        <Col>
          <h4 className="text-primary ml-4">Plantillas</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <TemplatesGrid category="pages" />
    </>
  );
};

export default connect()(PageTemplates);
