import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SEO from '../../components/Utils/SEO/SEO';
import { getPage } from '../../actions/utils';
import GuidesSection from '../Home/Landing/GuidesSection';
import '../../assets/stylesheets/components/pages/Page.scss';

const Page = ({ match, utils: { page, ongoingRequest } }) => {
  const dispatch = useDispatch();
  const showSubHeader = true;
  const language = localStorage.getItem('language');
  const { settings } = useSelector(state => state.utils);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getPage(match.url));
  }, [dispatch, match.url]);

  useEffect(() => {
    if (page && page.language !== language && page.reference_page_url) {
      window.location.href = `${window.location.origin}/${page.reference_page_url}`;
    }
  }, [page]);

  const renderContent = () => {
    if (page.body) {
      const { body, render_contact_form: renderContactForm } = page;

      return (
        <>
          <SEO
            title={`${page.title} | Navimag`}
            description={page.description}
            author={page.author || 'Navimag'}
          />
          <div
            className={`page-content ${showSubHeader ? '' : 'hide-subheader'}`}
          >
            <div dangerouslySetInnerHTML={{ __html: body }} />
            {renderContactForm && <GuidesSection settings={settings} />}
          </div>
        </>
      );
    }

    return <h4>404</h4>;
  };

  return (
    <div className="page">
      {ongoingRequest.getPage ? (
        <Spinner animation="border" variant="primary" className="spinner" />
      ) : (
        renderContent()
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  utils: state.utils
});

export default withRouter(connect(mapStateToProps)(Page));
