/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button } from 'react-bootstrap';

import { indexGuidesRequest } from '../../requests/guides';
import '../../assets/stylesheets/components/guides/GuidesGrid.scss';
import LeadNew from '../Leads/LeadNew';
import { CenteredModal } from '../Utils/Modal';

const GuidesGrid = ({ numOfGuides, customParams }) => {
  const [showModal, setShowModal] = useState(false);
  const [guides, setGuides] = useState([]);
  const [guideId, setGuideId] = useState(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const guidesRequest = () => {
    indexGuidesRequest({
      dispatch,
      params: {
        ...customParams,
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: numOfGuides,
        language
      },
      successCallback: result => {
        const sortedData = camelCaseRecursive(result.data.data).sort(
          (a, b) => a.position - b.position
        );
        setGuides(sortedData);
      }
    });
  };

  useEffect(guidesRequest, [numOfGuides, customParams]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <section className="guide-section mb-4">
      <div className="grid-container">
        {guides.map(guide => (
          <div key={guide.id} className="grid-item">
            <div className="guide-card">
              <div className="guide-info">
                <img
                  className="guide-card-image"
                  src={guide.attachedPreviewImage.fileUrl}
                  alt="Guide preview"
                />
              </div>
              <p className="guide-title mt-1">{guide.title}</p>
              <p className="guide-description mb-2">{guide.description}</p>

              <div className="d-flex w-100 justify-content-between align-items-center">
                {guide.pageUrl !== null && (
                  <div style={{ width: '40%' }}>
                    <Button
                      as={Link}
                      to={guide.pageUrl}
                      variant="primary-outline"
                      block
                      className="p-2 w-100"
                      style={{ fontSize: '12px' }}
                    >
                      {t('tourism.guidesSection.body.firstBtnText')}
                    </Button>
                  </div>
                )}

                <div style={{ width: guide.pageUrl ? '58%' : '100%' }}>
                  <Button
                    variant="primary"
                    block
                    onClick={() => {
                      setGuideId(guide.id);
                      setShowModal(true);
                    }}
                    className="p-2 w-100"
                    style={{ fontSize: '12px' }}
                  >
                    {t('tourism.guidesSection.body.secondBtnText')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CenteredModal
        size="lg"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div
            className="px-2 pb-2 d-flex justify-content-center"
            style={{ minHeight: '430px' }}
          >
            <LeadNew guideId={guideId} />
          </div>
        }
      />
    </section>
  );
};

export default GuidesGrid;
