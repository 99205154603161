import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import snakeCaseKeys from 'snakecase-keys';
import {
  updateAdminSettingRequest,
  debounceIndexAdminSettingRequest
} from '../../../requests/admin/settings';
import AdminSettingTable from './AdminSettingTable';
import useFetchData from '../../../hooks/useFetchData';
import { sendAlert } from '../../../actions/utils';

const AdminSettingIndex = ({ settingType = 'admin' }) => {
  const dispatch = useDispatch();
  const {
    data: { data: settings },
    totalData: totalSettings,
    isFetching,
    moreData,
    setMoreData
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminSettingRequest,
    customParams: {
      sort_direction: 'desc',
      accepted_text_settings: true,
      by_settingType: settingType
    },
    withoutWrapper: true
  });

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Configuración actualizada con éxito'
      })
    );
    setMoreData(!moreData);
  };

  const handleUpdateRequest = (id, values) => {
    const { image, file } = values.setting;
    const sendParams = snakeCaseKeys(values);
    const { setting: valuesSetting } = sendParams;
    updateAdminSettingRequest(id, {
      dispatch,
      params: { setting: { ...valuesSetting, image, file } },
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <div>
      <Row id="index-videos">
        <Col>
          {settingType === 'cargo' && (
            <h4 className="text-primary p-4">Settings cargo</h4>
          )}
        </Col>
      </Row>
      <AdminSettingTable
        settings={settings}
        tableDataAmount={totalSettings}
        isFetching={isFetching}
        moreData={moreData}
        handleUpdateRequest={handleUpdateRequest}
        setMoreData={setMoreData}
      />
    </div>
  );
};

export default AdminSettingIndex;
