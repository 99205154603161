import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import OfficeForm from './OfficeForm';
import { createAdminOfficeRequest } from '../../../requests/admin/adminOffices';

const OfficeNew = props => {
  const { history } = props;
  const [office] = useState({
    name: '',
    address: '',
    city: '',
    country: '',
    phone: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    history.push(`/admin/offices`);
  };

  const handleCreateRequest = _office => {
    createAdminOfficeRequest({
      dispatch,
      params: _office,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  const handleGoback = () => {
    history.push({
      pathname: '/admin/navbar_and_footer',
      state: { type: 'Oficinas' }
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-offices">
        <Col>
          <h4 className="text-primary pl-4">Crear Oficina</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }} className="mt-4 pt-4">
          <OfficeForm
            office={office}
            action="new"
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect()(OfficeNew);
