import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import AttractionsIndex from '../Attractions/AttractionsIndex';
import AdminSettingIndex from '../Settings/AdminSettingIndex';
import ReviewIndex from '../Review/ReviewIndex';
import SlideIndex from '../../Slide/SlideIndex';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';

const Landing = props => {
  const { location } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Configuraciones Landing');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Landing</h4>
        </Col>
      </Row>
      <ModelFilter
        models={[
          'Configuraciones Landing',
          'Configuraciones generales',
          'Slides turismo',
          'Atractivos',
          'Reviews'
        ]}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '80px' }}>
        <Col>
          {selectedModel === 'Configuraciones Landing' && (
            <AdminSettingIndex settingType="tourism_landing" />
          )}
          {selectedModel === 'Configuraciones generales' && (
            <AdminSettingIndex settingType="tourism" />
          )}
          {selectedModel === 'Slides turismo' && (
            <SlideIndex {...props} categoryType="tourism_slide" />
          )}
          {selectedModel === 'Atractivos' && <AttractionsIndex />}
          {selectedModel === 'Reviews' && <ReviewIndex />}
        </Col>
      </Row>
    </>
  );
};

export default Landing;
