const tourismRate = {
  id: '',
  roomType: '',
  availableRooms: '',
  bedType: '',
  singleRate: '',
  doubleRate: '',
  tripleRate: '',
  quadrupleRate: '',
  sharedRate: '',
  position: 1,
  active: true
};

export default tourismRate;
