import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, useFormikContext, getIn } from 'formik';
import { FormikSelect } from '../../../components';

// A la espera de un endpoint decente por parte de Navimag
const routesData = [
  {
    id: 'Puerto Chacabuco - Puerto Montt',
    port: 'Pto. Chb.',
    code: 'UCO - PMC'
  },
  {
    id: 'Puerto Montt - Puerto Chacabuco',
    port: 'Pto. Montt',
    code: 'PMC - UCO'
  },
  {
    id: 'Puerto Montt - Puerto Natales',
    port: 'Pto. Montt',
    code: 'PMC - NAT'
  },
  {
    id: 'Puerto Natales - Puerto Montt',
    port: 'Pto. Natales',
    code: 'NAT - PMC'
  }
];

const FirstStepCargoAvailabilityForm = ({
  routes,
  ships,
  setUpdateAvailableShips,
  setAvailableShips,
  setAvailableItineraries,
  setAvailableDecks,
  setShowStepTwo,
  setShowStepThree,
  showShipsSelector,
  setSelectedPortText,
  setSelectedRouteText
}) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  return (
    <section className="mb-1 w-100">
      <Row className="d-flex align-items-center">
        <Col>
          <Field name="cargoAvailability[routeId]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Ruta"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={routes}
                  onChange={routeOption => {
                    const selectedRoute = routesData.find(
                      availableRoute => routeOption.label === availableRoute.id
                    );
                    setFieldValue('cargoAvailability', {
                      ...values.cargoAvailability,
                      shipId: '',
                      routeId: routeOption.value,
                      itineraryId: ''
                    });
                    setUpdateAvailableShips(true);
                    setAvailableShips([]);
                    setAvailableItineraries([]);
                    setAvailableDecks([]);
                    setShowStepTwo(false);
                    setShowStepThree(false);
                    setSelectedPortText(selectedRoute.port);
                    setSelectedRouteText(selectedRoute.code);
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          {showShipsSelector && (
            <Field name="cargoAvailability[shipId]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    {...field}
                    abbr
                    inputType="text"
                    label="Barco"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={ships}
                    onChange={routeOption => {
                      setFieldValue('cargoAvailability', {
                        ...values.cargoAvailability,
                        shipId: routeOption.value
                      });
                      setShowStepTwo(true);
                    }}
                  />
                );
              }}
            </Field>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default FirstStepCargoAvailabilityForm;
