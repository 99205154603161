import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormikInput, FormikCheckBox, FormikSelect } from '../../../components';
import useFetchData from '../../../hooks/useFetchData';
import { debounceIndexAdminFaqCategoriesRequest } from '../../../requests/admin/adminFaqCategories';
import { languages } from '../../../services/utils';

const FaqForm = props => {
  const { onHide, submitVariant, errors, touched, action, faq } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultCategory, setDefaultCategory] = useState(undefined);

  const { data: faqCategories } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminFaqCategoriesRequest,
    customParams: {
      display_length: 1000
    }
  });

  const findDefaultCategory = () => {
    setDefaultCategory(
      faqCategories.find(faqCategory => faqCategory.id === faq.faqCategoryId)
    );
  };

  useEffect(findDefaultCategory, [faqCategories]);

  return (
    <Form className="w-100 px-2">
      <Row className="justify-content-center">
        <Col md={6} sm={12}>
          <Field name="faq[question]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Pregunta"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="faq[answer]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="textarea"
                  isTextArea
                  label="Respuesta"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  rows={5}
                />
              );
            }}
          </Field>

          <Field name="faq[faqCategoryId]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Categoría"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={faqCategories}
                  defaultValue={defaultCategory?.value}
                  onChange={faqCategory => {
                    setFieldValue('faq', {
                      ...values.faq,
                      faqCategoryId: faqCategory.id,
                      language: faqCategory.language
                    });
                    setFieldValue(
                      'languageTranslate',
                      languages[faqCategory.language]
                    );
                  }}
                />
              );
            }}
          </Field>

          <Field name="languageTranslate">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  readOnly
                />
              );
            }}
          </Field>

          <Field name="faq[position]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Posición"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="faq[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center my-3">
        <Col md={6} sm={12}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    question,
    answer,
    language,
    faqCategoryId,
    position,
    active
  } = props.faq;
  return {
    faq: {
      id,
      question,
      answer,
      language,
      faqCategoryId,
      position,
      active
    },
    languageTranslate: languages[language]
  };
};

const validationSchema = Yup.object().shape({
  faq: Yup.object().shape({
    question: Yup.string().required('Requerido'),
    answer: Yup.string().required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = {
    faq: snakecaseKeys({ ...values.faq })
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(FaqForm);
