// navimag_frontend/src/app/components/Utils/Upload/UploadMultipleImages.js
/* eslint-disable no-unused-vars */

import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { Image, Form } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import IcoMoon from '../Icon/IcoMoon';

const UploadMultipleImages = ({ name, label, abbr = false }) => {
  const { setFieldValue, values } = useFormikContext();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg']
    },
    onDrop: acceptedFiles => {
      const images = acceptedFiles.map(file => ({
        preview: URL.createObjectURL(file),
        file
      }));

      setFieldValue(name, values.gallery.images.concat(images));
    }
  });

  const handleRemoveImage = (image, index, event) => {
    event.stopPropagation();
    const imageIdentifier = image.preview.id || index;
    const updatedImages = values.gallery.images.filter((img, idx) => {
      return image.preview.id
        ? img.preview.id !== imageIdentifier
        : idx !== imageIdentifier;
    });
    setFieldValue(name, updatedImages);
    const updatedDeletedImages = values.gallery.deletedImages || [];
    if (image.preview.id) {
      updatedDeletedImages.push(image.preview.id);
    }
    setFieldValue('gallery[deletedImages]', updatedDeletedImages);
  };

  return (
    <>
      {label ? (
        <Form.Label>
          {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
        </Form.Label>
      ) : (
        false
      )}
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? 'dropzone-active' : ''}`}
      >
        <input {...getInputProps()} />
        <p>Arrastra las imágenes aquí, o haz clic para seleccionarlas</p>
        <div className="multi-image-preview">
          {values.gallery.images.map((image, index) => (
            <div className="image-container" key={image.preview?.id || index}>
              <IcoMoon
                icon="trash"
                size={25}
                onClick={event => handleRemoveImage(image, index, event)}
                className="delete-icon"
              />
              <Image
                src={image.preview?.fileUrl || image.preview || defaultImg}
                fluid
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UploadMultipleImages;
