// navimag_frontend/src/app/screens/AdminScreens/Advisors/AdvisorForm.js
import React from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import snakecaseKeys from 'snakecase-keys';
import { Button, Row, Col } from 'react-bootstrap';

import { FormikCheckBox, FormikInput, FormikSelect } from '../../../components';

const AdvisorForm = ({ errors, touched, submitVariant, action = 'new' }) => {
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { setFieldValue, values } = useFormikContext();

  return (
    <Form className="w-100 px-2">
      <Row className="d-flex justify-content-center">
        <Col md={6}>
          <Field name="advisor[name]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Nombre"
                error={errors.name}
                touched={touched.name}
              />
            )}
          </Field>
          <Field name="advisor[email]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="email"
                label="Email"
                error={errors.email}
                touched={touched.email}
              />
            )}
          </Field>
          <Field name="advisor[phone]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="text"
                label="Teléfono"
                error={errors.phone}
                touched={touched.phone}
              />
            )}
          </Field>
          <Field name="advisor[route]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  inputType="text"
                  label="Ruta de asesor"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={[
                    {
                      label: 'Puerto Natales',
                      value: 'natales'
                    },
                    {
                      label: 'Puerto Chacabuco',
                      value: 'chacabuco'
                    }
                  ]}
                  defaultValue={values.advisor.route}
                  onChange={selectedRoute => {
                    setFieldValue('advisor[route]', selectedRoute.value);
                  }}
                />
              );
            }}
          </Field>
          <Field name="advisor[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { id, name, email, phone, route, active } = props.advisor;
  return {
    advisor: {
      id,
      name,
      email,
      phone,
      route,
      active
    }
  };
};

const validationSchema = Yup.object().shape({
  advisor: Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string()
      .email('Email inválido')
      .required('El email es requerido'),
    phone: Yup.string().required('El teléfono es requerido'),
    route: Yup.string().required('La ruta es requerida')
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = {
    advisor: snakecaseKeys({ ...values.advisor })
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true
})(AdvisorForm);
