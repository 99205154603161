import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DefaultModal } from '../components/Utils/Modal';
import { sendAlert } from '../actions/utils';

const useDestroyOne = ({
  setMoreData,
  deleteOneRequest,
  onDeleteMessage,
  confirmationText,
  modalTitle,
  parentId
}) => {
  const dispatch = useDispatch();
  const [modalBody, setModalBody] = useState('');
  const [modalItem, setModalItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const handleModalClose = () => setModalShow(false);

  const handleSuccessRemove = () => {
    handleModalClose();
    dispatch(sendAlert({ kind: 'success', message: onDeleteMessage }));
    setMoreData(m => !m);
  };

  const removeElement = id => {
    if (parentId) {
      deleteOneRequest(parentId, id, {
        dispatch,
        successCallback: () => handleSuccessRemove(id)
      });
    } else {
      deleteOneRequest(id, {
        dispatch,
        successCallback: () => handleSuccessRemove(id)
      });
    }
  };

  const handleModalConfirm = () => removeElement(modalItem.id);

  const handleShowModal = item => {
    setModalShow(true);
    setModalItem(item);
    setModalBody(<p>{confirmationText}</p>);
  };

  const OnDestroyModalComponent = () => (
    <DefaultModal
      title={modalTitle}
      body={modalBody}
      show={modalShow}
      handleClose={handleModalClose}
      handleConfirm={handleModalConfirm}
      titleBtnClose="Cancelar"
      titleBtnSave="Confirmar"
    />
  );

  return {
    OnDestroyModalComponent,
    handleShowModal
  };
};

export default useDestroyOne;
