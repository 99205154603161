import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import snakecaseKeys from 'snakecase-keys';
import { debounceIndexAdminPagesRequest } from '../../../requests/admin/adminPages';
import { lowerCaseTextFormat, languages } from '../../../services/utils';
import { FormikInput, FormikSwitch, FormikSelect } from '../../../components';
import useFetchData from '../../../hooks/useFetchData';
import API_CONFIG from '../../../config/configurations';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: white;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }
`;

const PageForm = props => {
  const { action, page, errors, touched, handleSubmit } = props;
  const { url } = API_CONFIG;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [editorRef, setEditorRef] = useState(null);
  const [parse, setParse] = useState(null);
  const [defaultPage, setDefaultPage] = useState(undefined);
  const { data: allPages } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPagesRequest,
    customParams: {
      sort_column: 'updated_at',
      sort_direction: 'desc',
      category: 'web'
    }
  });

  const findDefaultPage = () => {
    setDefaultPage(
      allPages.find(selectedPage => selectedPage.id === page.referencePageId)
    );
  };

  const filteredPages = allPages.filter(
    selectedPage => selectedPage.id !== page.id
  );

  useEffect(findDefaultPage, [allPages]);
  useEffect(() => {
    if (editorRef) {
      if (page && page.bodyJson) {
        setParse(JSON.parse(page.bodyJson));
        editorRef.loadDesign(parse);
      } else {
        setParse(null);
        editorRef.loadBlank({
          backgroundColor: '#fff',
          contentWidth: '100%'
        });
      }
    }
  }, [page, editorRef]);

  const exportHtmlAndSubmit = async () => {
    await new Promise(resolve => {
      editorRef.exportHtml(async data => {
        const { design, html } = data;
        setFieldValue('page', {
          ...values.page,
          body: html,
          bodyJson: JSON.stringify(design)
        });
        resolve();
      });
    });
    handleSubmit();
  };

  useEffect(() => {
    if (editorRef) {
      setEditorRef(editorRef);
    }
  }, [setEditorRef]);

  const handleFormatTextToLowerCase = e => {
    const formattedValue = lowerCaseTextFormat(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  if (action !== 'new' && (!page || !page.bodyJson)) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    );
  }

  return (
    <>
      <Form className="w-100 px-2">
        <Row>
          <Col md={6} sm={12}>
            <Field name="page[url]">
              {({ field }) => {
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="URL"
                    onChange={handleFormatTextToLowerCase}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={6} sm={12}>
            <Field name="page[title]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <Field name="page[language]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    abbr
                    {...field}
                    label="Idioma"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={Object.entries(languages).map(
                      ([value, label]) => ({
                        value,
                        label
                      })
                    )}
                    onChange={selectedOption => {
                      const selectedValue = selectedOption
                        ? selectedOption.value
                        : '';
                      setFieldValue(name, selectedValue);
                    }}
                    defaultValue={field.value}
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={4} sm={8}>
            <Field name="page[referencePageId]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    {...field}
                    inputType="text"
                    label="Versión en otro idioma"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={filteredPages}
                    defaultValue={defaultPage?.value}
                    onChange={selectedPage => {
                      setFieldValue('page', {
                        ...values.page,
                        referencePageId: selectedPage.id
                      });
                    }}
                  />
                );
              }}
            </Field>
          </Col>
          <Col
            md={2}
            sm={4}
            className="d-flex justify-content-center align-items-center"
          >
            <Field name="page[renderContactForm]">
              {({ field }) => (
                <FormikSwitch
                  abbr
                  {...field}
                  field={field}
                  label="¿Mostrar CTA predefinido?"
                />
              )}
            </Field>
          </Col>
          <Col
            md={2}
            sm={4}
            className="d-flex justify-content-center align-items-center"
          >
            <Field name="page[active]">
              {({ field }) => (
                <FormikSwitch
                  abbr
                  {...field}
                  field={field}
                  label="¿Página activa?"
                />
              )}
            </Field>
          </Col>
        </Row>

        <Row className="justify-content-center" style={{ minHeight: '100vh' }}>
          <Container>
            <Bar>
              <EmailEditor
                ref={setEditorRef}
                minHeight="100vh"
                appearance={{ theme: 'light' }}
                features={{
                  colorPicker: {
                    presets: ['#aa182c', '#8f0b1d']
                  }
                }}
                tools={{
                  button: {
                    properties: {
                      buttonColors: {
                        value: {
                          color: '#FFFFFF',
                          backgroundColor: '#aa182c',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      },
                      borderRadius: {
                        value: '10px'
                      }
                    }
                  },
                  form: {
                    properties: {
                      action: {
                        editor: {
                          data: {
                            actions: [
                              {
                                label: 'Crear Lead',
                                method: 'POST',
                                url: `${url()}/pages/general_contact_form`
                              }
                            ]
                          }
                        }
                      },
                      fields: {
                        value: [
                          {
                            name: 'name',
                            type: 'text',
                            label: 'Nombre',
                            placeholder_text: 'Ingresa tú nombre',
                            show_label: true,
                            required: true
                          },
                          {
                            name: 'email',
                            type: 'email',
                            label: 'Email',
                            placeholder_text: 'Ingresa tú email',
                            show_label: true,
                            required: true
                          },
                          {
                            name: 'message',
                            type: 'textarea',
                            label: 'Mensaje',
                            placeholder_text: 'Ingresa un mensaje',
                            show_label: true,
                            required: false
                          },
                          {
                            name: 'title',
                            type: 'hidden',
                            label: 'Título Lead',
                            value: '',
                            required: false
                          },
                          {
                            name: 'labels',
                            type: 'hidden',
                            label: 'Etiquetas Lead',
                            value: '',
                            required: false
                          }
                        ]
                      },
                      fieldBorder: {
                        value: {
                          borderTopWidth: '1px',
                          borderTopStyle: 'solid',
                          borderTopColor: 'lightgray',
                          borderLeftWidth: '1px',
                          borderLeftStyle: 'solid',
                          borderLeftColor: 'lightgray',
                          borderRightWidth: '1px',
                          borderRightStyle: 'solid',
                          borderRightColor: 'lightgray',
                          borderBottomWidth: '1px',
                          borderBottomStyle: 'solid',
                          borderBottomColor: 'lightgray'
                        }
                      },
                      fieldBackgroundColor: {
                        value: '#FFFFFF'
                      },
                      fieldColor: {
                        value: '#494949'
                      },
                      buttonText: {
                        value: 'Enviar'
                      },
                      buttonColors: {
                        value: {
                          color: '#FFFFFF',
                          backgroundColor: '#aa182c',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      },
                      fieldBorderRadius: {
                        value: '10px'
                      },
                      buttonBorderRadius: {
                        value: '10px'
                      }
                    }
                  },
                  menu: {
                    properties: {
                      menuColors: {
                        value: {
                          color: '#aa182c',
                          backgroundColor: '#FFFFFF',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      }
                    }
                  },
                  heading: {
                    properties: {
                      headingColors: {
                        value: {
                          color: '#aa182c',
                          hoverColor: '#8f0b1d'
                        }
                      }
                    }
                  }
                }}
              />
            </Bar>
          </Container>
        </Row>
        <Row className="d-flex justify-content-end py-4 my-4">
          <Col md={3} sm={12}>
            <Button
              variant="primary"
              className="w-100"
              onClick={exportHtmlAndSubmit}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const {
    id,
    title,
    body,
    url,
    active,
    language,
    referencePageId,
    bodyJson,
    renderContactForm
  } = props.page;
  return {
    page: {
      id,
      title,
      body,
      url,
      active,
      language,
      referencePageId,
      bodyJson,
      renderContactForm
    }
  };
};

const validationSchema = Yup.object().shape({
  page: Yup.object().shape({
    title: Yup.string().required('El título es requerido'),
    url: Yup.string().required('La url es requerido'),
    active: Yup.boolean(),
    renderContactForm: Yup.boolean(),
    body: Yup.string().required('El cuerpo es requerido'),
    bodyJson: Yup.string().required('El cuerpo es requerido'),
    language: Yup.string().required('El idioma es requerido')
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = {
    page: {
      ...snakecaseKeys({ ...values.page }),
      category: 'web'
    }
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(PageForm);
