/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import GalleryForm from './GalleryForm';

import {
  showAdminGalleryRequest,
  updateAdminGalleryRequest
} from '../../../requests/admin/adminGalleries';
import { sendAlert } from '../../../actions/utils';

const GalleryEdit = props => {
  const {
    history,
    match: {
      params: { id }
    }
  } = props;
  const [gallery, setGallery] = useState({
    name: '',
    active: false,
    position: null,
    images: []
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _gallery = camelCaseRecursive(response.data);
    setGallery(_gallery);
  };

  useEffect(() => {
    const { match } = props;
    const {
      params: { id: galleryId }
    } = match;

    showAdminGalleryRequest(galleryId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, [id, dispatch]);

  const handleGoback = () => {
    history.push('/admin/galleries');
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Galería actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _gallery => {
    updateAdminGalleryRequest(gallery.id, {
      dispatch,
      params: _gallery,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-galleries" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar Galería</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <GalleryForm
        gallery={gallery}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(GalleryEdit);
