import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import IcoMoon from '../Icon';

const DefaultProfileHeader = ({
  title,
  breadcrumb,
  body,
  firstColXs,
  tooltip
}) => {
  return (
    <Row className="align-items-center opacity-animation mb-1">
      <Col xs={firstColXs} md={breadcrumb ? 6 : 4}>
        {breadcrumb ? (
          <Breadcrumb routes={breadcrumb} />
        ) : (
          <h5 className={`left-animation ${body ? 'mb-2' : 'mb-4'}`}>
            {title}
            {tooltip && (
              <IcoMoon
                size="23"
                icon="info"
                tooltipText={tooltip}
                classNameTooltip="tooltip-index"
                roundBorder
              />
            )}
          </h5>
        )}
      </Col>
      <Col className="d-flex justify-content-end align-items-start">
        {body && body}
      </Col>
    </Row>
  );
};

export default DefaultProfileHeader;
