import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminPageRequest } from '../../../requests/admin/adminPages';
import columns from './Columns';

const BlogDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminPageRequest,
    onDeleteMessage: 'Blog Eliminado exitosamente',
    confirmationText: '¿Estás seguro de eliminar este Blog?',
    modalTitle: 'Eliminar Blog'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      case 'show':
        window.location.href = `${window.location.origin}/${item.url}`;
        break;
      case 'clone':
        window.location.href = `${window.location.origin}/admin/blogs/new?cloneId=${item.id}`;
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: true,
          withEdit: true,
          withClone: true,
          withDestroy: true,
          showClick: 'show'
        })}
        handleSortCase={sortColumnCase}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        withStartDate
        withEndDate
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(BlogDataTable));
