import React, { useState } from 'react';

const ShipsModal = ({ ships, shipName }) => {
  // Estado para controlar la imagen principal del carrusel
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  // Encuentra el barco seleccionado basado en el título del modal (esto puede cambiar si hay una mejor manera)
  const selectedShip = ships.find(ship => ship.name === shipName);

  return (
    <div>
      {selectedShip ? (
        <div>
          {/* Imagen principal del carrusel */}
          <div
            className="w-100"
            style={{
              height: '300px',
              backgroundImage: `url(${selectedShip.shipImages[activeImageIndex]})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat'
            }}
          />

          {/* Miniaturas de imágenes */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              marginTop: '10px'
            }}
          >
            {selectedShip.shipImages.map((img, index) => (
              <img
                src={img}
                alt={selectedShip.name}
                style={{ width: '60px', height: '60px', cursor: 'pointer' }}
                onClick={() => setActiveImageIndex(index)}
                aria-hidden
              />
            ))}
          </div>

          {/* Información del barco */}
          <div style={{ marginTop: '30px', marginBottom: '30px' }}>
            <h4 className="text-primary mb-4">{selectedShip.name}</h4>
            <p>
              <span style={{ fontWeight: 'bold' }}>Eslora:</span>{' '}
              {selectedShip.eslora}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Capacidad de Carga:</span>{' '}
              {selectedShip.cargoCapacity}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>
                Almacenamiento de Auto:
              </span>{' '}
              {selectedShip.autoStorage}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>
                Metros Lineales de Carga:
              </span>{' '}
              {selectedShip.linearMetersCargo}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>
                Capacidad de Pasajeros:
              </span>{' '}
              {selectedShip.passengerCapacity}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Año de Construcción:</span>{' '}
              {selectedShip.constructionYear}
            </p>
          </div>
        </div>
      ) : (
        <p>Barco no encontrado</p>
      )}
    </div>
  );
};

export default ShipsModal;
