import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import RouteForm from './RouteForm';
import cargoRate from '../../../components/Routes/cargoRate';
import tourismRate from '../../../components/Routes/tourismRate';
import daysItineraryDescription from '../../../components/Routes/daysItineraryDescription';
import { createAdminRouteRequest } from '../../../requests/admin/adminRoutes';
import { sendAlert } from '../../../actions/utils';

const RouteNew = props => {
  const { location } = props;
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const [route, setRoute] = useState({
    routeName: '',
    routeCode: '',
    routeDuration: '',
    routeCheckIn: '',
    origin: '',
    destination: '',
    position: 1,
    active: true,
    cargoRatesAttributes: [cargoRate],
    tourismRatesAttributes: [tourismRate],
    routesIncludedElementsAttributes: [],
    daysItineraryDescriptionsAttributes: [daysItineraryDescription],
    includedElements: [],
    interactiveMapRouteLink: '',
    routeType: 'both',
    language: ''
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    const routePath =
      searchParams.get('type') === 'both'
        ? 'routes_and_included'
        : 'cargo_routes_and_included';
    history.push({
      pathname: `/admin/${routePath}`,
      state: { type: 'Rutas & tarifas' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Ruta creada con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _route => {
    createAdminRouteRequest({
      dispatch,
      params: _route,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-routes" className="mb-4">
        <Col>
          <h4 className="text-primary">Crear Rutas</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <RouteForm route={route} action="new" formRequest={handleCreateRequest} />
    </div>
  );
};

export default connect()(RouteNew);
