import React from 'react';
import '../../../assets/stylesheets/components/utils/selector.scss';

const ModelFilter = ({ models, setSelectedModel, selectedModel }) => {
  return (
    <div className="model-selector-container">
      {models?.map(model => (
        <div
          aria-hidden="true"
          onClick={() => {
            setSelectedModel(model);
          }}
          className={`model-selector-box ${
            selectedModel === model
              ? 'model-selector-box-active'
              : 'model-selector-hover'
          }`}
        >
          <p
            className={`model-selector-box-text ${
              selectedModel === model ? 'text-light' : ''
            }`}
          >
            {model}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ModelFilter;
