/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import {
  FormikInput,
  FormikCheckBox,
  UploadImage,
  IcoMoon,
  FormikSelect
} from '../../../components';
import useFetchData from '../../../hooks/useFetchData';
import { debounceindexAdminAttractionsRequest } from '../../../requests/admin/adminAttractions';
import { languages } from '../../../services/utils';

const AttractionForm = props => {
  const { onHide, submitVariant, errors, touched, action, attraction } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultAttraction, setDefaultAttraction] = useState(undefined);

  const { image } = values.attraction;

  useEffect(() => {
    setDefaultAttraction(attraction);
  }, [attraction]);

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <Field name="attraction[attractionImage]">
            {({ field }) => {
              return (
                <UploadImage
                  {...field}
                  abbr
                  modelType="attraction"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="image1" size={20} />
                    </div>
                  }
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(defaultAttraction, 'image.fileUrl')}
                  fileName={getIn(defaultAttraction, 'image.filename')}
                  onChange={images =>
                    setFieldValue('attraction[attractionImage]', images)
                  }
                  error={getIn(errors, 'attraction[attractionImage]')}
                  touched={getIn(touched, 'attraction[attractionImage]')}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name="attraction[title]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="attraction[language]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  label="Idioma"
                  placeholder="Seleccionar Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={Object.entries(languages).map(([value, label]) => ({
                    value,
                    label
                  }))}
                  onChange={selectedOption => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : '';
                    setFieldValue(name, selectedValue);
                  }}
                  defaultValue={field.value}
                />
              );
            }}
          </Field>
          <Field name="attraction[link]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Link"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="attraction[description]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="textarea"
                  isTextArea
                  label="Descripción"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  rows={5}
                />
              );
            }}
          </Field>

          <Field name="attraction[position]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Posición"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="attraction[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    title,
    language,
    description,
    link,
    position,
    active,
    image
  } = props.attraction;
  return {
    attraction: {
      id,
      title,
      language,
      description,
      link,
      position,
      active,
      attractionImage: image
    },
    languageTranslate: languages[language]
  };
};

const validationSchema = Yup.object().shape({
  attraction: Yup.object().shape({
    title: Yup.string().required('El título es requerido'),
    language: Yup.string().required('El idioma es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    attractionImage: Yup.mixed().required('Debes adjuntar una imagen'),
    link: Yup.string().required('El link es requerido'),
    // .url('Debe ser una URL válida'),
    active: Yup.boolean(),
    position: Yup.number()
      .integer()
      .required('La posición es requerida')
  })
});

const handleSubmit = (values, { props }) => {
  const { attractionImage } = values.attraction;
  const paramsToSend = {
    attraction: snakecaseKeys({ ...values.attraction })
  };
  if (attractionImage) paramsToSend.attraction.image = attractionImage;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AttractionForm);
