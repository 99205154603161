import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexFaqsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/faqs${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexFaqsRequest = AwesomeDebouncePromise(
  indexFaqsRequest,
  300
);
