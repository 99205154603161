import ApiService from '../services/apiService';

export const showUserRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/users/show', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateUserRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('put', '/users/update', {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteDocumentRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('delete', '/users/destroy_document', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateUserPasswordRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback,
  callback
}) => {
  ApiService.request('put', '/change_password', {
    dispatch,
    params,
    successCallback,
    failureCallback,
    callback
  });
};
