import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import 'react-image-gallery/styles/css/image-gallery.css';

import GalleriesGrid from '../../components/Galleries/GalleriesGrid';
import SEO from '../../components/Utils/SEO/SEO';

const Galleries = ({ customParams }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  return (
    <>
      <SEO
        title={t('galleries.galleriesSection.seo.title')}
        description={t('galleries.galleriesSection.seo.description')}
      />
      <Row className="justify-content-center d-flex bottom-animation">
        <Col className="title_text mt-4">
          <h4 className="text-left" style={{ paddingBottom: '5px' }}>
            {t('galleries.galleriesSection.body.sectionTitle')}
          </h4>
          <p className="text-left mb-4">
            {t('galleries.galleriesSection.body.sectionSubTitle')}
          </p>
          <GalleriesGrid numOfGalleries={10} customParams={customParams} />
        </Col>
      </Row>
    </>
  );
};

export default Galleries;
