/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Container, Image, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import indexAttractionsRequest from '../../requests/attractions';
import '../../assets/stylesheets/components/attractions/AttractionsGrid.scss';

const AttractionsGrid = () => {
  const [attractions, setAttractions] = useState([]);
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';
  const { t } = useTranslation();

  const attracctionsRequest = () => {
    indexAttractionsRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 4,
        language
      },
      successCallback: result => {
        setAttractions(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(attracctionsRequest, []);

  return (
    <Container>
      <section className="animated-grid">
        {attractions.map(attraction => (
          <div key={`attraction-grid-${attraction.id}`} className="card">
            <div className="attraction-info">
              <p className="attraction-title">{attraction.title}</p>
              <p className="attraction-description">{attraction.description}</p>
              <Button
                onClick={() => window.open(attraction.link, '_blank')}
                target="_blank"
                type="submit"
                variant="primary"
                className="w-100 attraction-button"
              >
                {t('tourism.landing.attractions.knowMore')}
              </Button>
            </div>
            <Image
              src={attraction.image.fileUrl}
              className="attraction-image"
            />
          </div>
        ))}
      </section>
    </Container>
  );
};

export default AttractionsGrid;
