import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../components';
import SlideDataTable from './SlideDataTable';
import { debounceIndexSlidesRequest } from '../../requests/admin/adminSlides';
import useFetchData from '../../hooks/useFetchData';

const SlideIndex = ({ categoryType }) => {
  const [customParams, setCustomParams] = useState(() => {
    return categoryType === 'all'
      ? {
          sort_column: 'position',
          sort_direction: 'asc',
          display_length: 15
        }
      : {
          sort_column: 'position',
          sort_direction: 'asc',
          display_length: 15,
          slide_category: categoryType
        };
  });

  const {
    data: slides,
    totalData: totalSlides,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexSlidesRequest,
    customParams
  });
  const modelUrl = '/admin/slides';
  const clearCustomParams = () => {
    setCustomParams(() => {
      return categoryType === 'all'
        ? { display_length: 15 }
        : { slide_category: categoryType, display_length: 15 };
    });
  };
  useEffect(clearCustomParams, [isFetching]);

  return (
    <div className="p-4">
      <Row id="index-admin-slides">
        {categoryType !== 'all' ? (
          <>
            <Col />
            <Col md={2}>
              <LinkBtn
                variant="primary"
                block
                to={`/admin/slides/new?slideCategoryType=${categoryType}`}
              >
                Crear
              </LinkBtn>
            </Col>
          </>
        ) : (
          <Col>
            <h4 className="text-primary mt-4 ml-4">Todos los Slides</h4>
          </Col>
        )}
      </Row>
      <SlideDataTable
        tableData={slides}
        tableDataAmount={totalSlides}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
        categoryType={categoryType}
      />
    </div>
  );
};

export default withRouter(connect()(SlideIndex));
