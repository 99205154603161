import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { useWindowSize } from '../../hooks';
import { FormBookTrip, CenteredModal } from '../../components';
import FirstSection from './Landing/FirstSection';
import SecondSection from './Landing/SecondSection';
import RoutesSection from './Landing/RoutesSection';
import FouthSection from './Landing/FouthSection';
import FifthSection from './Landing/FifthSection';
import SixthSection from './Landing/SixthSection';
import CargoBannerSection from './Landing/CargoBannerSection';
import GuidesSection from './Landing/GuidesSection';
import SEO from '../../components/Utils/SEO/SEO';
import { redirectToReservation } from '../../services/utils';
import { indexRoutesRequest } from '../../requests/routes';
// import CookieConsentComponent from '../../components/CookieConsent/CookieConsent';

const bookTrip = {
  route: '',
  month: '',
  year: '',
  adults: 0,
  children: 0,
  infants: 0
};

const hardcodedTourismDates = [
  {
    idViaje: 32645,
    fechaZarpe: '2024-11-11T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 183
  },
  {
    idViaje: 32638,
    fechaZarpe: '2024-11-15T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 184
  },
  {
    idViaje: 32638,
    fechaZarpe: '2024-11-18T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 184
  },
  {
    idViaje: 32639,
    fechaZarpe: '2024-11-22T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 185
  },
  {
    idViaje: 32639,
    fechaZarpe: '2024-11-25T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 185
  },
  {
    idViaje: 32640,
    fechaZarpe: '2024-11-29T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 186
  },
  {
    idViaje: 32640,
    fechaZarpe: '2024-12-02T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 186
  },
  {
    idViaje: 32644,
    fechaZarpe: '2024-12-06T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 187
  },
  {
    idViaje: 32644,
    fechaZarpe: '2024-12-09T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 187
  },
  {
    idViaje: 32641,
    fechaZarpe: '2024-12-13T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 188
  },
  // {
  //   idViaje: 32641,
  //   fechaZarpe: '2024-12-06T06:00:00-03:00',
  //   routeDestination: 'Puerto Natales - Puerto Montt',
  //   ship: 'ESPERANZA',
  //   numeroComercial: 188
  // },
  {
    idViaje: 32643,
    fechaZarpe: '2024-12-16T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 189
  },
  {
    idViaje: 32643,
    fechaZarpe: '2024-12-20T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 189
  },
  {
    idViaje: 32642,
    fechaZarpe: '2024-12-27T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 190
  },
  {
    idViaje: 32642,
    fechaZarpe: '2024-12-31T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 190
  },
  {
    idViaje: 32656,
    fechaZarpe: '2025-01-03T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 191
  },
  {
    idViaje: 32656,
    fechaZarpe: '2025-01-07T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 191
  },
  {
    idViaje: 32657,
    fechaZarpe: '2025-01-10T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 192
  },
  {
    idViaje: 32657,
    fechaZarpe: '2025-01-14T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 192
  },
  {
    idViaje: 32662,
    fechaZarpe: '2025-01-17T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 193
  },
  {
    idViaje: 32662,
    fechaZarpe: '2025-01-21T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 193
  },
  {
    idViaje: 32658,
    fechaZarpe: '2025-01-24T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 194
  },
  {
    idViaje: 32658,
    fechaZarpe: '2025-01-28T06:00:00-03:00',
    routeDestination: 'Puerto Natales - Puerto Montt',
    ship: 'ESPERANZA',
    numeroComercial: 194
  },
  {
    idViaje: 32659,
    fechaZarpe: '2025-01-31T16:00:00-03:00',
    routeDestination: 'Puerto Montt - Puerto Natales',
    ship: 'ESPERANZA',
    numeroComercial: 195
  }
  // {idViaje: 32659, fechaZarpe: '2025-02-04T08:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 195},
  // {idViaje: 32660, fechaZarpe: '2025-02-07T16:00:00-03:00', routeDestination: 'Puerto Montt - Puerto Natales', ship: 'ESPERANZA', numeroComercial: 196},
  // {idViaje: 32660, fechaZarpe: '2025-02-11T06:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 196},
  // {idViaje: 32663, fechaZarpe: '2025-02-14T16:00:00-03:00', routeDestination: 'Puerto Montt - Puerto Natales', ship: 'ESPERANZA', numeroComercial: 197},
  // {idViaje: 32663, fechaZarpe: '2025-02-18T06:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 197},
  // {idViaje: 32661, fechaZarpe: '2025-02-21T16:00:00-03:00', routeDestination: 'Puerto Montt - Puerto Natales', ship: 'ESPERANZA', numeroComercial: 198},
  // {idViaje: 32661, fechaZarpe: '2025-02-25T06:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 198},
  // {idViaje: 32664, fechaZarpe: '2025-02-28T16:00:00-03:00', routeDestination: 'Puerto Montt - Puerto Natales', ship: 'ESPERANZA', numeroComercial: 199},
  // {idViaje: 32664, fechaZarpe: '2025-03-04T06:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 199},
  // {idViaje: 32665, fechaZarpe: '2025-03-07T16:00:00-03:00', routeDestination: 'Puerto Montt - Puerto Natales', ship: 'ESPERANZA', numeroComercial: 200},
  // {idViaje: 32665, fechaZarpe: '2025-03-11T06:00:00-03:00', routeDestination: 'Puerto Natales - Puerto Montt', ship: 'ESPERANZA', numeroComercial: 200}
];

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const { windowSize } = useWindowSize();
  const language = localStorage.getItem('language') || 'es';
  const [heroTitle, setHeroTitle] = useState('');
  const { settings } = useSelector(state => state.utils);
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [routes, setRoutes] = useState([]);
  const [
    availableTourismItineraries,
    setAvailableTourismItineraries
  ] = useState([]);

  const [tourismSeasonStart, setTourismSeasonStart] = useState(undefined);
  const [tourismSeasonEnd, setTourismSeasonEnd] = useState(undefined);

  const updateAvailableCargoItineraries = () => {
    const data = camelCaseRecursive(
      JSON.parse(settings.availableTourismItineraries)
    );
    setAvailableTourismItineraries(data);
    setAvailableTourismItineraries(hardcodedTourismDates);

    const oneYearFromNow = new Date().setFullYear(new Date().getFullYear() + 1);

    const SeasonStart =
      settings.tourismSeasonStartDate !== ''
        ? settings.tourismSeasonStartDate
        : Date.now();
    const SeasonEnd =
      settings.tourismSeasonEndDate !== ''
        ? settings.tourismSeasonEndDate
        : oneYearFromNow;

    setTourismSeasonStart(new Date(SeasonStart));
    setTourismSeasonEnd(new Date(SeasonEnd));
  };

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        language,
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setRoutes(camelCaseRecursive(result.data.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const setHeroData = () => {
    const title = language === 'en' ? 'landingHeroEn' : 'landingHeroEs';
    const titleValue = settings[title] || '';
    setHeroTitle(titleValue);
  };

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  const handleCloseModal = () => setShowModal(false);

  useEffect(routesRequestWrapper, []);
  useEffect(setHeroData, [settings, language]);
  useEffect(updateAvailableCargoItineraries, [settings]);

  return (
    <>
      <SEO
        title={t('tourism.landing.seo.title')}
        description={t('tourism.landing.seo.description')}
        showSpanishChatbot={language === 'es'}
        showEnglishChatbot={language === 'en'}
      />
      {/* <CookieConsentComponent /> */}
      <FirstSection
        bookTrip={bookTrip}
        isDesktop={isDesktop}
        setShowModal={setShowModal}
        handleCreateRequest={redirectToReservation}
        title={heroTitle}
      />
      <SecondSection
        availableTourismItineraries={availableTourismItineraries}
        tourismSeasonStart={tourismSeasonStart}
        tourismSeasonEnd={tourismSeasonEnd}
      />
      <RoutesSection
        routes={routes}
        isDesktop={isDesktop}
        availableTourismItineraries={availableTourismItineraries}
        tourismSeasonStart={tourismSeasonStart}
        tourismSeasonEnd={tourismSeasonEnd}
      />

      <MarginTopBottomWrapper childComponent={<FouthSection />} />
      <MarginBottomWrapper
        childComponent={<FifthSection isDesktop={isDesktop} />}
      />
      <SixthSection />

      <CargoBannerSection />
      <GuidesSection settings={settings} />
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(Home);

const MarginTopBottomWrapper = ({ childComponent }) => {
  return (
    <Row className="mt-5 mb-5 align-items-center justify-content-center">
      <Col md={10}>{childComponent}</Col>
    </Row>
  );
};

const MarginBottomWrapper = ({ childComponent }) => {
  return (
    <Row className="mb-5 align-items-center justify-content-center">
      <Col md={10}>{childComponent}</Col>
    </Row>
  );
};
