import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { LinkBtn, FormikInput, MainLogo } from '../../components';
import { requestSignIn } from '../../actions/auth';

const Login = props => {
  const { onHide, isValid, errors, touched } = props;
  return (
    <Row
      id="login"
      className="justify-content-center align-items-center full-height"
    >
      <Col md={6} className="Container-login__image">
        <Button className="mt-4 btn btn-contact">Volver a navimag</Button>
      </Col>
      <Col md={6} className="Container-login__form">
        <Row>
          <div className="col d-flex justify-content-center">
            <MainLogo style={{ height: '125px', width: '125px' }} />
          </div>
        </Row>

        <Row>
          <Col className="card-login">
            <h4 className="d-flex justify-content-center">Iniciar sesión</h4>
            <p className="d-flex justify-content-center">Administración</p>
            <Form>
              <Field name="email" className="mt-2">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label="E-mail"
                    placeholder="Email"
                    inputType="email"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Field name="password" className="mb-2">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    inputType="password"
                    label="Contraseña"
                    placeholder="Contraseña"
                    error={errors[field.name]}
                    touched={touched[field.name]}
                  />
                )}
              </Field>
              <Button
                size="lg"
                className="mt-4 btn-red"
                block
                type="submit"
                disabled={!isValid}
                onClick={onHide}
              >
                Iniciar Sesión
              </Button>
            </Form>
            <div className="text-center mt-2">
              <LinkBtn to="/recover_password">¿Olvidaste tu cuenta?</LinkBtn>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn({
      user: {
        email: values.email,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
