/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';
import { languages } from '../../../services/utils';

const getTitleCell = ({ title }) => title;
const getUrlCell = ({ url }) => url;
const getActiveCell = item => (item.active ? 'Sí' : 'No');
const getHighlightedCell = item => (item.highlighted ? 'Sí' : 'No');
const getAuthorCell = ({ author }) => {
  if (author) {
    return author;
  }
  return 'No hay autor';
};
const getPublishedDateCell = ({ publishedDate }) => {
  if (!publishedDate) {
    return 'No disponible';
  }
  const [year, month, day] = publishedDate?.split('-');
  const monthName = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(
    new Date(year, month - 1)
  );
  const formattedDate = `${day} de ${monthName} del ${year}`;
  return formattedDate;
};
const getLanguageCell = ({ language }) => languages[language];
const getReferenceCell = ({ referencePageUrl }) => {
  return referencePageUrl ? (
    <a href={`${window.location.origin}/${referencePageUrl}`}>
      {referencePageUrl}
    </a>
  ) : (
    'No hay referencia'
  );
};

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withClone = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Título',
      selector: () => 'title',
      sortable: true,
      cell: getTitleCell,
      grow: '2'
    },
    {
      name: 'URL',
      selector: () => 'url',
      sortable: true,
      cell: getUrlCell,
      grow: '1.5'
    },
    {
      name: 'Activo',
      selector: () => 'active',
      grow: '.5',
      sortable: true,
      cell: getActiveCell
    },
    {
      name: 'Destacado',
      selector: () => 'highlighted',
      grow: '.5',
      sortable: true,
      cell: getHighlightedCell
    },
    {
      name: 'Autor',
      selector: () => 'author',
      sortable: true,
      cell: getAuthorCell,
      grow: '.5'
    },
    {
      name: 'Fecha de publicación',
      selector: () => 'publishedDate',
      cell: getPublishedDateCell,
      grow: '1'
    },
    {
      name: 'Idioma',
      selector: () => 'language',
      sortable: true,
      cell: getLanguageCell,
      grow: '.5'
    },
    {
      name: 'Referencia',
      selector: () => 'reference',
      grow: '1',
      cell: getReferenceCell
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withClone={withClone}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);
export default columns;
