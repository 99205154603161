import React, { useState, useEffect } from 'react';
import { Row, Col, CardColumns, Form } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import { FormikInputRadio, FormikInput } from '../../../components';

import CargoLeadElementCard from '../../../components/Utils/Cards/CargoLeadElementCard';
import { binaryOptions, cargoRateImagesOptions } from '../../../services/utils';

const FirstPageCargoLeadsForm = ({ modelName, routes }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const [selectedRoute, setSelectedRoute] = useState({});
  const [selectedRouteCargoElements, setSelectedRouteCargoElements] = useState(
    []
  );
  const [selectedRouteCargoElement, setSelectedRouteCargoElement] = useState({
    linealLength: false,
    roundTrip: false
  });

  const setSelectedRouteData = () => {
    if (selectedRoute && Object.keys(selectedRoute).length) {
      setSelectedRouteCargoElements(selectedRoute.cargoRatesAttributes);
    }
  };

  const setInitialRoutes = () => {
    if (values && routes !== [] && values?.cargoLead.routeId !== '') {
      setSelectedRoute(
        routes.find(
          route => String(route.id) === String(values?.cargoLead.routeId)
        )
      );
    }
  };

  const setInitialRouteCargoElement = () => {
    if (
      values &&
      selectedRoute &&
      Object.keys(selectedRoute).length &&
      routes !== [] &&
      values?.cargoLead.cargoRateId !== ''
    ) {
      const cargoRateElement = selectedRoute?.cargoRatesAttributes.find(
        cargoRate =>
          String(cargoRate.value) === String(values?.cargoLead.cargoRateId)
      );
      if (cargoRateElement) {
        setSelectedRouteCargoElement(
          cargoRateImagesOptions.find(
            element => element.value === cargoRateElement.imageType
          )
        );
      } else {
        setSelectedRouteCargoElement({
          linealLength: false,
          roundTrip: false
        });
      }
    }
  };

  const updateSelectedOptionValue = () => {
    const cargoRateElement = selectedRoute?.cargoRatesAttributes?.find(
      cargoRate =>
        String(cargoRate.value) === String(values?.cargoLead.cargoRateId)
    );

    if (cargoRateElement) {
      const imageOptions = cargoRateImagesOptions?.find(
        element => element.value === cargoRateElement?.imageType
      );

      setFieldValue('cargoLead', {
        ...values.cargoLead,
        roundTrip: imageOptions.roundTrip ? values.cargoLead.roundTrip : '',
        linealLength: imageOptions.linealLength
          ? values.cargoLead.linealLength
          : ''
      });
    }
  };

  useEffect(setInitialRoutes, [values]);
  useEffect(updateSelectedOptionValue, [
    values?.cargoLead?.cargoRateId,
    selectedRoute
  ]);
  useEffect(setInitialRouteCargoElement, [selectedRoute]);
  useEffect(setSelectedRouteData, [selectedRoute]);

  const handleOnClick = cargoRateElement => {
    setSelectedRouteCargoElement(
      cargoRateImagesOptions.find(
        element => element.value === cargoRateElement.imageType
      )
    );
    setFieldValue('cargoLead', {
      ...values.cargoLead,
      cargoRateId: cargoRateElement.value
    });
  };

  return (
    <>
      <section className="mb-1 w-100">
        <Row className="d-flex align-items-center">
          <Col>
            <Field name={`${modelName}[routeId]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  field={field}
                  abbr
                  label="¿Cuál ruta requieres?"
                  options={routes}
                  fieldName={`${modelName}[routeId]`}
                  error={getIn(errors, `${modelName}[routeId]`)}
                  touched={getIn(touched, `${modelName}[routeId]`)}
                  setData={setSelectedRoute}
                  columnClass="two-columns"
                  customFormLabel="cargo-lead-input-radio-label"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Label>
              ¿Qué tipo de vehículo necesitas transportar?
              <abbr className="text-danger">*</abbr>
            </Form.Label>
            <CardColumns className="card-cargo-body-container mt-4">
              {selectedRouteCargoElements.map(cargoElement => {
                return (
                  <CargoLeadElementCard
                    key={cargoElement.value}
                    title={cargoElement.label}
                    categoryImage={cargoElement.imageType}
                    onClick={() => handleOnClick(cargoElement)}
                    selectedClassName={
                      String(values.cargoLead.cargoRateId) ===
                      String(cargoElement.value)
                        ? 'selected-category'
                        : ''
                    }
                  />
                );
              })}
            </CardColumns>
          </Col>
        </Row>
        <Row>
          <Col>
            {selectedRouteCargoElement.linealLength && (
              <Field name={`${modelName}[linealLength]`}>
                {({ field }) => {
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      label="Metro lineal de Vehículo"
                      placeholder="Ingresa el largo de tu vehiculo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  );
                }}
              </Field>
            )}
            {selectedRouteCargoElement.roundTrip && (
              <Field name={`${modelName}[roundTrip]`}>
                {({ field }) => (
                  <FormikInputRadio
                    {...field}
                    field={field}
                    abbr
                    label="¿Requieres viaje ida y vuelta?"
                    options={binaryOptions}
                    fieldName={`${modelName}[roundTrip]`}
                    error={getIn(errors, `${modelName}[roundTrip]`)}
                    touched={getIn(touched, `${modelName}[roundTrip]`)}
                    customFormLabel="cargo-lead-input-radio-label"
                  />
                )}
              </Field>
            )}
          </Col>
        </Row>
      </section>
    </>
  );
};

export default FirstPageCargoLeadsForm;
