import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import FaqForm from './FaqForm';
import { createAdminFaqRequest } from '../../../requests/admin/adminFaqs';
import { sendAlert } from '../../../actions/utils';

const FaqNew = props => {
  const { history, location } = props;
  const { search } = location;
  const searchParams = new URLSearchParams(search);

  // eslint-disable-next-line no-unused-vars
  const [faq, setFaq] = useState({
    question: '',
    answer: '',
    language: '',
    faqCategoryId: 1,
    position: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    const initialPath = searchParams.get('type');
    history.push({
      pathname: `/admin/${initialPath}_faqs_and_categories`,
      state: { type: 'FAQs' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Faq creada con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _faq => {
    createAdminFaqRequest({
      dispatch,
      params: _faq,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-faqs" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear FAQ</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <FaqForm faq={faq} action="new" formRequest={handleCreateRequest} />
    </div>
  );
};

export default connect()(FaqNew);
