import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../../components';
import { showAdminPageRequest } from '../../../requests/admin/adminPages';
import basicBlog from './basicBlog';

const BlogShow = ({ match }) => {
  const { id } = match.params;
  const [blog, setBlog] = useState(basicBlog);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    setBlog(camelCaseRecursive(response.data));
  };

  const handleShowPage = () => {
    showAdminPageRequest(id, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(handleShowPage, []);

  return (
    <>
      <Row id="show_blogs">
        <Col md={2}>
          <LinkBtn variant="primary" block to={`/admin/blogs/${blog.id}/edit`}>
            Editar
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/admin/blogs">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <div
        dangerouslySetInnerHTML={{ __html: blog.body }}
        style={{
          width: '100%',
          marginTop: '20px',
          padding: '20px',
          borderRadius: '4px'
        }}
      />
    </>
  );
};
export default connect()(BlogShow);
