import React from 'react';
import * as Yup from 'yup';
import { Field, Form, withFormik, getIn } from 'formik';
import { Row, Col, Button, Spinner, Container } from 'react-bootstrap';
import { UploadImage, FormikInput } from '../../components';

const userRoles = {
  admin: 'Administrador',
  tourist: 'Turista'
};

const ProfileForm = ({ errors, touched, setFieldValue, user, onRequest }) => {
  return (
    <Form>
      <Container className="py-4 ">
        <Row>
          <Col md={4} sm={12}>
            <div style={{ width: '70%', margin: 'auto' }}>
              <Field name="user[avatar]">
                {({ field }) => {
                  return (
                    <UploadImage
                      {...field}
                      modelType="profile"
                      accept="image/png, image/jpg, image/jpeg"
                      numberId="01"
                      setFieldValue={setFieldValue}
                      imageUrl={getIn(user, 'avatar[0]url')}
                      fileName={getIn(user, 'avatar[0]name')}
                      customImageStyles="avatar-profile-form-image"
                      onChange={images => setFieldValue(field.name, images)}
                    />
                  );
                }}
              </Field>
            </div>
          </Col>
          <Col md={8} sm={12}>
            <Row className="sm-w-100 md-w-75 m-auto">
              <Col md={6}>
                <Field name="user[firstName]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Nombre"
                      placeholder="Ingresa tu nombre"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={6}>
                <Field name="user[lastName]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Apellido"
                      placeholder="Ingresa tu apellido"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="user[email]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      disabled
                      inputType="text"
                      label="Correo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col md={12}>
                <Field name="user[roles]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      disabled
                      inputType="text"
                      label="Roles de usuario"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </Col>
              <Col xs={8} sm={6} md={4} className="ml-auto">
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4 p-2"
                  disabled={onRequest}
                  style={{ fontSize: '12px' }}
                  block
                >
                  {onRequest && (
                    <Spinner
                      as="span"
                      animation="border"
                      className="mr-2"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Guardar
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const setInitialValues = props => {
  const { user } = props;

  return {
    user: {
      avatar: user.avatar || {},
      email: user.email || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      roles: user.roles?.map(role => userRoles[role.label]).join(' - ') || ''
    }
  };
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    firstName: Yup.string().required('Debes ingresar tu nombre'),
    lastName: Yup.string().required('Debes ingresar tu apellido'),
    email: Yup.string()
      .email('Debes ingresar un correo electrónico válido')
      .required('Debes ingresar tu correo electrónico')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ProfileForm);
