import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import FaqCategoryForm from './FaqCategoryForm';

import {
  showAdminFaqCategoryRequest,
  updateAdminFaqCategoryRequest
} from '../../../requests/admin/adminFaqCategories';
import { sendAlert } from '../../../actions/utils';

const FaqCategoryEdit = props => {
  const { history } = props;
  const [faqCategory, setFaqCategory] = useState({
    id: 1,
    category: '',
    language: '',
    position: 1,
    categoryType: 'tourism',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _faqCategory = camelCaseRecursive(response.data);
    setFaqCategory(_faqCategory);
  };

  useEffect(() => {
    const { match } = props;
    const faqCategoryId = match.params.id;
    showAdminFaqCategoryRequest(faqCategoryId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: `/admin/${faqCategory.categoryType}_faqs_and_categories`,
      state: { type: 'Categorías de FAQs' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Categoría actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _faqCategory => {
    const faqCategoryId = _faqCategory.faq_category.id;
    updateAdminFaqCategoryRequest(faqCategoryId, {
      dispatch,
      params: _faqCategory,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="p-4">
      <Row id="edit-faq-category" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar categoría de FAQ</h4>
        </Col>
        <Col md={2}>
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <FaqCategoryForm
            faqCategory={faqCategory}
            action="edit"
            formRequest={handleUpdateRequest}
            categoryType={faqCategory.categoryType}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect()(FaqCategoryEdit);
