import React, { useState, useEffect } from 'react';
import jwt from 'jwt-decode';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import {
  FormikInput,
  FormikCheckBox,
  FormikSelect,
  UploadFile,
  IcoMoon
} from '../../../components';
import { Can } from '../../../config/abilityContext';
import CargoRatesAttributes from '../../../components/Routes/CargoRatesAttributes';
import TourismRatesAttributes from '../../../components/Routes/TourismRatesAttributes';
import DaysItineraryDescriptionsAttributes from '../../../components/Routes/DaysItineraryDescriptionsAttributes';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import { indexAdminIncludedElementsRequest } from '../../../requests/admin/adminIncludedElements';
import useFetchData from '../../../hooks/useFetchData';
import { routeOptions, languageOptions } from '../../../services/utils';

const RouteForm = props => {
  const { onHide, submitVariant, errors, touched, action, route } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const modelName = 'route';

  const [defaultIncludedElements, setDefaultIncludedElements] = useState(
    undefined
  );
  const { values, setFieldValue } = useFormikContext();
  const [defaultRoute, setDefaultRoute] = useState(undefined);
  const [defaultLanguage, setDefaultLanguage] = useState(undefined);
  const [defaultBrochure, setDefaultBrochure] = useState(undefined);
  const [defaultItinerary, setDefaultItinerary] = useState(undefined);
  const [selectedShipDirection, setSelectedShipDirection] = useState(
    'Norte - Sur'
  );

  const payload = localStorage.getItem('jwt');
  const user = payload && jwt(payload);

  const { routeType } = values.route;

  const { roles } = user;
  let isCargoAdmin = false;
  let isTourismAdmin = false;
  let isTourism = false;

  if (routeType) {
    isTourism = routeType === 'both';
  }

  if (roles.includes('admin') || roles.includes('tourism_admin')) {
    isTourismAdmin = true;
  }

  if (roles.includes('admin') || roles.includes('cargo_admin')) {
    isCargoAdmin = true;
  }

  const findDefaultRoute = () => {
    setDefaultRoute(
      routeOptions.find(routeOption => routeOption.value === route?.routeType)
    );
  };

  const findDefaultLanguage = () => {
    setDefaultLanguage(
      languageOptions.find(language => language.value === route?.language)
    );
  };

  useEffect(findDefaultRoute, [route]);
  useEffect(findDefaultLanguage, [route]);

  useEffect(() => {
    setDefaultBrochure(route);
  }, [route, props]);

  useEffect(() => {
    setDefaultItinerary(route);
  }, [route, props]);

  const { data: includedElements } = useFetchData({
    debouncedIndexRequest: indexAdminIncludedElementsRequest,
    fetchingErrorMessage:
      'Error al buscar elementos incluidos. Por favor inténtelo nuevamente.',
    customParams: {
      sort_column: 'description',
      sort_direction: 'asc'
    }
  });

  const findDefaultIncludedRoles = () => {
    setDefaultIncludedElements(
      includedElements
        .filter(includedElement =>
          route.includedElements
            ?.map(element => element.value)
            .includes(includedElement.id)
        )
        .map(element => ({ value: element.value, label: element.label }))
    );
  };

  const mapDefaultIncludedElements = () => {
    setFieldValue(
      'route[routesIncludedElementsAttributes]',
      route.includedElements?.map(element => ({
        includedElementId: element.value
      }))
    );
  };

  const handleSelectorIsMulti = (data, allowEmpty = false) => {
    const newData = data.map(element => ({
      value: element.value,
      label: element.label
    }));
    if ((allowEmpty && newData.length === 0) || (data && data.length === 0)) {
      route.includedElements = [];
      setFieldValue('route[routesIncludedElementsAttributes]', []);
    } else {
      route.includedElements = newData;
      setFieldValue(
        'route[routesIncludedElementsAttributes]',
        data.map(element => ({ includedElementId: element.value }))
      );
    }
  };

  useEffect(findDefaultIncludedRoles, [
    route.includedElements,
    includedElements
  ]);
  useEffect(mapDefaultIncludedElements, [defaultIncludedElements]);

  return (
    <Form className="w-100 px-2">
      <Col>
        <Row>
          <Col>
            <Field name="route[routeName]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Nombre de ruta"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="route[routeCode]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Codigo de ruta (origen)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={4}>
            <Field name="route[destinationRouteCode]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Codigo de ruta (destino)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col md={2} className="d-flex justify-content-center pt-3">
            <Field name="route[active]">
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Activo"
                  custom
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="route[routeDuration]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Duración estimada de la ruta (origen)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[routeCheckIn]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Lugar del check in (origen)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[destinationDuration]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Duración estimada de la ruta (destino)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[destinationCheckin]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Lugar del check in (destino)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="route[origin]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Origen"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[destination]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="Destino"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[ship]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    inputType="text"
                    label="Barcaza (solo turismo)"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="route[language]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    {...field}
                    abbr
                    inputType="text"
                    label="Idioma"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={languageOptions}
                    defaultValue={defaultLanguage?.value}
                    onChange={languageOption => {
                      setFieldValue('route', {
                        ...values.route,
                        language: languageOption.value
                      });
                    }}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[routeType]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    {...field}
                    abbr
                    inputType="text"
                    label="Tipo de ruta"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={routeOptions}
                    defaultValue={defaultRoute?.value}
                    onChange={routeOption => {
                      setFieldValue('route', {
                        ...values.route,
                        routeType: routeOption.value
                      });
                    }}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[position]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="number"
                    label="Posición"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="route[routeBrochure]">
              {({ field }) => {
                return (
                  <UploadFile
                    {...field}
                    abbr
                    label="Pdf Brochure"
                    iconComponent={
                      <div className="circle-image">
                        <IcoMoon icon="files" size={20} />
                      </div>
                    }
                    accept="application/pdf"
                    setFieldValue={setFieldValue}
                    fileName={getIn(
                      defaultBrochure,
                      'attachedBrochure.filename'
                    )}
                    onChange={async pickedPdf => {
                      setFieldValue('route[routeBrochure]', pickedPdf);
                    }}
                    error={getIn(errors, 'route[routeBrochure]')}
                    touched={getIn(touched, 'route[routeBrochure]')}
                    uploadFileId="pdfBrochure"
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="route[routeItinerary]">
              {({ field }) => {
                return (
                  <UploadFile
                    {...field}
                    abbr
                    label="Pdf Itinerary"
                    iconComponent={
                      <div className="circle-image">
                        <IcoMoon icon="files" size={20} />
                      </div>
                    }
                    accept="application/pdf"
                    setFieldValue={setFieldValue}
                    fileName={getIn(
                      defaultItinerary,
                      'attachedItinerary.filename'
                    )}
                    onChange={async pickedPdf => {
                      setFieldValue('route[routeItinerary]', pickedPdf);
                    }}
                    error={getIn(errors, 'route[routeItinerary]')}
                    touched={getIn(touched, 'route[routeItinerary]')}
                    uploadFileId="pdfItinerary"
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="route[interactiveMapRouteLink]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    inputType="text"
                    label="Link del mapa interactivo de la ruta"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>

        <Can I="manage" a={isTourism ? 'Tourist' : ''}>
          <Row>
            <Col>
              <Field name="route[routesIncludedElementsAttributes]">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    isMulti
                    label="Elementos incluidos"
                    placeholder="Seleccionar elementos incluidos"
                    options={includedElements}
                    value={
                      (route.includedElements &&
                        route.includedElements
                          .map(element => element.value)
                          .join(',')) ||
                      ''
                    }
                    defaultValue={
                      (route.includedElements &&
                        route.includedElements
                          .map(element => element.value)
                          .join(',')) ||
                      ''
                    }
                    defaultMultiValues={defaultIncludedElements}
                    onChange={data =>
                      handleSelectorIsMulti(data || [], field.name)
                    }
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          </Row>
          {/* Descipciones */}
          <hr />
          <h5 className="text-secondary">Descripcion de días</h5>

          <ModelFilter
            models={['Norte - Sur', 'Sur - Norte']}
            setSelectedModel={setSelectedShipDirection}
            selectedModel={selectedShipDirection}
          />

          <Row style={{ marginTop: '60px' }} className="nested-rates">
            <Col>
              {selectedShipDirection === 'Norte - Sur' && (
                <DaysItineraryDescriptionsAttributes
                  modelName={modelName}
                  route={route}
                  shipDirection="north_south"
                />
              )}
              {selectedShipDirection === 'Sur - Norte' && (
                <DaysItineraryDescriptionsAttributes
                  modelName={modelName}
                  route={route}
                  shipDirection="south_north"
                />
              )}
            </Col>
          </Row>
        </Can>
        {/* Tarifas de carga */}
        <Can I="manage" a={isCargoAdmin ? 'CargoAdmin' : ''}>
          <hr />
          <h5 className="text-secondary">Tarifas de carga</h5>
          <Row className="nested-rates">
            <Col>
              <CargoRatesAttributes modelName={modelName} route={route} />
            </Col>
          </Row>
        </Can>
        {/* Tarifas de turismo */}
        <Can I="manage" a={isTourismAdmin ? 'TourismAdmin' : ''}>
          <hr />
          <h5 className="text-secondary">Tarifas de turismo</h5>
          <Row className="nested-rates">
            <Col>
              <TourismRatesAttributes modelName={modelName} />
            </Col>
          </Row>
        </Can>
        <hr />
        <Row className="d-flex justify-content-end mr-1 pt-4 pb-4">
          <Col md={3}>
            <Button
              type="submit"
              variant={submitVariant}
              block
              onClick={onHide}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    routeName,
    routeCode,
    routeDuration,
    routeCheckIn,
    origin,
    destination,
    position,
    active,
    cargoRatesAttributes,
    tourismRatesAttributes,
    routesIncludedElementsAttributes,
    daysItineraryDescriptionsAttributes,
    includedElements,
    routeType,
    language,
    ship,
    interactiveMapRouteLink,
    attachedBrochure,
    attachedItinerary,
    destinationCheckin,
    destinationDuration,
    destinationRouteCode
  } = props.route;
  return {
    route: {
      id,
      routeName,
      routeCode,
      routeDuration,
      routeCheckIn,
      origin,
      destination,
      position,
      active,
      cargoRatesAttributes,
      tourismRatesAttributes,
      routesIncludedElementsAttributes,
      daysItineraryDescriptionsAttributes,
      includedElements,
      routeType,
      language,
      ship,
      interactiveMapRouteLink,
      routeBrochure: attachedBrochure,
      routeItinerary: attachedItinerary,
      destinationCheckin,
      destinationDuration,
      destinationRouteCode
    }
  };
};

const validationSchema = Yup.object().shape({
  route: Yup.object().shape({
    routeName: Yup.string().required('Requerido'),
    routeCode: Yup.string().required('Requerido'),
    destinationRouteCode: Yup.string().required('Requerido'),
    origin: Yup.string().required('Requerido'),
    destination: Yup.string().required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean(),
    routeBrochure: Yup.mixed().required('Debes adjuntar un Pdf'),
    routeItinerary: Yup.mixed().required('Debes adjuntar un Pdf')
  })
});

const handleSubmit = (values, { props }) => {
  const { route } = props;
  const { routeBrochure, routeItinerary } = values.route;
  const updateValues = { ...values }; // Make a copy of the values object

  updateValues.route.routesIncludedElementsAttributes = updateValues.route.routesIncludedElementsAttributes.map(
    element => {
      const found =
        route.routesIncludedElementsAttributes &&
        route.routesIncludedElementsAttributes.find(
          attr => attr.includedElementId === element.includedElementId
        );
      if (found) {
        return { ...found };
      }
      return element;
    }
  );

  if (props.action !== 'new') {
    const preProcessedValues =
      updateValues.route.routesIncludedElementsAttributes;
    const deletedValues = route.routesIncludedElementsAttributes
      .map(includedElement => {
        const found = preProcessedValues.find(
          element =>
            element.includedElementId === includedElement.includedElementId
        );
        if (found) {
          return null;
        }
        return { ...includedElement, _destroy: true };
      })
      .filter(elements => {
        return elements != null;
      });

    updateValues.route.routesIncludedElementsAttributes = [
      ...preProcessedValues,
      ...deletedValues
    ];
  }

  const paramsToSend = snakecaseKeys(
    {
      route: updateValues.route
    },
    { exclude: ['_destroy'] }
  );
  const { formRequest } = props;
  if (routeBrochure) paramsToSend.route.brochure = routeBrochure;
  if (routeItinerary) paramsToSend.route.itinerary = routeItinerary;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(RouteForm);
