/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Pagination,
  Spinner,
  FormControl,
  Form
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/en-gb';

import GuidesSection from '../../screens/Home/Landing/GuidesSection';
import { indexPagesRequest } from '../../requests/pages';
import '../../assets/stylesheets/components/blogs/BlogsGrid.scss';
import defaultImage from '../../assets/images/default.png';

const BlogsGrid = () => {
  const dispatch = useDispatch();
  const blogSectionRef = useRef(null);
  const { t, i18n } = useTranslation();

  const pagesToShow = 9;
  const ellipsis = ' ';

  const [blogs, setBlogs] = useState([]);
  const [highlightedBlogs, setHighlightedBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const [totalBlogs, setTotalBlogs] = useState(0);
  const { settings } = useSelector(state => state.utils);

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
    moment.locale(language || 'es');
  }, []);

  const fetchHighlightedBlogs = () => {
    indexPagesRequest({
      dispatch,
      params: {
        display_length: 2,
        sort_column: 'updated_at',
        sort_direction: 'desc',
        category: 'blog',
        highlighted: true,
        language: i18n.language
      },
      successCallback: result => {
        setHighlightedBlogs(camelCaseRecursive(result.data.data));
      }
    });
  };

  const fetchBlogs = () => {
    setIsLoading(true);
    indexPagesRequest({
      dispatch,
      params: {
        display_length: pagesToShow,
        display_start: (currentPage - 1) * pagesToShow,
        sort_column: 'updated_at',
        sort_direction: 'desc',
        category: 'blog',
        highlighted: false,
        query: searchQuery,
        ...(startDate && { date_from: startDate }),
        ...(endDate && { date_to: endDate }),
        language: i18n.language
      },
      successCallback: result => {
        setBlogs(camelCaseRecursive(result.data.data));
        setTotalBlogs(Math.ceil(result.data.metadata.amount / pagesToShow));
        setIsLoading(false);
      }
    });
  };

  const handleStartDateChange = date => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = date => {
    setEndDate(date);
    if (startDate && date < startDate) {
      setStartDate(date);
    }
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
    blogSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    fetchHighlightedBlogs();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchBlogs();
  }, [currentPage]);

  const renderHighlightedBlogs = () => {
    if (highlightedBlogs.length === 0) {
      return null;
    }

    return (
      <>
        <h4 className="title-landing text-center">
          {t('tourism.blogs.blogsGrid.highlighted')}
        </h4>

        <section className="blog-section">
          <Row className="w-100 m-0">
            {highlightedBlogs.map((blog, index) => (
              <Col md={index === 0 ? 8 : 4} key={index}>
                <div className="blog-card">
                  <div className="blog-highlighted-info">
                    <p className="blog-highlighted-title">{blog.title}</p>
                    <p className="blog-highlighted-description">
                      {blog.description}
                    </p>
                    <Button
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/${blog.url}`,
                          '_blank'
                        )
                      }
                      target="_blank"
                      type="submit"
                      variant="primary"
                      className="w-100 blog-button"
                    >
                      {t('tourism.blogs.blogsGrid.readMore')}
                    </Button>
                  </div>
                  <Image
                    src={blog.image.fileUrl}
                    className="blog-highlighted-image"
                  />
                </div>
              </Col>
            ))}
          </Row>
        </section>
      </>
    );
  };

  const renderPaginationItems = () => {
    const paginationItems = [];

    paginationItems.push(
      <Pagination.First
        key="first"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(1)}
      />
    );

    paginationItems.push(
      <Pagination.Prev
        key="prev"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      />
    );

    if (currentPage > pagesToShow + 1) {
      paginationItems.push(
        <Pagination.Item key="page1" onClick={() => handlePageChange(1)}>
          {1}
        </Pagination.Item>
      );

      if (currentPage > pagesToShow + 2) {
        paginationItems.push(
          <Pagination.Ellipsis key="ellipsis1" disabled>
            {ellipsis}
          </Pagination.Ellipsis>
        );
      }
    }

    Array.from(Array(totalBlogs).keys())
      .slice(currentPage - 1, currentPage + pagesToShow)
      .forEach(page => {
        paginationItems.push(
          <Pagination.Item
            key={page + 1}
            active={currentPage === page + 1}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </Pagination.Item>
        );
      });

    if (currentPage < totalBlogs - pagesToShow) {
      if (currentPage < totalBlogs - pagesToShow - 1) {
        paginationItems.push(
          <Pagination.Ellipsis key="ellipsis2" disabled>
            {ellipsis}
          </Pagination.Ellipsis>
        );
      }

      paginationItems.push(
        <Pagination.Item
          key="pageN"
          onClick={() => handlePageChange(totalBlogs)}
        >
          {totalBlogs}
        </Pagination.Item>
      );
    }

    paginationItems.push(
      <Pagination.Next
        key="next"
        disabled={currentPage === totalBlogs}
        onClick={() => handlePageChange(currentPage + 1)}
      />
    );

    paginationItems.push(
      <Pagination.Last
        key="last"
        disabled={currentPage === totalBlogs}
        onClick={() => handlePageChange(totalBlogs)}
      />
    );

    return paginationItems;
  };

  const renderBlogCards = () => {
    if (isLoading) {
      return (
        <Col className="text-center">
          <Spinner animation="border" variant="primary" />
        </Col>
      );
    }

    return (
      <>
        <h4 className="title-landing text-center">
          {t('tourism.blogs.blogsGrid.article')}
        </h4>
        <div className="search-container">
          <div className="date-range">
            <div className="date-label">
              {t('tourism.blogs.blogsGrid.startDate')}
            </div>
            <DatePicker
              id="startDate"
              selected={startDate}
              onChange={handleStartDateChange}
            />
          </div>
          <div className="date-range">
            <div className="date-label">
              {t('tourism.blogs.blogsGrid.endDate')}
            </div>
            <DatePicker
              id="endDate"
              selected={endDate}
              onChange={handleEndDateChange}
            />
          </div>

          <div className="search-input">
            <Form.Group>
              <FormControl
                placeholder={t('tourism.blogs.blogsGrid.searchPlaceholder')}
                name="textInput"
                value={searchQuery}
                onChange={e => {
                  setSearchQuery(e.target.value);
                  setCurrentPage(1);
                }}
              />
            </Form.Group>
          </div>
          <Button className="search-button" onClick={() => fetchBlogs()}>
            {t('tourism.blogs.blogsGrid.search')}
          </Button>
        </div>

        <section className="blog-section" ref={blogSectionRef}>
          {blogs.length === 0 && !isLoading && (
            <p className="text-center">
              {t('tourism.blogs.blogsGrid.noArticlesFound')}
            </p>
          )}
          <Row className="w-100 m-0">
            {blogs.map((blog, index) => (
              <Col md={4} key={index}>
                <div className="blog-card">
                  <div className="blog-info">
                    {blog.image && blog.image.fileUrl ? (
                      <Image
                        src={blog.image.fileUrl}
                        alt="blog"
                        className="blog-image"
                      />
                    ) : (
                      <Image
                        src={defaultImage}
                        alt="default"
                        className="blog-image"
                      />
                    )}
                    {blog.publishedDate && (
                      <p className="blog-date">
                        {moment(blog.publishedDate).format('LL')}
                      </p>
                    )}
                  </div>
                  {blog.title && (
                    <p className="blog-title mt-1">{blog.title}</p>
                  )}
                  {blog.description && (
                    <p className="blog-description mb-2">{blog.description}</p>
                  )}
                  <Button
                    onClick={() =>
                      window.open(
                        `${window.location.origin}/${blog.url}`,
                        '_blank'
                      )
                    }
                    target="_blank"
                    type="submit"
                    variant="primary"
                    className="w-100 blog-button"
                  >
                    {t('tourism.blogs.blogsGrid.readMore')}
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
          <Pagination className="pagination-container">
            {renderPaginationItems()}
          </Pagination>
        </section>
      </>
    );
  };

  return (
    <>
      <Container>{renderHighlightedBlogs()}</Container>
      <Container> {renderBlogCards()} </Container>
      <GuidesSection settings={settings} />
    </>
  );
};

export default BlogsGrid;
