import ApiService from '../services/apiService';

const indexOfficesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/offices${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export default indexOfficesRequest;
