import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../../assets/stylesheets/components/transport/TransportSection.scss';
import Slides from '../../../components/Utils/Slides';
import { indexSlidesRequest } from '../../../requests/slides';

const TransportSection = () => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: 'transport'
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(slidesRequest, []);

  return (
    <Row className="transport-section">
      <Slides items={slides} />
      <Col className="title_text mt-4 d-flex flex-column justify-content-center align-items-center ">
        <p
          style={{
            color: 'white',
            fontSize: '30px',
            fontWeight: 'bold',
            maxWidth: '70%',
            textAlign: 'center'
          }}
        >
          Transporta tu vehículo con nosotros
        </p>
        <p
          style={{
            color: 'white',
            fontSize: '15px',
            maxWidth: '70%',
            textAlign: 'center'
          }}
        >
          Cotiza el transporte de tu Camión, maquinaria o automóvil con
          nosotros. Todos los vehículos transportados en nuestros ferries
          cuentan con un seguro que protege su valor de ante cualquier
          incidente.
        </p>

        <Button
          as={Link}
          to={{ pathname: '/cargo/contact' }}
          className="m-4 p-3 see-transport-btn w-25"
        >
          Contacta a tu asesor
        </Button>
      </Col>
    </Row>
  );
};

export default TransportSection;
