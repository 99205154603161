import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import IcoMoon from '../Icon/IcoMoon';

const IconBtn = ({
  children,
  className: customClassName,
  disabled,
  icon,
  iconClass = '',
  iconSize = 'md',
  noBtn,
  noIcon,
  onlyIcon,
  to,
  variant,
  ...props
}) => {
  return (
    <>
      {!children ? (
        <div className={`icon-container ${iconSize}`}>
          {onlyIcon ? (
            <div className={`only-icon ${customClassName}`}>
              {icon && (
                <IcoMoon className={iconClass} icon={icon} variant={variant} />
              )}
            </div>
          ) : (
            <Button
              as={to ? Link : Button}
              to={to}
              className={`icon-btn ${customClassName} ${
                noBtn ? 'non-clickable' : ''
              } ${disabled ? 'disabled' : ''}`}
              variant={variant}
              {...props}
            >
              {icon && (
                <IcoMoon
                  className={`icon-btn--icon ${iconClass}`}
                  icon={icon}
                />
              )}
            </Button>
          )}
        </div>
      ) : (
        <Button
          as={to ? Link : Button}
          to={to}
          className={`icon-btn ${customClassName} ${
            noBtn ? 'non-clickable' : ''
          } ${disabled ? 'disabled' : ''}`}
          variant={variant}
          {...props}
        >
          {children}
          {icon && (
            <IcoMoon className={`icon-btn--icon ${iconClass}`} icon={icon} />
          )}
        </Button>
      )}
    </>
  );
};

export default IconBtn;
