import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';

import { indexSlidesRequest } from '../../../requests/slides';
import CarouselComponent from '../Carousel/Carousel';

const SlidesAndText = ({
  isDesktop,
  title,
  subtitle,
  description,
  slideType = ''
}) => {
  const dispatch = useDispatch();
  const [slides, setSlides] = useState([]);

  const fetchSlides = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: slideType
      },
      successCallback: result => {
        const updatedSlides = camelCaseRecursive(result.data.data).map(
          slide => ({
            ...slide,
            img: slide.fileInfo.fileUrl
          })
        );
        setSlides(updatedSlides);
      }
    });
  };

  useEffect(fetchSlides, []);

  return (
    <Row className="fifth-section mb-4">
      {isDesktop && (
        <Col md={5}>
          <CarouselComponent
            noArrow
            className="carousel-facilities-map"
            classNameImg="img-map-section"
            data={slides}
          />
        </Col>
      )}
      <Col
        md={isDesktop ? 7 : 12}
        className="d-flex flex-column justify-content-center col-right"
      >
        <h2 className="text-primary text-center mb-2 title">{title}</h2>
        <h5 className="mb-3">{subtitle}</h5>
        <p>{description}</p>
      </Col>
      {!isDesktop && (
        <Col md={12}>
          <CarouselComponent
            noArrow
            className="carousel-facilities-map"
            classNameImg="img-map-section"
            data={slides}
          />
        </Col>
      )}
    </Row>
  );
};

export default SlidesAndText;
