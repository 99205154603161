import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import smileIcon from '../../../assets/images/Smile.png';
import boatIcon from '../../../assets/images/Boat.png';
import luggageIcon from '../../../assets/images/Luggage.png';

const CardSection = ({
  cargoAvailabilityRedirect,
  cargoItineraryRedirect,
  cargoServicesRedirect
}) => {
  const { settings } = useSelector(state => state.utils);

  return (
    <Container className="cargo-card-section-container">
      <Row>
        <Col className="text-center p-4 m-4">
          <img src={smileIcon} alt="Smile" style={{ height: '55px' }} />
          <Row className="mb-4">
            <Col>
              <Row className="align-items-center justify-content-center negative-margin-left-text">
                <h2>+</h2>
                <h2>{settings.landingCargoTravelledDistance}</h2>
              </Row>
              <Row className="align-items-center justify-content-center">
                <p>Millas en 2 rutas</p>
              </Row>
            </Col>
          </Row>
          <Button
            variant="primary"
            className="w-75 py-3"
            style={{ fontSize: '13px' }}
            onClick={cargoServicesRedirect}
          >
            Conoce nuestras rutas
          </Button>
        </Col>
        <Col className="text-center p-4 m-4">
          <img src={boatIcon} alt="Boat" style={{ height: '55px' }} />
          <Row className="mb-4">
            <Col>
              <Row className="align-items-center justify-content-center negative-margin-left-text">
                <h2>+</h2>
                <h2>{settings.landingCargoAvailableCapacity}</h2>
              </Row>
              <Row className="align-items-center justify-content-center">
                <p>de Capacidad en 3 barcos</p>
              </Row>
            </Col>
          </Row>
          <Button
            variant="primary"
            className="w-75 py-3"
            style={{ fontSize: '13px' }}
            onClick={cargoAvailabilityRedirect}
          >
            Ver disponibilidad
          </Button>
        </Col>
        <Col className="text-center p-4 m-4">
          <img src={luggageIcon} alt="Luggage" style={{ height: '55px' }} />
          <Row className="mb-4">
            <Col>
              <Row className="align-items-center justify-content-center negative-margin-left-text">
                <h2>+</h2>
                <h2>{settings.landingCargoTravelFrecuency}</h2>
              </Row>
              <Row className="align-items-center justify-content-center">
                <p>Viajes al año, 2 frecuencias por semana</p>
              </Row>
            </Col>
          </Row>
          <Button
            variant="primary"
            className="w-75 py-3"
            style={{ fontSize: '13px' }}
            onClick={cargoItineraryRedirect}
          >
            Ver Itinerario
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CardSection;
