import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import GuideForm from './GuideForm';
import { createAdminGuideRequest } from '../../../requests/admin/adminGuides';
import { sendAlert } from '../../../actions/utils';

const GuideNew = props => {
  const { history } = props;
  const [guide] = useState({
    title: '',
    language: '',
    description: '',
    pageId: '',
    active: true,
    position: null,
    attachedPreviewImage: null,
    attachedMainImage: null,
    attachedEbook: null
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    history.push({
      pathname: '/admin/ebooks_and_videos',
      state: { type: 'Guías' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Guía creada con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _guide => {
    createAdminGuideRequest({
      dispatch,
      params: _guide,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-guides" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear Guía</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <GuideForm guide={guide} action="new" formRequest={handleCreateRequest} />
    </div>
  );
};

export default connect()(GuideNew);
