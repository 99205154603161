import React from 'react';
import { Button } from 'react-bootstrap';

const LanguageButtons = ({
  language = 'es',
  setLanguage = () => {},
  includeFrench = false
}) => {
  return (
    <div className="align-self-end main-navbar">
      <Button
        variant="dark"
        className={`btn-switch-esp ${language === 'es' ? 'btn-active' : ''}`}
        onClick={() => {
          setLanguage('es');
        }}
      >
        Español
      </Button>
      {includeFrench && (
        <Button
          variant="dark"
          className={`btn-switch-esp btn-switch-ing ${
            language === 'fr' ? 'btn-active' : ''
          }`}
          onClick={() => {
            setLanguage('fr');
          }}
        >
          Frances
        </Button>
      )}
      <Button
        variant="dark"
        className={`btn-switch-ing ${language === 'en' ? 'btn-active' : ''}`}
        onClick={() => {
          setLanguage('en');
        }}
      >
        Inglés
      </Button>
    </div>
  );
};

export default LanguageButtons;
