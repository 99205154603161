import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const indexAdminSettingRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) =>
  ApiService.request('get', '/admin/settings', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const showAdminSettingRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/settings/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const updateAdminSettingRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/settings/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexAdminSettingRequest = AwesomeDebouncePromise(
  indexAdminSettingRequest,
  300
);
