import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import {
  showSlideRequest,
  updateSlideRequest
} from '../../requests/admin/adminSlides';
import { sendAlert } from '../../actions/utils';

const slideBackUrl = {
  all: '/admin/slides',
  tourism_hero: '/admin/tourism_slides_hero',
  cargo_hero: '/admin/cargo_slides_hero',
  tourism_slide: '/admin/tourism_slides',
  our_ferries_category: '/admin/tourism_our_ferries'
};

const SlideEdit = props => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryType = urlParams.get('slideCategoryType');

  const [slide, setSlide] = useState({
    id: '',
    fileInfo: {},
    position: '',
    caption: '',
    active: true
  });
  const dispatch = useDispatch();
  const { slideType } = props;
  const handleSuccessShow = response => {
    const _slide = camelCaseRecursive(response.data);
    setSlide(_slide);
  };

  useEffect(() => {
    const { match } = props;
    const slideId = match.params.id;
    showSlideRequest(slideId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = () => {
    const { history } = props;
    history.push(slideBackUrl[categoryType]);
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Slide actualizado con éxito'
      })
    );
  };

  const handleUpdateRequest = _slide => {
    const slideId = _slide.slide.id;
    updateSlideRequest(slideId, {
      dispatch,
      params: _slide,
      formData: true,
      successCallback: response => handleSuccessUpdate(response)
    });
  };

  return (
    <div className="pt-4">
      <Row className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar Slide</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <LinkBtn variant="light" block to={slideBackUrl[categoryType]}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <SlideForm
        slide={slide}
        action="edit"
        formRequest={handleUpdateRequest}
        slideType={slideType}
      />
    </div>
  );
};

export default connect()(SlideEdit);
