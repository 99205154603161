import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import AdminSettingIndex from '../Settings/AdminSettingIndex';

const AdminSettings = props => {
  const { location } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Configuraciones admin');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Configuraciones</h4>
        </Col>
      </Row>
      <ModelFilter
        models={['Configuraciones admin', 'Todas las configuraciones']}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />

      <Row style={{ marginTop: '40px' }}>
        <Col>
          {selectedModel === 'Configuraciones admin' && (
            <AdminSettingIndex settingType="admin" />
          )}
          {selectedModel === 'Todas las configuraciones' && (
            <AdminSettingIndex settingType="all" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default AdminSettings;
