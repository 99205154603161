import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminUserRequest } from '../../../requests/admin/adminUsers';
import columns from './Columns';

const UserDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminUserRequest,
    onDeleteMessage: 'Usuario Eliminado exitosamente',
    confirmationText: '¿Estás seguro de eliminar este usuario?',
    modalTitle: 'Eliminar Usuario'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const filteredTableData = tableData.filter(item => item.active);

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase}
        data={filteredTableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
      />
      <OnDestroyModalComponent />
    </>
  );
};
export default withRouter(connect()(UserDataTable));
