import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';

import SlideIndex from '../../Slide/SlideIndex';
import AdminSettingIndex from '../Settings/AdminSettingIndex';

const AdminOurFerries = props => {
  const { location, categoryType } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Slides');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Nuestros Ferries</h4>
        </Col>
      </Row>
      <ModelFilter
        models={['Slides', 'Textos e imagenes']}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '40px' }}>
        <Col>
          {selectedModel === 'Slides' && (
            <SlideIndex categoryType={categoryType} />
          )}
          {selectedModel === 'Textos e imagenes' && (
            <AdminSettingIndex settingType="tourism_our_ferries" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default AdminOurFerries;
