import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import IncludedElementForm from './IncludedElementForm';

import {
  showAdminIncludedElementRequest,
  updateAdminIncludedElementRequest
} from '../../../requests/admin/adminIncludedElements';
import { sendAlert } from '../../../actions/utils';

const IncludedElementEdit = props => {
  const { history } = props;
  const [includedElement, setIncludedElement] = useState({
    id: 1,
    category: '',
    language: '',
    position: 1,
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _includedElement = camelCaseRecursive(response.data);
    setIncludedElement(_includedElement);
  };

  const handleGoback = () => {
    history.push({
      pathname: '/admin/routes_and_included',
      state: { type: 'Elementos Incluidos' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Elemento actualizado con éxito'
      })
    );
    handleGoback();
  };

  useEffect(() => {
    const { match } = props;
    const includedElementId = match.params.id;
    showAdminIncludedElementRequest(includedElementId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleUpdateRequest = _includedElement => {
    const includedElementId = _includedElement.included_element.id;
    updateAdminIncludedElementRequest(includedElementId, {
      dispatch,
      params: _includedElement,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="p-4">
      <Row id="edit-included-element" className="mb-4 pb-4">
        <Col>
          <h4 className="text-primary">
            Editar elemento incluido en ruta de turismo
          </h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <IncludedElementForm
        includedElement={includedElement}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(IncludedElementEdit);
