import React from 'react';
import jwt from 'jwt-decode';
import { Route, Redirect } from 'react-router-dom';
import { Sidebar } from '../../components';

const AdminLoggedLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const payload = localStorage.getItem('jwt');
        const user = payload && jwt(payload);
        if (!payload)
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );

        const { roles } = user;
        if (
          !roles.includes('admin') &&
          !roles.includes('tourist') &&
          !roles.includes('cargo') &&
          !roles.includes('tourism_admin') &&
          !roles.includes('cargo_admin')
        )
          return (
            <div className="w-50 mx-auto text-center">
              Need to be an admin to access this route
            </div>
          );
        return (
          <>
            <Sidebar />
            <div className="content-body">
              <Component {...props} />
            </div>
          </>
        );
      }}
    />
  );
};

export default AdminLoggedLayout;
