import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import VideoForm from './VideoForm';

import {
  showAdminVideoRequest,
  updateAdminVideoRequest
} from '../../../requests/admin/adminVideos';
import { sendAlert } from '../../../actions/utils';

const VideoEdit = props => {
  const { history } = props;
  const [video, setVideo] = useState({
    title: '',
    description: '',
    link: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _video = camelCaseRecursive(response.data);
    setVideo(_video);
  };

  useEffect(() => {
    const { match } = props;
    const videoId = match.params.id;

    showAdminVideoRequest(videoId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: '/admin/ebooks_and_videos',
      state: { type: 'Videos' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Video actualizado con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _video => {
    const videoId = _video.video.id;
    updateAdminVideoRequest(videoId, {
      dispatch,
      params: _video,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-videos" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar Video</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <VideoForm
        video={video}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(VideoEdit);
