import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { LinkBtn } from '../../components';
import SlideForm from './SlideForm';
import { createSlideRequest } from '../../requests/admin/adminSlides';
import { sendAlert } from '../../actions/utils';

const slideBackUrl = {
  all: '/admin/slides',
  tourism_hero: '/admin/tourism_slides_hero',
  cargo_hero: '/admin/cargo_slides_hero',
  tourism_slide: '/admin/tourism_slides',
  our_ferries_category: '/admin/tourism_our_ferries'
};

const SlideNew = props => {
  const urlParams = new URLSearchParams(window.location.search);
  const categoryType = urlParams.get('slideCategoryType');

  // eslint-disable-next-line no-unused-vars
  const [slide, setSlide] = useState({
    fileInfo: {},
    position: '',
    caption: '',
    active: true
  });
  const dispatch = useDispatch();
  const { slideCategoryType } = props;

  const handleSuccessCreate = () => {
    const { history } = props;
    history.push(slideBackUrl[categoryType]);
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Slide creado con éxito'
      })
    );
  };

  const handleCreateRequest = _slide => {
    createSlideRequest({
      dispatch,
      params: _slide,
      formData: true,
      successCallback: response => handleSuccessCreate(response)
    });
  };

  return (
    <div className="pt-4">
      <Row className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear Slide</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <LinkBtn variant="light" block to={slideBackUrl[categoryType]}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <SlideForm
        slide={slide}
        slideCategoryType={slideCategoryType}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default connect()(SlideNew);
