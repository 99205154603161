/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { debounceIndexAdminPagesRequest } from '../../../requests/admin/adminPages';
import {
  FormikInput,
  FormikCheckBox,
  UploadImage,
  UploadFile,
  IcoMoon,
  FormikSelect
} from '../../../components';
import useFetchData from '../../../hooks/useFetchData';
import { languages } from '../../../services/utils';

const GuideForm = props => {
  const { onHide, submitVariant, errors, touched, action, guide } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultPage, setDefaultPage] = useState(undefined);
  const [defaultGuide, setDefaultGuide] = useState(undefined);
  const { data: pages } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPagesRequest,
    customParams: {
      sort_column: 'updated_at',
      sort_direction: 'desc',
      category: 'web',
      serializer_selector: 'PageSelectorSerializer'
    }
  });

  const findDefaultPage = () => {
    setDefaultPage(pages.find(page => page.id === guide.pageId));
  };
  useEffect(findDefaultPage, [pages]);

  useEffect(() => {
    setDefaultGuide(guide);
  }, [guide, props]);

  function getPdfUrl(pickedPdf) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => {
        reject(error);
      };
      reader.readAsDataURL(pickedPdf);
    });
  }

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <Row>
            <Col>
              <Field name="guide[guideMainImage]">
                {({ field }) => {
                  return (
                    <UploadImage
                      {...field}
                      abbr
                      modelType="guide"
                      numberId="02"
                      label="Imagen Guia"
                      iconComponent={
                        <div className="circle-image">
                          <IcoMoon icon="image1" size={20} />
                        </div>
                      }
                      accept="image/jpg, image/png, image/jpeg"
                      setFieldValue={setFieldValue}
                      imageUrl={getIn(
                        defaultGuide,
                        'attachedMainImage.fileUrl'
                      )}
                      fileName={getIn(
                        defaultGuide,
                        'attachedMainImage.filename'
                      )}
                      onChange={images =>
                        setFieldValue('guide[guideMainImage]', images)
                      }
                      error={getIn(errors, 'guide[guideMainImage]')}
                      touched={getIn(touched, 'guide[guideMainImage]')}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col>
              <Field name="guide[guidePreviewImage]">
                {({ field }) => {
                  return (
                    <UploadImage
                      {...field}
                      abbr
                      modelType="guide"
                      numberId="02"
                      label="Imagen Preview"
                      iconComponent={
                        <div className="circle-image">
                          <IcoMoon icon="image1" size={20} />
                        </div>
                      }
                      accept="image/jpg, image/png, image/jpeg"
                      setFieldValue={setFieldValue}
                      imageUrl={getIn(
                        defaultGuide,
                        'attachedPreviewImage.fileUrl'
                      )}
                      fileName={getIn(
                        defaultGuide,
                        'attachedPreviewImage.filename'
                      )}
                      onChange={images =>
                        setFieldValue('guide[guidePreviewImage]', images)
                      }
                      error={getIn(errors, 'guide[guidePreviewImage]')}
                      touched={getIn(touched, 'guide[guidePreviewImage]')}
                    />
                  );
                }}
              </Field>
            </Col>
          </Row>
        </Col>
        <Col>
          <Field name="guide[title]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="guide[guideShowUrl]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Url de la guía"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Row className="d-flex align-items-end">
            <Col>
              <Field name="guide[position]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Posición"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col md={2} className="mb-3">
              <Field name="guide[active]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="Activo"
                    custom
                  />
                )}
              </Field>
            </Col>
          </Row>
          <Field name="guide[language]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  label="Idioma"
                  placeholder="Seleccionar Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={Object.entries(languages).map(([value, label]) => ({
                    value,
                    label
                  }))}
                  onChange={selectedOption => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : '';
                    setFieldValue(name, selectedValue);
                  }}
                  defaultValue={field.value}
                />
              );
            }}
          </Field>
          <Field name="guide[description]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Descripción"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="guide[videoMovie]">
            {({ field }) => {
              return (
                <UploadFile
                  {...field}
                  abbr
                  label="Archivo Pdf"
                  iconComponent={
                    <div className="circle-image">
                      <IcoMoon icon="files" size={20} />
                    </div>
                  }
                  accept="application/pdf"
                  setFieldValue={setFieldValue}
                  fileName={getIn(defaultGuide, 'attachedEbook.filename')}
                  onChange={async pickedPdf => {
                    setFieldValue('guide[guideEbook]', pickedPdf);
                  }}
                  error={getIn(errors, 'guide[guideEbook]')}
                  touched={getIn(touched, 'guide[guideEbook]')}
                  uploadFileId="guideEbook"
                />
              );
            }}
          </Field>

          <Field name="guide[pageId]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  inputType="text"
                  label="Página de la guía"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={pages}
                  defaultValue={field.value}
                  onChange={page => {
                    setFieldValue('guide', {
                      ...values.guide,
                      pageId: page.value
                    });
                  }}
                />
              );
            }}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    title,
    language,
    description,
    active,
    pageId,
    position,
    attachedPreviewImage,
    attachedMainImage,
    attachedEbook,
    guideShowUrl
  } = props.guide;
  return {
    guide: {
      id,
      title,
      language,
      description,
      active,
      pageId,
      position,
      guidePreviewImage: attachedPreviewImage,
      guideMainImage: attachedMainImage,
      guideEbook: attachedEbook,
      guideShowUrl
    }
  };
};

const validationSchema = Yup.object().shape({
  guide: Yup.object().shape({
    title: Yup.string().required('El título es requerido'),
    language: Yup.string().required('El idioma es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    active: Yup.boolean(),
    position: Yup.number()
      .integer()
      .required('La posición es requerida'),
    guidePreviewImage: Yup.mixed().required(
      'Debes adjuntar una imagen preview'
    ),
    guideMainImage: Yup.mixed().required('Debes adjuntar una imagen principal'),
    guideEbook: Yup.mixed().required('Debes adjuntar un Pdf')
  })
});

const handleSubmit = (values, { props }) => {
  const { guidePreviewImage, guideMainImage, guideEbook } = values.guide;
  const paramsToSend = {
    guide: snakecaseKeys({ ...values.guide })
  };
  if (guidePreviewImage) paramsToSend.guide.preview_image = guidePreviewImage;
  if (guideMainImage) paramsToSend.guide.main_image = guideMainImage;
  if (guideEbook) paramsToSend.guide.ebook = guideEbook;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(GuideForm);
