import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { indexSlidesRequest } from '../../../requests/slides';
import '../../../assets/stylesheets/components/cargo/CargoBanner.scss';
import { IcoMoon } from '../../../components';

const CargoBannerSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = localStorage.getItem('language') || 'es';
  const { settings } = useSelector(state => state.utils);

  const [slide, setSlide] = useState([]);
  const [titleText, setTitleText] = useState(
    t('tourism.landing.cargoBanner.title')
  );
  const [descriptionText, setDescriptionText] = useState(
    t('tourism.landing.cargoBanner.description')
  );
  const [buttonText, setButtonText] = useState(
    t('tourism.landing.cargoBanner.buttonText')
  );
  const [urlPath, setUrlPath] = useState('https://www.navimag.com/cargo');

  const setSectionData = () => {
    const title =
      language === 'en'
        ? 'landingCargoSectionTitleEn'
        : 'landingCargoSectionTitleEs';
    const titleValue =
      settings[title] || t('tourism.landing.cargoBanner.title');
    setTitleText(titleValue);

    const description =
      language === 'en'
        ? 'landingCargoSectionDescriptionEn'
        : 'landingCargoSectionDescriptionEs';
    const descriptionValue =
      settings[description] || t('tourism.landing.cargoBanner.description');
    setDescriptionText(descriptionValue);

    const button =
      language === 'en'
        ? 'landingCargoSectionButtonEn'
        : 'landingCargoSectionButtonEs';
    const buttonValue =
      settings[button] || t('tourism.landing.cargoBanner.buttonText');
    setButtonText(buttonValue);

    const urlValue =
      settings.landingCargoSectionUrl || 'https://www.navimag.com/cargo';
    setUrlPath(urlValue);
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 1,
        slide_type: 'cargo_banner_img'
      },
      successCallback: result => {
        setSlide(camelCaseRecursive(result.data.data[0]));
      }
    });
  };

  useEffect(slidesRequest, []);
  useEffect(setSectionData, [settings, language]);

  return (
    <Row className="d-flex cargo-banner">
      <div className="cargo-banner-image">
        <img src={slide?.fileInfo?.fileUrl} alt="cargo-img" />
      </div>
      <div className="cargo-banner-content">
        <h4 className="text-white">{titleText}</h4>
        <p className="text-white my-3">{descriptionText}</p>

        <Button
          variant="light"
          href={urlPath}
          className="d-flex justify-content-center align-items-center cargo-banner-btn"
        >
          {buttonText}
          <div className="box-icon-truck">
            <IcoMoon icon="truck" size={14} />
          </div>
        </Button>
      </div>
    </Row>
  );
};

export default CargoBannerSection;
