import React, { useState } from 'react';
import { Row, Button, Col, Container } from 'react-bootstrap';
import PuertoMonttImage from '../../../assets/images/Puerto-Montt.png';
import PuertoNatalesImage from '../../../assets/images/Puerto-Natales.png';
import PuertoChacabucoImage from '../../../assets/images/Puerto-Chacabuco.png';
import { DefaultModal } from '../../../components';

const OurTerminalsSection = () => {
  const [showMapModal, setShowMapModal] = useState(false);
  const [frameUrl, setFrameUrl] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const terminals = [
    {
      title: 'Puerto Montt',
      description:
        'Con su mezcla de cultura y patrimonio natural, Puerto Montt es una de las áreas más hermosas del sur de Chile.',
      image: PuertoMonttImage,
      iframeUrl:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2987.274679913608!2d-73.0433756!3d-41.5199904!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96183e7fa3d039ab%3A0x278559b2182a2039!2sNavimag%20Ferries!5e0!3m2!1ses!2scl!4v1657032806226!5m2!1ses!2scl'
    },
    {
      title: 'Puerto Natales',
      description:
        'Conocida como la puerta de entrada para las Torres del Paine; un lugar de paso para conocer atractivos gastronómicos, hoteleros y culturales.',
      image: PuertoNatalesImage,
      iframeUrl:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1235.568369469586!2d-72.51446326604916!3d-51.73053428149942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdafe9569858ff9f%3A0x400adfab3de6313e!2sNavimag%20Ferries%20Puerto%20Natales!5e0!3m2!1ses!2scl!4v1657033319824!5m2!1ses!2scl'
    },
    {
      title: 'Puerto Chacabuco',
      description: 'El puerto principal de la Región de Aysén.',
      image: PuertoChacabucoImage,
      iframeUrl:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5596.4162926497165!2d-72.8203849!3d-45.4656108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbd8cdf42dc44ef8b%3A0xc209b23d18b3915d!2sEmpresa%20Portuaria%20Puerto%20Chacabuco!5e0!3m2!1ses-419!2scl!4v1657033401909!5m2!1ses-419!2scl'
    }
  ];

  const handleOpneModal = (url, title) => {
    setFrameUrl(url);
    setModalTitle(title);
    setShowMapModal(true);
  };

  return (
    <div className="our-terminals">
      <h4 className="our-terminals-title mb-3">Nuestros Terminales</h4>
      <p className="our-terminals-description mb-4 p-4 text-center">
        Conoce las terminales donde trabajamos día a día para asegurar el
        transporte por la zona austral de Chile.
      </p>
      <Container>
        <Row className="our-terminals-cards-container">
          {terminals.map(terminal => (
            <Col sm={12} md={12} lg={4}>
              <div
                className="our-terminals-card"
                style={{ backgroundImage: `url(${terminal.image})` }}
              >
                <div className="our-terminals-info">
                  <p className="our-terminals-title">{terminal.title}</p>
                  <p className="our-terminals-description">
                    {terminal.description}
                  </p>
                  <Button
                    onClick={() =>
                      handleOpneModal(terminal.iframeUrl, terminal.title)
                    }
                    target="_blank"
                    type="submit"
                    variant="primary"
                    className="w-100 our-terminals-button"
                  >
                    Ver mapa
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <DefaultModal
        title={modalTitle}
        size="lg"
        body={
          <iframe
            title="Mapa de Nuestros Terminales"
            src={frameUrl}
            width="100%"
            height="450"
          />
        }
        handleClose={() => setShowMapModal(false)}
        show={showMapModal}
      />
    </div>
  );
};

export default OurTerminalsSection;
