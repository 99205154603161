import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Link } from 'react-router-dom';
import { indexAdminLeadsRequest } from '../../../requests/admin/adminLeads';
import googleAnalytics from '../../../assets/images/google_analytics.svg';
import '../../../assets/stylesheets/screens/dashborad/dashboard.scss';
import DashboardLeadsDataTable from './DashboardLeadsDataTable';
import { indexAdminCargoLeadsRequest } from '../../../requests/admin/adminCargoLeads';
import CargoLeadTable from '../Cargo/Leads/CargoLeadTable';

const Dashboard = props => {
  const dispatch = useDispatch();
  const { settings } = props;
  const [tourismLeads, setTourismLeads] = useState([]);
  const [cargoLeads, setCargoLeads] = useState([]);
  const [pipedriveLink, setPipedriveLink] = useState([]);
  const [googleAnalyticsLink, setGoogleAnalyticsLink] = useState([]);

  const tourismLeadsRequest = () => {
    indexAdminLeadsRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'created_at',
        sort_direction: 'desc',
        display_length: 5
      },
      successCallback: result => {
        setTourismLeads(camelCaseRecursive(result.data.data));
      }
    });
  };

  const cargoLeadsRequest = () => {
    indexAdminCargoLeadsRequest({
      dispatch,
      params: {
        active: true,
        sort_column: 'created_at',
        sort_direction: 'desc',
        display_length: 5
      },
      successCallback: result => {
        setCargoLeads(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(tourismLeadsRequest, []);
  useEffect(cargoLeadsRequest, []);

  useEffect(() => {
    setPipedriveLink(settings.pipedriveLink || '/');
    setGoogleAnalyticsLink(settings.googleAnalyticsLink || '/');
  }, [settings]);

  const handleGoogleAnalyticsClick = () => {
    window.open(`//${googleAnalyticsLink}`, '_blank');
  };
  const handlePipedriveClick = () => {
    window.open(`${pipedriveLink}`, '_blank');
  };

  return (
    <div className="dashboard">
      <h4 className="text-primary mb-2">Dashboard</h4>
      <Row>
        <Col sm={12} md={6} className="mb-4">
          <div
            className="dashboard-piepedrive-card"
            onClick={handlePipedriveClick}
            aria-hidden="true"
          >
            <p className="text-primary mb-4">pipedrive</p>
          </div>
        </Col>
        <Col sm={12} md={6} className="mb-4">
          <div
            className="dashboard-googleAnalytics-card"
            onClick={handleGoogleAnalyticsClick}
            aria-hidden="true"
          >
            <img src={googleAnalytics} alt="doorOpenSolid" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="card p-3">
            <p
              className="text-primary font-weight-bold mx-3"
              style={{ marginBottom: '-20px' }}
            >
              Últimos 5 Leads Turismo
            </p>
            <DashboardLeadsDataTable tableData={tourismLeads} />
            <Button
              as={Link}
              to="/admin/leads"
              variant="primary"
              block
              className="p-2 w-25"
              style={{ fontSize: '12px', marginLeft: 'auto' }}
            >
              Ver todas
            </Button>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="card p-3">
            <p
              className="text-primary font-weight-bold mx-3"
              style={{ marginBottom: '-20px' }}
            >
              Últimos 5 Leads Carga
            </p>
            <CargoLeadTable tableData={cargoLeads} />
            <Button
              as={Link}
              to="/admin/cargo_leads"
              variant="primary"
              block
              className="p-2 w-25"
              style={{ fontSize: '12px', marginLeft: 'auto' }}
            >
              Ver todas
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { settings } = state.utils;
  return {
    settings
  };
};

export default connect(mapStateToProps)(Dashboard);
