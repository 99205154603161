import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';

import {
  FormikInput,
  FormikInputRadio,
  FormikCheckBox
} from '../../../components';
import { nationalityOptions } from '../../../services/utils';

const SecondPageCargoLeadsForm = ({ modelName }) => {
  const { errors, touched } = useFormikContext();

  return (
    <>
      <section className="form-section mb-5 w-100">
        <Row className="justify-content-md-center mb-2">
          <Col>
            <Field name={`${modelName}[name]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  placeholder="Escribe tu nombre"
                  label="Nombre"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col>
            <Field name={`${modelName}[email]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Correo"
                  placeholder="correo@tuempresa.com"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Field name={`${modelName}[rut]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="RUT / Pasaporte"
                  placeholder="Escribe tu RUT o pasaporte"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col>
            <Field name={`${modelName}[nationality]`}>
              {({ field }) => (
                <FormikInputRadio
                  {...field}
                  field={field}
                  abbr
                  label="Nacionalidad"
                  options={nationalityOptions}
                  fieldName={`${modelName}[nationality]`}
                  error={getIn(errors, `${modelName}[nationality]`)}
                  touched={getIn(touched, `${modelName}[nationality]`)}
                  customFormLabel="cargo-lead-input-radio-label"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="form-italic">
              El precio estimado por esta herramienta de cotización se basa en
              las tarifas de Navimag actualmente vigentes y en la información
              entregada por el usuario. Por lo tanto, el precio estimado debe
              ser validado por el área comercial de Navimag, verificando que la
              información sobre las características del vehículo cotizado sea
              fidedigna. Para lo anterior, un ejecutivo se contactará en 72
              horas hábiles con usted para validar y confirmar la cotización.
              Navimag a su vez se compromete a proteger su privacidad, y solo
              usará su información personal para administrar su cuenta y
              proporcionar contenidos que pueda ser de su interés. No
              compartiremos sus datos con ningún tercero.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name={`${modelName}[acceptFutureCommunications]`}>
              {({ field }) => (
                <FormikCheckBox
                  {...field}
                  field={field}
                  label="Acepto recibir comunicaciones de Navimag"
                  custom
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  margin="cargo-leads-checkbox"
                />
              )}
            </Field>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default SecondPageCargoLeadsForm;
