import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import ReviewForm from './ReviewForm';
import { createAdminReviewRequest } from '../../../requests/admin/adminReviews';
import { sendAlert } from '../../../actions/utils';

const ReviewNew = props => {
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const review = {
    fileInfo: {},
    name: '',
    lastName: '',
    title: '',
    language: '',
    comment: '',
    review: 5,
    reviewLink: '',
    active: true
  };
  const dispatch = useDispatch();

  const handleSuccessCreate = response => {
    const _review = response.data;
    history.push(`/admin/reviews/${_review.id}`);
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Review creada con éxito'
      })
    );
  };

  const handleCreateRequest = _review => {
    createAdminReviewRequest({
      dispatch,
      params: _review,
      formData: true,
      successCallback: response => handleSuccessCreate(response)
    });
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/landing', state: { type: 'Reviews' } });
  };

  return (
    <div className="p-4">
      <Row id="create-reviews" className="mb-4">
        <Col>
          <h4 className="text-primary">Crear Review</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <ReviewForm
        review={review}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default connect()(ReviewNew);
