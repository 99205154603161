import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import AdminNavbarForm from './AdminNavbarForm';
import { createAdminNavbarRequest } from '../../../requests/admin/navbar';

const AdminNavbarNew = props => {
  const { isRoot, closeModal, updateData, barType, language } = props;
  // eslint-disable-next-line no-unused-vars
  const [navbar, setNavbar] = useState({
    id: '',
    category: '',
    language: '',
    link: '',
    parentId: isRoot ? null : 1,
    position: 9999,
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessCreate = () => {
    updateData();
    closeModal();
  };

  const handleCreateRequest = _navbar => {
    createAdminNavbarRequest({
      dispatch,
      params: _navbar,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <AdminNavbarForm
      navbar={navbar}
      action="new"
      formRequest={handleCreateRequest}
      isRoot={isRoot}
      barType={barType}
      language={language}
    />
  );
};

export default connect()(AdminNavbarNew);
