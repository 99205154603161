/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { FormikInput, FormikCheckBox } from '../../../components';
import { UploadMultipleImages } from '../../../components/Utils/Upload';

const GalleryForm = props => {
  const { onHide, submitVariant, errors, touched, action, gallery } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (gallery) {
      setFieldValue('gallery[images]', gallery.attachedImagesUrls || []);
    }
  }, [gallery, setFieldValue]);

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <Field name="gallery[nameEsp]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="text"
                label="Nombre español"
                error={getIn(errors, 'gallery[nameEsp]')}
                touched={getIn(touched, 'gallery[nameEsp]')}
              />
            )}
          </Field>
          <Field name="gallery[nameEng]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="text"
                label="Nombre inglés"
                error={getIn(errors, 'gallery[nameEng]')}
                touched={getIn(touched, 'gallery[nameEng]')}
              />
            )}
          </Field>
          <Field name="gallery[position]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="number"
                label="Posición"
                error={getIn(errors, 'gallery[position]')}
                touched={getIn(touched, 'gallery[position]')}
              />
            )}
          </Field>
          <Field name="gallery[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
        <Col>
          <UploadMultipleImages
            name="gallery[images]"
            label="Selecciona tus imagenes"
            abbr
          />
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { nameEsp, nameEng, active, position = '', attachedImagesUrls } =
    props.gallery || {};
  const images = attachedImagesUrls
    ? attachedImagesUrls.map(url => ({ preview: url }))
    : [];
  return {
    gallery: {
      nameEsp,
      nameEng,
      active,
      position: position != null ? position : '',
      images,
      deletedImages: []
    }
  };
};

const validationSchema = Yup.object().shape({
  gallery: Yup.object().shape({
    nameEsp: Yup.string().required('El campo es requerido'),
    nameEng: Yup.string().required('El campo es requerido'),
    active: Yup.boolean(),
    position: Yup.number()
      .typeError('La posición debe ser un número')
      .integer('La posición debe ser un número entero')
      .required('La posición es requerida'),
    images: Yup.array()
      .of(Yup.mixed())
      .required('Debes adjuntar al menos una imagen')
  })
});

const handleSubmit = (values, { props }) => {
  const { images } = values.gallery;
  const imageFiles = images.map(img => img.file);
  const paramsToSend = {
    gallery: snakecaseKeys({ ...values.gallery })
  };
  if (imageFiles.length > 0) {
    paramsToSend.gallery.images = imageFiles;
  }
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(GalleryForm);
