import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

const CargoItineraryCard = ({
  size,
  colorText,
  colorCard,
  colorBorder,
  date,
  month,
  hour,
  port,
  code,
  onClick,
  selectedClassName
}) => {
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className={`cargo-itinerary-card  text-center ${selectedClassName}`}
      style={{ width: size, cursor: 'pointer' }}
      border={colorBorder}
      onClick={onClick}
    >
      <Card.Body className="cargo-itinerary-card-body">
        <Row className="align-items-center">
          <Col>
            <Card.Title className="cargo-itinerary-date-text mb-0">
              {date}
            </Card.Title>
            <Card.Subtitle className="cargo-itinerary-month-text mb-1">
              {month} - {hour}
            </Card.Subtitle>
            <Card.Subtitle className="cargo-itinerary-port-text m-0">
              {port}
            </Card.Subtitle>
            <Card.Subtitle className="cargo-itinerary-port-text m-0">
              {code}
            </Card.Subtitle>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CargoItineraryCard.propTypes = {
  size: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string,
  date: PropTypes.string,
  month: PropTypes.string,
  hour: PropTypes.string,
  port: PropTypes.string,
  code: PropTypes.string
};

CargoItineraryCard.defaultProps = {
  size: '100%',
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null,
  date: '',
  month: '',
  hour: '',
  port: '',
  code: ''
};

export default CargoItineraryCard;
