import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import RouteIndex from '../Routes/RouteIndex';
import IncludedElementIndex from '../IncludedElements/IncludedElementIndex';

const RouteAndIncludedElements = props => {
  const { location, routeType } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Rutas & tarifas');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Rutas & tarifas</h4>
        </Col>
      </Row>
      {routeType !== 'cargo' && (
        <ModelFilter
          models={['Rutas & tarifas', 'Elementos Incluidos']}
          setSelectedModel={setSelectedModel}
          selectedModel={selectedModel}
        />
      )}
      <Row style={{ marginTop: routeType === 'cargo' ? '' : '80px' }}>
        <Col>
          {selectedModel === 'Rutas & tarifas' && (
            <RouteIndex routeType={routeType} />
          )}
          {selectedModel === 'Elementos Incluidos' && <IncludedElementIndex />}
        </Col>
      </Row>
    </>
  );
};

export default RouteAndIncludedElements;
