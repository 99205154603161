import React from 'react';
import { Row, CardColumns, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CargoDeckCard from '../../../components/Utils/Cards/CargoDeckCard ';
import IcoMoon from '../../../components/Utils/Icon/IcoMoon';

const ThirdStepCargoAvailabilityForm = ({ availableDecks }) => {
  return (
    <section className="form-section mb-5 w-100">
      <Row className="justify-content-md-center">
        <CardColumns className="card-cargo-body-container mb-3">
          {availableDecks.map(cargoDecks => {
            return (
              <CargoDeckCard
                deck={cargoDecks.cubierta}
                totalCapacity={cargoDecks.mliCapacidad}
                occupiedCapacity={cargoDecks.mliOcupado}
              />
            );
          })}
        </CardColumns>
        <Button
          as={Link}
          to="/cargo/contact"
          type="submit"
          variant="primary"
          className="btn-card-section"
        >
          Contactar asesor
          <IcoMoon className="icon-btn--icon" icon="person" />
        </Button>
      </Row>
    </section>
  );
};

export default ThirdStepCargoAvailabilityForm;
