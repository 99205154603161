import React from 'react';

const PublicError = () => {
  return (
    <div className="error-page">
      <div>
        <h1>429</h1>
        <h2>Espere unos minutos</h2>
        <p>
          Se ha superado el límite de intentos para iniciar sesión en esta
          cuenta
        </p>
      </div>
    </div>
  );
};

export default PublicError;
