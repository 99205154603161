/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-useless-path-segments
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import snakecaseKeys from 'snakecase-keys';
import { Row, Col, Button, Spinner } from 'react-bootstrap';
import { FormikInput } from '../Utils/Input';
import { FormikSelect } from '../Utils/Select';

const LeadForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    lead,
    contact = false,
    isLoading
  } = props;
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);
  return (
    <Form>
      <Row>
        <Col sm={12} md={6}>
          <Field name="lead[name]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.name')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
        </Col>
        <Col sm={12} md={6}>
          <Field name="lead[email]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.email')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field name="lead[clientType]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.clientType')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  isSearchable={false}
                  placeholder={t('tourism.landing.leadForm.placeHolder')}
                  options={[
                    {
                      label: t('tourism.landing.leadForm.tourist'),
                      value: 'tourist'
                    },
                    {
                      label: t('tourism.landing.leadForm.cargoCarrier'),
                      value: 'cargo'
                    },
                    {
                      label: t('tourism.landing.leadForm.agency'),
                      value: 'other'
                    }
                  ]}
                  onChange={clientType => {
                    setFieldValue('lead[clientType]', clientType.value);
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col sm={12} md={6}>
          <Field name="lead[destinyType]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.destinyType')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  isSearchable={false}
                  placeholder={t('tourism.landing.leadForm.placeHolder')}
                  options={[
                    { label: 'Carretera Austral', value: 'Carretera Austral' },
                    {
                      label: 'Torres del Paine',
                      value: 'Torres del Paine'
                    },
                    {
                      label: t('tourism.landing.leadForm.unknown'),
                      value: 'No lo sé aún'
                    }
                  ]}
                  onChange={destinyType => {
                    setFieldValue('lead[destinyType]', destinyType.value);
                  }}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <Field name="lead[travelTime]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.travelTime')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  isSearchable={false}
                  placeholder={t('tourism.landing.leadForm.placeHolder')}
                  options={[
                    {
                      label: t('tourism.landing.leadForm.season2023-2024'),
                      value: 'Temporada 2023 - 2024'
                    },
                    {
                      label: t('tourism.landing.leadForm.season2024-2025'),
                      value: 'Temporada 2024 - 2025'
                    },
                    {
                      label: t('tourism.landing.leadForm.season2025-2026'),
                      value: 'Temporada 2025 - 2026'
                    },
                    {
                      label: t('tourism.landing.leadForm.seasonUndefined'),
                      value: 'Quizás después de estas temporadas'
                    }
                  ]}
                  onChange={travelTime => {
                    setFieldValue('lead[travelTime]', travelTime.value);
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col sm={12} md={6}>
          <Field name="lead[travelerType]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label={t('tourism.landing.leadForm.travelerType')}
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  isSearchable={false}
                  placeholder={t('tourism.landing.leadForm.placeHolder')}
                  options={[
                    {
                      label: t('tourism.landing.leadForm.adventureSeeker'),
                      value: 'Buscador de aventuras'
                    },
                    {
                      label: t('tourism.landing.leadForm.coupleTravel'),
                      value: 'Viaje en pareja'
                    },
                    {
                      label: t('tourism.landing.leadForm.familyTravel'),
                      value: 'Viaje Familiar'
                    },
                    {
                      label: t('tourism.landing.leadForm.soloTravel'),
                      value: 'Viajero Solitario'
                    }
                  ]}
                  onChange={travelerType => {
                    setFieldValue('lead[travelerType]', travelerType.value);
                  }}
                />
              );
            }}
          </Field>
        </Col>
      </Row>
      {contact && (
        <Row>
          <Col sm={12} md={6}>
            <Field name="lead[phone]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label={t('tourism.landing.leadForm.phone')}
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col sm={12} md={6}>
            <Field name="lead[message]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="textarea"
                    isTextArea
                    label={t('tourism.landing.leadForm.message')}
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    rows={4}
                    style={{ resize: 'vertical' }}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
      )}
      <Row>
        <Col className="col-12 col-lg-6" />
        <Col className="col-12 col-lg-6">
          {!contact && (
            <Button
              type="submit"
              onClick={onHide}
              className="btn btn-primary w-100 p-2 mt-4 mb-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                t('tourism.landing.leadForm.submit')
              )}
            </Button>
          )}
          {contact && (
            <Button
              type="submit"
              onClick={onHide}
              className="btn btn-primary w-100 p-2 mt-4 mb-2"
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                t('tourism.landing.leadForm.submitContact')
              )}
            </Button>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    name,
    email,
    clientType,
    destinyType,
    travelTime,
    travelerType,
    phone,
    message
  } = props.lead;
  return {
    lead: {
      id,
      name,
      email,
      clientType,
      destinyType,
      travelTime,
      travelerType,
      phone,
      message
    }
  };
};

const validationSchema = Yup.object().shape({
  lead: Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    email: Yup.string()
      .required('El email es requerido')
      .email('Debe ser un email válido'),
    clientType: Yup.string().required('El tipo de cliente es requerido'),
    destinyType: Yup.string().required('El tipo de destino es requerido'),
    travelTime: Yup.string().required('La epoca de viaje es requerida'),
    travelerType: Yup.string().required('El tipo de viajero es requerido'),
    phone: Yup.string().required('El teléfono es requerido'),
    message: Yup.string().required('El mensaje es requerido')
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const paramsToSend = {
    lead: snakecaseKeys({ ...values.lead })
  };
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit
})(LeadForm);
