import React from 'react';
import { Row, Col } from 'react-bootstrap';

import SlideIndex from '../../Slide/SlideIndex';

const SlidesHero = props => {
  const { categoryType } = props;

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Slides Hero</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <SlideIndex slideTitle="Slides Hero" categoryType={categoryType} />
        </Col>
      </Row>
    </>
  );
};

export default SlidesHero;
