const basicPage = {
  id: undefined,
  active: true,
  title: undefined,
  url: undefined,
  body: undefined,
  bodyJson: undefined,
  highlighted: false,
  description: undefined,
  publishedDate: undefined,
  author: undefined,
  category: undefined,
  language: undefined,
  referencePageId: undefined,
  referencePageUrl: undefined
};
export default basicPage;
