import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useLocation } from 'react-router-dom';
import BlogForm from './BlogForm';
import {
  createAdminPageRequest,
  showAdminPageRequest
} from '../../../requests/admin/adminPages';
import basicBlog from './basicBlog';
import { sendAlert } from '../../../actions/utils';

const BlogNew = props => {
  const { history } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [blog, setBlog] = useState(basicBlog);
  const [cloneId, setCloneId] = useState(null);
  const [isBlogLoaded, setIsBlogLoaded] = useState(false);

  const handleSuccessShow = response => {
    const _blog = camelCaseRecursive(response.data);
    const { body, bodyJson } = _blog;
    setBlog({ ...blog, body, bodyJson });
    setIsBlogLoaded(true);
  };

  const handleGoback = () => {
    history.push({
      pathname: '/admin/pages_and_blogs',
      state: { type: 'Blogs' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Blog creado con éxito'
      })
    );
    handleGoback();
  };

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.errors?.url[0] || 'Error al realizar la solicitud';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleCreateRequest = _blog => {
    createAdminPageRequest({
      dispatch,
      params: _blog,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  const handleFailureShowRequest = error => {
    const errorMessage =
      error.response?.data?.message || 'Error al realizar la solicitud';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
    setIsBlogLoaded(true);
  };

  const getBlog = () => {
    showAdminPageRequest(cloneId, {
      dispatch,
      successCallback: handleSuccessShow,
      failureCallback: handleFailureShowRequest
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const cloneIdParam = searchParams.get('cloneId');
    const parsedCloneId = cloneIdParam ? parseInt(cloneIdParam, 10) : null;
    if (parsedCloneId === null) {
      setIsBlogLoaded(true);
    }
    setCloneId(parsedCloneId);
  }, [location.search]);

  useEffect(() => {
    if (cloneId !== null) {
      getBlog();
    }
  }, [cloneId]);

  return (
    <div className="pt-4">
      <Row id="create-blogs">
        <Col>
          <h4>Crear Blog</h4>
        </Col>
        <Col md={2}>
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      {isBlogLoaded && (
        <BlogForm blog={blog} action="new" formRequest={handleCreateRequest} />
      )}
      {!isBlogLoaded && (
        <Row className="justify-content-center">
          <Spinner animation="border" variant="primary" />
        </Row>
      )}
    </div>
  );
};

export default connect()(BlogNew);
