import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import carIcon from '../../../assets/images/car-black-icon.svg';
import CargoLandingServiceCard from '../../../components/Utils/Cards/CargoLandingServiceCard';
import { uuidV4 } from '../../../services/utils';

const TransportServicesSection = ({ routes, settings }) => {
  const [cargoTransportServiceData, setCargoTransportServiceData] = useState(
    []
  );
  const { t, i18n } = useTranslation();
  const setUpTranslation = () => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  };

  const history = useHistory();
  const cargoLeadRedirect = () => {
    history.push(`/cargo/leads`);
  };

  const cargoTransportService = [
    {
      serviceName: t('cargo.landing.transportServicesSection.towing'),
      serviceCategory: 'towing_category',
      serviceIcon: settings?.cargoCardTowingCategoryImage,
      data: []
    },
    {
      serviceName: t('cargo.landing.transportServicesSection.machinery'),
      serviceCategory: 'machinery_category',
      serviceIcon: settings?.cargoCardMachineryCategoryImage,
      data: []
    },
    {
      serviceName: t(
        'cargo.landing.transportServicesSection.lightweight_vehicles'
      ),
      serviceCategory: 'lightweight_category',
      serviceIcon: settings?.cargoCardLightWeightCategoryImage,
      data: []
    },
    {
      serviceName: t('cargo.landing.transportServicesSection.general'),
      serviceCategory: 'general_category',
      serviceIcon: settings?.cargoCardGeneralCategoryImage,
      data: []
    }
  ];

  const buildCargoTransportServiceData = () => {
    if (routes.length > 0) {
      routes.forEach(route => {
        route?.cargoRatesAttributes.forEach(cargoRate => {
          const category = cargoTransportService.find(
            cat => cat.serviceCategory === cargoRate.cardCategory
          );
          const isFound = category?.data.find(
            element =>
              element.vehicleTypeCategory === cargoRate.vehicleTypeCategory
          );
          if (isFound === undefined && category !== undefined) {
            category.data.push({
              vehicleTypeCategory: cargoRate.vehicleTypeCategory,
              vehicleLength: cargoRate.vehicleLength
            });
          }
        });
      });
      if (cargoTransportService[3].data.length === 0) {
        cargoTransportService[3].data.push({
          vehicleTypeCategory: 'general_cargo',
          vehicleLength: t(
            'cargo.landing.transportServicesSection.general_cargo_vehicle_length'
          )
        });
      }
      setCargoTransportServiceData(cargoTransportService);
    }
  };

  useEffect(setUpTranslation, []);
  useEffect(buildCargoTransportServiceData, [routes, settings]);

  return (
    <div className="cargo-landing-service">
      <Row className="align-items-center align-text-center justify-content-center mt-5 px-2 mb-4">
        <div className="landing-cargo-transport-title-service">
          <h4 className="cargo-landing-service-title mb-3">
            {t('cargo.landing.transportServicesSection.title')}
          </h4>
          <p className="cargo-landing-service-description mb-4">
            {t('cargo.landing.transportServicesSection.subtitle')}
          </p>
        </div>
      </Row>
      <Row className="align-items-center justify-content-center mt-2 cargo-landing-card-container">
        {cargoTransportServiceData.map(category => {
          return (
            <Col
              className="d-flex justify-content-center"
              sm={12}
              lg={6}
              xl={3}
              key={uuidV4()}
            >
              <CargoLandingServiceCard
                title={category.serviceName}
                imgSrc={category.serviceIcon}
                additionalElements={category.data}
                altMessage={t(
                  'cargo.landing.transportServicesSection.general_cargo_vehicle_length'
                )}
              />
            </Col>
          );
        })}
      </Row>
      <div className="middle-background-stripe" />
      <Row className="cargo-landing-service-background align-items-center justify-content-center m-0 ">
        <Col md={4} xl={3} className="d-flex justify-content-center">
          <Button
            type="submit"
            variant="light"
            className="p-3"
            style={{ borderRadius: '10px', color: '$primary' }}
            onClick={cargoLeadRedirect}
          >
            {t('cargo.landing.transportServicesSection.quote')}
            <Image
              src={carIcon}
              alt="icono auto"
              className="ml-2"
              style={{ fill: 'red !important' }}
            />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TransportServicesSection;
