import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { showUserRequest, updateUserRequest } from '../../requests/user';
import { DefaultProfileHeader } from '../../components';
import '../../assets/stylesheets/screens/profile/profileEdit.scss';
import { validateToken } from '../../actions/auth';
import ProfileForm from './ProfileForm';
import { sendAlert } from '../../actions/utils';

const ProfileEdit = () => {
  const [onRequest, setOnRequest] = useState(false);
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const { avatar } = values.user;

    const params = snakeCaseKeys(values);
    if (avatar) params.user.avatar = avatar;

    updateUserRequest({
      dispatch,
      params,
      formData: true,
      successCallback: () => {
        dispatch(validateToken());
        setOnRequest(false);
        history.push('/admin/profile');
        dispatch(
          sendAlert({
            kind: 'success',
            message: 'Perfil actualizado exitosamente'
          })
        );
      }
    });
  };

  useEffect(() => {
    setOnRequest(true);
    showUserRequest({
      dispatch,
      successCallback: response => {
        setUser(camelCaseRecursive(response.data));
        setOnRequest(false);
      }
    });
  }, []);

  return (
    <div className="profile-user-edit">
      <DefaultProfileHeader
        breadcrumb={[
          { key: 1, name: 'Mi Perfil', href: '/admin/profile' },
          { key: 2, name: 'Editar perfil' }
        ]}
        body={
          <Button
            variant="light"
            className="profile-edit-header-btn"
            as={Link}
            to="/admin/profile"
          >
            Volver
          </Button>
        }
      />

      <div className={onRequest ? 'bg-opacity' : ''}>
        <ProfileForm
          user={user}
          formRequest={handleUpdateRequest}
          onRequest={onRequest}
        />
      </div>
    </div>
  );
};

export default ProfileEdit;
