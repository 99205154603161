import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import RouteForm from './RouteForm';
import cargoRate from '../../../components/Routes/cargoRate';
import tourismRate from '../../../components/Routes/tourismRate';
import daysItineraryDescription from '../../../components/Routes/daysItineraryDescription';

import {
  showAdminRouteRequest,
  updateAdminRouteRequest
} from '../../../requests/admin/adminRoutes';
import { sendAlert } from '../../../actions/utils';

const RouteEdit = props => {
  const { history } = props;
  const [route, setRoute] = useState({
    id: 1,
    routeName: '',
    routeCode: '',
    routeDuration: '',
    routeCheckIn: '',
    origin: '',
    destination: '',
    position: 1,
    active: true,
    cargoRatesAttributes: [cargoRate],
    tourismRatesAttributes: [tourismRate],
    routesIncludedElementsAttributes: [],
    daysItineraryDescriptionsAttributes: [daysItineraryDescription],
    includedElements: [],
    interactiveMapRouteLink: '',
    language: ''
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _route = camelCaseRecursive(response.data);
    setRoute(_route);
  };

  useEffect(() => {
    const { match } = props;
    const routeId = match.params.id;
    showAdminRouteRequest(routeId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    const routePath =
      route.routeType === 'both'
        ? 'routes_and_included'
        : 'cargo_routes_and_included';
    history.push({
      pathname: `/admin/${routePath}`,
      state: { type: 'Rutas & tarifas' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Ruta actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _route => {
    const routeId = _route.route.id;
    updateAdminRouteRequest(routeId, {
      dispatch,
      params: _route,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-route" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar Ruta</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <RouteForm
        route={route}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(RouteEdit);
