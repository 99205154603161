import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

import { deleteAdminAttractionRequest } from '../../../requests/admin/adminAttractions';
import { languages } from '../../../services/utils';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Posición',
      selector: () => 'position',
      sortable: true,
      cell: ({ position }) => position,
      grow: '.5'
    },
    {
      name: 'Título',
      selector: 'title',
      sortable: true,
      cell: ({ title }) => title,
      grow: '1.5'
    },
    {
      name: 'Idioma',
      selector: () => 'language',
      sortable: true,
      cell: ({ language }) => languages[language],
      grow: '1'
    },
    {
      name: 'Descripción',
      selector: 'description',
      cell: ({ description }) => `${description.slice(0, 30)}...`,
      grow: '2'
    },
    {
      name: 'Link',
      selector: 'link',
      cell: ({ link }) => link,
      grow: '2'
    },
    {
      name: 'Activo',
      selector: () => 'active',
      grow: '.5',
      sortable: true,
      cell: ({ active }) => (active ? 'Sí' : 'No')
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const AttractionsDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminAttractionRequest,
    onDeleteMessage: 'Atracción eliminada exitosamente',
    confirmationText: '¿Estás seguro de eliminar ésta atracción?',
    modalTitle: 'Eliminar Atracción'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'position':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        RowsPage={10}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(AttractionsDataTable));
