import leaderEducation from './leaderEducation';

const ourLeader = {
  id: '',
  name: '',
  occupation: '',
  caption: '',
  language: 'es',
  position: 1,
  active: true,
  leaderEducationsAttributes: [leaderEducation]
};

export default ourLeader;
