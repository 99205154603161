import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput, FormikCheckBox } from '../Utils/Input';

const TourismRatesForm = ({ forNestedAttributes, index, parentModel }) => {
  const modelName = `${parentModel}[tourismRatesAttributes][${index}]`;
  const { errors, touched } = useFormikContext();

  return (
    <Col className="nested-row-form-container">
      <Row>
        <Col className="d-flex justify-content-center title-label-container">
          {forNestedAttributes && (
            <p className="title-label">{`Tarifa de Turismo ${index + 1}`}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="details-label-container">
          <p className="details-label">Detalles de la tarifa</p>
        </Col>
      </Row>
      <Row className="d-flex align-items-end">
        <Col>
          <Field name={`${modelName}[roomType]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tipo de habitación"
                error={getIn(errors, `${modelName}[roomType]`)}
                touched={getIn(touched, `${modelName}[roomType]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[availableRooms]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Cantidad de habitaciones disponibles"
                error={getIn(errors, `${modelName}[availableRooms]`)}
                touched={getIn(touched, `${modelName}[availableRooms]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[bedType]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tipo de cama"
                error={getIn(errors, `${modelName}[bedType]`)}
                touched={getIn(touched, `${modelName}[bedType]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Posición"
                error={getIn(errors, `${modelName}[cargoUnit]`)}
                touched={getIn(touched, `${modelName}[cargoUnit]`)}
              />
            )}
          </Field>
        </Col>
        <Col className="d-flex justify-content-center pb-3">
          <Field name={`${modelName}[active]`}>
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-flex align-items-end">
        <Col>
          <Field name={`${modelName}[singleRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tarifa single"
                error={getIn(errors, `${modelName}[singleRate]`)}
                touched={getIn(touched, `${modelName}[singleRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[doubleRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tarifa doble"
                error={getIn(errors, `${modelName}[doubleRate]`)}
                touched={getIn(touched, `${modelName}[doubleRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[tripleRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tarifa triple"
                error={getIn(errors, `${modelName}[tripleRate]`)}
                touched={getIn(touched, `${modelName}[tripleRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[quadrupleRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tarifa cuadruple"
                error={getIn(errors, `${modelName}[quadrupleRate]`)}
                touched={getIn(touched, `${modelName}[quadrupleRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[sharedRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tarifa compartida"
                error={getIn(errors, `${modelName}[sharedRate]`)}
                touched={getIn(touched, `${modelName}[sharedRate]`)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default TourismRatesForm;
