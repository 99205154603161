import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import QuestionCell from './QuestionCell';
import { FaqsDataTableIndex } from '../../components';

const columns = memoize(() => [
  {
    cell: ({ question, answer }) => (
      <QuestionCell question={question} answer={answer} />
    ),
    grow: '1'
  }
]);

const PublicFaqDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  handleIndexRequest,
  RowsPage,
  categoryFilter,
  categories,
  selectedCategory,
  setSelectedCategory
}) => {
  const language = localStorage.getItem('language') || 'es';
  return (
    <>
      <FaqsDataTableIndex
        onRequest={isFetching}
        columns={columns()}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        queryPlaceholder={
          language === 'es'
            ? 'Buscar por palabras clave...'
            : 'Search by word...'
        }
        RowsPage={RowsPage}
        categoryFilter={categoryFilter}
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
};

export default withRouter(connect()(PublicFaqDataTable));
