import React, { useState, useContext } from 'react';
import { Nav, Accordion, Card } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Link } from 'react-router-dom';

import IcoMoon from '../Icon';
import { uuidV4 } from '../../../services/utils';

const CustomToggle = ({ icon, eventKey, title, setIsAccordionOpen }) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (eventKey === currentEventKey) {
      setIsAccordionOpen(false);
    } else {
      setIsAccordionOpen(true);
    }
  });
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Nav.Link role="button" onClick={decoratedOnClick}>
      <span className="link-menu accordion-header">
        <IcoMoon icon={icon} />
        <div className="accordion-header-title">{title}</div>
        <IcoMoon
          icon={isCurrentEventKey ? 'chevron-up' : 'chevron-down'}
          variant="transparent"
          className="chevron-down-icon"
        />
      </span>
    </Nav.Link>
  );
};

const AccordionNavLink = ({
  accordionTitle,
  accordionIcon,
  accordionItems,
  selectedPath,
  setSelectedPath
  // isActive
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Card className={`nav-accordion ${isAccordionOpen ? 'open' : ''}`}>
        <Card.Header>
          <CustomToggle
            title={accordionTitle}
            icon={accordionIcon}
            setIsAccordionOpen={setIsAccordionOpen}
            eventKey={`accordion-${accordionTitle}`}
          />
        </Card.Header>
        {accordionItems && accordionItems.length > 0 && (
          <Accordion.Collapse eventKey={`accordion-${accordionTitle}`}>
            <Card.Body>
              {accordionItems.map(({ itemTitle, to, id }) => (
                <Nav.Link
                  key={uuidV4()}
                  eventKey={to}
                  as={Link}
                  to={`/${to}`}
                  onClick={() => setSelectedPath(id)}
                  className={`accordion-sub-item ${id === selectedPath &&
                    'accordion-sub-item-selected'}`}
                >
                  <span className="link-menu nav-accordion--item">
                    <IcoMoon
                      icon="edit-black-point"
                      variant="disabled"
                      size="sm"
                      className="mr-5"
                    />
                    {itemTitle}
                  </span>
                </Nav.Link>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        )}
      </Card>
    </Accordion>
  );
};

export default AccordionNavLink;
