import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import AdvisorForm from './AdvisorForm';

import {
  showAdminAdvisorRequest,
  updateAdminAdvisorRequest
} from '../../../requests/admin/adminAdvisors';
import { sendAlert } from '../../../actions/utils';

const AdvisorEdit = props => {
  const { history } = props;
  const [advisor, setAdvisor] = useState({
    name: '',
    email: '',
    phone: '',
    route: ''
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _advisor = camelCaseRecursive(response.data);
    setAdvisor(_advisor);
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/advisors' });
  };

  useEffect(() => {
    const { match } = props;
    const advisorId = match.params.id;

    showAdminAdvisorRequest(advisorId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Advisor actualizado con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _advisor => {
    const advisorId = _advisor.advisor.id;
    updateAdminAdvisorRequest(advisorId, {
      dispatch,
      params: _advisor,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="p-4">
      <Row id="edit-advisors" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar Asesor</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <AdvisorForm
        advisor={advisor}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(AdvisorEdit);
