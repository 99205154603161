/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import { Image, Form } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import './styles.scss';
import { supportedImagaFormats } from '../../../services/utils';

const UploadImage = props => {
  const {
    imageUrl,
    fileName,
    error,
    touched,
    label,
    abbr,
    customImageStyles,
    modelType = 'default'
  } = props;
  const [url, setUrl] = useState('');
  const [name, setName] = useState('Elegir Imagen');

  const suggestedSizes = {
    default: '1200px x 690px',
    attraction: '1200px x 690px',
    blog: '1200px x 690px',
    guide: '600px x 600px',
    includedElement: '400px x 400px',
    review: '1200px x 690px',
    slide: '1200px x 690px',
    setting: '600px x 600px',
    profile: '300px x 300px',
    ourLeaders: '500px x 350px'
  };

  const getSuggestedSize = type => {
    return suggestedSizes[type] || 'Tamaño no especificado';
  };

  const _handleImageChange = e => {
    const { onChange } = props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setUrl(reader.result);
      setName(file.name);
    };
    reader.readAsDataURL(file);
    onChange(file);
  };

  useEffect(() => {
    if (fileName) setName(fileName);
  }, [fileName]);

  return (
    <div>
      {label ? (
        <Form.Label>
          {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
        </Form.Label>
      ) : (
        false
      )}
      <div className={`custom-file-img mb-3 ${customImageStyles}`}>
        <Image src={url || imageUrl || defaultImg} fluid />
      </div>
      <div className="input-group mb-3">
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input secondary"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            onChange={e => _handleImageChange(e)}
            accept={supportedImagaFormats}
          />
          <label
            className="custom-file-label text-truncate"
            htmlFor="inputGroupFile01"
          >
            {name}
          </label>
        </div>
      </div>
      <p
        style={{ fontSize: '11px', fontStyle: 'italic', marginBottom: '10px' }}
      >
        (Tamaño sugerido {getSuggestedSize(modelType)})
      </p>
      {error && touched && (
        <Form.Text className="text-danger my-3">{error}</Form.Text>
      )}
    </div>
  );
};

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;
