import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput, FormikCheckBox } from '../Utils/Input';
import { FormikSelect } from '../Utils/Select';
import {
  cargoRateCardCategoryOptions,
  cargoRateImagesOptions,
  cargoRateRatesTableOptions
} from '../../services/utils';

const CargoRateForm = ({ forNestedAttributes, index, parentModel, route }) => {
  const modelName = `${parentModel}[cargoRatesAttributes][${index}]`;
  const { errors, touched, values, setFieldValue } = useFormikContext();
  const [defaultImage, setDefaultImage] = useState(undefined);
  const [defaultCardCategory, setDefaultCardCategory] = useState(undefined);
  const [defaultRatesTableCategory, setDefaultRatesTableCategory] = useState(
    undefined
  );

  const findDefaultImage = () => {
    setDefaultImage(
      cargoRateImagesOptions.find(
        imageOption =>
          imageOption.value ===
          route.cargoRatesAttributes[index]?.vehicleTypeCategory
      )
    );
  };

  const findDefaultCardCategory = () => {
    setDefaultCardCategory(
      cargoRateCardCategoryOptions.find(
        cardCategoryOption =>
          cardCategoryOption.value ===
          route.cargoRatesAttributes[index]?.cardCategory
      )
    );
  };

  const findDefaultRatesTableCategory = () => {
    setDefaultRatesTableCategory(
      cargoRateRatesTableOptions.find(
        ratesTableOption =>
          ratesTableOption.value ===
          route.cargoRatesAttributes[index]?.ratesTableCategory
      )
    );
  };

  useEffect(findDefaultImage, [route]);
  useEffect(findDefaultCardCategory, [route]);
  useEffect(findDefaultRatesTableCategory, [route]);

  return (
    <Col className="nested-row-form-container">
      <Row>
        <Col className="d-flex justify-content-center title-label-container">
          {forNestedAttributes && (
            <p className="title-label">{`Tarifa de Carga ${index + 1}`}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="details-label-container">
          <p className="details-label">Detalles de la tarifa</p>
        </Col>
      </Row>
      <Row className="d-felx align-items-end">
        <Col>
          <Field name={`${modelName}[vehicleType]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Tipo de vehiculo"
                error={getIn(errors, `${modelName}[vehicleType]`)}
                touched={getIn(touched, `${modelName}[vehicleType]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[vehicleTypeCategory]`}>
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Imagen de la categoria"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={cargoRateImagesOptions}
                  defaultValue={defaultImage?.value}
                  onChange={(routeOption, data) => {
                    const dataIndex = Number(data.name.match(/\d/g).join(''));
                    const { cargoRatesAttributes } = values.route;
                    cargoRatesAttributes[dataIndex].vehicleTypeCategory =
                      routeOption.value;
                    setFieldValue('route', {
                      ...values.route,
                      cargoRatesAttributes
                    });
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[cardCategory]`}>
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Categoria de agrupación (landing)"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={cargoRateCardCategoryOptions}
                  defaultValue={defaultCardCategory?.value}
                  onChange={(routeOption, data) => {
                    const dataIndex = Number(data.name.match(/\d/g).join(''));
                    const { cargoRatesAttributes } = values.route;
                    cargoRatesAttributes[dataIndex].cardCategory =
                      routeOption.value;
                    setFieldValue('route', {
                      ...values.route,
                      cargoRatesAttributes
                    });
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[ratesTableCategory]`}>
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Categoria de agrupación (tabla de precios)"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={cargoRateRatesTableOptions}
                  defaultValue={defaultRatesTableCategory?.value}
                  onChange={(routeOption, data) => {
                    const dataIndex = Number(data.name.match(/\d/g).join(''));
                    const { cargoRatesAttributes } = values.route;
                    cargoRatesAttributes[dataIndex].ratesTableCategory =
                      routeOption.value;
                    setFieldValue('route', {
                      ...values.route,
                      cargoRatesAttributes
                    });
                  }}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Posición"
                error={getIn(errors, `${modelName}[cargoUnit]`)}
                touched={getIn(touched, `${modelName}[cargoUnit]`)}
              />
            )}
          </Field>
        </Col>
        <Col className="d-flex justify-content-center pb-3">
          <Field name={`${modelName}[active]`}>
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>
      <Row className="d-felx align-items-end">
        <Col>
          <Field name={`${modelName}[loadedInitialRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Valor ida cargado"
                error={getIn(errors, `${modelName}[loadedInitialRate]`)}
                touched={getIn(touched, `${modelName}[loadedInitialRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[emptyInitialRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Valor ida descargado"
                error={getIn(errors, `${modelName}[emptyInitialRate]`)}
                touched={getIn(touched, `${modelName}[emptyInitialRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[loadedReturnRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Valor vuelta cargado"
                error={getIn(errors, `${modelName}[loadedReturnRate]`)}
                touched={getIn(touched, `${modelName}[loadedReturnRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[emptyReturnRate]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Valor vuelta descargado"
                error={getIn(errors, `${modelName}[emptyReturnRate]`)}
                touched={getIn(touched, `${modelName}[emptyReturnRate]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[specialPrice]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                label="Precio especial"
                error={getIn(errors, `${modelName}[specialPrice]`)}
                touched={getIn(touched, `${modelName}[specialPrice]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[vehicleLength]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Largo del vehiculo"
                error={getIn(errors, `${modelName}[vehicleLength]`)}
                touched={getIn(touched, `${modelName}[vehicleLength]`)}
              />
            )}
          </Field>
        </Col>
        <Col>
          <Field name={`${modelName}[cargoUnit]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Unidad de medida"
                error={getIn(errors, `${modelName}[cargoUnit]`)}
                touched={getIn(touched, `${modelName}[cargoUnit]`)}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default CargoRateForm;
