import React from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';

import { IconBtn } from '../Button';

const backgroundColor = props => {
  if (props.isDragDisabled) {
    return 'lightgrey';
  }
  return props.isActive ? 'LightSlateGrey' : '#f3f7fb';
};
const Container = styled.div`
  border: 1px solid lightblue;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => backgroundColor(props)};
`;

const Subcategory = props => {
  const {
    subcategory,
    index,
    setEditCategoryData,
    setOpenEditModal,
    setDeleteCategoryId,
    setOpenDeleteModal,
    setIsRoot
  } = props;
  const isDragDisabled = subcategory.categoryId === 'subcategory-10';
  return (
    <Draggable
      key={subcategory.categoryId}
      draggableId={subcategory.categoryId}
      index={index}
      // isDragDisabled = true, disabled all subcategorys
      // isDragDisabled condition, only that subcategory will be disabled
      isDragDisabled={subcategory.categoryId === 'subcategory-10'}
    >
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          isActive={subcategory.active}
          isDragDisabled={isDragDisabled}
        >
          {/* <Handle {...provided.dragHandleProps} /> */}
          <Row>
            <Col className="col-6">{subcategory.category}</Col>

            <Col className="col-2">
              <IconBtn
                icon="trash"
                iconSize="xs"
                className="btn delete-btn no-shadow"
                onClick={() => {
                  setDeleteCategoryId(subcategory.id);
                  setIsRoot(false);
                  setOpenDeleteModal(true);
                }}
              />
            </Col>
            <Col className="col-2">
              <IconBtn
                icon="pencil"
                iconSize="xs"
                className="btn edit-btn no-shadow ml-1"
                onClick={() => {
                  setEditCategoryData(subcategory);
                  setIsRoot(false);
                  setOpenEditModal(true);
                }}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Draggable>
  );
};

export default Subcategory;
