import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ComponentDataTable } from '../../../components';
import columns from './settingsColumns';
import '../../../assets/stylesheets/components/settings/adminSettingsTable.scss';

const AdminSettingTable = ({
  settings,
  tableDataAmount,
  moreData,
  isFetching,
  handleUpdateRequest,
  handleIndexRequest
}) => {
  if (isFetching) {
    return (
      <div className="containerSpinnerLoad fullHeightAbsolute">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div className="admin-setting-table">
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns(handleUpdateRequest)}
        data={settings}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        queryFilter={false}
      />
    </div>
  );
};

export default AdminSettingTable;
