import React, { useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import EsperanzaShip from '../../../assets/images/ships/EsperanzaShip.png';
import TrinidadShip from '../../../assets/images/ships/TrinidadShip.png';
import DalkaShip from '../../../assets/images/ships/DalkaShip.png';
import DalkaShip01 from '../../../assets/images/ships/DalkaShip01.jpg';
import DalkaShip02 from '../../../assets/images/ships/DalkaShip02.png';
import EsperanzaShip01 from '../../../assets/images/ships/EsperanzaShip01.png';
import EsperanzaShip02 from '../../../assets/images/ships/EsperanzaShip02.png';
import TrinidadShip01 from '../../../assets/images/ships/TrinidadShip01.jpg';
import TrinidadShip02 from '../../../assets/images/ships/TrinidadShip02.jpg';
import { DefaultModal, IcoMoon } from '../../../components';
import ShipsModal from './ShipsModal';

const OurShipsSection = () => {
  const [showMapModal, setShowMapModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const ships = [
    {
      name: 'Dalka (UCO)',
      eslora: '147 metros',
      cargoCapacity: '8.000 toneladas',
      autoStorage: '50 unidades',
      linearMetersCargo: '1.310 metros',
      passengerCapacity: '12 transportistas',
      constructionYear: 1992,
      shipImage: DalkaShip,
      shipImages: [DalkaShip01, DalkaShip02]
    },
    {
      name: 'Esperanza (NAT)',
      eslora: '150 metros',
      cargoCapacity: '5.500 toneladas',
      autoStorage: '50 unidades',
      linearMetersCargo: '1.650 metros',
      passengerCapacity: '244 personas',
      constructionYear: 2020,
      shipImage: EsperanzaShip,
      shipImages: [EsperanzaShip01, EsperanzaShip02]
    },
    {
      name: 'Aurora (NAT)',
      eslora: '122 metros',
      cargoCapacity: '4.500 toneladas',
      autoStorage: '20 unidades',
      linearMetersCargo: '1.200 metros',
      passengerCapacity: '12 transportistas',
      constructionYear: 1993,
      shipImage: TrinidadShip,
      shipImages: [TrinidadShip01, TrinidadShip02]
    }
  ];

  const handleOpneModal = shipName => {
    setModalTitle(shipName);
    setShowMapModal(true);
  };

  return (
    <div className="our-ships">
      <h4 className="our-ships-title mb-3">Nuestros Barcos</h4>
      <p className="our-ships-description mb-4 text-center p-4">
        Conoce los barcos con los que trabajamos día a día para asegurar el
        transporte por la zona austral de Chile.
      </p>
      <Container>
        <Row className="our-ships-cards-container">
          {ships.map(ship => (
            <Col sm={12} md={12} lg={4} key={ship.name}>
              <div
                className="our-ships-card"
                style={{ backgroundImage: `url(${ship.shipImage})` }}
              >
                <div className="our-ships-info">
                  <p className="our-ships-name">{ship.name}</p>
                  {/* el hover quedo comentado a petición del cliente. El css esta en _our-ships.scss */}
                  <div className="our-ships-data">
                    <p className="our-ships-description">
                      Capacidad Pasajeros: {ship.passengerCapacity}
                      <br />
                      Capacidad Carga: {ship.cargoCapacity}
                      <br />
                      Eslora: {ship.eslora}
                      <br />
                      Año de construcción: {ship.constructionYear}
                    </p>
                    <IcoMoon
                      icon="zoom-in"
                      size={32}
                      color="white"
                      onClick={() => handleOpneModal(ship.name)}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <DefaultModal
        title={modalTitle}
        size="lg"
        body={<ShipsModal ships={ships} shipName={modalTitle} />}
        handleClose={() => setShowMapModal(false)}
        show={showMapModal}
      />
    </div>
  );
};

export default OurShipsSection;
