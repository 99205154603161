import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import AttractionForm from './AttractionForm';

import {
  showAdminAttractionRequest,
  updateAdminAttractionRequest
} from '../../../requests/admin/adminAttractions';
import { sendAlert } from '../../../actions/utils';

const AttractionEdit = props => {
  const { history } = props;
  const [attraction, setAttraction] = useState({
    name: '',
    description: '',
    language: '',
    city: '',
    country: '',
    category: '',
    price: '',
    duration: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _attraction = camelCaseRecursive(response.data);
    setAttraction(_attraction);
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/landing', state: { type: 'Atractivos' } });
  };

  useEffect(() => {
    const { match } = props;
    const attractionId = match.params.id;

    showAdminAttractionRequest(attractionId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Configuración actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _attraction => {
    const attractionId = _attraction.attraction.id;
    updateAdminAttractionRequest(attractionId, {
      dispatch,
      params: _attraction,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="p-4">
      <Row id="edit-attractions" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar Atracción</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <AttractionForm
        attraction={attraction}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(AttractionEdit);
