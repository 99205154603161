import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

const ItineraryDay = ({ day, number }) => {
  const [showDescription, setShowDescription] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className="itinerary-description-days-item"
      onClick={() => setShowDescription(!showDescription)}
      aria-hidden="true"
    >
      <div className="itinerary-description-days-item-day">
        {`${t('tourism.landing.routesSection.day')} ${number}`}
      </div>
      <div className="itinerary-description-days-item-destiny">
        <p className="itinerary-description-days-item-destiny-title">
          {day.mainTitle}
        </p>
        <p className="itinerary-description-days-item-destiny-description">
          {showDescription && day.description}
        </p>
      </div>
    </div>
  );
};

export default ItineraryDay;
