import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import AttractionForm from './AttractionForm';
import { createAdminAttractionRequest } from '../../../requests/admin/adminAttractions';
import { sendAlert } from '../../../actions/utils';

const AttractionNew = () => {
  const [attraction] = useState({
    name: '',
    language: '',
    description: '',
    category: '',
    capacity: '',
    image: '',
    price: '',
    active: true
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Configuración actualizada con éxito'
      })
    );
    history.goBack();
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/landing', state: { type: 'Atractivos' } });
  };

  const handleCreateRequest = _attraction => {
    createAdminAttractionRequest({
      dispatch,
      params: _attraction,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="p-4">
      <Row id="create-attractions" className="mb-4">
        <Col>
          <h4 className="text-primary">Crear Atracción</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <AttractionForm
        attraction={attraction}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default connect()(AttractionNew);
