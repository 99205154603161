import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import AdvisorForm from './AdvisorForm';
import { createAdminAdvisorRequest } from '../../../requests/admin/adminAdvisors';
import { sendAlert } from '../../../actions/utils';

const AdvisorNew = () => {
  const [advisor] = useState({
    name: '',
    email: '',
    phone: '',
    route: ''
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Asesor creado con éxito'
      })
    );
    history.goBack();
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/advisors' });
  };

  const handleCreateRequest = _advisor => {
    createAdminAdvisorRequest({
      dispatch,
      params: _advisor,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="p-4">
      <Row id="create-advisors" className="mb-4">
        <Col>
          <h4 className="text-primary">Crear Asesor</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <AdvisorForm
        advisor={advisor}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default AdvisorNew;
