import React, { useEffect, useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { indexGuidesRequest } from '../../requests/guides';
import { showPagesRequest } from '../../requests/pages';
import '../../assets/stylesheets/components/guides/Guide.scss';
import GuidesSection from '../Home/Landing/GuidesSection';

const GuideShow = ({ match }) => {
  const { id } = match.params;
  const [guide, setGuide] = useState({});
  const [page, setPage] = useState({});
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSuccessShowPage = response => {
    setPage(camelCaseRecursive(response.data));
  };

  useEffect(() => {
    indexGuidesRequest({
      dispatch,
      params: {
        active: true,
        guide_show_url: id
      },
      successCallback: result => {
        if (result.data.data.length > 0) {
          setGuide(camelCaseRecursive(result.data.data[0]));
          setNotFound(false);
        } else {
          setNotFound(true);
        }
      }
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (guide.pageUrl) {
      showPagesRequest(guide.pageUrl, {
        dispatch,
        successCallback: handleSuccessShowPage
      });
    }
  }, [guide, dispatch]);

  return (
    <>
      {notFound ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: '80vh' }}
        >
          <h4 className="text-primary">{t('guides.guideNotFoundTitle')}</h4>
          <p>{t('guides.guideNotFoundText')}</p>
        </div>
      ) : (
        <>
          <div
            id="show-guide"
            className="col-12 col-lg-9 mx-auto"
            style={{ marginTop: 100, marginBottom: 100, paddingTop: 70 }}
          >
            <Row id="public-guide-title">
              <Col>
                <h2 className="text-primary">{guide.title}</h2>
              </Col>
            </Row>
            <Row id="public-guide-image">
              <Col className="d-flex justify-content-center">
                <Image
                  src={guide.attachedMainImage?.fileUrl}
                  style={{ maxWidth: 200 }}
                />
              </Col>
            </Row>
            <div className="guide-page">
              <div
                dangerouslySetInnerHTML={{ __html: page.body }}
                style={{
                  width: '100%',
                  marginTop: '20px'
                }}
              />
            </div>
          </div>
          <GuidesSection guideId={guide.id} />
        </>
      )}
    </>
  );
};
export default withRouter(GuideShow);
