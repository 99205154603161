import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Card, Spinner } from 'react-bootstrap';
import axios from 'axios';

import { uuidV4 } from '../../services/utils';
import { indexRoutesRequest } from '../../requests/routes';
import star from '../../assets/images/rates/star.svg';
import doorOpenSolid from '../../assets/images/rates/door-open-solid.svg';
import bedSolid from '../../assets/images/rates/bed-solid.svg';
import userSolid01 from '../../assets/images/rates/user-solid-1.svg';
import userSolid02 from '../../assets/images/rates/user-solid-2.svg';
import userSolid03 from '../../assets/images/rates/user-solid-3.svg';
import userSolid04 from '../../assets/images/rates/user-solid-4.svg';
import userSolidShared from '../../assets/images/rates/user-solid-shared.svg';
import '../../assets/stylesheets/components/rates/Rates.scss';
import RouteFilter from '../Utils/Filter/RouteFilter';
import { useScrollPosition } from '../../hooks';
import KnowMoreCards from '../KnowMoreCards/KnowMoreCards';

const TourismRates = ({ setShowSubHeader = () => {} }) => {
  const { t, i18n } = useTranslation();
  const [routes, setRoutes] = useState([]);
  const [disclaimer, setDisclaimer] = useState('');
  const [selectedRoute, setSelectedRoute] = useState(undefined);
  const [loading, setIsLoading] = useState(undefined);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const { settings } = useSelector(state => state.utils);

  const setDisclaimerCode = () => {
    const disclaimerCode =
      language === 'en' ? 'ratesDisclaimerEn' : 'ratesDisclaimerEs';
    const disclaimerValue = settings[disclaimerCode] || '';
    setDisclaimer(disclaimerValue);
  };

  const routeRequestWrapper = () => {
    setIsLoading(true);
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token,
        language
      },
      successCallback: result => {
        setRoutes(camelCaseRecursive(result.data.data));
        setSelectedRoute(camelCaseRecursive(result.data.data)[0]);
        setIsLoading(false);
      }
    });
    return () => cancelToken.cancel();
  };

  const ratesRef = useRef();
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (ratesRef.current !== null) {
      const { offsetTop } = ratesRef.current;
      if (scrollPosition.y >= offsetTop - 425) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };

  useEffect(evaluatePosition, [scrollPosition]);

  useEffect(routeRequestWrapper, []);
  useEffect(setDisclaimerCode, [settings, language]);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  const ratesHeader = () => (
    <div className="rates-header">
      <div className="rates-header-item">
        <img src={star} alt="Star" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.category')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={doorOpenSolid} alt="doorOpenSolid" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.numberOfCabins')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={bedSolid} alt="bedSolid" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.beds')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={userSolid01} alt="userSolid01" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.single')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={userSolid02} alt="userSolid02" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.double')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={userSolid03} alt="userSolid03" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.triple')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={userSolid04} alt="userSolid04" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.quadruple')}
        </p>
      </div>
      <div className="rates-header-item">
        <img src={userSolidShared} alt="userSolidShared" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.rateSection.body.header.shared')}
        </p>
      </div>
    </div>
  );

  const ratesRowItem = (tourismRate, style) => (
    <div className="rates-row mb-2" key={uuidV4()}>
      <div className={`rates-row-item border-radius-left ${style} `}>
        <p>{tourismRate.roomType}</p>
      </div>
      <div className={`rates-row-item ${style}`}>
        <p>{tourismRate.availableRooms}</p>
      </div>
      <div
        className={`rates-row-item border-radius-right ${style}`}
        style={{ marginRight: '.5%' }}
      >
        <p>{tourismRate.bedType}</p>
      </div>
      <div
        className={`rates-row-item border-radius-left ${style} `}
        style={{ marginLeft: '.5%' }}
      >
        <p>
          {tourismRate.singleRate
            ? `USD ${parseInt(tourismRate.singleRate, 10)}`
            : '-'}
        </p>
      </div>
      <div className={`rates-row-item ${style}`}>
        <p>
          {tourismRate.doubleRate
            ? `USD ${parseInt(tourismRate.doubleRate, 10)}`
            : '-'}
        </p>
      </div>
      <div className={`rates-row-item ${style}`}>
        <p>
          {tourismRate.tripleRate
            ? `USD ${parseInt(tourismRate.tripleRate, 10)}`
            : '-'}
        </p>
      </div>
      <div className={`rates-row-item ${style}`}>
        <p>
          {tourismRate.quadrupleRate
            ? `USD ${parseInt(tourismRate.quadrupleRate, 10)}`
            : '-'}
        </p>
      </div>
      <div className={`rates-row-item border-radius-right ${style}`}>
        <p>
          {tourismRate.sharedRate
            ? `USD ${parseInt(tourismRate.sharedRate, 10)}`
            : '-'}
        </p>
      </div>
    </div>
  );

  const servicesCards = route => {
    return (
      <div className="route-services-container">
        {route?.includedElements?.map(includedElement => (
          <div className="route-services-item" key={uuidV4()}>
            <img
              src={includedElement?.fileInfo?.fileUrl}
              alt="service01"
              style={{ width: '70px' }}
            />
            <p className="mt-2">{includedElement?.description}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="my-4 rates-content-box">
      <h3 className="text-center text-primary">
        {t('tourism.rateSection.body.title')}
      </h3>
      <p className="text-center mt-3 mb-4">{disclaimer}</p>
      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: '460px' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!loading && (
        <>
          {false && (
            <RouteFilter
              routes={routes}
              setSelectedRoute={setSelectedRoute}
              selectedRoute={selectedRoute}
            />
          )}
          <div ref={ratesRef} className="rates-div-scroll">
            <div className="rates-div">
              <Card className="p-4 mt-4">
                {ratesHeader()}
                {selectedRoute?.tourismRatesAttributes?.map(
                  (tourismRate, index) => {
                    const style =
                      index % 2
                        ? 'rates-row-item-light-red'
                        : 'rates-row-item-red';
                    return ratesRowItem(tourismRate, style);
                  }
                )}
              </Card>
            </div>
          </div>
          <div className="my-4 py-4">
            <h3 className="text-center text-primary my-4">
              {t('tourism.rateSection.body.allIncludeTitle')}
            </h3>
            {servicesCards(selectedRoute)}
          </div>
          <KnowMoreCards />
        </>
      )}
    </div>
  );
};

export default TourismRates;
