import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormikInput, FormikCheckBox, FormikSelect } from '../../../components';
import { languageOptions } from '../../../services/utils';

const FaqCategoryForm = props => {
  const { onHide, submitVariant, errors, touched, action, faqCategory } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultLanguage, setDefaultLanguage] = useState(undefined);

  const findDefaultLanguage = () => {
    setDefaultLanguage(
      languageOptions.find(language => language.value === faqCategory?.language)
    );
  };

  useEffect(findDefaultLanguage, [faqCategory]);

  return (
    <Form className="w-100 px-2">
      <Row className="justify-content-center">
        <Col md={6}>
          <Field name="faqCategory[category]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Categoría"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="faqCategory[language]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  inputType="text"
                  label="Idioma"
                  placeholder="Seleccionar Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={languageOptions}
                  defaultValue={defaultLanguage?.value}
                  onChange={languageOption => {
                    setFieldValue('faqCategory', {
                      ...values.faqCategory,
                      language: languageOption.value
                    });
                  }}
                />
              );
            }}
          </Field>

          <Field name="faqCategory[position]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Posición"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="faqCategory[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center my-3">
        <Col md={6}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { id, category, language, position, active } = props.faqCategory;
  const { categoryType } = props;
  return {
    faqCategory: {
      id,
      category,
      language,
      position,
      categoryType,
      active
    }
  };
};

const validationSchema = Yup.object().shape({
  faqCategory: Yup.object().shape({
    category: Yup.string().required('Requerido'),
    language: Yup.string()
      .required('Requerido')
      .matches(
        /^(?=(?:.{2})$)[A-z]*$/,
        'El idioma debe tener maximo 2 caracteres'
      ),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = snakecaseKeys({
    faqCategory: values.faqCategory
  });
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(FaqCategoryForm);
