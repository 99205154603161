import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import LeadForm from '../../components/Leads/LeadForm';
import createLeadRequest from '../../requests/lead';
import { sendAlert } from '../../actions/utils';
import SEO from '../../components/Utils/SEO/SEO';

const HomeContact = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSuccessCreate = () => {
    setIsLoading(false);
    window.location.href = window.location.origin;
  };

  const handleFailureRequest = error => {
    setIsLoading(false);
    const errorMessage =
      error?.response?.data?.errors[0] || 'Error al realizar la solicitud';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleCreateRequest = _lead => {
    setIsLoading(true);
    const leadParams = {
      ..._lead
    };

    leadParams.lead.title = 'Lead formulario contacto';
    createLeadRequest({
      dispatch,
      params: leadParams,
      successCallback: response => handleSuccessCreate(response),
      failureCallback: response => handleFailureRequest(response)
    });
  };

  return (
    <Row className="justify-content-center align-items-center full-height">
      <SEO
        title={t('tourism.contact.seo.title')}
        description={t('tourism.contact.seo.description')}
      />

      <Col md={10} style={{ maxWidth: '700px', paddingTop: '150px' }}>
        <h3 className="text-center text-primary">
          {t('tourism.contact.body.title')}
        </h3>
        <h5 className="text-center mt-3 mb-4">
          {t('tourism.contact.body.description')}
        </h5>
        <LeadForm
          onHide={null}
          submitVariant={null}
          errors={null}
          touched={null}
          action="new"
          lead={{
            name: '',
            email: '',
            clientType: '',
            destinyType: '',
            travelTime: '',
            travelerType: '',
            phone: '',
            message: ''
          }}
          formRequest={handleCreateRequest}
          contact
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default connect()(HomeContact);
