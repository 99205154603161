import React, { useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { FormikInput, BasicTextArea } from '../../components';
import { sendPartnersContactFormRequest } from '../../requests/utils';
import { sendAlert } from '../../actions/utils';
import SEO from '../../components/Utils/SEO/SEO';

const HomePartnersContact = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SEO
        title={t('tourism.contactPartners.seo.title')}
        description={t('tourism.contactPartners.seo.description')}
      />
      <Row className="justify-content-center align-items-center full-height">
        <Col md={8}>
          <h3 className="text-center text-primary">
            {t('tourism.contactPartners.body.title')}
          </h3>
          <h5 className="text-center mt-3 mb-4">
            {t('tourism.contactPartners.body.description')}
          </h5>
          <Form>
            <Row>
              <Col>
                <Field name="name">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label={t('tourism.contactPartners.form.name')}
                      placeholder=""
                      abbr
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
                <Field name="message">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      label={t('tourism.contactPartners.form.message')}
                      placeholder=""
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
              </Col>
              <Col>
                <Field name="email">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      label={t('tourism.contactPartners.form.email')}
                      placeholder="email@navimag.com"
                      abbr
                      inputType="email"
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
                <Field name="agency">
                  {({ field }) => (
                    <BasicTextArea
                      {...field}
                      label={t('tourism.contactPartners.form.agency')}
                      placeholder=""
                      abbr
                      error={errors[field.name]}
                      touched={touched[field.name]}
                    />
                  )}
                </Field>
              </Col>
            </Row>
            <p>{t('tourism.contactPartners.body.disclaimerText')}</p>
            <Row className="justify-content-end">
              <Col md={4}>
                <Button
                  variant={submitVariant}
                  size="lg"
                  className="mt-4"
                  block
                  type="submit"
                  disabled={!isValid}
                  onClick={onHide}
                >
                  {t('tourism.contactPartners.body.contactButton')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const initialValues = {
  name: '',
  email: '',
  agency: '',
  message: ''
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Este campo es obligatorio'),
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  agency: Yup.string().required('Este campo es obligatorio'),
  message: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props, resetForm }) => {
  const { dispatch, history } = props;
  sendPartnersContactFormRequest({
    dispatch,
    params: {
      name: values.name,
      agency: values.agency,
      email: values.email,
      message: values.message
    },
    successCallback: result => {
      resetForm();
      history.push('/');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default connect()(
  withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema,
    handleSubmit
  })(HomePartnersContact)
);
