import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import {
  FormikInput,
  FormikCheckBox,
  FormikSelect,
  UploadImage
} from '../../../components';

import LeaderEducationsAttributes from '../../../components/OurLeaders/LeaderEducationsAttributes';
import { languageOptions } from '../../../services/utils';

const OurLeaderForm = props => {
  const { onHide, submitVariant, errors, touched, action, ourLeader } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const modelName = 'ourLeader';

  const { values, setFieldValue } = useFormikContext();

  const [defaultLanguage, setDefaultLanguage] = useState(undefined);

  const findDefaultLanguage = () => {
    setDefaultLanguage(
      languageOptions.find(language => language.value === ourLeader?.language)
    );
  };

  useEffect(findDefaultLanguage, [ourLeader]);

  return (
    <Form className="w-100 px-2">
      <Col>
        <Row>
          <Col>
            <Field name="ourLeader[image]">
              {({ field }) => {
                const { name } = field;
                const { fileInfo } = ourLeader;
                return (
                  <UploadImage
                    {...field}
                    modelType="ourLeaders"
                    accept="image/jpg, image/png, image/jpeg"
                    setFieldValue={setFieldValue}
                    imageUrl={getIn(fileInfo, 'fileUrl')}
                    fileName={getIn(fileInfo, 'filename')}
                    onChange={_avatar => setFieldValue(name, _avatar)}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Row>
              <Col>
                <Field name="ourLeader[name]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="text"
                        label="Nombre de líder"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              </Col>
              <Col>
                <Field name="ourLeader[occupation]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="text"
                        label="Cargo"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="ourLeader[caption]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="textarea"
                        isTextArea
                        label="Objetivos personales"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="ourLeader[language]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikSelect
                        {...field}
                        abbr
                        inputType="text"
                        label="Idioma"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                        options={languageOptions}
                        defaultValue={defaultLanguage?.value}
                        onChange={languageOption => {
                          setFieldValue('ourLeader', {
                            ...values.ourLeader,
                            language: languageOption.value
                          });
                        }}
                      />
                    );
                  }}
                </Field>
              </Col>
              <Col>
                <Field name="ourLeader[position]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        abbr
                        inputType="number"
                        label="Posición"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              </Col>
              <Col>
                <Field name="ourLeader[active]">
                  {({ field }) => (
                    <FormikCheckBox
                      {...field}
                      field={field}
                      label="Activo"
                      custom
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Detalles de estudios */}
        <hr />
        <h5 className="text-secondary">Detalles de estudios</h5>
        <Row className="nested-rates">
          <Col>
            <LeaderEducationsAttributes modelName={modelName} />
          </Col>
        </Row>
        <hr />
        <Row className="d-flex justify-content-end mr-1 pt-4 pb-4">
          <Col md={3}>
            <Button
              type="submit"
              variant={submitVariant}
              block
              onClick={onHide}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    name,
    occupation,
    caption,
    language,
    position,
    active,
    fileInfo,
    leaderEducationsAttributes
  } = props.ourLeader;
  return {
    ourLeader: {
      id,
      name,
      occupation,
      caption,
      language,
      position,
      active,
      image: getIn(fileInfo, 'fileSignature'),
      leaderEducationsAttributes
    }
  };
};

const validationSchema = Yup.object().shape({
  ourLeader: Yup.object().shape({
    name: Yup.string().required('Requerido'),
    occupation: Yup.string().required('Requerido'),
    caption: Yup.string().required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean(),
    image: Yup.mixed().required('Debes adjuntar una imagen')
  })
});

const handleSubmit = (values, { props }) => {
  const { image } = values.ourLeader;

  const paramsToSend = snakecaseKeys(
    {
      ourLeader: snakecaseKeys(
        { ...values.ourLeader },
        { exclude: ['_destroy'] }
      )
    },
    { exclude: ['_destroy'] }
  );

  if (image) paramsToSend.our_leader.image = image;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OurLeaderForm);
