import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Guides from '../Guides/Guides';
import Videos from '../Videos/Videos';
import Galleries from '../Galleries/Galleries';
import '../../assets/stylesheets/components/VideosAndGuides/VideosAndGuides.scss';
import { QueryFilter } from '../../components';
import GuidesSection from '../Home/Landing/GuidesSection';
import KnowMoreCards from '../../components/KnowMoreCards/KnowMoreCards';

const filterParams = {
  query: '',
  dateTo: '',
  dateFrom: ''
};

const VideosAndGuides = () => {
  const language = localStorage.getItem('language') || 'es';
  const { settings } = useSelector(state => state.utils);
  const location = useLocation();
  const { type } = location.state || {};
  const [show, setShow] = useState('guides');
  const customParamsRef = useRef({ ...filterParams });
  const [customParams, setCustomParams] = useState({
    ...customParamsRef.current
  });
  const [queryPlaceholder, setQueryPlaceholder] = useState(
    'Buscar por palabra'
  );

  const setInitialTab = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const showParam = urlParams.get('show');

    if (showParam === 'guides') {
      setShow(showParam);
    }

    if (showParam === 'videos') {
      setShow(showParam);
    }
    if (showParam === 'galleries') {
      setShow('galleries');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (language === 'en') setQueryPlaceholder('Search by keyword');
  }, []);

  useEffect(() => {
    if (type === 'guides') setShow('guides');
    if (type === 'videos') setShow('videos');
    if (type === 'galleries') setShow('galleries');
  }, [type]);

  useEffect(setInitialTab, []);

  return (
    <>
      <div className="container videosandguides-container mt-4">
        <div className="videosandguides-controls">
          <div className="videosandguides-selector">
            <Button
              aria-hidden="true"
              variant={`${show === 'guides' ? 'primary' : 'primary-outline'}`}
              onClick={() => {
                setShow('guides');
                setCustomParams(filterParams);
              }}
              className="videosandguides-btn mr-2"
            >
              E-books
            </Button>
            <Button
              aria-hidden="true"
              variant={`${show === 'videos' ? 'primary' : 'primary-outline'}`}
              onClick={() => {
                setShow('videos');
                setCustomParams(filterParams);
              }}
              className="videosandguides-btn mr-2"
            >
              Videos
            </Button>
            <Button
              aria-hidden="true"
              variant={`${
                show === 'galleries' ? 'primary' : 'primary-outline'
              }`}
              onClick={() => {
                setShow('galleries');
                setCustomParams(filterParams);
              }}
              className="videosandguides-btn"
            >
              {language === 'es' ? 'Galerías' : 'Galleries'}
            </Button>
          </div>
          <div style={{ minWidth: '300px' }}>
            <QueryFilter
              sidebar
              customParams={customParams}
              setCustomParams={setCustomParams}
              paramName="query"
              queryPlaceholder={queryPlaceholder}
            />
          </div>
        </div>
        {show === 'guides' && <Guides customParams={customParams} />}
        {show === 'videos' && <Videos customParams={customParams} />}
        {show === 'galleries' && <Galleries customParams={customParams} />}
        <KnowMoreCards />
      </div>
      <GuidesSection settings={settings} />
    </>
  );
};

export default VideosAndGuides;
