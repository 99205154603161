import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminOfficeRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/offices/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminOfficesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/offices${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminOfficeRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/admin/offices', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminOfficeRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/offices/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminOfficeRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/admin/offices/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminOfficesRequest = AwesomeDebouncePromise(
  indexAdminOfficesRequest,
  300
);
