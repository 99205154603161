import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import PageDataTable from './PageDataTable';
import LanguageButtons from '../../../components/Utils/Button/LanguageButtons';

import { debounceIndexAdminPagesRequest } from '../../../requests/admin/adminPages';
import useFetchData from '../../../hooks/useFetchData';

const PageIndex = () => {
  const [language, setLanguage] = useState('es');
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [customParams, setCustomParams] = useState({
    sort_column: 'updated_at',
    sort_direction: 'desc',
    language,
    category: 'web',
    display_length: 15
  });

  const {
    data: pages,
    totalData: totalPages,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest,
    error
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPagesRequest,
    customParams
  });

  const modelUrl = '/admin/pages';
  const clearCustomParams = () => {
    setCustomParams({ language, category: 'web', display_length: 15 });
  };
  const updateCustomParams = () => {
    setCustomParams({ language, category: 'web', display_length: 15 });
    setAllowFetchRecords(true);
  };
  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(updateCustomParams, [language]);
  useEffect(fetchNewRecords, [customParams]);

  // Handle the error condition
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Row id="index-pages">
        <Col className="">
          <LanguageButtons
            language={language}
            setLanguage={setLanguage}
            includeFrench
          />
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="pages-and-blogs-btn"
            block
            to="/admin/pages/templates"
          >
            Plantillas
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="pages-and-blogs-btn"
            block
            to="/admin/pages/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <PageDataTable
        tableData={pages}
        tableDataAmount={totalPages}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(PageIndex));
