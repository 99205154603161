import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import PrincipalNavbar from './PrincipalNavbar';
import indexNavbarRequest from '../../../requests/navbar';
import IcoMoon from '../Icon/IcoMoon';
import { uuidV4 } from '../../../services/utils';

const NavbarTop = ({ cargo = false }) => {
  const language = localStorage.getItem('language') || 'es';
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [btnLang, setBtnLang] = useState({
    esp: 'btn-active',
    ing: ''
  });

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const { t, i18n } = useTranslation();

  const setTranslation = () => {
    i18n.changeLanguage(language);
  };

  const fetchNavbarData = () => {
    const tourismType =
      localStorage.getItem('language') === 'en' ? 'navbarEnglish' : 'navbar';
    const barType = cargo ? 'navbarCargo' : tourismType;
    indexNavbarRequest({
      dispatch,
      params: {
        actives: true,
        bar_type: barType,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setData(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(setTranslation, []);
  useEffect(fetchNavbarData, []);

  const handleContactClick = () => {
    if (cargo) {
      history.push('/cargo/contact');
    } else {
      history.push('/contact');
    }
    setMenuOpen(false);
  };

  const handleNavimagButtonClick = () => {
    if (cargo) {
      history.push('/cargo/leads');
    } else {
      history.push('/cargo');
    }
    setMenuOpen(false);
  };

  const handleLanguageSelection = lang => {
    let languageVal = '';
    if (lang === 'esp') {
      setBtnLang({ esp: 'btn-active', ing: '' });
      localStorage.setItem('language', 'es');
      languageVal = 'es';
    } else if (lang === 'ing') {
      setBtnLang({ esp: '', ing: 'btn-active' });
      localStorage.setItem('language', 'en');
      languageVal = 'en';
    }
    window.location.href = `${window.location.href}?language=${languageVal}`;
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setBtnLang({
        esp: savedLanguage === 'es' ? 'btn-active' : '',
        ing: savedLanguage === 'en' ? 'btn-active' : ''
      });
    }
    if (cargo && savedLanguage !== 'es') {
      localStorage.setItem('language', 'es');
    }
  }, [language]);

  const isActiveLink = link => {
    return pathname === link;
  };

  const handleLinkClick = () => {
    if (menuOpen) {
      setMenuOpen(false);
    }
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const languageButtons = () => {
    return (
      !cargo && (
        <div className="align-self-end">
          <Button
            variant="dark"
            className={`btn-esp ${btnLang.esp}`}
            onClick={() => handleLanguageSelection('esp')}
          >
            ESP
          </Button>
          <Button
            variant="dark"
            className={`btn-ing ${btnLang.ing}`}
            onClick={() => handleLanguageSelection('ing')}
          >
            ENG
          </Button>
        </div>
      )
    );
  };

  return (
    <>
      <PrincipalNavbar cargo={cargo} toggleMenu={toggleMenu}>
        <Navbar.Collapse className="mb-2 mb-lg-0 main-navbar" in={menuOpen}>
          <div className="language-buttons-on-small-screeen">
            {languageButtons()}
          </div>
          <Nav className="mr-auto flex-wrap mb-3 mb-lg-0">
            {data?.map(category => {
              const activeClass = isActiveLink(category.link)
                ? 'navbar-link-active'
                : 'nav-link';

              if (category.category === 'GPS') {
                return (
                  <Nav.Link
                    key={uuidV4()}
                    href={category.link}
                    target="_blank"
                    className={activeClass}
                    onClick={handleLinkClick}
                  >
                    {category.category}
                  </Nav.Link>
                );
              }
              return (
                <Nav.Link
                  key={uuidV4()}
                  as={Link}
                  to={category.link}
                  className={activeClass}
                  onClick={handleLinkClick}
                >
                  {category.category}
                </Nav.Link>
              );
            })}
          </Nav>

          <div className="d-flex justify-content-center justify-content-lg-end flex-wrap btn-box-navbar">
            <Button
              className="btn-sm-outline-white mr-0"
              onClick={handleContactClick}
            >
              {t('navbar.contact')}
            </Button>
            <div
              aria-hidden
              className=" btn-navbar-white"
              onClick={handleNavimagButtonClick}
            >
              {cargo ? t('navbar.navimagCargo') : t('navbar.navimag')}
              <div className="box-icon-truck">
                <IcoMoon icon="truck" size={14} />
              </div>
            </div>
            {!cargo && (
              <div className="language-buttons">{languageButtons()}</div>
            )}
          </div>
        </Navbar.Collapse>
      </PrincipalNavbar>
    </>
  );
};

export default NavbarTop;
