import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import IcoMoon from '../Icon';

const PassengerSelector = ({ type, modelName, values, setFieldValue }) => {
  const { t } = useTranslation();
  const passengerOptions = {
    adults: {
      label: t('tourism.subHeader.adult'),
      description: t('tourism.subHeader.adultsRestrictions')
    },
    children: {
      label: t('tourism.subHeader.child'),
      description: t('tourism.subHeader.childrenRestrictions')
    },
    infants: {
      label: t('tourism.subHeader.infant'),
      description: t('tourism.subHeader.infantRestrictions')
    }
  };

  const option = passengerOptions[type];
  const [currentValue, setCurrentvalue] = useState(
    getIn(values, modelName) || 0
  );

  function addPassenger() {
    setCurrentvalue(prevValue => prevValue + 1);
    setFieldValue(modelName, currentValue + 1);
  }

  function substractPassanger() {
    if (currentValue <= 0) return;
    setCurrentvalue(prevValue => prevValue - 1);
    setFieldValue(modelName, currentValue - 1);
  }

  return (
    <div className="passenger-selector">
      <div className="passenger-info">
        <p className="passenger-type">{option.label}</p>
        <p className="passenger-description">{option.description}</p>
      </div>
      <div className="passenger-controls">
        <IcoMoon
          className="passenger-icon"
          color="white"
          icon="minus"
          size={20}
          onClick={() => substractPassanger()}
        />
        <p className="font-weight-bold">{currentValue}</p>
        <IcoMoon
          className="passenger-icon"
          color="white"
          icon="add"
          size={20}
          onClick={() => addPassenger()}
        />
      </div>
    </div>
  );
};

export default PassengerSelector;
