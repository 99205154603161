import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import FaqCategoryForm from './FaqCategoryForm';
import { createAdminFaqCategoryRequest } from '../../../requests/admin/adminFaqCategories';
import { sendAlert } from '../../../actions/utils';

const FaqNew = props => {
  const { location } = props;
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const [faqCategory, setFaq] = useState({
    category: '',
    language: '',
    position: 1,
    active: true
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    const initialPath = searchParams.get('type');
    history.push({
      pathname: `/admin/${initialPath}_faqs_and_categories`,
      state: { type: 'Categorías de FAQs' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Categoría creada con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _faqCategory => {
    createAdminFaqCategoryRequest({
      dispatch,
      params: _faqCategory,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-faq-categories" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear Categoría de FAQ</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <FaqCategoryForm
        faqCategory={faqCategory}
        action="new"
        formRequest={handleCreateRequest}
        categoryType={searchParams.get('type')}
      />
    </div>
  );
};

export default connect()(FaqNew);
