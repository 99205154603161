import React from 'react';
import memoize from 'memoize-one';
import ImagePreview from './ImagePreview';
import EditSetting from './EditSetting';

const columns = memoize(handleUpdateRequest => [
  {
    name: 'Nombre setting',
    cell: ({ label }) => <p className="label">{label}</p>,
    grow: '2'
  },
  {
    name: 'Valor setting',
    cell: ({ value, dataType, image, file }) => {
      const isFile = ['file', 'image'].includes(dataType);
      const attachmentUrl = image?.fileUrl || file?.fileUrl;

      return isFile ? (
        <ImagePreview imagePreview={attachmentUrl} />
      ) : (
        <p className="setting-value-text">
          {value.length > 50 ? `${value.slice(0, 180)}...` : value}
        </p>
      );
    },
    grow: '4'
  },
  {
    cell: setting => (
      <EditSetting
        handleUpdateRequest={handleUpdateRequest}
        setting={setting}
      />
    ),
    grow: '.5'
  }
]);

export default columns;
