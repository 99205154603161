import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { IconBtn } from '../Utils/Button';
import { capitalizeString } from '../../services/utils';

const DefaultHeader = ({
  children,
  className,
  headerBtn,
  headerBtnOptions,
  title
}) => (
  <Row className={`custom-header ${className}`}>
    {title && (
      <Col className="custom-header__title">
        <h5 className="text-color-primary-dark">{title}</h5>
      </Col>
    )}
    {headerBtn && (
      <Col md={6} lg={4} className="custom-header__button">
        <IconBtn
          variant={headerBtnOptions.variant}
          icon={headerBtnOptions.icon}
          to={headerBtnOptions.path}
          onClick={headerBtnOptions.onClick}
          disabled={headerBtnOptions.disabled}
          className="rounded-border"
        >
          {capitalizeString(headerBtnOptions.name)}
        </IconBtn>
      </Col>
    )}
    <Col xs={12}>{children}</Col>
  </Row>
);
const defaultBtnProps = {
  disabled: false,
  icon: '',
  name: 'Nuevo',
  path: '',
  variant: 'primary'
};

DefaultHeader.defaultProps = {
  className: '',
  headerBtnOptions: defaultBtnProps
};

export default DefaultHeader;
