import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import AdminNavbarDragAndDrop from './AdminNavbarDragAndDrop';

const AdminNavbarIndex = ({ cargo = false }) => {
  const [navbarType, setNavbarType] = useState(
    cargo ? 'navbarCargo' : 'navbar'
  );
  const [language, setLanguage] = useState('es');
  const onPressSetNavbarType = () => {
    if (!cargo) {
      const type = language === 'es' ? 'navbar' : 'navbarEnglish';
      setNavbarType(type);
    }
  };

  useEffect(onPressSetNavbarType, [language]);

  return (
    <Row id="index-admin-navbar">
      <Col>
        <AdminNavbarDragAndDrop
          barType={navbarType}
          language={language}
          setLanguage={setLanguage}
          cargo={cargo}
        />
      </Col>
    </Row>
  );
};

export default withRouter(AdminNavbarIndex);
