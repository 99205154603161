import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../../services/apiService';

export const showAdminFaqRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/admin/faqs/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAdminFaqsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/admin/faqs${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const createAdminFaqRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/admin/faqs', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export const updateAdminFaqRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request('put', `/admin/faqs/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const deleteAdminFaqRequest = (
  id,
  { dispatch, failureCallback, successCallback }
) =>
  ApiService.request('delete', `/admin/faqs/${id}`, {
    dispatch,
    failureCallback,
    successCallback
  });

export const debounceIndexAdminFaqsRequest = AwesomeDebouncePromise(
  indexAdminFaqsRequest,
  300
);
