import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import BlogForm from './BlogForm';
import basicBlog from './basicBlog';

import {
  showAdminPageRequest,
  updateAdminPageRequest
} from '../../../requests/admin/adminPages';
import { sendAlert } from '../../../actions/utils';

const BlogEdit = props => {
  const { history } = props;
  const [blog, setBlog] = useState(basicBlog);
  const dispatch = useDispatch();

  const handleFailureRequest = error => {
    const errorMessage =
      error?.response?.data?.errors?.url[0] || 'Error al realizar la solicitud';
    dispatch(sendAlert({ kind: 'error', message: errorMessage }));
  };

  const handleSuccessShow = response => {
    const _blog = camelCaseRecursive(response.data);
    setBlog(_blog);
  };

  useEffect(() => {
    const { match } = props;
    const pageId = match.params.id;
    showAdminPageRequest(pageId, {
      dispatch,
      successCallback: handleSuccessShow
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: '/admin/pages_and_blogs',
      state: { type: 'Blogs' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Blog actualizado con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _blog => {
    const pageId = _blog.page.id;
    updateAdminPageRequest(pageId, {
      dispatch,
      params: _blog,
      formData: true,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureRequest
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-blogs">
        <Col>
          <h4>Editar Blog</h4>
        </Col>
        <Col md={2}>
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <BlogForm blog={blog} action="edit" formRequest={handleUpdateRequest} />
    </div>
  );
};

export default connect()(BlogEdit);
