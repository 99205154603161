import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Card, Spinner, Row, Col, Container } from 'react-bootstrap';
import axios from 'axios';

import { uuidV4 } from '../../services/utils';
import { indexRoutesRequest } from '../../requests/routes';
import { indexSlidesRequest } from '../../requests/slides';
import CarouselComponent from '../Utils/Carousel/Carousel';
import star from '../../assets/images/tourism/our-ferries/star.svg';
import bedSolid from '../../assets/images/tourism/our-ferries/bed-solid.svg';
import showerSolid from '../../assets/images/tourism/our-ferries/shower-solid.svg';
import window from '../../assets/images/tourism/our-ferries/window.svg';

const AvailableFacilities = ({ isDesktop = true }) => {
  const { t, i18n } = useTranslation();
  const [selectedRoute, setSelectedRoute] = useState(undefined);
  const [loading, setIsLoading] = useState(undefined);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const [slides, setSlides] = useState([]);

  const fetchSlides = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        slide_type: 'our_ferries_images'
      },
      successCallback: result => {
        const updatedSlides = camelCaseRecursive(result.data.data).map(
          slide => ({
            ...slide,
            img: slide.fileInfo.fileUrl
          })
        );
        setSlides(updatedSlides);
      }
    });
  };

  useEffect(fetchSlides, []);

  const routeRequestWrapper = () => {
    setIsLoading(true);
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'both',
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token,
        language
      },
      successCallback: result => {
        setSelectedRoute(camelCaseRecursive(result.data.data)[0]);
        setIsLoading(false);
      }
    });
    return () => cancelToken.cancel();
  };

  useEffect(routeRequestWrapper, []);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  const facilitiesHeader = () => (
    <div className="facilities-header">
      <div className="facilities-header-item">
        <img src={star} alt="Star" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.ourFerries.availableFacilities.cabin')}
        </p>
      </div>
      <div className="facilities-header-item">
        <img src={bedSolid} alt="bedSolid" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.ourFerries.availableFacilities.bed')}
        </p>
      </div>
      <div className="facilities-header-item">
        <img src={window} alt="windows" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.ourFerries.availableFacilities.windows')}
        </p>
      </div>
      <div className="facilities-header-item">
        <img src={showerSolid} alt="showerSolid" />
        <p className="mt-1 font-weight-bold">
          {t('tourism.ourFerries.availableFacilities.toilet')}
        </p>
      </div>
    </div>
  );

  const facilitiesRowItem = (tourismRate, style) => (
    <div className="facilities-row mb-2" key={uuidV4()}>
      <div
        className={`facilities-row-item facilities-border-radius-left ${style} `}
      >
        <p>{tourismRate.roomType}</p>
      </div>
      <div className={`facilities-row-item ${style}`}>
        <p>{tourismRate.bedDescription}</p>
      </div>
      <div className={`facilities-row-item ${style}`}>
        <p>
          {tourismRate.hasWindows
            ? t('tourism.ourFerries.common.yes')
            : t('tourism.ourFerries.common.no')}
        </p>
      </div>
      <div
        className={`facilities-row-item facilities-border-radius-right ${style}`}
      >
        <p>{language === 'es' ? `Privado` : 'Private'}</p>
      </div>
    </div>
  );

  return (
    <Container>
      <div className="my-4 facilities-content-box">
        <h3 className="text-center text-primary">
          {t('tourism.ourFerries.availableFacilities.title')}
        </h3>
        <p className="text-center mt-3 mb-4">
          {t('tourism.ourFerries.availableFacilities.description')}
        </p>
        {loading && (
          <div
            className="w-100 d-flex justify-content-center align-items-center"
            style={{ minHeight: '460px' }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!loading && (
          <Row className="justify-content-center mb-4">
            <Col xs={12} sm={12} md={12} lg={5}>
              <CarouselComponent
                noArrow
                className="carousel-facilities"
                classNameImg="img-facilities-section"
                data={slides}
              />
            </Col>
            <Col
              md={isDesktop ? 7 : 12}
              className="d-flex flex-column justify-content-center col-right"
            >
              <div className="facilities-div">
                <Card className="p-4 mt-4">
                  {facilitiesHeader()}
                  {selectedRoute?.tourismRatesAttributes?.map(
                    (tourismRate, index) => {
                      const style =
                        index % 2
                          ? 'facilities-row-item-light-red '
                          : 'facilities-row-item-red';
                      return facilitiesRowItem(tourismRate, style);
                    }
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default AvailableFacilities;
