import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { getIn } from 'formik';
import { FormikDatePicker, UploadImage, IconBtn } from '../../../components';

import fieldName from './adminSettingFieldName';
import GuideSelector from './GuideSelector';
import PageSelector from './PageSelector';

const AdminSettingForm = ({
  setting,
  handleCloseModal,
  handleUpdateRequest
}) => {
  const { label, dataType, value, id, code } = setting;
  const [dateField, setDateField] = useState('');
  const isFile = ['file', 'image'].includes(dataType);
  const isDate = ['date'].includes(dataType);
  const isGuideSelector = ['principal_guide_en', 'principal_guide_es'].includes(
    code
  );
  const isPageSelector = ['cargo_rates_page'].includes(code);
  const inputName = fieldName[dataType];
  const [fieldValue, setFieldValue] = useState(value);

  const handleSubmit = () => {
    const sendParams = {
      setting: {
        [inputName]: fieldValue
      }
    };
    handleUpdateRequest(id, sendParams);
  };

  const handleChange = e => {
    setFieldValue(e.target.value);
  };

  const handleSelectGuide = guide => setFieldValue(guide);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 setting-update-form">
      <h5 className="text-primary mb-2">Editar setting</h5>
      {!(isFile || isDate) && !isGuideSelector && !isPageSelector && (
        <Form.Group className="form-input" controlId={inputName}>
          <Form.Label className="setting-update-form-label">
            {label} <abbr className="text-danger">*</abbr>
          </Form.Label>
          <Form.Control
            as="textarea"
            value={fieldValue}
            onChange={handleChange}
            className="setting-update-form-textarea"
          />
        </Form.Group>
      )}
      {isFile && (
        <UploadImage
          isImage={dataType === 'image'}
          label={label}
          name="setting[image]"
          imageUrl={getIn(setting.image, 'fileUrl')}
          fileName={getIn(setting.image, 'filename')}
          onChange={data => setFieldValue(data)}
        />
      )}
      {dataType === 'date' && (
        <FormikDatePicker
          selected={dateField}
          autoFocus
          showYearDropdown
          onChange={date => {
            setDateField(date);
            setFieldValue(date);
          }}
        />
      )}
      {isGuideSelector && (
        <GuideSelector
          label={label}
          value={value}
          handleSelectGuide={handleSelectGuide}
        />
      )}
      {isPageSelector && (
        <PageSelector
          label={label}
          value={value}
          handleSelectGuide={handleSelectGuide}
          ß
        />
      )}
      <div className="w-100 d-flex justify-content-center align-items-center">
        <span className="link-menu float-right px-1">
          <IconBtn
            icon="checkmark-circle"
            iconSize="xs"
            className="btn no-shadow btn-success"
            onClick={handleSubmit}
          />
        </span>
        <span className="link-menu float-right px-1">
          <IconBtn
            icon="close"
            iconSize="xs"
            className="btn no-shadow"
            type="button"
            onClick={() => handleCloseModal()}
          />
        </span>
      </div>
    </div>
  );
};

export default AdminSettingForm;
