import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';

import useFetchData from '../../../hooks/useFetchData';
import {
  debounceIndexAdminNavbarRequest,
  indexAdminNavbarRootCategoriesRequest
} from '../../../requests/admin/navbar';
import { FormikInput, FormikCheckBox, FormikSelect } from '../../../components';
import { languages } from '../../../services/utils';

const AdminNavbarForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    navbar,
    isRoot,
    barType
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Guardar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultCategory, setDefaultCategory] = useState(undefined);
  const [rootCategories, setRootCategories] = useState([]);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  const fetchRootCategories = () => {
    indexAdminNavbarRootCategoriesRequest({
      dispatch,
      params: {
        bar_type: barType,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setRootCategories(camelCaseRecursive(result.data.data));
      }
    });
  };

  const { data: navbars } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminNavbarRequest
  });

  const findDefaultCategory = () => {
    setDefaultCategory(
      rootCategories.find(
        rootCategory => rootCategory.value === navbar?.parentId
      )
    );
  };

  useEffect(findDefaultCategory, [navbars, rootCategories]);
  useEffect(fetchRootCategories, []);
  return (
    <Form>
      <Field name="navbar[category]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikInput
              {...field}
              abbr
              inputType="text"
              label="Nombre"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
            />
          );
        }}
      </Field>

      <Field name="navbar[language]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikSelect
              {...field}
              abbr
              label="Idioma"
              placeholder="Seleccionar Idioma"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
              options={Object.entries(languages).map(([value, label]) => ({
                value,
                label
              }))}
              onChange={selectedOption => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : '';
                setFieldValue(name, selectedValue);
              }}
              defaultValue={field.value}
            />
          );
        }}
      </Field>

      <Field name="navbar[link]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikInput
              {...field}
              inputType="text"
              label="Link"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
            />
          );
        }}
      </Field>

      <Field name="navbar[active]">
        {({ field }) => (
          <FormikCheckBox {...field} field={field} label="Activo" custom />
        )}
      </Field>

      {!isRoot && (
        <Field name="navbar[parentId]">
          {({ field }) => {
            const { name } = field;
            return (
              <FormikSelect
                {...field}
                abbr
                inputType="text"
                label="Categoría"
                error={getIn(errors, name)}
                touched={getIn(touched, name)}
                options={rootCategories}
                defaultValue={defaultCategory?.value}
                onChange={rootCategory => {
                  setFieldValue('navbar', {
                    ...values.navbar,
                    parentId: rootCategory.value
                  });
                }}
              />
            );
          }}
        </Field>
      )}
      <Row className="d-flex justify-content-end mb-3">
        <Col md={4}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { barType, language } = props;
  const { id, category, link, parentId, position, active } = props.navbar;
  return {
    navbar: {
      id,
      category,
      language,
      link,
      parentId: id ? parentId : null,
      position,
      active,
      barType
    },
    languageTranslate: languages[language]
  };
};

const validationSchema = Yup.object().shape({
  navbar: Yup.object().shape({
    category: Yup.string().required('Requerido'),
    parentId: Yup.number()
      .typeError('Debes escoger una categoría')
      .required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean()
  })
});

const RootValidationSchema = Yup.object().shape({
  navbar: Yup.object().shape({
    category: Yup.string().required('Requerido'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = snakecaseKeys({
    navbar: values.navbar
  });
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema: props =>
    props.isRoot ? RootValidationSchema : validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(AdminNavbarForm);
