import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, getIn, useFormikContext } from 'formik';
import { FormikInput, FormikCheckBox } from '../Utils/Input';
import { tourismShipDirectionOptions } from '../../services/utils';

const DaysItineraryDescriptionsForm = ({
  forNestedAttributes,
  index,
  parentModel,
  shipDirection,
  dayNumber
}) => {
  const modelName = `${parentModel}[daysItineraryDescriptionsAttributes][${index}]`;
  const { errors, touched } = useFormikContext();

  return (
    <Col className="nested-row-form-container">
      <Row>
        <Col className="d-flex justify-content-center title-label-container">
          {forNestedAttributes && (
            <p className="title-label">{`Descripción del dia ${dayNumber} - Dirección ${tourismShipDirectionOptions[shipDirection]}`}</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col className="details-label-container">
          <p className="details-label">Detalles del dia</p>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col md={8}>
          <Field name={`${modelName}[mainTitle]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Título"
                error={getIn(errors, `${modelName}[mainTitle]`)}
                touched={getIn(touched, `${modelName}[mainTitle]`)}
              />
            )}
          </Field>
        </Col>
        <Col md={2}>
          <Field name={`${modelName}[position]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="Día"
                error={getIn(errors, `${modelName}[position]`)}
                touched={getIn(touched, `${modelName}[position]`)}
              />
            )}
          </Field>
        </Col>
        <Col md={2} className="d-flex justify-content-center pt-2">
          <Field name={`${modelName}[active]`}>
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name={`${modelName}[description]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="textarea"
                isTextArea
                label="Comentario"
                error={getIn(errors, `${modelName}[description]`)}
                touched={getIn(touched, `${modelName}[description]`)}
                rows={5}
              />
            )}
          </Field>
        </Col>
      </Row>
    </Col>
  );
};

export default DaysItineraryDescriptionsForm;
