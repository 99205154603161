import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';

import GuidesGrid from '../../components/Guides/GuidesGrid';
import SEO from '../../components/Utils/SEO/SEO';

const Guides = ({ customParams }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  return (
    <>
      <SEO
        title={t('tourism.guidesSection.seo.title')}
        description={t('tourism.guidesSection.seo.description')}
      />
      <Row className="justify-content-center d-flex bottom-animation">
        <Col className="title_text mt-4">
          <h4 className="text-left" style={{ paddingBottom: '5px' }}>
            {t('tourism.guidesSection.body.sectionTitle')}
          </h4>
          <p className="text-left mb-4">
            {t('tourism.guidesSection.body.sectionSubTitle')}
          </p>
          <GuidesGrid numOfGuides={10} customParams={customParams} />
        </Col>
      </Row>
    </>
  );
};

export default Guides;
