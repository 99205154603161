import React, { useState } from 'react';
import IcoMoon from '../../components/Utils/Icon/IcoMoon';

const QuestionCell = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const formatTextWithParagraphs = text => {
    const paragraphs = text.split('\\n\\n');
    return paragraphs.map(paragraph => (
      <p className="mb-2" style={{ color: 'gray' }} key={`${paragraph}`}>
        {paragraph}
      </p>
    ));
  };

  return (
    <div className="question-and-answer-cell">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <p
          className={`question-and-answer-cell-question ${
            showAnswer ? 'text-primary' : ''
          }`}
        >
          {question}
        </p>
        <IcoMoon
          onClick={() => setShowAnswer(!showAnswer)}
          icon="add"
          size={30}
          className={`${
            showAnswer ? 'answer-show-btn-active' : 'answer-show-btn'
          }`}
        />
      </div>
      <div className={`${showAnswer ? 'answer-show' : 'answer-hide'}`}>
        {formatTextWithParagraphs(answer)}
      </div>
    </div>
  );
};

export default QuestionCell;
