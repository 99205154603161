/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { clarity } from 'react-microsoft-clarity';

import store from './app/config/store';
import App from './App';
import { AbilityContext } from './app/config/abilityContext';
import ability from './app/config/ability';
import './index.scss';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_KEY
};

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
  clarity.init(process.env.REACT_APP_CLARITY_ID);
}

const app = (
  <Provider store={store}>
    <AbilityContext.Provider value={ability}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </AbilityContext.Provider>
  </Provider>
);
ReactDOM.render(app, document.getElementById('root'));
