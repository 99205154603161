import React, { useState, useEffect } from 'react';
// import camelCaseRecursive from 'camelcase-keys-recursive';
import { useDispatch } from 'react-redux';

import { FormikSelect } from '../../../components';
import { indexPagesRequest } from '../../../requests/pages';

const PageSelector = ({ value, label, handleSelectGuide }) => {
  const dispatch = useDispatch();
  const [pages, setPages] = useState([]);
  const [selectedValue, setSelectedValue] = useState(value);
  const pagesRequest = () => {
    indexPagesRequest({
      dispatch,
      params: {
        sort_column: 'updated_at',
        sort_direction: 'desc',
        category: 'web',
        active: 'true'
      },
      successCallback: result => {
        const transformedPages = result.data.data.map(obj => ({
          label: obj.title,
          value: obj.title
        }));
        setPages(transformedPages);
      }
    });
  };

  const handleNewSelection = newSelection => {
    setSelectedValue(newSelection);
    handleSelectGuide(newSelection);
  };

  useEffect(pagesRequest, [label]);

  return (
    <div className="w-100">
      <FormikSelect
        label={label}
        placeholder="Seleccionar pagina"
        options={pages}
        defaultValue={selectedValue}
        value={selectedValue}
        onChange={data => handleNewSelection(data?.value)}
        clas
      />
    </div>
  );
};

export default PageSelector;
