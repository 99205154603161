import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useTranslation } from 'react-i18next';

import indexReviewsRequest from '../../../requests/reviews';
import { ReviewCard } from '../../../components/Utils/Cards';

const FouthSection = () => {
  const [reviews, setReviews] = useState([]);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') === 'en' ? 'en' : 'es';
  const { t } = useTranslation();

  const reviewRequestWrapper = () => {
    indexReviewsRequest({
      dispatch,
      params: {
        actives: true,
        cancelToken: cancelToken.token,
        sort_direction: 'asc',
        sort_column: 'position',
        language
      },
      successCallback: result => {
        setReviews(camelCaseRecursive(result.data.data));
      }
    });
    return () => cancelToken.cancel();
  };
  useEffect(reviewRequestWrapper, []);

  return (
    <Col className="container_reviews">
      <Row className="reviews_text mb-4">
        <Col className="text-center">
          <h4 className="text-primary">{t('tourism.landing.reviews.title')}</h4>
        </Col>
      </Row>
      <Row className="m-auto" style={{ width: '100%' }}>
        {reviews.map(review => (
          <Col key={review.id}>
            <div className="carousel-review">
              <ReviewCard reviewData={review} />
            </div>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default FouthSection;
