import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import VideosGrid from '../../components/Videos/VideosGrid';
import SEO from '../../components/Utils/SEO/SEO';

const Videos = ({ customParams }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
  }, []);

  return (
    <>
      <SEO
        title={t('tourism.videosSection.seo.title')}
        description={t('tourism.videosSection.seo.description')}
      />
      <Row className="opacity-animation">
        <Col className="title_text mt-4">
          <h4 className="text-left" style={{ paddingBottom: '5px' }}>
            {t('tourism.videosSection.body.sectionTitle')}
          </h4>
          <p className="text-left mb-4">
            {t('tourism.videosSection.body.sectionSubTitle')}
          </p>
          <VideosGrid customParams={customParams} />
        </Col>
      </Row>
    </>
  );
};

export default Videos;
