/* eslint-disable dot-notation */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

import { deleteAdminGalleryRequest } from '../../../requests/admin/adminGalleries';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false
    // showClick = false,
    // editClick = false
  }) => [
    {
      name: 'Posición',
      selector: row => row.position,
      sortable: true,
      cell: ({ position }) => position,
      grow: '.5'
    },
    {
      name: 'Nombre español',
      selector: row => row.nameEsp,
      sortable: true,
      cell: ({ nameEsp }) => nameEsp,
      grow: '1.5'
    },
    {
      name: 'Nombre inglés',
      selector: row => row.nameEng,
      sortable: true,
      cell: ({ nameEng }) => nameEng,
      grow: '1.5'
    },
    {
      name: 'Activo',
      selector: row => row.active,
      grow: '.5',
      sortable: true,
      cell: ({ active }) => (active ? 'Sí' : 'No')
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const GalleriesDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminGalleryRequest,
    onDeleteMessage: 'Galería eliminada exitosamente',
    confirmationText: '¿Estás seguro de eliminar esta galería?',
    modalTitle: 'Eliminar Galería'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'position':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase}
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        RowsPage={10}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(GalleriesDataTable));
