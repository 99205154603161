import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { useWindowSize } from '../../../hooks';
import { CenteredModal, FormBookTrip } from '../../../components';
import { redirectToReservation } from '../../../services/utils';
import FirstSection from '../Landing/FirstSection';
import Rates from '../../../components/Cargo/CargoRates/Rates';
import { indexPagesRequest } from '../../../requests/pages';

import carIcon from '../../../assets/images/car-black-icon.svg';
import personIcon from '../../../assets/images/person.svg';
import SEO from '../../../components/Utils/SEO/SEO';

const bookTrip = {
  route: '',
  month: '',
  year: '',
  adults: 0,
  children: 0,
  infants: 0
};

const CargoRates = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { windowSize } = useWindowSize();
  const { i18n } = useTranslation();
  const { settings } = useSelector(state => state.utils);
  const dispatch = useDispatch();

  const [cargoRatePage, setCargoRataPage] = useState({});
  const [cargoRateNatalesPage, setCargoRateNatalesPage] = useState({});
  const [cargoRateChacabucoPage, setCargoRateChacabucoPage] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cargoRateNatalesPageRequest = () => {
    indexPagesRequest({
      dispatch,
      params: {
        active: true,
        title_search: settings.cargoRatesNatalesPage
      },
      successCallback: result => {
        setCargoRateNatalesPage(camelCaseRecursive(result.data.data[0]));
        setCargoRataPage(camelCaseRecursive(result.data.data[0]));
      }
    });
  };

  const cargoRateChacabucoPageRequest = () => {
    indexPagesRequest({
      dispatch,
      params: {
        active: true,
        title_search: settings.cargoRatesChacabucoPage
      },
      successCallback: result => {
        setCargoRateChacabucoPage(camelCaseRecursive(result.data.data[0]));
      }
    });
  };

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  useEffect(cargoRateNatalesPageRequest, [settings]);
  useEffect(cargoRateChacabucoPageRequest, [settings]);

  const handleCloseModal = () => setShowModal(false);
  console.log(isDesktop);

  return (
    <>
      <SEO
        title="Navimag Carga - Tarifas"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <div className="w-100" style={{ overflow: 'hidden' }}>
        <FirstSection
          title="Tarifas"
          body={<LandingButtons />}
          slideType="cargo_rates"
        />
      </div>
      <Rates
        cargoRateNatalesPage={cargoRateNatalesPage}
        cargoRateChacabucoPage={cargoRateChacabucoPage}
        setCargoRataPage={setCargoRataPage}
      />
      {cargoRatePage && (
        <Row className="align-items-center justify-content-center mb-4">
          <Col xs={10} md={8}>
            <div dangerouslySetInnerHTML={{ __html: cargoRatePage.body }} />
          </Col>
        </Row>
      )}
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

export default CargoRates;

const LandingButtons = () => {
  const history = useHistory();

  const cargoLeadRedirect = () => {
    history.push(`/cargo/leads`);
  };
  const contactRedirect = () => {
    history.push('/cargo/contact');
  };
  return (
    <Row className="align-items-center justify-content-center mt-5">
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          onClick={contactRedirect}
          type="submit"
          variant="primary"
          className="p-3 w-75"
        >
          Contacta a un asesor
          <Image src={personIcon} alt="icono person" className="ml-2" />
        </Button>
      </Col>
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          type="submit"
          variant="light"
          className="p-3 w-75"
          style={{ borderRadius: '10px', color: '$primary' }}
          onClick={cargoLeadRedirect}
        >
          Cotiza tu carga
          <Image
            src={carIcon}
            alt="icono auto"
            className="ml-2"
            style={{ fill: 'red !important' }}
          />
        </Button>
      </Col>
    </Row>
  );
};
