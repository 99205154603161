import React from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import {
  FormikInput,
  FormikCheckBox,
  UploadImage,
  FormikSelect
} from '../../components';
import slideOptions, {
  slideTourismHeroOptions,
  slideCargoHeroOptions,
  slideOurFerriesCategory,
  slideTourismOptions
} from './slideOptions';

const slideOptionsElements = {
  all: slideOptions,
  tourism_hero: slideTourismHeroOptions,
  cargo_hero: slideCargoHeroOptions,
  tourism_slide: slideTourismOptions,
  our_ferries_category: slideOurFerriesCategory
};

const SlideForm = props => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const { onHide, submitVariant, errors, touched, action, slide } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const urlParams = new URLSearchParams(window.location.search);
  const categoryType = urlParams.get('slideCategoryType');
  const showHeroElements = ['cargo_hero', 'tourism_hero'].includes(
    categoryType
  );
  const showEnglishElements = categoryType === 'tourism_hero';

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <Field name="slide[image]">
            {({ field }) => {
              const { name } = field;
              const { fileInfo } = slide;
              return (
                <UploadImage
                  {...field}
                  modelType="slide"
                  accept="image/jpg, image/png, image/jpeg"
                  setFieldValue={setFieldValue}
                  imageUrl={getIn(fileInfo, 'fileUrl')}
                  fileName={getIn(fileInfo, 'filename')}
                  onChange={_avatar => setFieldValue(name, _avatar)}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Field name="slide[slideType]">
            {({ field }) => (
              <FormikSelect
                {...field}
                abbr
                label="Tipo de Slide"
                placeholder="Seleccionar tipo de Slide"
                options={slideOptionsElements[categoryType]}
                defaultValue={slide.slideType}
                onChange={data =>
                  setFieldValue('slide[slideType]', data ? data.value : '')
                }
                setFieldTouched={() => setFieldTouched('slide[slideType]')}
                isSearchable={false}
                error={getIn(errors, 'slide[slideType]')}
                touched={getIn(touched, 'slide[slideType]')}
              />
            )}
          </Field>
          {showHeroElements && (
            <>
              <Field name="slide[heroTitleEs]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      inputType="text"
                      label="Título Hero Español"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
              {showEnglishElements && (
                <Field name="slide[heroTitleEn]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        inputType="text"
                        label="Título Hero Inglés"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              )}
              <Field name="slide[heroSubtitleEs]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      inputType="text"
                      label="Subtítulo Hero Español"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
              {showEnglishElements && (
                <Field name="slide[heroSubtitleEn]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        inputType="text"
                        label="Subtítulo Hero Inglés"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              )}
              <Field name="slide[heroButtonTextEs]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      inputType="text"
                      label="Texto botón Hero Español"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
              {showEnglishElements && (
                <Field name="slide[heroButtonTextEn]">
                  {({ field }) => {
                    const { name } = field;
                    return (
                      <FormikInput
                        {...field}
                        inputType="text"
                        label="Texto botón Hero Inglés"
                        error={getIn(errors, name)}
                        touched={getIn(touched, name)}
                      />
                    );
                  }}
                </Field>
              )}
            </>
          )}
          <Field name="slide[position]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="number"
                  label="Posición"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="slide[caption]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  inputType="text"
                  label="Caption"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>
          <Field name="slide[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    fileInfo,
    position,
    caption,
    active,
    slideType,
    heroTitleEs,
    heroTitleEn,
    heroSubtitleEs,
    heroSubtitleEn,
    heroButtonTextEs,
    heroButtonTextEn,
    slideCategoryType
  } = props.slide;
  return {
    slide: {
      id,
      image: getIn(fileInfo, 'fileSignature'),
      position,
      caption,
      active,
      slideType,
      heroTitleEs,
      heroTitleEn,
      heroSubtitleEs,
      heroSubtitleEn,
      heroButtonTextEs,
      heroButtonTextEn,
      slideCategoryType
    }
  };
};

const validationSchema = Yup.object().shape({
  slide: Yup.object().shape({
    image: Yup.mixed().required('Debes adjuntar una imagen'),
    position: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('Requerido'),
    caption: Yup.string(),
    slideType: Yup.string().required('Debes seleccionar un tipo de Slide'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const slideCategoryType = urlParams.get('slideCategoryType');

  const { image } = values.slide;
  const paramsToSend = {
    slide: snakecaseKeys({ ...values.slide, slideCategoryType })
  };
  if (image) paramsToSend.slide.image = image;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(SlideForm);
