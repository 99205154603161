import React from 'react';

import { uuidV4 } from '../../../services/utils';

const CategoryFilter = ({
  categories,
  setSelectedCategory,
  selectedCategory,
  setTextInput
}) => {
  return (
    <div className="faqs-category-selector-container">
      {categories?.map(category => (
        <div
          key={uuidV4()}
          aria-hidden="true"
          onClick={() => {
            setSelectedCategory(category.category);
            setTextInput('');
          }}
          className={`category-selector-box ${
            selectedCategory === category.category
              ? 'category-selector-box-active'
              : 'category-selector-hover'
          }`}
        >
          <p
            className={`category-selector-box-text ${
              selectedCategory === category.category ? 'text-light' : ''
            }`}
          >
            {category.category}
          </p>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
