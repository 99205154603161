import React, { useEffect, useState } from 'react';
import { Container, Col, Spinner, Row, Card, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexPagesRequest } from '../../requests/pages';
import '../../assets/stylesheets/components/templates/TemplatesGrid.scss';
import defaultImage from '../../assets/images/default.png';

const TemplatesGrid = props => {
  const { category } = props || 'pages';
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTemplates = () => {
    setIsLoading(true);
    indexPagesRequest({
      dispatch,
      params: {
        sort_column: 'updated_at',
        sort_direction: 'desc',
        category: 'template'
      },
      successCallback: result => {
        setTemplates(camelCaseRecursive(result.data.data));
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleUseTemplate = templateId => {
    const cloneUrl = `${window.location.origin}/admin/${category}/new?cloneId=${templateId}`;
    window.open(cloneUrl, '_blank');
  };

  return (
    <>
      {isLoading && (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isLoading && (
        <Container className="templates-grid-container">
          <Row>
            {templates.map(template => (
              <Col key={template.id} xs={12} sm={6} md={4} className="p-3">
                <Card className="template-card">
                  <div className="template-image-container">
                    <Card.Img
                      variant="top"
                      src={template.image?.fileUrl || defaultImage}
                      alt={template.title}
                      className="template-image"
                    />
                  </div>
                  <Card.Body className="template-card-body">
                    <Button
                      variant="primary"
                      onClick={() => handleUseTemplate(template.id)}
                    >
                      Usar Plantilla
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default TemplatesGrid;
