import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { IconBtn } from '../Button';
import Subcategory from './subcategory';

const backgroundColor = props => {
  if (props.isDraggingOver) {
    return 'skyblue';
  }
  return props.isActive ? 'white-card' : 'lightgoldenrodyellow';
};

const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: ${props =>
    props.isActive ? 'white-card' : 'lightgoldenrodyellow'};
  width: 300px;

  display: flex;
  flex-direction: column;
`;
const Title = styled.h3`
  padding: 8px;
`;
const SubcategoryList = styled.div`
  padding: 8px;
  border-radius: 10px;
  transition: background-color 0.2s ease;
  background-color: ${props => backgroundColor(props)};
  flex-grow: 1;
  min-height: 100px;
`;

const Column = props => {
  const {
    column,
    subcategories,
    index,
    isDropDisabled,
    setEditCategoryData,
    setOpenEditModal,
    setDeleteCategoryId,
    setOpenDeleteModal,
    setIsRoot
  } = props;

  return (
    <Draggable draggableId={column.categoryId} index={index}>
      {provided => (
        <Container
          id={column.categoryId}
          {...provided.draggableProps}
          ref={provided.innerRef}
          isActive={column.active}
        >
          <Row className="admin-navbar-card-header">
            <Col className="justify-content-left pl-0 col-6">
              <Title {...provided.dragHandleProps}>{column.category}</Title>
            </Col>

            <Row className="justify-content-center">
              <Col className="col-2">
                <IconBtn
                  icon="trash"
                  iconSize="xs"
                  className="btn delete-btn no-shadow"
                  onClick={() => {
                    setDeleteCategoryId(column.id);
                    setIsRoot(true);
                    setOpenDeleteModal(true);
                  }}
                />
              </Col>
              <Col className="col-2">
                <IconBtn
                  icon="pencil"
                  iconSize="xs"
                  className="btn edit-btn no-shadow"
                  onClick={() => {
                    setEditCategoryData(column);
                    setIsRoot(true);
                    setOpenEditModal(true);
                  }}
                />
              </Col>
            </Row>
          </Row>

          <Droppable
            droppableId={column.categoryId}
            type="subcategorie"
            // does not allow to move subcategories to the column
            // type={column.categoryId === "column-3" ? "done" : "active"}
            isDropDisabled={isDropDisabled}
          >
            {(_provided, snapshot) => (
              <SubcategoryList
                ref={_provided.innerRef}
                {..._provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
                isActive={column.active}
              >
                {subcategories.map((subcategory, _index) => (
                  <Subcategory
                    key={subcategory.categoryId}
                    subcategory={subcategory}
                    index={_index}
                    setEditCategoryData={setEditCategoryData}
                    setOpenEditModal={setOpenEditModal}
                    setDeleteCategoryId={setDeleteCategoryId}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setIsRoot={setIsRoot}
                  />
                ))}
                {_provided.placeholder}
              </SubcategoryList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
