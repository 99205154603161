import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import { useWindowSize } from '../../../hooks';
import { CenteredModal, FormBookTrip } from '../../../components';
import { redirectToReservation, uuidV4 } from '../../../services/utils';
import FirstSection from '../Landing/FirstSection';

import carIcon from '../../../assets/images/car-black-icon.svg';
import personIcon from '../../../assets/images/person.svg';
import SEO from '../../../components/Utils/SEO/SEO';
import Itineraries from '../../../components/Cargo/CargoItineraries/Itineraries';

const bookTrip = {
  route: '',
  month: '',
  year: '',
  adults: '',
  children: '',
  infants: ''
};

const CargoItineraries = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const { windowSize } = useWindowSize();
  const { i18n } = useTranslation();
  const { settings } = useSelector(state => state.utils);

  const [cargoItineraries, setCargoItineraries] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const buildCargoItineraries = () => {
    const today = new Date();
    const natalesRouteData = [];
    const chacabucoRouteData = [];
    const originalData = camelCaseRecursive(
      JSON.parse(settings.availableCargoItineraries)
    );

    originalData.forEach(route => {
      const routeDate = new Date(route.fechaZarpe);
      if (
        route.routeDestination.includes('Puerto Natales') &&
        routeDate > today
      ) {
        natalesRouteData.push(route);
      }
      if (
        route.routeDestination.includes('Puerto Chacabuco') &&
        routeDate > today
      ) {
        chacabucoRouteData.push(route);
      }
    });

    setCargoItineraries([
      {
        id: uuidV4(),
        routeName: 'Pto. Montt - Pto. Natales',
        itineraryData: natalesRouteData
      },
      {
        id: uuidV4(),
        routeName: 'Pto. Montt - Pto. Chacabuco',
        itineraryData: chacabucoRouteData
      }
    ]);
  };

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  useEffect(buildCargoItineraries, [settings]);

  useEffect(() => {
    // Scroll hacia arriba al cargar la página
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => setShowModal(false);
  console.log(isDesktop);

  return (
    <>
      <SEO
        title="Navimag Carga - Itinerarios"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <div className="w-100" style={{ overflow: 'hidden' }}>
        <FirstSection
          title="Itinerarios"
          body={<LandingButtons />}
          slideType="cargo_itineraries"
        />
      </div>

      <Itineraries cargoItineraries={cargoItineraries} />

      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

export default CargoItineraries;

const LandingButtons = () => {
  const history = useHistory();

  const cargoLeadRedirect = () => {
    history.push(`/cargo/leads`);
  };
  const contactRedirect = () => {
    history.push('/cargo/contact');
  };
  return (
    <Row className="align-items-center justify-content-center mt-5">
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          onClick={contactRedirect}
          type="submit"
          variant="primary"
          className="p-3 w-75"
        >
          Contacta a un asesor
          <Image src={personIcon} alt="icono person" className="ml-2" />
        </Button>
      </Col>
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          type="submit"
          variant="light"
          className="p-3 w-75"
          style={{ borderRadius: '10px', color: '$primary' }}
          onClick={cargoLeadRedirect}
        >
          Cotiza tu carga
          <Image
            src={carIcon}
            alt="icono auto"
            className="ml-2"
            style={{ fill: 'red !important' }}
          />
        </Button>
      </Col>
    </Row>
  );
};
