import React, { useEffect, useState } from 'react';
import { withFormik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'react-bootstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactGA from 'react-ga4';
import '../../assets/stylesheets/components/form-book-trip/FormBookTrip.scss';

import { useWindowSize } from '../../hooks';
import FieldsBookTrip from './FieldsBookTrip';
import {
  bookingTripCategory,
  bookingTripAction,
  bookingTripLabel
} from '../Utils/google-analytics/events/bookingTripEvent';

const FormBookTrip = ({
  defaultStyleColumns,
  modal,
  setShowModal,
  modelName,
  sizeColumns,
  setFieldValue,
  values
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const { windowSize } = useWindowSize();
  const isDesktop = windowSize.width > 991;
  const { t } = useTranslation();

  const disabledButton =
    Object.keys(values.bookTrip)
      .filter(key => key !== 'children' && key !== 'infants')
      .every(key => values.bookTrip[key] !== '') && values.bookTrip.adults > 0;

  const handleClick = () => {
    // Send a custom event
    ReactGA.event({
      category: bookingTripCategory,
      action: bookingTripAction,
      label: bookingTripLabel
    });

    if (modal) {
      if (isMobile) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    }
  };

  useEffect(() => {
    if (windowSize.width < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowSize]);

  return (
    <Form className="">
      <Row className="w-100 form-book-trip">
        <FieldsBookTrip
          isDesktop={isDesktop}
          defaultStyleColumns={defaultStyleColumns}
          modelName={modelName}
          sizeColumns={sizeColumns}
          windowSize={windowSize}
          setFieldValue={setFieldValue}
          values={values}
          disabledButton={disabledButton}
          handleClick={handleClick}
        />
        <Col
          md={2}
          sm={12}
          className="w-100 form-book-trip-col reserve-btn-container"
        >
          <Button
            type="submit"
            variant="primary"
            onClick={handleClick}
            disabled={!disabledButton}
            className="w-100 reserve-btn"
          >
            {t('tourism.subHeader.reserve')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { modelName, bookTrip } = props;

  return {
    [modelName]: {
      ...bookTrip
    }
  };
};

const validationSchema = Yup.object().shape({});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(FormBookTrip);
