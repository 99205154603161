import React, { useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { DefaultModal } from '../../../components';

const ImagePreview = ({ imagePreview }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Button
        variant="primary-outline"
        style={{ fontSize: '12px' }}
        onClick={() => setShowModal(true)}
      >
        Ver imagen
      </Button>
      <DefaultModal
        title=""
        body={
          <div className="d-flex justify-content-center h-100 w-100 mt-4">
            <Image
              className="w-100 h-100 image-with-zoom-image-size"
              src={imagePreview}
            />
          </div>
        }
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => setShowModal(false)}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="xl"
        noButtons
        className="image-modal"
      />
    </div>
  );
};

export default ImagePreview;
