import React from 'react';
import { Row, CardColumns } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import CargoItineraryCard from '../../../components/Utils/Cards/CargoItineraryCard';

const SecondStepCargoAvailabilityForm = ({
  modelName,
  availableItineraries,
  setGatherDecks,
  selectedPortText,
  selectedRouteText
}) => {
  const { values, setFieldValue } = useFormikContext();

  const handleOnClick = itinerary => {
    setFieldValue(modelName, {
      ...values.cargoAvailability,
      itineraryId: itinerary.idViaje
    });
    setGatherDecks(true);
  };

  return (
    <section className="form-section mb-5 w-100">
      <Row className="justify-content-md-center">
        <CardColumns className="card-cargo-body-container">
          {availableItineraries.map(cargoItinerary => {
            const today = new Date();
            const date = new Date(cargoItinerary.fechaZarpe);
            return today > date ? (
              ''
            ) : (
              <CargoItineraryCard
                date={date.getDate()}
                month={date.toLocaleString('default', { month: 'long' })}
                hour={date.toLocaleString('default', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
                port={selectedPortText}
                code={selectedRouteText}
                onClick={() => handleOnClick(cargoItinerary)}
                selectedClassName={
                  String(values.cargoAvailability.itineraryId) ===
                  String(cargoItinerary.idViaje)
                    ? 'cargo-itinerary-selected-card'
                    : ''
                }
              />
            );
          })}
        </CardColumns>
      </Row>
    </section>
  );
};

export default SecondStepCargoAvailabilityForm;
