import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col, Button, Image } from 'react-bootstrap';
import axios from 'axios';

import FirstSection from '../Landing/FirstSection';
import carIcon from '../../../assets/images/car-black-icon.svg';
import personIcon from '../../../assets/images/person.svg';
import SEO from '../../../components/Utils/SEO/SEO';
import TransportSection from '../Landing/TransportSection';
import OurTerminalsSection from './OurTerminalsSection';
import OurShipsSection from './OurShipsSection';
import ItinerarySection from './ItinerarySection';
import { indexRoutesRequest } from '../../../requests/routes';
import TransportServicesSection from '../Landing/TransportServicesSection';

const CargoServices = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.utils);
  const [routes, setRoutes] = useState([]);
  const cancelToken = axios.CancelToken.source();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'cargo',
        sort_direction: 'asc',
        sort_column: 'position',
        language: localStorage.getItem('language'),
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setRoutes(camelCaseRecursive(result?.data?.data));
      }
    });
    return () => cancelToken.cancel();
  };

  useEffect(routesRequestWrapper, []);

  return (
    <div className="landing-cargo">
      <SEO
        title="Navimag: Transporte de vehículos y carga en el Sur de Chile"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Conoce nuestros servicios"
        subtitle="Realiza ahora tu cotización de vehículos, camiones o carga vía teléfono o mail."
        body={<LandingButtons />}
        slideType="cargo_services"
      />
      <TransportServicesSection settings={settings} routes={routes} />
      <OurShipsSection />
      <ItinerarySection />
      <OurTerminalsSection />
      <TransportSection />
    </div>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(CargoServices);

const LandingButtons = () => {
  const history = useHistory();

  const cargoLeadRedirect = () => {
    history.push(`/cargo/leads`);
  };
  const contactRedirect = () => {
    history.push('/cargo/contact');
  };
  return (
    <Row className="align-items-center justify-content-center mt-5">
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          onClick={contactRedirect}
          type="submit"
          variant="primary"
          className="p-3 w-75"
        >
          Contacta a un asesor
          <Image src={personIcon} alt="icono person" className="ml-2" />
        </Button>
      </Col>
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          type="submit"
          variant="light"
          className="p-3 w-75"
          style={{ borderRadius: '10px', color: '$primary' }}
          onClick={cargoLeadRedirect}
        >
          Cotiza tu carga
          <Image
            src={carIcon}
            alt="icono auto"
            className="ml-2"
            style={{ fill: 'red !important' }}
          />
        </Button>
      </Col>
    </Row>
  );
};
