import ApiService from '../services/apiService';

const createCargoLeadRequest = ({
  dispatch,
  params,
  formData,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/cargo_leads', {
    dispatch,
    params,
    formData,
    failureCallback,
    successCallback
  });

export default createCargoLeadRequest;
