import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import AdminNavbarDragAndDrop from '../Navbar/AdminNavbarDragAndDrop';

const AdminFooterIndex = ({ cargo = false }) => {
  const [footerType, setFooterType] = useState(
    cargo ? 'footerCargo' : 'footer'
  );

  const [language, setLanguage] = useState('es');
  const onPressSetFooterType = () => {
    if (!cargo) {
      const type = language === 'es' ? 'footer' : 'footerEnglish';
      setFooterType(type);
    }
  };

  useEffect(onPressSetFooterType, [language]);

  return (
    <Row id="index-admin-navbar">
      <Col>
        <AdminNavbarDragAndDrop
          barType={footerType}
          language={language}
          setLanguage={setLanguage}
          cargo={cargo}
        />
      </Col>
    </Row>
  );
};

export default withRouter(AdminFooterIndex);
