import * as Yup from 'yup';

import { isValidRut, validPhoneInput } from './utils';

Yup.addMethod(Yup.string, 'rut', function rutValidate(message) {
  return this.test('test-name', message, function validRut(value) {
    // rut validation
    if (value && isValidRut(value)) {
      return true;
    }
    return false;
  });
});

Yup.addMethod(Yup.string, 'phone', function phoneValidate(message) {
  return this.test('test-name', message, function validPhone(value) {
    // phone validation
    if ((value && validPhoneInput(value)) || value === undefined) {
      return true;
    }
    return false;
  });
});
