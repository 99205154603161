import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { ComponentDataTable } from '../../../../components';

const getCreatedAtCell = ({ createdAt }) => createdAt;

const columns = memoize(() => [
  {
    name: 'Nombre Ruta',
    sortable: true,
    selector: () => 'route_origin',
    cell: ({ routeName }) => routeName,
    grow: '1'
  },
  {
    name: 'Categoría Vehiculo',
    selector: () => 'cargo_rate.vehicle_type',
    cell: ({ vehicleCategory }) => vehicleCategory,
    grow: '1.2'
  },
  {
    name: 'Nombre',
    selector: () => 'name',
    sortable: true,
    cell: ({ name }) => name,
    grow: '1'
  },
  {
    name: 'Correo',
    selector: () => 'email',
    sortable: true,
    cell: ({ email }) => email,
    grow: '1.3'
  },
  {
    name: 'RUT',
    selector: () => 'rut',
    sortable: true,
    cell: ({ rut }) => rut,
    grow: '1'
  },
  {
    name: 'Nacionalidad',
    selector: () => 'nationality',
    sortable: true,
    cell: ({ nationality }) => nationality,
    grow: '1.2'
  },
  {
    name: 'Acepta Comunicaciones Futuras',
    selector: () => 'accept_future_communications',
    sortable: true,
    cell: ({ acceptFutureCommunications }) =>
      acceptFutureCommunications ? 'Sí' : 'No',
    grow: '1.5'
  },
  {
    name: 'Longitud Lineal',
    selector: () => 'lineal_length',
    sortable: true,
    cell: ({ linealLength }) => (linealLength ? `${linealLength} ml` : 'N/A'), // Esto muestra 'N/A' si linealLength es null o undefined
    grow: '1'
  },
  {
    name: 'Ida y Vuelta',
    selector: () => 'round_trip',
    sortable: true,
    cell: ({ roundTrip }) => (roundTrip ? `Si` : 'N/A'), // Esto muestra 'N/A' si linealLength es null o undefined
    grow: '1'
  },
  {
    name: 'Cotización',
    selector: () => 'quotation',
    sortable: true,
    cell: ({ quotation }) => quotation || '-',
    grow: '1.3'
  },
  {
    name: 'F. creación',
    selector: () => 'created_at',
    sortable: true,
    grow: '2',
    cell: getCreatedAtCell
  }
]);

const CargoLeadTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  modelUrl,
  handleIndexRequest,
  setDateFilter
}) => {
  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        withStartDate
        withEndDate
        setDateFilter={setDateFilter}
      />
    </>
  );
};

export default withRouter(connect()(CargoLeadTable));
