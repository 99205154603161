import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import VideosDataTable from './VideosDataTable';
import LanguageButtons from '../../../components/Utils/Button/LanguageButtons';

import { debounceIndexAdminVideosRequest } from '../../../requests/admin/adminVideos';
import useFetchData from '../../../hooks/useFetchData';

const VideosIndex = () => {
  const [language, setLanguage] = useState('es');
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [customParams, setCustomParams] = useState({
    sort_column: 'position',
    sort_direction: 'asc',
    display_length: 10,
    language
  });

  const {
    data: videos,
    totalData: totalVideos,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminVideosRequest,
    customParams
  });

  const modelUrl = '/admin/videos';
  const clearCustomParams = () => {
    setCustomParams({ language, display_length: 10 });
  };
  const updateCustomParams = () => {
    setCustomParams({ language, display_length: 10 });
    setAllowFetchRecords(true);
  };
  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(updateCustomParams, [language]);
  useEffect(fetchNewRecords, [customParams]);
  return (
    <>
      <Row id="index-videos">
        <Col>
          <LanguageButtons language={language} setLanguage={setLanguage} />
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to="/admin/videos/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <VideosDataTable
        tableData={videos}
        tableDataAmount={totalVideos}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(VideosIndex));
