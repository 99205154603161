import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';
import { deleteAdminOurLeaderRequest } from '../../../requests/admin/adminOurLeaders';
import { languages } from '../../../services/utils';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withCopyClipboard = false,
    withDestroy = false,
    showClick = false,
    editClick = false,
    copyText = 'Copiar en el portapapeles'
  }) => [
    {
      name: 'Nombre de lider',
      selector: () => 'name',
      sortable: true,
      cell: ({ name }) => name,
      grow: '1'
    },
    {
      name: 'Cargo',
      selector: () => 'occupation',
      sortable: true,
      cell: ({ occupation }) => occupation,
      grow: '1'
    },
    {
      name: 'Objetivos personales',
      selector: () => 'caption',
      sortable: true,
      cell: ({ caption }) => caption,
      grow: '2'
    },
    {
      name: 'Idioma',
      selector: () => 'language',
      sortable: true,
      cell: ({ language }) => languages[language],
      grow: '1'
    },
    {
      name: 'Posición',
      selector: () => 'position',
      sortable: true,
      cell: ({ position }) => position,
      grow: '1'
    },
    {
      name: 'Activo',
      selector: () => 'active',
      grow: '1',
      sortable: true,
      cell: item => (item.active ? 'Sí' : 'No')
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withCopyClipboard={withCopyClipboard}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
          copyText={copyText}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const OurLeaderTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteAdminOurLeaderRequest,
    onDeleteMessage: 'Líder eliminada exitosamente',
    confirmationText: '¿Estás seguro de eliminar este líder?',
    modalTitle: 'Eliminar líder'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      case 'copyClipboard':
        navigator.clipboard
          .writeText(
            item.fileInfo.fileUrl || 'Recuerda añadir una foto primero'
          )
          .then(() => {
            // eslint-disable-next-line no-alert
            alert('Link copiado al portapapeles');
          });
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          copyText: 'Copiar URL de la foto',
          withCopyClipboard: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(OurLeaderTable));
