import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';

import FirstSection from '../Landing/FirstSection';
import SEO from '../../../components/Utils/SEO/SEO';
import { indexPagesRequest } from '../../../requests/pages';

const CargoTermsAndConditions = () => {
  const { settings } = useSelector(state => state.utils);
  const [cargoTermsAndCondition, setCargoTermsAndCondition] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cargoTermsAndConditionPageRequest = () => {
    indexPagesRequest({
      dispatch,
      params: {
        active: true,
        title_search: settings.cargoTermsAndConditionsPage
      },
      successCallback: result => {
        setCargoTermsAndCondition(camelCaseRecursive(result.data.data[0]));
      }
    });
  };

  useEffect(cargoTermsAndConditionPageRequest, [settings]);

  useEffect(() => {
    // Scroll hacia arriba al cargar la página
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mb-5">
      <SEO
        title="Navimag Carga - Contacto"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Términos y Condiciones Servicio de Carga"
        slideType="cargo_terms"
        slideHeight="40vh"
      />
      <Container className="py-4 my-4">
        {cargoTermsAndCondition && (
          <Row className="align-items-center justify-content-center mb-4">
            <Col xs={12} md={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: cargoTermsAndCondition.body
                }}
              />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CargoTermsAndConditions;
