import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LinkBtn } from '../../../components';
import TemplatesGrid from '../../../components/Templates/TemplatesGrid';

const BlogTemplates = () => {
  return (
    <>
      <Row id="templates">
        <Col>
          <h4>Plantillas</h4>
        </Col>
        <Col md={2}>
          <LinkBtn variant="light" block to="/admin/blogs">
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <TemplatesGrid category="blogs" />
    </>
  );
};

export default connect()(BlogTemplates);
