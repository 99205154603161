/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';

import { FormikInput, FormikCheckBox } from '../../../components';

const OfficeForm = props => {
  const { onHide, submitVariant, errors, touched, action, office } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [defaultOffice, setDefaultOffice] = useState(undefined);

  useEffect(() => {
    setDefaultOffice(office);
  }, [office]);

  return (
    <Form>
      <Field name="office[name]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikInput
              {...field}
              abbr
              inputType="text"
              label="Nombre"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
            />
          );
        }}
      </Field>
      <Field name="office[phone]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikInput
              {...field}
              abbr
              inputType="text"
              label="Teléfono"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
            />
          );
        }}
      </Field>
      <Field name="office[position]">
        {({ field }) => {
          const { name } = field;
          return (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              label="Posición"
              error={getIn(errors, name)}
              touched={getIn(touched, name)}
            />
          );
        }}
      </Field>
      <Field name="office[active]">
        {({ field }) => (
          <FormikCheckBox {...field} field={field} label="Activo" custom />
        )}
      </Field>
      <Row className="d-flex justify-content-end mb-3">
        <Col md={4}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const { id, name, phone, position, active } = props.office;
  return {
    office: {
      id,
      name,
      phone,
      position,
      active
    }
  };
};

const validationSchema = Yup.object().shape({
  office: Yup.object().shape({
    name: Yup.string().required('El nombre es requerido'),
    phone: Yup.string()
      .required('El teléfono es requerido')
      .matches(/^(?:\+?56)?(?:[0-9]\d{8})$/, 'Formato de teléfono inválido'),
    position: Yup.number()
      .integer()
      .required('La posición es requerida'),
    active: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const paramsToSend = {
    office: snakecaseKeys({ ...values.office })
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(OfficeForm);
