import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import route from '../../components/Routes/route';
import { showRouteRequest } from '../../requests/routes';
import LoadSpinner from '../../components/Utils/Spinner';
import RouteCard from '../../components/Landing/Routes/RouteCard';
import SEO from '../../components/Utils/SEO/SEO';

const RouteShow = props => {
  const [routeData, setRouteData] = useState(route);
  const [isFetching, setIsFetching] = useState(true);

  const handleSuccessShow = response => {
    const _route = camelCaseRecursive(response.data);
    setRouteData(_route);
    setIsFetching(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const { match, dispatch } = props;
    const routeId = match.params.id;
    showRouteRequest(routeId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  return isFetching ? (
    <LoadSpinner />
  ) : (
    <>
      <SEO />
      <Row className="third-section">
        <Col>
          <RouteCard key={`route-card-${route.id}`} route={routeData} />
        </Col>
      </Row>
    </>
  );
};

export default connect()(RouteShow);
