import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import IncludedElementForm from './IncludedElementForm';
import { createAdminIncludedElementRequest } from '../../../requests/admin/adminIncludedElements';
import { sendAlert } from '../../../actions/utils';

const IncludedElementNew = props => {
  const { history } = props;
  // eslint-disable-next-line no-unused-vars
  const [includedElement, setIncludedElement] = useState({
    description: '',
    image: '',
    language: ''
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    history.push({
      pathname: '/admin/routes_and_included',
      state: { type: 'Elementos Incluidos' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Elemento creado con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _includedElement => {
    createAdminIncludedElementRequest({
      dispatch,
      params: _includedElement,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="p-4">
      <Row id="create-included-elements" className="mb-4">
        <Col>
          <h4 className="text-primary">
            Crear elemento incluido en rutas de turismo
          </h4>
        </Col>
        <Col md={2}>
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <IncludedElementForm
            includedElement={includedElement}
            action="new"
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
    </div>
  );
};

export default connect()(IncludedElementNew);
