import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import ReviewForm from './ReviewForm';
import {
  showAdminReviewRequest,
  updateAdminReviewRequest
} from '../../../requests/admin/adminReviews';
import { sendAlert } from '../../../actions/utils';

const ReviewEdit = props => {
  const { history } = props;
  const [review, setReview] = useState({
    fileInfo: {},
    name: '',
    lastName: '',
    title: '',
    language: '',
    comment: '',
    review: 5,
    reviewLink: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _Review = camelCaseRecursive(response.data);
    setReview(_Review);
  };

  useEffect(() => {
    const { match } = props;
    const reviewId = match.params.id;
    showAdminReviewRequest(reviewId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleSuccessUpdate = response => {
    const _review = response.data;
    history.push(`/admin/reviews/${_review.id}`);
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Review editada con éxito'
      })
    );
  };

  const handleGoback = () => {
    history.push({ pathname: '/admin/landing', state: { type: 'Reviews' } });
  };

  const handleUpdateRequest = _review => {
    const reviewId = _review.review.id;
    updateAdminReviewRequest(reviewId, {
      dispatch,
      params: _review,
      formData: true,
      successCallback: response => handleSuccessUpdate(response)
    });
  };

  return (
    <div className="p-4">
      <Row id="edit-reviews" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar Review</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <ReviewForm
        review={review}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(ReviewEdit);
