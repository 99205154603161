import ApiService from '../services/apiService';

export const indexGalleriesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/galleries${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showGalleriesRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/galleries/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
