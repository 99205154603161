import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AttractionsGrid from '../../../components/Attractions/AttractionsGrid';

const SixthSection = () => {
  const { t } = useTranslation();
  return (
    <Row className="d-none d-md-flex mb-5">
      <Col className="title_text mt-4">
        <h4>{t('tourism.landing.attractions.title')}</h4>
        <AttractionsGrid />
      </Col>
    </Row>
  );
};

export default SixthSection;
