import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useAbility } from '@casl/react';
import { connect, useSelector } from 'react-redux';

import InnerNavbar from './InnerNavbar';
import { AbilityContext } from '../../../config/abilityContext';
import { updateAbility } from '../../../config/ability';

const NavbarLogged = props => {
  const { children } = props;
  const { user } = useSelector(state => state.auth);
  const ability = useAbility(AbilityContext);

  const updateAbilityRoles = () => {
    updateAbility(ability);
  };

  useEffect(updateAbilityRoles, []);

  return (
    <InnerNavbar>
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <NavDropdown alignRight title={user.email} id="basic-nav-dropdown">
            {children}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </InnerNavbar>
  );
};

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps)(NavbarLogged);
