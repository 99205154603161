import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';

import cargoArticulatedTruck from '../../../assets/images/cargo/cargo-articulated_trucks.svg';
import cargoBuses from '../../../assets/images/cargo/cargo-buses.svg';
import cargoLightWeightVehicles from '../../../assets/images/cargo/cargo-lightweight_vehicles.svg';
import cargoMachinery from '../../../assets/images/cargo/cargo-machinery.svg';
import cargoMotorcycles from '../../../assets/images/cargo/cargo-motorcycles.svg';
import cargoTowing from '../../../assets/images/cargo/cargo-towing.svg';
import cargoTrucks from '../../../assets/images/cargo/cargo-trucks.svg';
import cargoVan from '../../../assets/images/cargo/cargo-van.svg';
import cargoPerson from '../../../assets/images/cargo/cargo-person.svg';

const imageMapping = {
  articulated_trucks: cargoArticulatedTruck,
  buses: cargoBuses,
  lightweight_vehicles: cargoLightWeightVehicles,
  machinery: cargoMachinery,
  motorcycles: cargoMotorcycles,
  towing: cargoTowing,
  trucks: cargoTrucks,
  vans: cargoVan,
  carrier: cargoPerson
};

const CargoLeadElementCard = ({
  size,
  colorText,
  colorCard,
  colorBorder,
  title,
  categoryImage,
  onClick,
  selectedClassName
}) => {
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className={`card-cargo-categories d-flex justify-content-center text-center ${selectedClassName}`}
      style={{ width: size, cursor: 'pointer' }}
      border={colorBorder}
      onClick={onClick}
    >
      <Row className="align-items-center mb-2">
        <Col>
          <Card.Img
            className="card-cargo-categories-image"
            variant="top"
            src={imageMapping[categoryImage]}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <Card.Title className="card-cargo-categories-title">
            {title}
          </Card.Title>
        </Col>
      </Row>
    </Card>
  );
};

CargoLeadElementCard.propTypes = {
  size: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

CargoLeadElementCard.defaultProps = {
  size: '100%',
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default CargoLeadElementCard;
