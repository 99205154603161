import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { LinkBtn } from '../Button';
import IcoMoon from '../Icon/IcoMoon';
import { DefaultModal } from '../Modal';

const handleDelete = (
  arrayValues,
  setFieldValue,
  valuePath,
  index,
  setWithMethod
) => {
  const arrayValuesLength = arrayValues.length;
  const deleteValue = arrayValues[index];
  if (deleteValue.id && deleteValue !== '') {
    deleteValue._destroy = 'true';
    if (setWithMethod) {
      return setWithMethod(deleteValue);
    }
    return setFieldValue(`${valuePath}[${index}]`, deleteValue);
  }
  const newValues = [
    ...arrayValues.slice(0, index),
    ...arrayValues.slice(index + 1, arrayValuesLength)
  ];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

const addRow = (
  arrayValues,
  setFieldValue,
  valuePath,
  newAttributes,
  setWithMethod
) => {
  const newValues = [...arrayValues, newAttributes];
  if (setWithMethod) {
    return setWithMethod(newValues);
  }
  return setFieldValue(`${valuePath}`, newValues);
};

// Esta pensado para funcionar con los values de formik, para efecto de funcionar en el ejemplo de componentes
// Lo voy a cambiar un poco
const NestedBody = ({
  arrayValues,
  body,
  deleteLastOne,
  index,
  inputLength,
  setFieldValue,
  shownLength,
  valuePath,
  setWithMethod
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="nested-body">
      {body && (
        <Col className="w-100" style={{ height: '0px' }}>
          {deleteLastOne
            ? index === inputLength &&
              inputLength !== 0 &&
              shownLength !== 0 && (
                <IcoMoon
                  icon="close"
                  className="nested-form-delete-btn"
                  onClick={() =>
                    handleDelete(
                      arrayValues,
                      setFieldValue,
                      valuePath,
                      index,
                      setWithMethod
                    )
                  }
                />
              )
            : inputLength !== 0 &&
              shownLength !== 0 && (
                <IcoMoon
                  icon="close"
                  className="nested-form-delete-btn"
                  onClick={() => setShowModal(true)}
                />
              )}
        </Col>
      )}
      {body}
      <DefaultModal
        title=""
        body={
          <div className="d-flex justify-content-center align-items-center pb-4">
            <p>¿Estás seguro de eliminar este elemento?</p>
          </div>
        }
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={() => {
          handleDelete(
            arrayValues,
            setFieldValue,
            valuePath,
            index,
            setWithMethod
          );
          setShowModal(false);
        }}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
        size="md"
      />
    </div>
  );
};
const NestedForm = ({
  setFieldValue,
  newAttributes,
  mapInputs,
  arrayValues,
  valuePath,
  addTitle,
  deleteLastOne,
  setWithMethod
}) => {
  const inputLength = mapInputs.length - 1;
  const shownLength =
    mapInputs.filter(e => {
      return e !== undefined;
    }).length - 1;
  const nestedBody = mapInputs.map((body, index) => (
    <NestedBody
      key={`nested-${index.toString()}`}
      arrayValues={arrayValues}
      body={body}
      deleteLastOne={deleteLastOne}
      index={index}
      inputLength={inputLength}
      setFieldValue={setFieldValue}
      shownLength={shownLength}
      valuePath={valuePath}
      setWithMethod={setWithMethod}
    />
  ));
  return (
    <>
      {nestedBody}
      <Col md={12} className="d-flex justify-content-end">
        <LinkBtn
          variant="primary-outline"
          to="#"
          onClick={() =>
            addRow(
              arrayValues,
              setFieldValue,
              valuePath,
              newAttributes,
              setWithMethod
            )
          }
        >
          {addTitle}
        </LinkBtn>
      </Col>
    </>
  );
};

export default NestedForm;
