/* eslint-disable camelcase */
import React from 'react';
import memoize from 'memoize-one';
import { BasicActionBtns } from '../../../components';

const getEmailCell = ({ email }) => email;

const getCreatedAtCell = ({ createdAt }) => createdAt;

const getRolesCell = ({ roles }) =>
  roles && roles.length ? roles.map(role => role.label).join(' / ') : 'Sin rol';

const getActiveCell = item => (item.active ? 'Sí' : 'No');

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Correo electrónico',
      selector: () => 'email',
      sortable: true,
      cell: getEmailCell,
      grow: '2'
    },
    {
      name: 'F. creación',
      selector: () => 'created_at',
      sortable: true,
      grow: '2',
      cell: getCreatedAtCell
    },
    {
      name: 'Roles',
      selector: () => 'role',
      sortable: false,
      grow: '2',
      cell: getRolesCell
    },
    {
      name: 'Activo',
      selector: () => 'active',
      grow: '1',
      sortable: true,
      cell: getActiveCell
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

export default columns;
