import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import OfficesDataTable from './OfficesDataTable';

import { debounceIndexAdminOfficesRequest } from '../../../requests/admin/adminOffices';
import useFetchData from '../../../hooks/useFetchData';

const OfficesIndex = () => {
  const [customParams] = useState({
    display_length: 10
  });

  const {
    data: offices,
    totalData: totalOffices,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminOfficesRequest,
    customParams
  });

  const modelUrl = '/admin/offices';

  return (
    <>
      <Row id="index-offices" style={{ marginTop: '-10px' }}>
        <Col />
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to="/admin/offices/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <OfficesDataTable
        tableData={offices}
        tableDataAmount={totalOffices}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(OfficesIndex));
