import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';

import Slides from '../../../components/Utils/Slides';
import { indexSlidesRequest } from '../../../requests/slides';

const FirstSection = ({
  title,
  subtitle,
  body,
  slideType,
  customHeroStyle = '',
  customSlidesStyles,
  slideHeight = '100vh'
}) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const [slideTitle, setSlideTitle] = useState('');
  const [slideSubtitle, setSlideSubtitle] = useState('');
  const [finishedFirstFetch, setFinishedFirstFetch] = useState(false);
  const [noSlideFound, setNoSlideFound] = useState(false);

  const updateSlideTextValues = () => {
    if (slides.length !== 0 && !noSlideFound) {
      const { heroTitleEs, heroSubtitleEs } = slides[0];
      setSlideTitle(heroTitleEs);
      setSlideSubtitle(heroSubtitleEs);
    }
    if (slides.length === 0 && !noSlideFound && finishedFirstFetch) {
      setNoSlideFound(true);
    }
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: slideType
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setFinishedFirstFetch(true);
      }
    });
  };

  const requestDefaultSlide = () => {
    if (noSlideFound && finishedFirstFetch) {
      indexSlidesRequest({
        dispatch,
        params: {
          actives: true,
          sort_column: 'position',
          sort_direction: 'asc',
          display_length: 5,
          slide_type: 'landing_cargo'
        },
        successCallback: result => {
          setSlides(camelCaseRecursive(result.data.data));
        }
      });
    }
  };

  useEffect(slidesRequest, []);
  useEffect(updateSlideTextValues, [slides, finishedFirstFetch]);
  useEffect(requestDefaultSlide, [noSlideFound, finishedFirstFetch]);

  return (
    <Row className={`hero ${customHeroStyle}`} style={{ height: slideHeight }}>
      <Slides
        items={slides}
        customSlidesStyles={customSlidesStyles}
        slideHeight={slideHeight}
      />
      <Col sm={12} className="align-self-center container-hero__content">
        <h1 className="mb-4 title-landing text-center text-white">
          {slideTitle !== '' && slideTitle !== undefined && slideTitle !== null
            ? slideTitle
            : title}
        </h1>
        <h5 className="text-white text-justify text-center">
          {slideSubtitle !== '' &&
          slideSubtitle !== undefined &&
          slideSubtitle !== null
            ? slideSubtitle
            : subtitle}
        </h5>
        {body}
      </Col>
    </Row>
  );
};

export default FirstSection;
