import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';

const CarouselComponent = ({ data, className, classNameImg, noArrow }) => {
  return (
    <Carousel
      className={`carousel-component ${className} ${
        noArrow ? 'hidden-arrow' : ''
      }`}
    >
      {data.map(i => (
        <Carousel.Item key={i.id}>
          <img
            className={`d-block w-100 img-carousel ${classNameImg}`}
            src={i.img}
            alt="imagen carrusel"
          />
          <Carousel.Caption>
            <h3>{i.title}</h3>
            <p>{i.subtitle}</p>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

CarouselComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      img: PropTypes.string
    })
  ).isRequired
};

export default CarouselComponent;
