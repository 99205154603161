import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { CenteredModal, FormBookTrip } from '../../components';
import { redirectToReservation } from '../../services/utils';
import SEO from '../../components/Utils/SEO/SEO';
import OurLeadersIndex from '../../components/OurLeaders/OurLeadersIndex';

const OurLeaders = () => {
  const [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  const routesBreakdownRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const language = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(language);
  }, []);

  const bookTrip = {
    route: '',
    month: '',
    year: '',
    adults: 0,
    children: 0,
    infants: 0
  };

  return (
    <>
      <SEO
        title={t('tourism.ourLeaders.seo.title')}
        description={t('tourism.ourLeaders.seo.description')}
      />
      <div ref={routesBreakdownRef} style={{ marginTop: 200 }} />
      <OurLeadersIndex />
      <CenteredModal
        size="md"
        onHide={handleCloseModal}
        onClickHide={handleCloseModal}
        show={showModal}
        body={
          <div className="px-2 pb-2 modal-booktrip">
            <FormBookTrip
              defaultStyleColumns
              showModal={showModal}
              setShowModal={setShowModal}
              modelName="bookTrip"
              sizeColumns={[12, 12, 12, 12, 12, 12, 12]}
              bookTrip={bookTrip}
              formRequest={redirectToReservation}
            />
          </div>
        }
      />
    </>
  );
};

export default OurLeaders;
