import React from 'react';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col } from 'react-bootstrap';
import snakecaseKeys from 'snakecase-keys';
import { languages } from '../../../services/utils';

import {
  FormikInput,
  FormikCheckBox,
  UploadImage,
  FormikSelect
} from '../../../components';

const ReviewForm = props => {
  const {
    onHide,
    submitVariant,
    errors,
    touched,
    action,
    setFieldValue,
    review
  } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';

  return (
    <Form className="w-100 px-2">
      <Row>
        <Col>
          <Field name="review[image]">
            {({ field }) => {
              const { name } = field;
              const { fileInfo } = review;
              return (
                <UploadImage
                  {...field}
                  modelType="review"
                  imageUrl={getIn(fileInfo, 'fileUrl')}
                  fileName={getIn(fileInfo, 'filename')}
                  onChange={_avatar => setFieldValue(name, _avatar)}
                  error={getIn(errors, 'review[image]')}
                  touched={getIn(touched, 'review[image]')}
                />
              );
            }}
          </Field>
        </Col>
        <Col>
          <Row>
            <Col>
              <Field name="review[name]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Nombre"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col>
              <Field name="review[lastName]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="text"
                      label="Apellido"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
          </Row>

          <Field name="review[language]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikSelect
                  {...field}
                  abbr
                  label="Idioma"
                  placeholder="Seleccionar Idioma"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  options={Object.entries(languages).map(([value, label]) => ({
                    value,
                    label
                  }))}
                  onChange={selectedOption => {
                    const selectedValue = selectedOption
                      ? selectedOption.value
                      : '';
                    setFieldValue(name, selectedValue);
                  }}
                  defaultValue={field.value}
                />
              );
            }}
          </Field>

          <Field name="review[title]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="review[comment]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  abbr
                  inputType="textarea"
                  isTextArea
                  label="Comentario"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                  className="form-control"
                />
              );
            }}
          </Field>

          <Row>
            <Col>
              <Field name="review[review]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Reseña"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
            <Col>
              <Field name="review[position]">
                {({ field }) => {
                  const { name } = field;
                  return (
                    <FormikInput
                      {...field}
                      abbr
                      inputType="number"
                      label="Posición"
                      error={getIn(errors, name)}
                      touched={getIn(touched, name)}
                    />
                  );
                }}
              </Field>
            </Col>
          </Row>

          <Field name="review[reviewLink]">
            {({ field }) => {
              const { name } = field;
              return (
                <FormikInput
                  {...field}
                  inputType="text"
                  label="Link"
                  error={getIn(errors, name)}
                  touched={getIn(touched, name)}
                />
              );
            }}
          </Field>

          <Field name="review[active]">
            {({ field }) => (
              <FormikCheckBox {...field} field={field} label="Activo" custom />
            )}
          </Field>
        </Col>
      </Row>

      <Row className="d-flex justify-content-end mb-3">
        <Col md={2}>
          <Button type="submit" variant={submitVariant} block onClick={onHide}>
            {btnMessage}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    fileInfo,
    name,
    lastName,
    title,
    language,
    comment,
    review,
    reviewLink,
    active,
    position
  } = props.review;
  return {
    review: {
      id,
      image: getIn(fileInfo, 'fileSignature'),
      name,
      lastName,
      title,
      language,
      comment,
      review,
      reviewLink,
      active,
      position
    },
    languageTranslate: languages[language]
  };
};

const validationSchema = Yup.object().shape({
  review: Yup.object().shape({
    image: Yup.mixed().required('Debes adjuntar una imagen'),
    name: Yup.string().required('El nombre es requerido'),
    lastName: Yup.string().required('El apellido es requerido'),
    title: Yup.string().required('El título es requerido'),
    language: Yup.string().required('El idioma es requerido'),
    comment: Yup.string().required('El comentario es requerido'),
    review: Yup.number()
      .typeError('Debes escoger un número')
      .integer('Debe ser un numero entero')
      .required('La reseña es requerida')
      .min(0)
      .max(5),
    active: Yup.boolean(),
    position: Yup.number()
      .integer()
      .required('La posición es requerida'),
    reviewLink: Yup.string().required('El link es requerido')
    // .url('El link debe ser una url válida')
  })
});

const handleSubmit = (values, { props }) => {
  const { image } = values.review;
  const valuesCopy = values;
  delete valuesCopy.review.image;
  const paramsToSend = {
    review: { ...snakecaseKeys(valuesCopy.review), image }
  };
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(ReviewForm);
