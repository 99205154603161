import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import GuideForm from './GuideForm';

import {
  showAdminGuideRequest,
  updateAdminGuideRequest
} from '../../../requests/admin/adminGuides';
import { sendAlert } from '../../../actions/utils';

const GuideEdit = props => {
  const { history } = props;
  const [guide, setGuide] = useState({
    title: '',
    language: '',
    description: '',
    pageId: '',
    active: true,
    position: null,
    attachedPreviewImage: null,
    attachedMainImage: null,
    attachedEbook: null
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _guide = camelCaseRecursive(response.data);
    setGuide(_guide);
  };

  useEffect(() => {
    const { match } = props;
    const guideId = match.params.id;

    showAdminGuideRequest(guideId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: '/admin/ebooks_and_videos',
      state: { type: 'Guías' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Guía actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _guide => {
    const guideId = _guide.guide.id;
    updateAdminGuideRequest(guideId, {
      dispatch,
      params: _guide,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-guides" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar Guía</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <GuideForm
        guide={guide}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(GuideEdit);
