import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { es } from 'date-fns/locale';
import DatePicker from 'react-datepicker';

const DateFilter = ({
  customParams,
  finishDateField,
  setCustomParams,
  startDateField
}) => {
  const [startDate, setStartDate] = useState(customParams[startDateField]);
  const [finishDate, setFinishDate] = useState(customParams[finishDateField]);

  return (
    <div className="w-100">
      <Form.Group className="d-flex">
        <div className="datepicker-container">
          <div className="datepicker px-1 pr-2">
            <DatePicker
              showYearDropdown
              className="form-control"
              dateFormat="dd-MM-yyyy"
              popperPlacement="top"
              selected={startDate}
              autoComplete="off"
              onChange={date => {
                setStartDate(date);
                setCustomParams(params => ({
                  ...params,
                  [startDateField]: date || ''
                }));
              }}
              placeholderText="Desde"
              locale={es}
              selectsStart
            />
          </div>
          <div className="datepicker">
            <DatePicker
              showYearDropdown
              className="form-control p-3"
              selected={finishDate}
              popperPlacement="top"
              autoComplete="off"
              onChange={selectedDate => {
                setFinishDate(selectedDate);
                setCustomParams(params => ({
                  ...params,
                  [finishDateField]: selectedDate || ''
                }));
              }}
              placeholderText="Hasta"
              locale={es}
              dateFormat="dd-MM-yyyy"
              selectsEnd
              startDate={startDate}
              minDate={startDate}
              endDate={finishDate}
            />
          </div>
        </div>
      </Form.Group>
    </div>
  );
};

export default DateFilter;
