import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';

import { ComponentDataTable } from '../../../components';
import { useDestroyOne } from '../../../hooks';

// import { deleteLeadRequest } from '../../../requests/admin/adminLeads';

const columns = memoize(() => [
  {
    name: 'Nombre',
    selector: 'name',
    sortable: true,
    cell: ({ name }) => name,
    grow: '1'
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    cell: ({ email }) => email,
    grow: '1'
  },
  {
    name: 'Activo',
    selector: () => 'active',
    grow: '.5',
    sortable: true,
    cell: ({ active }) => (active ? 'Sí' : 'No')
  },
  {
    name: 'Tipo de Cliente',
    selector: 'client_type',
    sortable: true,
    cell: ({ clientType }) => clientType,
    grow: '1'
  },
  {
    name: 'Tipo de Destino',
    selector: 'destiny_type',
    sortable: true,
    cell: ({ destinyType }) => destinyType,
    grow: '1'
  },
  {
    name: 'Tiempo de Viaje',
    selector: 'travel_time',
    sortable: true,
    cell: ({ travelTime }) => travelTime,
    grow: '2'
  },
  {
    name: 'Tipo de Viajero',
    selector: 'traveler_type',
    sortable: true,
    cell: ({ travelerType }) => travelerType,
    grow: '1'
  },
  {
    name: 'Teléfono',
    selector: 'phone',
    cell: ({ phone }) => phone || 'N/A',
    grow: '1'
  }
]);

const LeadsDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest,
  categories,
  selectedCategory,
  setSelectedCategory,
  rowsPage
}) => {
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData
    // deleteOneRequest: deleteLeadRequest,
    // onDeleteMessage: 'Lead eliminado exitosamente',
    // confirmationText: '¿Estás seguro de eliminar este lead?',
    // modalTitle: 'Eliminar Lead'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'name':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: false,
          withEdit: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
        categoryFilter
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        RowsPage={rowsPage}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(LeadsDataTable));
