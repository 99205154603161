/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Button, Spinner } from 'react-bootstrap';

import { indexGalleriesRequest } from '../../requests/galleries';
import '../../assets/stylesheets/components/galleries/GalleriesGrid.scss';
import defaultImage from '../../assets/images/default.png';

const GalleriesGrid = ({ numOfGalleries, customParams }) => {
  const history = useHistory();
  const [galleries, setGalleries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const galleriesRequest = () => {
    setIsLoading(true);
    indexGalleriesRequest({
      dispatch,
      params: {
        ...customParams,
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: numOfGalleries,
        language
      },
      successCallback: result => {
        const sortedData = camelCaseRecursive(result.data.data).sort(
          (a, b) => a.position - b.position
        );
        setGalleries(sortedData);
        setIsLoading(false);
      },
      errorCallback: () => {
        setIsLoading(false);
        setGalleries([]);
      }
    });
  };

  const navigateToGallery = id => {
    history.push(`/galleries/${id}`);
  };

  useEffect(galleriesRequest, [numOfGalleries, customParams]);

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px'
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <section className="gallery-section">
      <div className="grid-container">
        {galleries.length > 0 &&
          galleries.map(gallery => (
            <div key={gallery.id} className="grid-item">
              <div className="gallery-card">
                <div
                  className="gallery-info"
                  style={{
                    backgroundImage: gallery.attachedImagesUrls[0]?.fileUrl
                      ? `url("${gallery.attachedImagesUrls[0]?.fileUrl}")`
                      : `url("${defaultImage}")`
                  }}
                />
                <p className="gallery-title mt-1">
                  {language === 'es' ? gallery.nameEsp : gallery.nameEng}
                </p>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div style={{ width: '100%' }}>
                    <Button
                      variant="primary"
                      block
                      onClick={() => navigateToGallery(gallery.id)}
                      className="p-2 w-100"
                      style={{ fontSize: '12px' }}
                    >
                      {t('galleries.galleriesSection.body.secondBtnText')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {galleries.length === 0 && (
        <h5 className="d-flex justify-content-center">
          {t('galleries.galleriesSection.body.noGalleriesFound')}
        </h5>
      )}
    </section>
  );
};

export default GalleriesGrid;
