import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import RouteDataTable from './RouteDataTable';

import { debounceIndexAdminRoutesRequest } from '../../../requests/admin/adminRoutes';

import useFetchData from '../../../hooks/useFetchData';

const RouteIndex = ({ routeType }) => {
  const [customParams, setCustomParams] = useState({
    display_length: 10,
    route_type_filter: routeType
  });

  const {
    data: routes,
    totalData: totalRoutes,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminRoutesRequest,
    customParams
  });

  const clearCustomParams = () => {
    setCustomParams({ display_length: 10, route_type_filter: routeType });
  };
  useEffect(clearCustomParams, [isFetching]);

  const modelUrl = '/admin/routes';

  return (
    <>
      <Row id="index-admin-routes">
        <Col />
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to={`/admin/routes/new?type=${routeType}`}
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <RouteDataTable
        tableData={routes}
        tableDataAmount={totalRoutes}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(RouteIndex));
