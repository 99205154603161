// navimag_frontend/src/app/screens/AdminScreens/Galleries/GalleryNew.js
/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import GalleryForm from './GalleryForm';
import { createAdminGalleryRequest } from '../../../requests/admin/adminGalleries';
import { sendAlert } from '../../../actions/utils';

const GalleryNew = props => {
  const { history } = props;
  const [gallery] = useState({
    name: '',
    active: true,
    position: null,
    attachedImages: []
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    history.push({
      pathname: '/admin/galleries', // Cambia según la ruta adecuada
      state: { type: 'Galerías' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Galería creada con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _gallery => {
    createAdminGalleryRequest({
      dispatch,
      params: _gallery,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-galleries" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear Galería</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <GalleryForm
        gallery={gallery}
        action="new"
        formRequest={handleCreateRequest}
      />
    </div>
  );
};

export default connect()(GalleryNew);
