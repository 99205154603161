import React, { useState, useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@atlaskit/css-reset';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakecaseKeys from 'snakecase-keys';
import { Button, Row, Col } from 'react-bootstrap';

import LoadSpinner from '../../../components/Utils/Spinner';
import LanguageButtons from '../../../components/Utils/Button/LanguageButtons';
import Column from '../../../components/Utils/MultiDragAndDrop';
import { DefaultModal } from '../../../components';
import AdminNavbarNew from './AdminNavbarNew';
import AdminNavbarEdit from './AdminNavbarEdit';

import {
  indexAdminNavbarRequest,
  updateAdminNavbarTreeRequest,
  deleteAdminNavbarRequest
} from '../../../requests/admin/navbar';

const Container = styled.div`
  display: flex;
`;

const AdminNavbarDragAndDrop = ({ barType, language, cargo, setLanguage }) => {
  const [data, setData] = useState([]);
  const [isRoot, setIsRoot] = useState(true);
  const [editCategoryData, setEditCategoryData] = useState({});
  const [deleteCategoryId, setDeleteCategoryId] = useState(undefined);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [fetchData, setFetchData] = useState(true);
  const [isFetching, setIsFetching] = useState(true);

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  const fetchNavbarData = () => {
    setIsFetching(true);
    indexAdminNavbarRequest({
      dispatch,
      params: {
        bar_type: barType,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setData(camelCaseRecursive(result.data.data));
        setIsFetching(false);
      }
    });
  };

  const updateData = () => {
    setFetchData(!fetchData);
  };

  const closeNewModal = () => {
    setShowNewCategoryModal(false);
  };

  const closeEditModal = () => {
    setShowEditCategoryModal(false);
  };

  const closeDeleteModal = () => {
    setDeleteCategoryModal(false);
  };

  const sendDeleteRequest = () => {
    deleteAdminNavbarRequest(deleteCategoryId, {
      dispatch,
      successCallback: () => {
        setDeleteCategoryId(undefined);
        updateData();
        closeDeleteModal();
      }
    });
  };

  useEffect(fetchNavbarData, [fetchData, barType]);

  const updateNavbarTree = params => {
    updateAdminNavbarTreeRequest({
      dispatch,
      params
    });
  };

  const onDragStart = start => {
    document.body.style.color = 'orange';
    document.body.style.transition = 'background-color 0.2s ease';

    // does not allow to move subcategory to previous columns
    const homeIndex = data.columnOrder.indexOf(start.source.droppableId);
    setData({ ...data, homeIndex });
  };

  const onDragUpdate = update => {
    const { destination } = update;
    const opacity = destination
      ? destination.index / Object.keys(data.subcategories).length
      : 0;

    document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`;
  };

  const onDragEnd = result => {
    document.body.style.color = 'inherit';
    document.body.style.backgroundColor = 'inherit';

    // does not allow to move subcategory to previous columns
    setData({ ...data, homeIndex: null });

    const { draggableId, source, destination, type } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = [...data.columnOrder];
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newData = {
        ...data,
        columnOrder: newColumnOrder
      };

      updateNavbarTree(snakecaseKeys(newData));
      setData(newData);

      return;
    }

    const start = data.columns[source.droppableId];
    const finish = data.columns[destination.droppableId];

    if (start === finish) {
      const newCategoryIds = [...start.categoryIds];
      // Remove item from list
      newCategoryIds.splice(source.index, 1);
      // Place it back in same list in new position.
      newCategoryIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        categoryIds: newCategoryIds
      };

      const newData = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.categoryId]: newColumn
        }
      };
      updateNavbarTree(snakecaseKeys(newData));
      setData(newData);
      return;
    }

    // Moving from one list to another
    const startCategoryIds = [...start.categoryIds];
    startCategoryIds.splice(source.index, 1);
    const newStart = {
      ...start,
      categoryIds: startCategoryIds
    };

    const finishCategoryIds = [...finish.categoryIds];
    finishCategoryIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      categoryIds: finishCategoryIds
    };

    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [newStart.categoryId]: newStart,
        [newFinish.categoryId]: newFinish
      }
    };

    updateNavbarTree(snakecaseKeys(newData));
    setData(newData);
  };

  return isFetching ? (
    <LoadSpinner />
  ) : (
    <div className="container-fluid">
      <Row className="d-flex justify-content-end drag-drop-btns">
        <Col>
          {!cargo && (
            <LanguageButtons language={language} setLanguage={setLanguage} />
          )}
        </Col>
        <Button
          className="create-btn mr-3"
          onClick={() => {
            setIsRoot(true);
            setShowNewCategoryModal(true);
          }}
        >
          Añadir categoría
        </Button>
        <Button
          className="create-btn"
          onClick={() => {
            setIsRoot(false);
            setShowNewCategoryModal(true);
          }}
        >
          Añadir sub-categoría
        </Button>
      </Row>

      <div className="container-cards-navbar">
        <DragDropContext
          onDragStart={onDragStart}
          onDragUpdate={onDragUpdate}
          onDragEnd={onDragEnd}
        >
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="column"
          >
            {provided => (
              <Container {...provided.droppableProps} ref={provided.innerRef}>
                {data?.columnOrder.map((columnId, index) => {
                  const column = data?.columns[columnId];
                  const subcategories = column?.categoryIds.map(
                    subcategoryId => data?.subcategories[subcategoryId]
                  );

                  // does not allow to move subcategory to previous columns
                  // const isDropDisabled = index < data.homeIndex;
                  const isDropDisabled = false;
                  return (
                    <Column
                      key={column.categoryId}
                      column={column}
                      subcategories={subcategories}
                      isDropDisabled={isDropDisabled}
                      index={index}
                      setEditCategoryData={setEditCategoryData}
                      setOpenEditModal={setShowEditCategoryModal}
                      setDeleteCategoryId={setDeleteCategoryId}
                      setOpenDeleteModal={setDeleteCategoryModal}
                      setIsRoot={setIsRoot}
                    />
                  );
                })}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </DragDropContext>
        <DefaultModal
          title={isRoot ? 'Añadir categoría' : 'Añadir sub-categoría'}
          body={
            <AdminNavbarNew
              isRoot={isRoot}
              closeModal={closeNewModal}
              updateData={updateData}
              barType={barType}
              language={language}
            />
          }
          handleClose={closeNewModal}
          show={showNewCategoryModal}
        />
        <DefaultModal
          title={isRoot ? 'Editar categoría' : 'Editar sub-categoría'}
          body={
            <AdminNavbarEdit
              editCategoryData={editCategoryData}
              isRoot={isRoot}
              closeModal={closeEditModal}
              updateData={updateData}
              barType={barType}
              language={language}
            />
          }
          handleClose={closeEditModal}
          show={showEditCategoryModal}
        />
        <DefaultModal
          title={
            isRoot
              ? '¿Estás seguro de eliminar esta Categoría?'
              : '¿Estás seguro de eliminar esta Sub-categoría?'
          }
          body={
            isRoot ? (
              <div>
                <p>
                  Eliminar esta categoría eliminará todas las subcategorías bajo
                  esta categoría
                </p>
              </div>
            ) : (
              <div>
                <p>Una vez eliminada no podras recuperar esta categoría</p>
              </div>
            )
          }
          titleBtnClose="Cancelar"
          titleBtnSave="Sí, Eliminar"
          handleConfirm={sendDeleteRequest}
          handleClose={closeDeleteModal}
          show={showDeleteCategoryModal}
        />
      </div>
    </div>
  );
};

export default AdminNavbarDragAndDrop;
