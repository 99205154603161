import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

import cargoArticulatedTruck from '../../../assets/images/cargo/cargo-articulated_trucks.svg';
import cargoBuses from '../../../assets/images/cargo/cargo-buses.svg';
import cargoLightWeightVehicles from '../../../assets/images/cargo/cargo-lightweight_vehicles.svg';
import cargoMachinery from '../../../assets/images/cargo/cargo-machinery.svg';
import cargoMotorcycles from '../../../assets/images/cargo/cargo-motorcycles.svg';
import cargoTowing from '../../../assets/images/cargo/cargo-towing.svg';
import cargoTrucks from '../../../assets/images/cargo/cargo-trucks.svg';
import cargoGeneral from '../../../assets/images/cargo/cargo-general.svg';
import cargoVan from '../../../assets/images/cargo/cargo-van.svg';
import cargoPerson from '../../../assets/images/cargo/cargo-person.svg';

import { useWindowSize } from '../../../hooks';
import { uuidV4 } from '../../../services/utils';

const imageMapping = {
  articulated_trucks: cargoArticulatedTruck,
  buses: cargoBuses,
  lightweight_vehicles: cargoLightWeightVehicles,
  machinery: cargoMachinery,
  motorcycles: cargoMotorcycles,
  towing: cargoTowing,
  trucks: cargoTrucks,
  vans: cargoVan,
  carrier: cargoPerson,
  general_cargo: cargoGeneral
};

const CargoLandingServiceCard = ({
  size,
  colorText,
  colorCard,
  colorBorder,
  title,
  imgSrc,
  additionalElements,
  altMessage = ''
}) => {
  const [animationIn, setAnimationIn] = useState('');
  const [animationOut, setAnimationOut] = useState('');

  const [isMobile, setIsMobile] = useState(false);
  const { windowSize } = useWindowSize();
  const updateMobileSize = () => {
    if (windowSize.width < 991) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(updateMobileSize, [windowSize]);
  return (
    <Card
      bg={colorCard}
      text={colorText}
      className="cargo-landing-service-card text-center"
      style={{ width: size }}
      border={colorBorder}
      onMouseEnter={() => {
        if (isMobile) {
          setAnimationIn('');
          setAnimationOut('');
        } else {
          setAnimationIn('animation-in');
          setAnimationOut('');
        }
      }}
      onMouseLeave={() => {
        if (isMobile) {
          setAnimationIn('');
          setAnimationOut('');
        } else {
          setAnimationOut('animation-out');
          setAnimationIn('');
        }
      }}
    >
      <Card.Img className="card-image-container" variant="top" src={imgSrc} />
      <Card.Title className="card-title-text mt-2">{title}</Card.Title>
      <Card.Body className={`card-body ${animationIn} ${animationOut}`}>
        {additionalElements.map(element => {
          return (
            <div className="card-body-container mb-3" key={uuidV4()}>
              <Card.Img
                className="card-body-category-image"
                src={imageMapping[element.vehicleTypeCategory]}
              />
              <Card.Text className="card-body-category-text">
                {element.vehicleLength ? element.vehicleLength : altMessage}
              </Card.Text>
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

CargoLandingServiceCard.propTypes = {
  size: PropTypes.string,
  colorText: PropTypes.string,
  colorCard: PropTypes.string,
  colorBorder: PropTypes.string
};

CargoLandingServiceCard.defaultProps = {
  size: '100%',
  colorText: 'black',
  colorCard: 'light',
  colorBorder: null
};

export default CargoLandingServiceCard;
