import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CarouselComponent } from '../../../components';
import { indexSlidesRequest } from '../../../requests/slides';
import { redirectToReservationWithoutParams } from '../../../services/utils';

const FifthSection = ({ isDesktop }) => {
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.utils);
  const language = localStorage.getItem('language') || 'es';
  const { t, i18n } = useTranslation();
  const [navimagExperienceTitle, setNavimagExperienceTitle] = useState('');
  const [navimagExperienceSubTitle, setNavimagExperienceSubTitle] = useState(
    ''
  );
  const [
    navimagExperienceDescription,
    setNavimagExperienceDescription
  ] = useState('');
  const history = useHistory();

  const setNavimagExperienceData = () => {
    const title =
      language === 'en'
        ? 'landingOnBoardExperienceTitleEn'
        : 'landingOnBoardExperienceTitleEs';
    const subtitle =
      language === 'en'
        ? 'landingOnBoardExperienceSubtitleEn'
        : 'landingOnBoardExperienceSubtitleEs';
    const description =
      language === 'en'
        ? 'landingOnBoardDescriptionEn'
        : 'landingOnBoardDescriptionEs';

    const titleValue = settings[title] || '';
    const subtitleValue = settings[subtitle] || '';
    const descriptionValue = settings[description] || '';

    setNavimagExperienceTitle(titleValue);
    setNavimagExperienceSubTitle(subtitleValue);
    setNavimagExperienceDescription(descriptionValue);
    i18n.changeLanguage(language);
  };

  const slidesRequest = () => {
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: 5,
        slide_type: 'navimag_experience'
      },
      successCallback: result => {
        const updatedSlides = camelCaseRecursive(result.data.data).map(
          slide => ({
            ...slide,
            img: slide.fileInfo.fileUrl
          })
        );
        setSlides(updatedSlides);
      }
    });
  };

  const redirectToVideos = () => {
    history.push(`/videos-guides`);
  };

  useEffect(setNavimagExperienceData, [settings, language]);
  useEffect(slidesRequest, []);

  return (
    <Row className="fifth-section mb-4">
      <Col md={5}>
        <CarouselComponent
          noArrow
          className="carousel-landing"
          classNameImg="img-fifth-section"
          data={slides}
        />
      </Col>
      <Col
        md={isDesktop ? 7 : 12}
        className="d-flex flex-column justify-content-center col-right"
      >
        <h2 className="text-primary mb-2 title">{navimagExperienceTitle}</h2>
        <h5 className="mb-3">{navimagExperienceSubTitle}</h5>
        <p>{navimagExperienceDescription}</p>
        <div className="d-flex justify-content-end mt-4">
          <Button
            onClick={redirectToReservationWithoutParams}
            variant="dark"
            className="mr-3 btn-mobile px-3 py-2"
          >
            {t('tourism.landing.navimagExperience.bookMyTrip')}
          </Button>
          <Button
            onClick={redirectToVideos}
            variant="transparent"
            className="btn-mobile white px-3 py-2"
          >
            {t('tourism.landing.navimagExperience.openVideo')}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default FifthSection;
