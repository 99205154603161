import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const useFetchForRemoteSelect = ({
  indexRequest,
  debouncedIndexRequest,
  value,
  initialParams,
  nestedDependency,
  forSelector = true
}) => {
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const stableDispatch = useCallback(dispatch, []);
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const sendParams = {
    for_selector: true,
    sort_column: 'name',
    sort_direction: 'asc',
    display_length: 1000
  };

  if (!forSelector) delete sendParams.for_selector;

  const initialFetch = useCallback(
    params => {
      indexRequest({
        dispatch: stableDispatch,
        params: {
          ...sendParams,
          ...params,
          ...initialParams,
          cancelToken: cancelToken.token
        },
        successCallback: response => {
          setOptions(response.data.data);
          setIsFetching(false);
        }
      });
      setIsFetching(true);
    },
    [stableDispatch, nestedDependency]
  );

  const fetchOptions = (inputValue, callback) => {
    debouncedIndexRequest({
      dispatch,
      params: {
        query: inputValue,
        sort_column: 'name',
        sort_direction: 'asc',
        display_length: 1000,
        ...sendParams,
        ...initialParams
      },
      successCallback: response => callback(response.data.data)
    });
  };

  const selectedOption = options.find(
    option =>
      option.value === parseInt(value, 10) || option.id === parseInt(value, 10)
  );

  useEffect(() => {
    initialFetch(initialParams);
    return () => cancelToken.cancel();
  }, [initialFetch]);

  return { selectedOption, options, fetchOptions, setOptions, isFetching };
};

export default useFetchForRemoteSelect;
