import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../components';
import { sendAlert } from '../../actions/utils';
import { forgotPasswordRequest } from '../../requests/utils';

const RecoverPassword = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;

  return (
    <Row className="justify-content-center align-items-center full-height">
      <Col md={4}>
        <h4>CAMBIAR CONTRASEÑA</h4>
        <Form>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                label="E-mail"
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Cambiar Contraseña
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);
