import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Image, Container } from 'react-bootstrap';

import rightLeftIcon from '../../../assets/images/right-left-solid.svg';

const ItinerarySection = () => {
  const routesData = [
    {
      title: 'Ruta Natales',
      origin: 'Pto. Montt',
      destination: 'Pto. Natales',
      description:
        'El ferry recorre más de 1600 kilómetros en 70 horas a través de los fiordos patagónicos.'
    },
    {
      title: 'Ruta Chacabuco',
      origin: 'Pto. Montt',
      destination: 'Chacabuco',
      description:
        'Trayecto de 23 horas a puerto Chacabuco, acceso para Puerto Aysén y Coyhaique.'
    }
  ];

  return (
    <div className="itinerary-section">
      <h4 className="itinerary-section-title mb-3">Itinerarios</h4>
      <p className="itinerary-section-description mb-4 text-center p-4">
        Tenemos zarpes disponibles todas las semanas del año.Trasladamos carga y
        vehículos desde tres puertos del sur de Chile. A continuación, nuestras
        rutas
      </p>
      <Container>
        <Row className="itinerary-section-cards-container">
          {routesData.map(route => (
            <Col key={route.title}>
              <div className="itinerary-card d-flex flex-column justify-content-center">
                <h5 className="text-primary text-center">{route.title}</h5>
                <div className="d-flex justify-content-center my-2">
                  <h5>{route.origin}</h5>
                  <Image
                    src={rightLeftIcon}
                    alt="icono person"
                    className="mx-3"
                  />
                  <h5>{route.destination}</h5>
                </div>
                <p className="text-center p-4">{route.description}</p>
                <div className="d-flex flex-column p-4 mx-4">
                  <Button
                    variant="primary-outline mb-2"
                    as={Link}
                    to={{ pathname: '/cargo/contact' }}
                  >
                    Contactar asesor
                  </Button>
                  <Button
                    variant="primary"
                    as={Link}
                    to={{ pathname: '/cargo/itineraries' }}
                  >
                    Ver itinerario
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default ItinerarySection;
