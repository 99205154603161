import ApiService from '../services/apiService';

export const indexCargoAvailabilityRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) => {
  ApiService.request('get', '/navimag_api_cargo', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexCargoAvailabilityShipsRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) =>
  ApiService.request('get', `/navimag_api_cargo/cargo_ships`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexCargoAvailabilityRoutesRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) =>
  ApiService.request('get', `/navimag_api_cargo/cargo_routes`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexCargoAvailabilityRoutesItinerariesRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) =>
  ApiService.request('get', `/navimag_api_cargo/cargo_route_itineraries`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexCargoAvailabilityItineraryAvailabilityRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) =>
  ApiService.request('get', `/navimag_api_cargo/cargo_itinerary_availability`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexAvailabileItinerariesRequest = ({
  dispatch,
  params = {},
  successCallback,
  failureCallback
}) =>
  ApiService.request('get', `/navimag_api_cargo/available_itineraries`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
