import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import ModelFilter from '../../../components/Utils/Filter/ModelFilter';
import PageIndex from '../Pages/PageIndex';
import BlogIndex from '../Blogs/BlogIndex';

const NavbarAndFoote = props => {
  const { location } = props;
  const { state } = location;
  const { type } = state || {};
  const [selectedModel, setSelectedModel] = useState('Páginas');

  useEffect(() => {
    if (type) {
      setSelectedModel(type);
    }
  }, [type]);

  return (
    <>
      <Row>
        <Col>
          <h4 className="text-primary mt-4 ml-4">Páginas & Blogs</h4>
        </Col>
      </Row>
      <ModelFilter
        models={['Páginas', 'Blogs']}
        setSelectedModel={setSelectedModel}
        selectedModel={selectedModel}
      />
      <Row style={{ marginTop: '80px' }}>
        <Col>
          {selectedModel === 'Páginas' && <PageIndex />}
          {selectedModel === 'Blogs' && <BlogIndex />}
        </Col>
      </Row>
    </>
  );
};

export default NavbarAndFoote;
