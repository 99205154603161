import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Spinner, Col, Row } from 'react-bootstrap';
import axios from 'axios';

import ourLeadersRequest from '../../requests/ourLeaders';
import { useScrollPosition } from '../../hooks';
import { uuidV4 } from '../../services/utils';
import OurLeaderCard from '../Utils/Cards/OurLeaderCard';

const OurLeadersIndex = ({ setShowSubHeader = () => {} }) => {
  const { t, i18n } = useTranslation();
  const [ourLeaders, setOurLeaders] = useState([]);
  const [loading, setIsLoading] = useState(undefined);
  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const ourLeadersRequestWrapper = () => {
    setIsLoading(true);
    ourLeadersRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        sort_column: 'position',
        cancelToken: cancelToken.token,
        language
      },
      successCallback: result => {
        setOurLeaders(camelCaseRecursive(result.data.data));
        setIsLoading(false);
      }
    });
    return () => cancelToken.cancel();
  };

  const ratesRef = useRef();
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (ratesRef.current !== null) {
      const { offsetTop } = ratesRef.current;
      if (scrollPosition.y >= offsetTop - 425) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };

  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(evaluatePosition, [scrollPosition]);
  useEffect(ourLeadersRequestWrapper, []);
  useEffect(() => {
    const lan = localStorage.getItem('language') || 'es';
    i18n.changeLanguage(lan);
    goToTop();
  }, []);

  return (
    <div className="my-4 our-leaders-content-box">
      <div className="mb-5">
        <h1 className="text-center text-primary mb-2 title">
          {t('tourism.ourLeaders.body.title')}
        </h1>
        <p className="our-leaders-description-text mt-3 mb-4">
          {t('tourism.ourLeaders.body.description')}
        </p>
      </div>

      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: '460px' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!loading && (
        <Row
          ref={ratesRef}
          className="align-items-center justify-content-center mt-4"
        >
          {ourLeaders.map(ourLeader => {
            return (
              <Col
                className="d-flex justify-content-center"
                sm={12}
                md={6}
                lg={6}
                xl={6}
                key={uuidV4()}
              >
                <OurLeaderCard ourLeader={ourLeader} />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default OurLeadersIndex;
