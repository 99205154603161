import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput } from '../../components';
import { signUpRequest } from '../../actions/auth';

const Register = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  return (
    <Row className="justify-content-center align-items-center full-height">
      <Col md={4}>
        <h4>CREAR CUENTA</h4>
        <Form>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                label="E-mail"
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                label="Contraseña"
                placeholder="Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="passwordConfirmation">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                label="Confirmar Contraseña"
                placeholder="Confirmar Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            Crear Cuenta
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Obligatorio'),
  password: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres'),
  passwordConfirmation: Yup.string()
    .required('Este campo es obligatorio')
    .min(6, 'La contraseña debe tener más de 6 caracteres')
    .test(
      'password-confirmation-test',
      'Las contraseñas no coinciden',
      function equalTo(value) {
        if (value && value === this.parent.password) {
          return true;
        }
        return false;
      }
    )
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    signUpRequest({
      user: {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Register)
  )
);
