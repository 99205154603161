import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ferryEsperanza from '../../assets/images/rates/ferry-esperanza.png';
import navimagExperience from '../../assets/images/rates/navimag-experience.png';

const KnowMoreCards = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const goToTop = () => {
    if (location.pathname === '/tourism-rates') {
      window.scrollTo(0, 0);
    } else {
      history.push('/tourism-rates');
    }
  };

  return (
    <div className="route-services-card">
      <div
        className="route-services-card-item"
        style={{ backgroundImage: `url(${ferryEsperanza})` }}
      >
        <p>{t('tourism.rateSection.body.ferryDescription')}</p>
        <div className="d-flex mt-3">
          <Button
            type="submit"
            variant="primary"
            className="route-services-card-btn mr-2"
            onClick={() => goToTop()}
          >
            {t('tourism.rateSection.body.reserveTrip')}
          </Button>
          <Button
            as={Link}
            to={{ pathname: '/videos-guides', state: { type: 'guides' } }}
            type="submit"
            variant="light"
            className="route-services-card-btn"
          >
            {t('tourism.rateSection.body.learnMoreFerries')}
          </Button>
        </div>
      </div>
      <div
        className="route-services-card-item"
        style={{ backgroundImage: `url(${navimagExperience})` }}
      >
        <p>{t('tourism.rateSection.body.experienceDescription')}</p>
        <div className="d-flex mt-3">
          <Button
            type="submit"
            variant="primary"
            className="route-services-card-btn mr-2"
            onClick={goToTop}
          >
            {t('tourism.rateSection.body.reserveTrip')}
          </Button>
          <Button
            as={Link}
            to={{ pathname: '/videos-guides', state: { type: 'videos' } }}
            type="submit"
            variant="light"
            className="route-services-card-btn"
          >
            {t('tourism.rateSection.body.watchVideos')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default KnowMoreCards;
