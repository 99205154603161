import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import OurLeaderForm from './OurLeaderForm';
import ourLeaderData from '../../../components/OurLeaders/ourLeader';

import {
  showAdminOurLeaderRequest,
  updateAdminOurLeaderRequest
} from '../../../requests/admin/adminOurLeaders';

import { sendAlert } from '../../../actions/utils';

const OurLeaderEdit = props => {
  const { history } = props;
  const [ourLeader, setOurLeader] = useState(ourLeaderData);
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _ourLeader = camelCaseRecursive(response.data);
    setOurLeader(_ourLeader);
  };

  useEffect(() => {
    const { match } = props;
    const ourLeaderId = match.params.id;
    showAdminOurLeaderRequest(ourLeaderId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: '/admin/our_leaders',
      state: { type: 'Rutas' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Líder actualizado con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _ourLeader => {
    const ourLeaderId = _ourLeader.our_leader.id;
    updateAdminOurLeaderRequest(ourLeaderId, {
      dispatch,
      params: _ourLeader,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-ourLeader" className="mb-4">
        <Col>
          <h4 className="text-primary">Editar líder</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <OurLeaderForm
        ourLeader={ourLeader}
        action="edit"
        formRequest={handleUpdateRequest}
      />
    </div>
  );
};

export default connect()(OurLeaderEdit);
