import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import UserDataTable from './UserDataTable';
import { debounceIndexAdminUsersRequest } from '../../../requests/admin/adminUsers';
import useFetchData from '../../../hooks/useFetchData';

const UserIndex = () => {
  const {
    data: users,
    totalData: totalUsers,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminUsersRequest
  });

  const modelUrl = '/admin/users';

  return (
    <>
      <Row id="index-users" className="pt-4">
        <Col>
          <h4 className="text-primary ml-4">Usuarios</h4>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="create-btn"
            block
            to="/admin/users/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <UserDataTable
        tableData={users}
        tableDataAmount={totalUsers}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(UserIndex));
