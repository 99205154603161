import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import axios from 'axios';

import shipImage from '../../assets/images/carga_A739258-1.webp';
import { useWindowSize } from '../../hooks';
import FirstSection from './Landing/FirstSection';
import CardsSection from './Landing/CardsSection';
import RouteAndTerminals from './Landing/RouteAndTerminals';
import TransportSection from './Landing/TransportSection';
import carIcon from '../../assets/images/car-black-icon.svg';
import personIcon from '../../assets/images/person.svg';
import SEO from '../../components/Utils/SEO/SEO';
import AvailableItineraries from '../../components/Landing/Routes/AvailableItineraries';
import TransportServicesSection from './Landing/TransportServicesSection';
import { indexRoutesRequest } from '../../requests/routes';

const HomeCargo = () => {
  const [isDesktop, setIsDesktop] = useState(true);
  const { windowSize } = useWindowSize();
  const history = useHistory();
  const { settings } = useSelector(state => state.utils);
  const [availableCargoItineraries, setAvailableCargoItineraries] = useState(
    []
  );
  const [routes, setRoutes] = useState([]);

  const cancelToken = axios.CancelToken.source();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routesRequestWrapper = () => {
    indexRoutesRequest({
      dispatch,
      params: {
        actives: true,
        route_type_filter: 'cargo',
        sort_direction: 'asc',
        sort_column: 'position',
        language: localStorage.getItem('language'),
        cancelToken: cancelToken.token
      },
      successCallback: result => {
        setRoutes(camelCaseRecursive(result?.data?.data));
      }
    });
    return () => cancelToken.cancel();
  };

  const updateAvailableCargoItineraries = () => {
    const data = camelCaseRecursive(
      JSON.parse(settings.availableCargoItineraries)
    );
    setAvailableCargoItineraries(data);
  };
  const cargoAvailabilityRedirect = () => {
    history.push(`/cargo/availability`);
  };

  const cargoItineraryRedirect = () => {
    history.push(`/cargo/itineraries`);
  };

  const cargoServicesRedirect = () => {
    history.push(`/cargo/services`);
  };

  useEffect(() => {
    if (windowSize.width > 991) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [windowSize.width]);

  useEffect(() => {
    // Scroll hacia arriba al cargar la página
    window.scrollTo(0, 0);
  }, []);

  useEffect(routesRequestWrapper, []);
  useEffect(updateAvailableCargoItineraries, [settings]);

  return (
    <div className="landing-cargo">
      <SEO
        title="Navimag: Transporte de vehículos y carga en el Sur de Chile"
        description="Transporta carga y vehículos de forma segura y confiable con la experiencia y respaldo que solo Navimag puede ofrecer. ¡Cotiza tu servicio con nosotros!"
      />
      <FirstSection
        title="Navimag Carga"
        subtitle="Transporta lo que necesites desde Puerto Montt, Chacabuco o Puerto Natales."
        body={<LandingButtons />}
        slideType="landing_cargo"
      />
      <CardsSection
        cargoAvailabilityRedirect={cargoAvailabilityRedirect}
        cargoItineraryRedirect={cargoItineraryRedirect}
        cargoServicesRedirect={cargoServicesRedirect}
      />
      <TransportServicesSection settings={settings} routes={routes} />
      <AvailableItineraries
        availableDates={availableCargoItineraries}
        aditionalStyles={{ background: '#F1F1F1', borderRadius: '30px' }}
        shipImage={shipImage}
        isCargo
      />
      <RouteAndTerminals isDesktop={isDesktop} />
      <TransportSection />
    </div>
  );
};

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(HomeCargo);

const LandingButtons = () => {
  const history = useHistory();

  const cargoLeadRedirect = () => {
    history.push(`/cargo/leads`);
  };
  const contactRedirect = () => {
    history.push('/cargo/contact');
  };
  return (
    <Row className="align-items-center justify-content-center mt-5">
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          onClick={contactRedirect}
          type="submit"
          variant="primary"
          className="p-3 w-75"
        >
          Contacta a un asesor
          <Image src={personIcon} alt="icono person" className="ml-2" />
        </Button>
      </Col>
      <Col md={4} xl={3} className="mt-5 d-flex justify-content-center">
        <Button
          type="submit"
          variant="light"
          className="p-3 w-75"
          style={{ borderRadius: '10px', color: '$primary' }}
          onClick={cargoLeadRedirect}
        >
          Cotiza tu carga
          <Image
            src={carIcon}
            alt="icono auto"
            className="ml-2"
            style={{ fill: 'red !important' }}
          />
        </Button>
      </Col>
    </Row>
  );
};
