import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useAbility } from '@casl/react';
import jwt from 'jwt-decode';

import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import { getSettings, sendAlert } from './app/actions/utils';
import { AbilityContext } from './app/config/abilityContext';
import { updateAbility } from './app/config/ability';
import { requestSignOut } from './app/actions/auth';
import './app/config/i18n';
import './index.scss';

const App = () => {
  const { signedIn } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const ability = useAbility(AbilityContext);

  const handleOnLogin = () => {
    if (!signedIn) return;
    updateAbility(ability);
  };

  const validLanguage = language => {
    const validLanguages = ['es', 'en'];
    return validLanguages.includes(language.toLowerCase());
  };

  const removeURLParameter = (url, parameter) => {
    const urlparts = url.split('?');
    if (urlparts.length >= 2) {
      const prefix = `${encodeURIComponent(parameter)}=`;
      const pars = urlparts[1].split(/[&;]/g);

      for (let i = pars.length - 1; i > 0; i -= 1) {
        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
          pars.splice(i, 1);
        }
      }
      return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
    }
    return url;
  };

  const setInitialLanguage = () => {
    const dontOverride = localStorage.getItem('dontOverride') === 'true';
    const urlParams = new URLSearchParams(window.location.search);
    const language = urlParams.get('language');
    const urlPath = window.location.pathname;

    if (urlPath.search('/es/') !== -1 && language === null && !dontOverride) {
      localStorage.setItem('language', 'es');
    }

    if (urlPath.search('/en/') !== -1 && language === null && !dontOverride) {
      localStorage.setItem('language', 'en');
    }

    if (language !== null && validLanguage(language) && !dontOverride) {
      localStorage.setItem('language', language.toLocaleLowerCase());
      localStorage.setItem('dontOverride', true);
      window.location.replace(
        removeURLParameter(window.location.href, 'language')
      );
    }

    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'es');
    }
    if (dontOverride) {
      localStorage.setItem('dontOverride', false);
    }
  };

  useEffect(setInitialLanguage, []);
  useEffect(handleOnLogin, [signedIn]);

  useEffect(() => {
    const payload = localStorage.getItem('jwt');

    dispatch(getSettings());

    if (!payload) return;
    const currentTime = Date.now() / 1000;
    const isExpired = currentTime >= jwt(payload).exp;

    if (isExpired) {
      dispatch(sendAlert({ kind: 'error', message: 'Tu sesión ha expirado' }));
      dispatch(requestSignOut());
    }
  }, []);

  return (
    <GlobalErrorBoundary>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      <Alert />
    </GlobalErrorBoundary>
  );
};

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
