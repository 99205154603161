import React from 'react';

import { uuidV4 } from '../../../services/utils';

const RouteFilter = ({
  routes,
  setSelectedRoute,
  selectedRoute,
  setIsFirstRoute = () => {},
  setShowSpecialPriceDisclaimer = () => {},
  customContainerStyle,
  customSelectorBoxStyle,
  customSelectorBoxTextStyle,
  customSelectedBoxStyle
}) => {
  const containerStyle = customContainerStyle || 'route-selector-container';
  const selectorBoxStyle = customSelectorBoxStyle || 'route-selector-box';
  const textStyle = customSelectorBoxTextStyle || 'route-selector-box-text';
  const selectedStyle = customSelectedBoxStyle || 'route-selector-box-active';

  return (
    <div className={containerStyle}>
      {routes?.map(route => (
        <div
          key={uuidV4()}
          aria-hidden="true"
          onClick={() => {
            setSelectedRoute(route);
            setShowSpecialPriceDisclaimer(false);
            if (route === routes[0]) {
              setIsFirstRoute(true);
            } else {
              setIsFirstRoute(false);
            }
          }}
          className={`${selectorBoxStyle} ${
            selectedRoute?.id === route?.id ? selectedStyle : ''
          }`}
        >
          <p
            className={`${textStyle} ${
              selectedRoute?.id === route?.id ? 'text-light' : ''
            }`}
          >
            {route.routeName}
          </p>
        </div>
      ))}
    </div>
  );
};

export default RouteFilter;
