import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { useAbility } from '@casl/react';

import { setSidebar } from '../../../actions/utils';
// import AdminSidebarLinks from './AdminSidebarLinks';
import SidebarBasic from './SidebarBasic';
import { validateToken } from '../../../actions/auth';

// import { AbilityContext } from '../../../config/abilityContext';

const Sidebar = ({ sidebarOpen }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const location = useLocation();
  const { pathname } = location;
  const closeSidebar = () => dispatch(setSidebar({ show: false }));

  const pathName = () => {
    const paths = pathname.split('/');
    return paths[1];
  };

  // const isActive = path => {
  //   return pathname.includes(path);
  // };

  // const ability = useAbility(AbilityContext);

  useEffect(() => {
    if (user.id === 0) {
      dispatch(validateToken());
    }
  }, []);

  return (
    <>
      <div className={`sidebar ${!sidebarOpen ? 'hidden-sidebar' : ''}`}>
        <>
          <SidebarBasic pathName={pathName()} closeSidebar={closeSidebar} />
          {/* {ability.can('manage', 'admin') && (
            <AdminSidebarLinks
              isActive={isActive}
              handleCloseSidebar={closeSidebar}
            />
          )} */}
        </>
      </div>
      <div
        className={`${sidebarOpen ? 'overlay' : 'overlay-hidden'}`}
        onClick={closeSidebar}
        aria-hidden="true"
      />
    </>
  );
};

export default Sidebar;
