import React, { useRef, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import RouteFilter from '../Utils/Filter/RouteFilter';
import { uuidV4 } from '../../services/utils';
import { useScrollPosition } from '../../hooks';

const RoutesBreakdown = ({
  routes,
  setSelectedRoute,
  selectedRoute,
  setShowSubHeader = () => {}
}) => {
  const { t } = useTranslation();
  const routesBreakdownRef = useRef();
  const { scrollPosition } = useScrollPosition();

  const evaluatePosition = () => {
    if (routesBreakdownRef.current !== null) {
      const { offsetTop } = routesBreakdownRef.current;
      if (scrollPosition.y >= offsetTop + 400) {
        setShowSubHeader(true);
      } else {
        setShowSubHeader(false);
      }
    }
  };
  useEffect(evaluatePosition, [scrollPosition]);

  return (
    <>
      <Row className="m-4">
        <Col>
          <h2 className="text-justify text-start text-primary mb-2 title">
            {t('tourism.theRoute.routeBreakDown.routeTitle')}
          </h2>
        </Col>
        <Col>
          <RouteFilter
            routes={routes}
            setSelectedRoute={setSelectedRoute}
            selectedRoute={selectedRoute}
          />
        </Col>
      </Row>

      <Row ref={routesBreakdownRef}>
        <Col className="route-day-card-container">
          {selectedRoute?.daysItineraryDescriptionsAttributes.map(
            (data, index) => (
              <Card key={uuidV4()} className="route-day-card">
                <Card.Title className="route-day-title">
                  {`${t('tourism.theRoute.routeBreakDown.day')} ${index + 1}: ${
                    data.mainTitle
                  }`}
                </Card.Title>
                <Card.Body className="route-day-description">
                  {data.description}
                </Card.Body>
              </Card>
            )
          )}
        </Col>
      </Row>
    </>
  );
};

export default RoutesBreakdown;
