import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import FaqForm from './FaqForm';

import {
  showAdminFaqRequest,
  updateAdminFaqRequest
} from '../../../requests/admin/adminFaqs';
import { sendAlert } from '../../../actions/utils';

const FaqEdit = props => {
  const { history } = props;
  const [faq, setFaq] = useState({
    question: '',
    answer: '',
    language: '',
    faqCategoryId: 1,
    position: 1,
    active: true,
    faqCategoryType: 'tourism'
  });
  const dispatch = useDispatch();

  const handleSuccessShow = response => {
    const _faq = camelCaseRecursive(response.data);
    setFaq(_faq);
  };

  useEffect(() => {
    const { match } = props;
    const faqId = match.params.id;
    showAdminFaqRequest(faqId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });
  }, []);

  const handleGoback = () => {
    history.push({
      pathname: `/admin/${faq.faqCategoryType}_faqs_and_categories`,
      state: { type: 'FAQs' }
    });
  };

  const handleSuccessUpdate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Faq actualizada con éxito'
      })
    );
    handleGoback();
  };

  const handleUpdateRequest = _faq => {
    const faqId = _faq.faq.id;
    updateAdminFaqRequest(faqId, {
      dispatch,
      params: _faq,
      formData: true,
      successCallback: () => handleSuccessUpdate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="edit-faqs" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Editar FAQ</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <FaqForm faq={faq} action="edit" formRequest={handleUpdateRequest} />
    </div>
  );
};

export default connect()(FaqEdit);
