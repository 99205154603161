import React, { useState, useEffect } from 'react';
import { withFormik, Field, Form, getIn, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import EmailEditor from 'react-email-editor';
import snakecaseKeys from 'snakecase-keys';
import { debounceIndexAdminPagesRequest } from '../../../requests/admin/adminPages';
import { lowerCaseTextFormat, languages } from '../../../services/utils';
import {
  FormikInput,
  FormikSwitch,
  UploadImage,
  IcoMoon,
  FormikSelect
} from '../../../components';
import useFetchData from '../../../hooks/useFetchData';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
`;

const Bar = styled.div`
  flex: 1;
  background-color: white;
  color: #000;
  padding: 10px;
  display: flex;
  max-height: 40px;

  h1 {
    flex: 1;
    font-size: 16px;
    text-align: left;
  }
`;

const BlogForm = props => {
  const { action, blog, errors, touched, handleSubmit } = props;
  const btnMessage = action === 'new' ? 'Crear' : 'Editar';
  const { values, setFieldValue } = useFormikContext();
  const [editorRef, setEditorRef] = useState(null);
  const [parse, setParse] = useState(null);

  const [defaultBlog, setDefaultBlog] = useState(undefined);
  const { data: allBlogs } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPagesRequest,
    customParams: {
      sort_column: 'updated_at',
      sort_direction: 'desc',
      category: 'blog'
    }
  });

  const findDefaultBlog = () => {
    setDefaultBlog(
      allBlogs.find(selectedBlog => selectedBlog.id === blog.referencePageId)
    );
  };

  const filteredBlogs = allBlogs.filter(
    selectedBlog => selectedBlog.id !== blog.id
  );

  useEffect(findDefaultBlog, [allBlogs]);

  useEffect(() => {
    if (editorRef) {
      if (blog && blog.bodyJson) {
        setParse(JSON.parse(blog.bodyJson));
        editorRef.loadDesign(parse);
      } else {
        setParse(null);
        editorRef.loadBlank({
          backgroundColor: '#fff',
          contentWidth: '100%'
        });
      }
    }
  }, [blog, editorRef]);

  const exportHtmlAndSubmit = async () => {
    await new Promise(resolve => {
      editorRef.exportHtml(async data => {
        const { design, html } = data;
        setFieldValue('blog', {
          ...values.blog,
          body: html,
          bodyJson: JSON.stringify(design)
        });
        resolve();
      });
    });
    handleSubmit();
  };

  useEffect(() => {
    if (editorRef) {
      setEditorRef(editorRef);
    }
  }, [setEditorRef]);

  const handleFormatTextToLowerCase = e => {
    const formattedValue = lowerCaseTextFormat(e.target.value);
    setFieldValue(e.target.name, formattedValue);
  };

  if (action !== 'new' && (!blog || !blog.bodyJson)) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    );
  }

  return (
    <>
      <Form>
        <Row>
          <Col md={6} sm={12}>
            <Field name="blog[title]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Título"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} sm={12}>
            <Field name="blog[url]">
              {({ field }) => {
                return (
                  <FormikInput
                    {...field}
                    abbr
                    inputType="text"
                    label="URL"
                    placeholder="blog/"
                    onChange={handleFormatTextToLowerCase}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="blog[description]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="text"
                  label="Descripción"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Field name="blog[blogImage]">
          {({ field }) => (
            <UploadImage
              {...field}
              abbr
              modelType="blog"
              numberId="02"
              iconComponent={
                <div className="circle-image">
                  <IcoMoon icon="image1" size={20} />
                </div>
              }
              accept="image/jpg, image/png, image/jpeg"
              setFieldValue={setFieldValue}
              imageUrl={getIn(blog, 'image.fileUrl')}
              fileName={getIn(blog, 'image.filename')}
              onChange={images => setFieldValue('blog[blogImage]', images)}
              error={getIn(errors, 'blog[blogImage]')}
              touched={getIn(touched, 'blog[blogImage]')}
            />
          )}
        </Field>
        <Row>
          <Col md={4} sm={4}>
            <Field name="blog[highlighted]">
              {({ field }) => (
                <FormikSwitch
                  abbr
                  {...field}
                  field={field}
                  label="¿Página Destacada?"
                />
              )}
            </Field>
          </Col>
          <Col md={4} sm={4}>
            <Field name="blog[renderContactForm]">
              {({ field }) => (
                <FormikSwitch
                  abbr
                  {...field}
                  field={field}
                  label="¿Mostrar CTA predefinido?"
                />
              )}
            </Field>
          </Col>
          <Col md={4} sm={4}>
            <Field name="blog[active]">
              {({ field }) => (
                <FormikSwitch
                  abbr
                  {...field}
                  field={field}
                  label="¿Página activa?"
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <Field name="blog[publishedDate]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  inputType="date"
                  label="Fecha de publicación"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6} sm={12}>
            <Field name="blog[author]">
              {({ field }) => (
                <FormikInput
                  abbr
                  {...field}
                  inputType="text"
                  label="Autor"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col>
            <Field name="blog[language]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    abbr
                    {...field}
                    label="Idioma"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={Object.entries(languages).map(
                      ([value, label]) => ({
                        value,
                        label
                      })
                    )}
                    onChange={selectedOption => {
                      const selectedValue = selectedOption
                        ? selectedOption.value
                        : '';
                      setFieldValue(name, selectedValue);
                    }}
                    defaultValue={field.value}
                  />
                );
              }}
            </Field>
          </Col>
          <Col>
            <Field name="blog[referencePageId]">
              {({ field }) => {
                const { name } = field;
                return (
                  <FormikSelect
                    {...field}
                    inputType="text"
                    label="Versión en otro idioma"
                    error={getIn(errors, name)}
                    touched={getIn(touched, name)}
                    options={filteredBlogs}
                    defaultValue={defaultBlog?.value}
                    onChange={selectedBlog => {
                      setFieldValue('blog', {
                        ...values.blog,
                        referencePageId: selectedBlog.id
                      });
                    }}
                  />
                );
              }}
            </Field>
          </Col>
        </Row>
        <Row className="d-flex justify-content-end">
          <Col md={2} sm={12}>
            <Button
              variant="primary"
              className="w-100"
              onClick={exportHtmlAndSubmit}
            >
              {btnMessage}
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Container>
            <Bar>
              <EmailEditor
                ref={setEditorRef}
                minHeight={800}
                appearance={{ theme: 'light' }}
                features={{
                  colorPicker: {
                    presets: ['#aa182c', '#8f0b1d']
                  }
                }}
                tools={{
                  button: {
                    properties: {
                      buttonColors: {
                        value: {
                          color: '#FFFFFF',
                          backgroundColor: '#aa182c',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      },
                      borderRadius: {
                        value: '10px'
                      }
                    }
                  },
                  form: {
                    properties: {
                      fieldBorder: {
                        value: {
                          borderTopWidth: '1px',
                          borderTopStyle: 'solid',
                          borderTopColor: 'lightgray',
                          borderLeftWidth: '1px',
                          borderLeftStyle: 'solid',
                          borderLeftColor: 'lightgray',
                          borderRightWidth: '1px',
                          borderRightStyle: 'solid',
                          borderRightColor: 'lightgray',
                          borderBottomWidth: '1px',
                          borderBottomStyle: 'solid',
                          borderBottomColor: 'lightgray'
                        }
                      },
                      fieldBackgroundColor: {
                        value: '#FFFFFF'
                      },
                      fieldColor: {
                        value: '#494949'
                      },
                      buttonText: {
                        value: 'Enviar'
                      },
                      buttonColors: {
                        value: {
                          color: '#FFFFFF',
                          backgroundColor: '#aa182c',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      },
                      fieldBorderRadius: {
                        value: '10px'
                      },
                      buttonBorderRadius: {
                        value: '10px'
                      }
                    }
                  },
                  menu: {
                    properties: {
                      menuColors: {
                        value: {
                          color: '#aa182c',
                          backgroundColor: '#FFFFFF',
                          hoverColor: '#FFFFFF',
                          hoverBackgroundColor: '#8f0b1d'
                        }
                      }
                    }
                  },
                  heading: {
                    properties: {
                      headingColors: {
                        value: {
                          color: '#aa182c',
                          hoverColor: '#8f0b1d'
                        }
                      }
                    }
                  }
                }}
              />
            </Bar>
          </Container>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const {
    id,
    title,
    description,
    url,
    highlighted,
    publishedDate,
    author,
    active,
    body,
    language,
    referencePageId,
    bodyJson,
    image,
    renderContactForm
  } = props.blog;
  return {
    blog: {
      id,
      title,
      description,
      url,
      highlighted,
      publishedDate,
      author,
      active,
      body,
      language,
      referencePageId,
      bodyJson,
      blogImage: image,
      renderContactForm
    }
  };
};

const validationSchema = Yup.object().shape({
  blog: Yup.object().shape({
    title: Yup.string().required('El Título es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    blogImage: Yup.mixed().required('Debes adjuntar una imagen'),
    url: Yup.string().required('La url es requerida'),
    highlighted: Yup.boolean(),
    active: Yup.boolean(),
    renderContactForm: Yup.boolean(),
    body: Yup.string().required('El cuerpo es requerido'),
    bodyJson: Yup.string().required('El cuerpo es requerido'),
    publishedDate: Yup.date().required('La fecha es requerida'),
    author: Yup.string().required('El autor es requerido'),
    language: Yup.string().required('El idioma es requerido')
  })
});

const handleSubmit = (values, { props }) => {
  const { blogImage } = values.blog;
  const paramsToSend = {
    page: {
      ...snakecaseKeys({ ...values.blog }),
      category: 'blog'
    }
  };
  if (blogImage) paramsToSend.page.image = blogImage;
  const { formRequest } = props;
  formRequest(paramsToSend);
};

export default withFormik({
  mapPropsToValues: setInitialValues,
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(BlogForm);
