import React from 'react';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import memoize from 'memoize-one';

import { BasicActionBtns, ComponentDataTable } from '../../components';
import { useDestroyOne } from '../../hooks';
import { deleteSlideRequest } from '../../requests/admin/adminSlides';
import slideOptions from './slideOptions';

const columns = memoize(
  ({
    handleBasicActions,
    modelUrl,
    withShow = false,
    withEdit = false,
    withDestroy = false,
    showClick = false,
    editClick = false
  }) => [
    {
      name: 'Posición',
      selector: () => 'position',
      sortable: true,
      cell: ({ position }) => position,
      grow: '.5'
    },
    {
      name: 'Típo de Slide',
      selector: () => 'slide_type',
      sortable: true,
      cell: ({ slideType }) =>
        (slideOptions.find(option => option.value === slideType) || {}).label,
      grow: '1'
    },
    {
      name: 'Caption',
      selector: () => 'caption',
      sortable: true,
      cell: ({ caption }) => caption,
      grow: '2'
    },
    {
      name: 'Creación',
      selector: () => 'created_at',
      sortable: true,
      cell: ({ createdAt }) => createdAt,
      grow: '1'
    },
    {
      name: 'Activo',
      grow: '1',
      selector: () => 'active',
      sortable: true,
      cell: item => (item.active ? 'Sí' : 'No')
    },
    {
      cell: item => (
        <BasicActionBtns
          item={item}
          clickHandler={handleBasicActions}
          path={modelUrl}
          withShow={withShow}
          withEdit={withEdit}
          withDestroy={withDestroy}
          showClick={showClick}
          editClick={editClick}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
      grow: '1',
      minWidth: '150px'
    }
  ]
);

const SlideDataTable = ({
  tableData,
  tableDataAmount,
  isFetching,
  moreData,
  setMoreData,
  modelUrl,
  handleIndexRequest,
  categoryType
}) => {
  const history = useHistory();
  const { handleShowModal, OnDestroyModalComponent } = useDestroyOne({
    setMoreData,
    deleteOneRequest: deleteSlideRequest,
    onDeleteMessage: 'Slide Eliminado exitosamente',
    confirmationText: '¿Estás seguro de eliminar éste slide?',
    modalTitle: 'Eliminar slide'
  });

  const handleBasicActions = (item, action) => {
    switch (action) {
      case 'edit':
        history.push(
          `/admin/slides/${item.id}/edit?slideCategoryType=${categoryType}`
        );
        break;
      case 'destroy':
        handleShowModal(item);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    // Just for example of how to handle special sorts
    switch (name) {
      case 'caption':
        // sort_by_something = name of the scope in your model
        // return { sort_by_something: name };
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  return (
    <>
      <ComponentDataTable
        onRequest={isFetching}
        columns={columns({
          handleBasicActions,
          modelUrl,
          withShow: true,
          editClick: true,
          withDestroy: true
        })}
        handleSortCase={sortColumnCase} // how to handle special sorts, if not need it just dont sent it
        data={tableData}
        totalRows={tableDataAmount}
        moreData={moreData}
        resourceRequest={handleIndexRequest}
      />
      <OnDestroyModalComponent />
    </>
  );
};

export default withRouter(connect()(SlideDataTable));
