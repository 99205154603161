import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { LinkBtn } from '../../../components';
import BlogDataTable from './BlogDataTable';
import LanguageButtons from '../../../components/Utils/Button/LanguageButtons';

import { debounceIndexAdminPagesRequest } from '../../../requests/admin/adminPages';
import useFetchData from '../../../hooks/useFetchData';

const BlogIndex = () => {
  const [language, setLanguage] = useState('es');
  const [allowFetchRecords, setAllowFetchRecords] = useState(false);
  const [customParams, setCustomParams] = useState({
    sort_column: 'updated_at',
    sort_direction: 'desc',
    language,
    category: 'blog',
    display_length: 15
  });

  const {
    data: blogs,
    totalData: totalBlogs,
    isFetching,
    moreData,
    setMoreData,
    handleIndexRequest,
    error
  } = useFetchData({
    debouncedIndexRequest: debounceIndexAdminPagesRequest,
    customParams
  });

  const modelUrl = '/admin/blogs';
  const clearCustomParams = () => {
    setCustomParams({ language, category: 'blog', display_length: 15 });
  };
  const updateCustomParams = () => {
    setCustomParams({ language, category: 'blog', display_length: 15 });
    setAllowFetchRecords(true);
  };
  const fetchNewRecords = () => {
    if (allowFetchRecords) {
      setMoreData(!moreData);
      setAllowFetchRecords(false);
    }
  };

  useEffect(clearCustomParams, [isFetching]);
  useEffect(updateCustomParams, [language]);
  useEffect(fetchNewRecords, [customParams]);

  // Handle the error condition
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Row id="index-pages">
        <Col>
          <LanguageButtons
            language={language}
            setLanguage={setLanguage}
            includeFrench
          />
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="pages-and-blogs-btn"
            block
            to="/admin/blogs/templates"
          >
            Plantillas
          </LinkBtn>
        </Col>
        <Col md={2}>
          <LinkBtn
            variant="primary"
            className="pages-and-blogs-btn"
            block
            to="/admin/blogs/new"
          >
            Crear
          </LinkBtn>
        </Col>
      </Row>
      <BlogDataTable
        tableData={blogs}
        tableDataAmount={totalBlogs}
        isFetching={isFetching}
        handleIndexRequest={handleIndexRequest}
        moreData={moreData}
        modelUrl={modelUrl}
        setMoreData={setMoreData}
      />
    </>
  );
};

export default withRouter(connect()(BlogIndex));
