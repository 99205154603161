import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import VideoForm from './VideoForm';
import { createAdminVideoRequest } from '../../../requests/admin/adminVideos';
import { sendAlert } from '../../../actions/utils';

const VideoNew = props => {
  const { history } = props;
  const [video] = useState({
    title: '',
    description: '',
    link: '',
    active: true
  });
  const dispatch = useDispatch();

  const handleGoback = () => {
    history.push({
      pathname: '/admin/ebooks_and_videos',
      state: { type: 'Videos' }
    });
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Video creado con éxito'
      })
    );
    handleGoback();
  };

  const handleCreateRequest = _video => {
    createAdminVideoRequest({
      dispatch,
      params: _video,
      formData: true,
      successCallback: () => handleSuccessCreate()
    });
  };

  return (
    <div className="pt-4">
      <Row id="create-videos" className="mb-4">
        <Col>
          <h4 className="text-primary ml-4">Crear Video</h4>
        </Col>
        <Col md={2} className="d-flex justify-content-end pr-4">
          <Button variant="light" onClick={() => handleGoback()}>
            Volver
          </Button>
        </Col>
      </Row>
      <VideoForm video={video} action="new" formRequest={handleCreateRequest} />
    </div>
  );
};

export default connect()(VideoNew);
