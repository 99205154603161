export const AccordionItemsTourism = [
  {
    id: 101,
    itemTitle: 'Slides Hero',
    to: 'admin/tourism_slides_hero'
  },
  {
    id: 102,
    itemTitle: 'Landing',
    to: 'admin/landing'
  },
  {
    id: 103,
    itemTitle: 'Rutas & tarifas',
    to: 'admin/routes_and_included'
  },
  {
    id: 104,
    itemTitle: 'FAQs & Categorías',
    to: 'admin/tourism_faqs_and_categories'
  },
  {
    id: 105,
    itemTitle: 'Nuestros Ferries',
    to: 'admin/tourism_our_ferries'
  },
  {
    id: 106,
    itemTitle: 'Páginas & Blogs',
    to: 'admin/pages_and_blogs'
  },
  {
    id: 107,
    itemTitle: 'E-books & Videos',
    to: 'admin/ebooks_and_videos'
  },
  {
    id: 108,
    itemTitle: 'Galerías',
    to: 'admin/galleries'
  },
  {
    id: 109,
    itemTitle: 'Navbar & Footer',
    to: 'admin/navbar_and_footer'
  },
  {
    id: 110,
    itemTitle: 'Leads',
    to: 'admin/leads'
  }
];

export const AccordionItemsCargo = [
  {
    id: 201,
    itemTitle: 'Slides Hero',
    to: 'admin/cargo_slides_hero'
  },
  {
    id: 202,
    itemTitle: 'Rutas & tarifas',
    to: 'admin/cargo_routes_and_included'
  },
  {
    id: 203,
    itemTitle: 'Categorías & FAQs',
    to: 'admin/cargo_faqs_and_categories'
  },
  {
    id: 204,
    itemTitle: 'Configuraciones cargo',
    to: 'admin/settings_cargo'
  },
  {
    id: 205,
    itemTitle: 'Cargo Leads',
    to: 'admin/cargo_leads'
  },
  {
    id: 206,
    itemTitle: 'Navbar & Footer',
    to: 'admin/cargo_navbar_and_footer'
  },
  {
    id: 207,
    itemTitle: 'Asesores',
    to: 'admin/advisors'
  }
];

export const AccordionItemsAdmin = [
  {
    id: 301,
    itemTitle: 'Usuarios',
    to: 'admin/users'
  },
  {
    id: 302,
    itemTitle: 'Nuestros Líderes',
    to: 'admin/our_leaders'
  },
  {
    id: 303,
    itemTitle: 'Configuraciones',
    to: 'admin/settings'
  }
];
