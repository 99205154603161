import React from 'react';
import { useFormikContext } from 'formik';
import DaysItineraryDescriptionsForm from './DaysItineraryDescriptionsForm';

import NestedForm from '../Utils/NestedForm';
import daysItineraryDescription from './daysItineraryDescription';

const DaysItineraryDescriptionsAttributes = ({
  modelName,
  route,
  shipDirection
}) => {
  const { setFieldValue, values } = useFormikContext();
  const daysItineraryDescriptionsAttributes =
    values?.route?.daysItineraryDescriptionsAttributes;

  const mapResults = daysItineraryDescriptionsAttributes.map((body, index) => {
    if (body._destroy || body.shipDirection !== shipDirection) {
      return undefined;
    }

    return (
      <DaysItineraryDescriptionsForm
        key={`days-${index.toString()}`}
        index={index}
        parentModel={modelName}
        forNestedAttributes
        route={route}
        shipDirection={shipDirection}
        dayNumber={body.position}
      />
    );
  });

  return (
    <NestedForm
      attributesTitle="Descripción de los dias"
      mapInputs={mapResults}
      arrayValues={daysItineraryDescriptionsAttributes}
      setFieldValue={setFieldValue}
      valuePath={`${modelName}[daysItineraryDescriptionsAttributes]`}
      newAttributes={{ ...daysItineraryDescription, shipDirection }}
      addTitle="Agregar descripción del día"
    />
  );
};

export default DaysItineraryDescriptionsAttributes;
