/* eslint-disable react/no-array-index-key */
import 'video-react/dist/video-react.css';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import indexVideosRequest from '../../requests/videos';
import '../../assets/stylesheets/components/videos/VideosGrid.scss';
import VideoPlayer from './VideoPlayer';

const VideosGrid = ({ numOfVideos, customParams }) => {
  const [videos, setVideos] = useState([]);
  const dispatch = useDispatch();
  const language = localStorage.getItem('language') || 'es';

  const videosRequest = () => {
    indexVideosRequest({
      dispatch,
      params: {
        ...customParams,
        active: true,
        sort_column: 'position',
        sort_direction: 'asc',
        display_length: numOfVideos,
        language
      },
      successCallback: result => {
        setVideos(camelCaseRecursive(result.data.data));
      }
    });
  };

  useEffect(videosRequest, [customParams]);

  return (
    <section className="videos-section">
      {videos.map((video, index) => (
        <div key={`card-${index}`}>
          <VideoPlayer src={video.video.fileUrl} />
          <div className="video-info">
            <p className="video-title">{video.title}</p>
            <p className="video-description">{video.description}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default VideosGrid;
